import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { Person } from '@app/modules/person/person.model'
import { PersonFormService } from '@app/modules/person/person-form.service'

@Component({
  selector: 'app-existing-people-form',
  templateUrl: './existing-people-form.component.html'
})

export class ExistingPeopleFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Contacts
   */
  @Input() existingPeopleForm: FormGroup

  /**
   * Main form change
   */
  @Output() existingPeopleFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Constructor
   */
  public constructor (
    public personFormService: PersonFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    if (this.existingPeopleForm) {
      this.display = true
    }
  }

  /**
   * Person search result formatter
   *
   * Converts the object into a readable string.
   */
  public formatPersonResult: any = (item: any): any => {
    const strParts: Array<any> = []
    const name: Array<any> = []

    // if (!this.myUtil.isEmpty(x.title)) {
    //   name.push(x.title.name)
    // }

    if (item.firstName) {
      name.push(item.firstName)
    }

    if (item.lastName) {
      name.push(item.lastName)
    }

    if (name.length > 0) {
      strParts.push(name.join(' '))
    }

    if (item.companies && item.companies.length > 0) {
      strParts.push(item.companies[0].name)
    }

    return strParts.join(', ')
  }

  /**
   * Remove existing contact
   *
   * @param event  Event
   * @param person Person
   * @param index  index
   */
  public removeExistingContact (event: Event, person: Person, index: number): void {
    event.preventDefault()
    event.stopPropagation()
    if (person) {}
    if (index) {}

    const control: FormArray = this.existingPeopleForm.get('contacts') as FormArray

    // Mark it for delete if it has an id.
    if (person.uuid) {
      let deleteControl: FormArray
      deleteControl = this.existingPeopleForm.get('contactsDeleted') as FormArray
      deleteControl.push(this.personFormService.getForm(new Person(person), ''))
    }

    if (control) {
      control.removeAt(index)
    }
  }
}
