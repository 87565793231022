import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-unallocated-email-recipients-display',
  templateUrl: './unallocated-email-recipients-display.component.html',
  providers: []
})

export class UnallocatedEmailRecipientsDisplayComponent implements OnInit, OnDestroy {

  /**
   * Recipients
   */
  @Input() recipients: any

  @Input() to: Array<any>

  /**
   * Recipients
   */
  public items: Array<any>

  public unmatchedRecipients: boolean
  /**
   * Display
   */
  public display: boolean

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * First item
   */
  public firstItem: any

  /**
   * Constructor
   */
  public constructor () {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    this.display = false
    this.items = []

    this.unmatchedRecipients = false

    if (this.recipients) {
      const items: Array<any> = []
      const recipients: Array<any> = this.recipients.split(',')

      recipients.map((recipient) => {
        const sp: Array<any> = recipient.split('<')
        const name: string = sp[0].trim()
        const email: string = sp[1].replace('>', '').trim()
        let matched: boolean
        matched = false

        this.to.map((to) => {
          to.emailAddresses.map((emailAddress) => {
            if (emailAddress.email.trim() === email) {
              matched = true
            }
          })
        })

        if (!matched) {
          this.unmatchedRecipients = true
        }

        items.push({
          name: name,
          email: email,
          matched: matched
        })
      })

      items.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      this.items = items
    }

    if (this.items.length > 0) {
      this.firstItem = this.items[0]
      this.display = true
    }
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

}
