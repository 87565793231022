import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from '@app/modules/alert/alert.service'
import { EventService } from '@app/shared/services/event.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ValidationService } from '@app/shared/services/validation.service'
import { Company } from '@app/modules/company/company.model'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { OpportunityService } from '@app/modules/opportunity/opportunity.service'
import { OpportunityFormService } from '@app/modules/opportunity/opportunity-form.service'

@Component({
  selector: 'app-tab-opportunities-table',
  templateUrl: './tab-opportunities-table.component.html'
})

export class TabOpportunitiesTableComponent implements OnInit, OnDestroy {

  /**
   * Opportunities
   */
  @Input() results: Array<any>

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Company
   *
   * We cant include company in the form because we'll get circular deps.
   */
  @Input() company: Company

  /**
   * Show new
   */
  @Input() showNew: boolean

  /**
   * Person form
   */
  public opportunityForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    private alertService: AlertService,
    private eventService: EventService,
    private modalService: NgbModal,
    public validationService: ValidationService,
    private opportunityFormService: OpportunityFormService,
    private opportunityService: OpportunityService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    let opportunity: Opportunity
    switch (type) {
      case 'editOpportunity':

        this.getOpportunity(data.uuid)
        .then((result) => {
          console.log(result)
          opportunity = new Opportunity(result)
          this.opportunityForm = this.opportunityFormService.getForm(opportunity, 'edit')
          this.currentModal = this.modalService.open(content, {
            size: 'lg',
            windowClass: 'fade modal-xl',
            keyboard: false
          })
        })
        .catch((err) => {
          this.alertService.error('Error: ' + err)
        })
        break
      case 'newOpportunity':
        // @todo Where opened from... assume company for now.
        //  Because if it's company we'll set company if it's person we set person etc.
        opportunity = new Opportunity({
          companyUuid: this.mainForm.value.uuid
          // company: new Company(this.mainForm.value.uuid)
        })

        this.opportunityForm = this.opportunityFormService.getForm(opportunity, 'new')
        break
      default:
        return
    }
  }

  /**
   * Get opportunity
   *
   * This needs to match the form on edit opportunity
   */
  public getOpportunity (uuid: string): Promise<any> {
    return this.opportunityService.api.getOne(uuid, {
      includes: [
        'salesPersonUser',
        'opportunityAgreements',
        'productServices',
        'company',
        'company.addresses',
        'company.addresses.country',
        'site',
        'site.address',
        'site.address.country',
        'tasks',
        'tasks.taskType',
        'tasks.organiserUser',
        'shutdown',
        'shutdown.person',
        'probability',
        'contacts',
        'contacts.companies',
        'contacts.companies.addresses',
        'contacts.companies.addresses.country',
        'contacts.telephones',
        'contacts.telephones.telephoneName',
        'contacts.emailAddresses',
        'contacts.emailAddresses.emailAddressName'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save opportunity
   */
  public saveOpportunity (): void {
    console.log('saveOpportunity', this.opportunityForm.value)
    this.validationService.runValidation(this.opportunityForm)

    if (!this.opportunityForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.opportunityForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.opportunityService.saveOpportunity(this.opportunityForm.value)
    .then((result) => {
      const control: FormArray = this.mainForm.get('opportunities') as FormArray
      const index: number = control.value.findIndex(x => x.uuid === result.uuid)

      if (index !== -1) {
        control.removeAt(index)
      }

      const opportunityForm: FormGroup = this.opportunityFormService.getForm(new Opportunity(result), '')
      control.push(opportunityForm)

      this.alertService.success('Saved: ' + opportunityForm.value.name, 10000)
      this.currentModal.close()
      this.eventService.events.emit({
        name: 'OPPORTUNITY.saved',
        from: 'TabOpportunitiesTableComponent.saveOpportunity',
        desc: 'Saved opportunity.',
        data: { opportunityUuid: result.uuid }
      })
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Delete opportunity
   */
  public deleteOpportunity (uuid: string): void {
    this.opportunityService.api.delete(uuid)
    .toPromise()
    .then(() => {
      this.alertService.success('Deleted: ' + this.opportunityForm.value.uuid, 10000)
      this.currentModal.close()
      this.eventService.events.emit({
        name: 'OPPORTUNITY.deleted',
        from: 'TabOpportunitiesTableComponent.deleteOpportunity',
        desc: 'Deleted opportunity.',
        data: { opportunityUuid: uuid }
      })
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

}
