// Angular
import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
// Other
import * as moment from 'moment'
// Services.
import { AppService } from '@app/shared/services/app.service'
// Service models.
import { LoginService } from '@app/modules/login/login.service'
import { UserService } from '@app/modules/user/user.service'
import { ValidationService } from '@app/shared/services/validation.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {

  /**
   * Ready
   */
  public display: boolean

  /**
   * Login form group
   */
  public loginForm: FormGroup

  /**
   * Return url
   */
  public returnUrl: string

  /**
   * Copyright year
   */
  public copyrightYear: string

  /**
   * Error message
   */
  public errorMsg: string

  /**
   * Constructor
   */
  public constructor (
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public appService: AppService,
    public loginService: LoginService,
    public userService: UserService,
    public validationService: ValidationService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl']
      }
    })

  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.loginForm = this.loginService.getForm()

    this.copyrightYear = moment().local().format('YYYY')

    this.display = true
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On submit
   */
  public onSubmit (): void {
    this.validationService.runValidation(this.loginForm)
    if (!this.loginForm.valid) {
      this.errorMsg = 'Please enter the required fields.'
      return
    }

    const data: any = this.loginForm.value

    this.appService.authService.login(data)
    .then((result) => {
      const token: any = this.appService.authService.decodeToken(result['token'])

      console.log(token)
      // this.errorMsg = 'Login failed. (Error: Could not get valid api key)';

      this.userService.api.getOne(token['sub'], {
        includes: [
          'roles'
        ]
      })
      .toPromise()
      .then((user) => {
        this.appService.setCurrentUser(user)

        if (this.returnUrl) {
          this.router.navigate([this.returnUrl])
        }

        if (!this.returnUrl) {
          this.router.navigate(['/dashboard'])
        }
      })
      .catch((err) => {
        this.errorMsg = 'Login failed. (Error: Could not find user)'
        console.error(err)
      })
    })
    .catch((err) => {
      console.error(err)
      switch (err.code) {
        case 401:
          this.errorMsg = 'Incorrect details.'
          break
        case 400:
          this.errorMsg = 'Please enter the required fields.'
          break
        default:
          this.errorMsg = 'Login failed.'
      }
    })
  }

  /**
   * Mark form group touched
   *
   * Marks all controls in a form group as touched.
   *
   * @param formGroup Form group
   */
  public markFormGroupTouched (formGroup: FormGroup): void {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched()
      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c))
      }
    })
  }
}
