import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { Subscription } from 'rxjs'
import { FileNavigatorService, FmService } from '../../services'

@Component({
  selector: 'app-fm-folder-branch-item',
  templateUrl: './fm-folder-branch-item.component.html',
  providers: []
})

export class FmFolderBranchItemComponent implements OnInit, OnDestroy {

  /**
   * FmItem
   *
   * @type {FmItem}
   */
  @Input() item: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   *
   * @param {AlertService} private alertService [description]
   */
  public constructor (
    public fileNavigatorService: FileNavigatorService,
    public fmService: FmService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {

  }

}
