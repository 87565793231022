import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { Observable } from 'rxjs'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

@Injectable()

export class PersonApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/people'
  }

  // ---------------------------------------------------------------------------
  // Companies (child through pivot)
  // ---------------------------------------------------------------------------

  /**
   * Get company
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   * @param params     Params
   */
  public getCompany (parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'companies', childUuid, params)
  }

  /**
   * Get companies
   *
   * @param parentUuid Parent primary key
   * @param params     Params
   */
  public getCompanies (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'companies', params)
  }

  /**
   * Attach company
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public attachCompany (parentUuid: string, childUuid: string): Observable<any> {
    return this.attachChild(parentUuid, 'companies', childUuid)
  }

  /**
   * Detatach company
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public detachCompany (parentUuid: string, childUuid: string): Observable<any> {
    return this.detachChild(parentUuid, 'companies', childUuid)
  }

  /**
   * Sync companies
   *
   * @param parentUuid   Parent primary key
   * @param childUuids   Child primary key
   * @param detachOthers Detach others not in childUuids array
   */
  public syncCompanies (parentUuid: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    return this.syncChildren(parentUuid, 'companies', childUuids, detachOthers)
  }

  // ---------------------------------------------------------------------------
  // Persons addresses
  // ---------------------------------------------------------------------------

  /**
   * Save address
   *
   * @param uuid      Parent primary key
   * @param childData Child data
   *
   */
  public saveAddress (uuid: string, childData: Object): Observable<any> {
    return this.saveChild(uuid, 'addresses', childData)
  }

  /**
   * Delete address
   *
   * @param uuid    Parent primary key
   * @param childUuid Child primary key
   *
   */
  public deleteAddress (uuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(uuid, 'addresses', childUuid)
  }

  /**
   * Destroy address
   *
   * @param uuid      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyAddress (uuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(uuid, 'addresses', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Persons email addresses
  // ---------------------------------------------------------------------------

  /**
   * Save email address
   *
   * @param parentUuid  Parent primary key
   * @param childData Child data
   *
   */
  public saveEmailAddress (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'email-addresses', childData)
  }

  /**
   * Delete address
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteEmailAddress (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'email-addresses', childUuid)
  }

  /**
   * Destroy address
   *
   * @param id        Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyEmailAddress (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'email-addresses', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Persons social accounts
  // ---------------------------------------------------------------------------

  /**
   * Save social
   *
   * @param parentUuid Parent primary key
   * @param childData  Child data
   *
   */
  public saveSocial (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'socials', childData)
  }

  /**
   * Delete social
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteSocial (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'socials', childUuid)
  }

  /**
   * Destroy social
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public destroySocial (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'socials', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Persons telephone numbers
  // ---------------------------------------------------------------------------

  /**
   * Save telephone
   *
   * @param parentUuid Parent primary key
   * @param childData  Child data
   *
   */
  public saveTelephone (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'telephones', childData)
  }

  /**
   * Delete telephone
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteTelephone (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'telephones', childUuid)
  }

  /**
   * Destroy telephone
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public destroyTelephone (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'telephones', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Persons uri's
  // ---------------------------------------------------------------------------

  /**
   * Save uri
   *
   * @param parentUuid Parent primary key
   * @param childData  Child data
   *
   */
  public saveUri (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'uris', childData)
  }

  /**
   * Delete uri
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteUri (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'uris', childUuid)
  }

  /**
   * Destroy uri
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public destroyUri (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'uris', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Opportunities
  // ---------------------------------------------------------------------------

  /**
   * Get opportunities
   *
   * @param parentUuid Parent primary key
   * @param params     Params
   */
  public getOpportunities (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'opportunities', params)
  }
}
