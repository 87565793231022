// Angular
import { Router } from '@angular/router'
import { Component, Input, OnInit, OnDestroy } from '@angular/core'
// Services.
import { AppService } from '@app/shared/services/app.service'
// import { Opportunity } from '@app/modules/opportunity/opportunity.model'

@Component({
  selector: 'app-tab-documents-table',
  templateUrl: './tab-documents-table.component.html'
})

export class TabDocumentsTableComponent implements OnInit, OnDestroy {

  /**
   * Results
   */
  @Input() results: Array<any>

  /**
   * Display page
   */
  public display: boolean

  public storages: Array<any> = []
  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    // Services.
    public appService: AppService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

}
