import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core'

// declare var $: any

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html',
  styleUrls: ['./blank-layout.component.css']
})

export class BlankLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor () { }

  /**
   * Ng on init
   */
  public ngOnInit (): void {}

  /**
   * Ng after view init
   */
  public ngAfterViewInit (): void {
    // $('body').addClass('gray-bg')
  }

  /**
   * Ng on destroy
   */
  public ngOnDestroy (): void {
    // $('body').removeClass('gray-bg')
  }

}
