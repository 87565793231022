// Angular
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
// Form services.
import { CompanyFormService } from '@app/modules/company/company-form.service'
import { DeleteIdFormService } from '@app/modules/delete-id/delete-id-form.service'
// Models
import { Company } from '@app/modules/company/company.model'

@Component({
  selector: 'app-company-finder-form-array',
  templateUrl: './company-finder-form-array.component.html'
})

export class CompanyFinderFormArrayComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private companyFormService: CompanyFormService,
    private deleteIdFormService: DeleteIdFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.mainForm.addControl('companies', this.formBuilder.array([]))
  }

  /**
   * Add
   */
  public add (): void {
    const control: FormArray = this.mainForm.get('companies') as FormArray
    const company: Company = new Company()
    const form: FormGroup = this.companyFormService.getForm(company, 'new')
    control.push(form)
  }

  /**
   * Remove
   */
  public remove (item: FormGroup): void {
    const control: FormArray = this.mainForm.get('companies') as FormArray
    const index: number = control.controls.findIndex(x => x === item)
    const company: Company = new Company(item.value)

    if (company.uuid) {
      // Mark it for delete.
      const deleted: FormArray = this.mainForm.get('companiesDeleted') as FormArray
      deleted.push(this.deleteIdFormService.getForm({ uuid: company.uuid }))
    }

    control.removeAt(index)
  }

}
