import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpService } from '@app/shared/services/http.service'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

@Injectable()

export class TaskApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/tasks'
  }

  /**
   * Attach contact
   */
  public attachScheduledWith(parentUuid: string, childUuid: string, data?: Object): Observable<any> {
    return this.attachChild(parentUuid, 'scheduled-with', childUuid, data)
  }

  /**
   * Detatach contact
   */
  public detachScheduledWith(parentUuid: string, childUuid: string): Observable<any> {
    return this.detachChild(parentUuid, 'scheduled-with', childUuid)
  }

  /**
   * Sync contacts
   */
  public syncScheduledWith(parentUuid: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    return this.syncChildren(parentUuid, 'scheduled-with', childUuids, detachOthers)
  }

}
