import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { ConfigService, FileNavigatorService, FmService } from '../../services'

@Component({
  selector: 'app-fm-main-icons',
  templateUrl: './fm-main-icons.component.html'
})

export class FmMainIconsComponent implements OnInit, OnDestroy {

  /**
   * Display
   */
  public display: boolean

  /**
   * Config
   */
  public config: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public configService: ConfigService,
    public fileNavigatorService: FileNavigatorService,
    public fmService: FmService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.config = this.configService.config
    this.display = true
  }

}
