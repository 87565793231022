import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { Observable, Subject } from 'rxjs'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModalComponent } from '@app/shared/components/confirm-modal/confirm-modal.component'

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>
}

@Injectable()

export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Close result
   */
  public closeResult: Subject<boolean> = new Subject()

  /**
   * Constructor
   */
  constructor (
    private modalService: NgbModal
  ) {}

  /**
   * Can deactivate
   */
  public canDeactivate (component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ? true : this.openModal()
    // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    // see http://stackoverflow.com/a/42207299/7307355
  }

  /**
   * Open modal
   */
  public openModal (): Observable<boolean> {
    this.currentModal = this.modalService.open(ConfirmModalComponent, {
      windowClass: 'fade',
      keyboard: false
    })

    this.currentModal
    .result
    .then((closeResult) => {
      this.closeResult.next(closeResult)
    },
    () => {
      this.closeResult.next(false)
    })

    return this.closeResult
  }

}
