import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { LocalStorageService } from 'angular-2-local-storage'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { AddressService } from '@app/modules/address/address.service'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { OpportunityApiService } from '@app/modules/opportunity/services/opportunity-api.service'
import { OpportunityAgreement } from '@app/modules/opportunity-agreement/opportunity-agreement.model'
import { Person } from '@app/modules/person/person.model'
import { PersonService } from '@app/modules/person/person.service'
import { Address } from '@app/modules/address/address.model'
import { Company } from '@app/modules/company/company.model'
import { EmailAddress } from '@app/modules/email-address/email-address.model'
import { Social } from '@app/modules/social/social.model'
import { Telephone } from '@app/modules/telephone/telephone.model'
import { Uri } from '@app/modules/uri/uri.model'
import { DeleteId } from '@app/modules/delete-id/delete-id.model'

@Injectable()

export class OpportunityService {

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0,
    itemIds: []
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'opportunity.updatedAt',
    direction: 'DESC'
  }

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private myUtil: MyUtilityService,
    public api: OpportunityApiService,
    public addressService: AddressService,
    public personService: PersonService
  ) {}

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      proposalNo: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      jobNo: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      name: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      company: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      companyUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityStageUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityStatusUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      finalInvoice: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      finalInvoiceOperator: [
        'eq',
        [
          Validators.maxLength(3)
        ]
      ],
      salesPersonUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      probabilityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })
    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public search (searchFilters: any | Object): Promise<any> {
    const filters: Array<any> = []

    if (!this.myUtil.isEmpty(searchFilters.proposalNo)) {
      filters.push({
        key: 'proposalNo',
        value: searchFilters.proposalNo,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.jobNo)) {
      filters.push({
        key: 'jobNo',
        value: searchFilters.jobNo,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.name)) {
      filters.push({
        key: 'name',
        value: searchFilters.name,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.company)) {
      filters.push({
        key: 'company.name',
        value: searchFilters.company,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.companyUuid)) {
      filters.push({
        key: 'companyUuid',
        value: searchFilters.companyUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.opportunityStatusUuid)) {
      filters.push({
        key: 'opportunityStatusUuid',
        value: searchFilters.opportunityStatusUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.opportunityStageUuid)) {
      filters.push({
        key: 'opportunityStageUuid',
        value: searchFilters.opportunityStageUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.finalInvoice)) {
      filters.push({
        key: 'finalInvoice',
        value: searchFilters.finalInvoice,
        operator: searchFilters.finalInvoiceOperator
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.salesPersonUserUuid)) {
      filters.push({
        key: 'salesPersonUserUuid',
        value: searchFilters.salesPersonUserUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.probabilityUuid)) {
      filters.push({
        key: 'probabilityUuid',
        value: searchFilters.probabilityUuid,
        operator: 'eq'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: false,
          filters: filters
        }
      )
    }

    const params: any = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'opportunity.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // this.setItemIds(totalResults)

      // Add pagination and includes for result set
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        'salesPersonUser',
        'productServices',
        'company',
        'opportunityStatus',
        'opportunityStage',
        'probability'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Set item ids
   *
   * Store the oportunity ids so we can retrive them from the edit view
   * for prev/next.
   */
  public setItemIds (totalResults: Array<any>): void {
    const itemIds: Array<number> = []
    totalResults.map((item) => {
      itemIds.push(item.uuid)
    })

    this.localStorageService.set('opportunityItemIds', itemIds)

    this.pagination.itemIds = itemIds
  }

  /**
   * Get mini nav
   */
  public getMiniNav (currentUuId: string): any {
    const miniNav: any = {
      prevId: null,
      current: currentUuId,
      nextId: null,
      total: null
    }

    if (this.pagination.itemIds.length === 0) {
      const itemIds: any = this.localStorageService.get('opportunityItemIds')
      if (itemIds !== null) {
        this.pagination.itemIds = itemIds
      }
    }

    miniNav.total = this.pagination.itemIds.length

    let i: number
    i = 0

    this.pagination.itemIds.map((item) => {
      if (item === currentUuId && !this.myUtil.isEmpty(this.pagination.itemIds[i + 1])) {
        miniNav.nextId = this.pagination.itemIds[i + 1]
      }
      if (item === currentUuId) {
        miniNav.current = (i + 1)
      }

      if (item === currentUuId && !this.myUtil.isEmpty(this.pagination.itemIds[i - 1])) {
        miniNav.prevId = this.pagination.itemIds[i - 1]
      }
      i++
    })

    return miniNav
  }

  /**
   * Save opportunity
   */
  public saveOpportunity (opportunity: Opportunity): Promise<any> {
    console.log('opportunityService.saveOpportunity() big', opportunity)

    // Save the person.
    return this.api.save(opportunity)
    .toPromise()
    .then((opportunityResult) => {
      const opportunityUuid: string = opportunityResult.uuid
      const promises: Array<Promise<any>> = []

      if (opportunity.opportunityAgreements) {
        opportunity.opportunityAgreements.map((opportunityAgreement: OpportunityAgreement) => {
          opportunityAgreement.opportunityUuid = opportunityUuid
          const promise: Promise<any> = this.api.saveOpportunityAgreement(opportunityUuid, opportunityAgreement)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (opportunity.shutdown &&
        (opportunity.shutdown.chaseDate ||
        opportunity.shutdown.shutdownDate ||
        opportunity.shutdown.personUuid ||
        opportunity.shutdown.uuid)) {
        const promise: Promise<any> = this.api.saveShutdown(opportunityUuid, opportunity.shutdown)
        .toPromise()
        .then((result) => {
          // opportunity.shutdownUuid = result.uuid
          return result
        })
        .catch((err) => {
          throw err
        })

        promises.push(promise)
      }

      // if (person.addressesDeleted) {
      //   person.addressesDeleted.map((del: DeleteId) => {
      //     const promise: Promise<any> = this.api.deleteAddress(personUuid, del.uuid)
      //     .toPromise()
      //     .then((result) => {
      //       return result
      //     })
      //     .catch((err) => {
      //       throw err
      //     })
      //     promises.push(promise)
      //   })
      // }

      // Save & or Delete the persons addresses, companies, emailAddresses, socials, telephones, uris
      return Promise.all(promises)
      .then(() => {
        // Get the new/updated person.
        return opportunityResult
        // return this.getEmployee(personUuid)
        // .then((result) => {
        //   return result
        // })
        // .catch((err) => {
        //   throw err
        // })
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save person
   */
  public saveContact (opportunityUuid: string, person: Person): Promise<any> {
    return this.api.saveContact(opportunityUuid, person)
    .toPromise()
    .then((personResult) => {
      console.log('personResult', personResult)

      const personUuid: string = personResult.uuid
      const promises: Array<Promise<any>> = []

      if (person.addresses) {
        person.addresses.map((address: Address) => {
          const promise: Promise<any> = this.personService.api.saveAddress(personUuid, address)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.addressesDeleted) {
        person.addressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteAddress(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.companies) {
        const uuids: Array<any> = []
        person.companies.map((company: Company) => {
          uuids.push(company.uuid)
        })
        // Detach others fales no deletions here.
        const promise: Promise<any> = this.personService.api.syncCompanies(personUuid, uuids, false)
        .toPromise()
        .then((result) => {
          return result
        })
        .catch((err) => {
          throw err
        })

        promises.push(promise)
      }

      if (person.emailAddresses) {
        person.emailAddresses.map((emailAddress: EmailAddress) => {
          const promise: Promise<any> = this.personService.api.saveEmailAddress(personUuid, emailAddress)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.emailAddressesDeleted) {
        person.emailAddressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteEmailAddress(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.socials) {
        person.socials.map((social: Social) => {
          const promise: Promise<any> = this.personService.api.saveSocial(personUuid, social)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.socialsDeleted) {
        person.socialsDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteSocial(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.telephones) {
        person.telephones.map((telephone: Telephone) => {
          const promise: Promise<any> = this.personService.api.saveTelephone(personUuid, telephone)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.telephonesDeleted) {
        person.telephonesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteTelephone(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.uris) {
        person.uris.map((uri: Uri) => {
          const promise: Promise<any> = this.personService.api.saveUri(personUuid, uri)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.urisDeleted) {
        person.urisDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteUri(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      // Save & or Delete the persons addresses, companies, emailAddresses, socials, telephones, uris
      return Promise.all(promises)
      .then(() => {
        return personResult
      })
      .catch((err) => {
        throw err
      })

    })
  }

  /**
   * Remove contact
   */
  public attachContact (opportunityUuid: string, personUuid: string): Promise<any> {
    return this.api.attachContact(opportunityUuid, personUuid)
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })

  }

  /**
   * Remove contact
   */
  public detachContact (opportunityUuid: string, personUuid: string): Promise<any> {
    return this.api.detachContact(opportunityUuid, personUuid)
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })

  }

  /**
   * Delete contact
   */
  public deleteContact (opportunityUuid: string, personUuid: string): Promise<any> {
    return this.api.deleteContact(opportunityUuid, personUuid)
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

}
