import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { UserService } from '@app/modules/user/user.service'
import { UserFormService } from '@app/modules/user/user-form.service'

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})

export class EditUserComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  public mainForm: FormGroup

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public appService: AppService,
    private alertService: AlertService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public routingStateService: RoutingStateService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    public myUtil: MyUtilityService,
    public userService: UserService,
    public userFormService: UserFormService
  ) {
    this.selectOptionsService.init(false)
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.routeSub()
  }

  /**
   * Route sub
   */
  public routeSub (): void {
    const sub: Subscription = this.activatedRoute
    .params
    .subscribe((routeParams) => {
      this.userService.api.getOne(routeParams['uuid'], {
      })
      .toPromise()
      .then((result) => {
        this.mainForm = this.userFormService.getForm(result, 'edit')
        // this.watchMainFormSub()
        this.display = true
      })
      .catch((err) => {
        console.error(err)
      })
    })

    this.subscriptions.push(sub)
  }

  /**
   * Watch main form sub
   */
  // public watchMainFormSub (): void {
  //   const sub: Subscription = this.mainForm
  //   .valueChanges.pipe(
  //     debounceTime(800),
  //     distinctUntilChanged()
  //   )
  //   .subscribe((data) => {
  //     if (!this.mainForm.valid) {
  //       return
  //     }
  //     this.saveUser(data)
  //     // .patchValue({ uuid: result.uuid, createdAt: result.createdAt, updatedAt: result.updatedAt },
  //   })

  //   this.subscriptions.push(sub)
  // }

  /**
   * Save task
   */
  public saveUser (): void {
    console.log('saveUser', this.mainForm.value)
    this.validationService.runValidation(this.mainForm)

    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.userService.api.save(this.mainForm.value)
    .toPromise()
    .then((result) => {
      console.log('user save', result)
      this.mainForm.patchValue(result, { emitEvent: true })
      this.alertService.success('Saved: ' + (this.mainForm.value.firstName ? this.mainForm.value.firstName : '') +
      ' ' + (this.mainForm.value.lastName ? this.mainForm.value.lastName : ''), 4000)
      this.mainForm.markAsPristine()

      // Get the updated task.
      // this.getTask(result.uuid)
      // .then((newResult: Task) => {
      //   this.alertService.success('Saved: ' + this.mainForm.value.regarding, 4000)
      // })
      // .catch((err) => {
      //   console.error(err)
      // })
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Delete user
   */
  public deleteUser (userUuid: string): void {
    console.log('deleteUser', userUuid)
    this.userService.api.delete(userUuid)
    .toPromise()
    .then((result) => {
      console.log('result', result)
      this.router.navigate(['/admin', 'users'])
    })
    .catch((err) => {
      console.error(err)
    })
  }

}
