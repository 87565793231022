import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { UserApiService } from '@app/modules/user/user-api.service'

@Injectable()

export class UserService {

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'username',
    direction: 'ASC'
  }

  /**
   * Constructor
   */
  public constructor (
    // Angular.
    public formBuilder: FormBuilder,
    public api: UserApiService
  ) {}

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      username: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      email: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      firstName: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      lastName: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      fullName: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      roleId: [
        null,
        [
          ValidationService.isNumeric
        ]
      ]
    })

    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public search (searchFilters: any | Object): Promise<any> {
    console.log('searchFilters', searchFilters)
    const filters: Array<any> = []

    if (searchFilters.username) {
      filters.push({
        key: 'username',
        value: searchFilters.username,
        operator: 'ct'
      })
    }

    if (searchFilters.email) {
      filters.push({
        key: 'email',
        value: searchFilters.email,
        operator: 'ct'
      })
    }

    if (searchFilters.fullName) {
      filters.push({
        key: 'fullName',
        value: searchFilters.fullName,
        operator: 'ct'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: true,
          filters: filters
        }
      )
    }

    const params: any | Object = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'user.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // Add pagination and includes for result set.
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        'person',
        'roles'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      console.error(err)
    })

  }

}
