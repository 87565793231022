import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { UserService } from '@app/modules/user/user.service'
import { RoleApiService } from '@app/modules/role/role-api.service'

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html'
})

export class UserRolesComponent implements OnInit, OnDestroy {

  /**
   * User primary key
   */
  @Input() userUuid: string

  /**
   * Display page
   */
  public display: boolean

  /**
   * Roles assigned
   */
  public rolesAssigned: Array<any> = []

  /**
   * Roles available
   */
  public rolesAvailable: Array<any> = []

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public appService: AppService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public userService: UserService,
    public roleApiService: RoleApiService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.getRolesAssigned()
    this.display = true
  }

  /**
   * Get roles assigned
   */
  public getRolesAssigned (): void {
    this.userService.api.getRoles(this.userUuid)
    .toPromise()
    .then((result) => {
      this.rolesAssigned = result
      this.getRolesAvailable()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get role ids assigned
   */
  public getRoleIdsAssigned (): Array<any> {
    // Default roles.
    // 1: Super users.
    // 2: Administrators.
    // 3: Members.
    // 4: Guests.
    // 5: Clients.
    // 6+: Client groups.

    // Exclude roles we don't want to see as available.
    const uuids: Array<any> = [
      // 3,
      // 4,
      // 5
    ]

    this.rolesAssigned.map((role) => {
      uuids.push(role.uuid)
    })
    return uuids
  }

  /**
   * Get roles available
   */
  public getRolesAvailable (): void {
    const params: any | Object = {
      limit: 0,
      filter_groups: [
        {
          filters: []
        }
      ]
    }

    const roleIdsAssigned: Array<any> = this.getRoleIdsAssigned()

    if (roleIdsAssigned.length > 0) {
      params.filter_groups[0].filters.push({
        key: 'uuid',
        value: [
          roleIdsAssigned
        ],
        operator: 'in',
        not: true
      })
    }

    this.roleApiService.getMany(params)
    .toPromise()
    .then((result) => {
      this.rolesAvailable = result
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Grant role
   */
  public grantRole (role: any | Object): void {
    // @todo Check if already exists.
    this.userService.api.attachRole(this.userUuid, role.uuid)
    .toPromise()
    .then((result) => {
      console.log('grantRole result', result)
      this.getRolesAssigned()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Remove role
   */
  public removeRole (role: any | Object): void {
    this.userService.api.detachRole(this.userUuid, role.uuid)
    .toPromise()
    .then((result) => {
      console.log('deleteRole result', result)
      this.getRolesAssigned()
    })
    .catch((err) => {
      console.error(err)
    })
  }

}
