import { Injectable } from '@angular/core'
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { Address } from '@app/modules/address/address.model'
import { AddressFormService } from '@app/modules/address/address-form.service'
import { Company } from '@app/modules/company/company.model'
import { CompanyFormService } from '@app/modules/company/company-form.service'
import { EmailAddress } from '@app/modules/email-address/email-address.model'
import { EmailAddressFormService } from '@app/modules/email-address/email-address-form.service'
import { Social } from '@app/modules/social/social.model'
import { SocialFormService } from '@app/modules/social/social-form.service'
import { Telephone } from '@app/modules/telephone/telephone.model'
import { TelephoneFormService } from '@app/modules/telephone/telephone-form.service'
import { Uri } from '@app/modules/uri/uri.model'
import { UriFormService } from '@app/modules/uri/uri-form.service'
import { Person } from '@app/modules/person/person.model'
import { PersonToCompany } from '@app/modules/person-to-company/person-to-company.model'
import { PersonToCompanyFormService } from '@app/modules/person-to-company/person-to-company-form.service'

@Injectable()

export class PersonFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      titleUuid: [
        ValidationService.isUuid
      ]
    },
    {
      firstName: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      middleNames: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      lastName: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191),
        Validators.required
      ]
    },
    {
      suffixes: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      shortname: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(4)
      ]
    },
    {
      genderUuid: [
        ValidationService.isUuid
      ]
    },
    {
      avatarUri: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      occupation: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private addressFormService: AddressFormService,
    private companyFormService: CompanyFormService,
    private emailAddressFormAService: EmailAddressFormService,
    private socialFormService: SocialFormService,
    private telephoneFormService: TelephoneFormService,
    private uriFormService: UriFormService,
    private personToCompanyFormService: PersonToCompanyFormService
    // private opportunityFormService: OpportunityFormService
  ) {}

  /**
   * Get form
   */
  public getForm (person: Person, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      titleUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      firstName: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      middleNames: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      lastName: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      suffixes: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      shortname: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(4)
        ]
      ],
      genderUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      avatarUri: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      dob: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      occupation: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // Relations.
      addresses: this.formBuilder.array([]),
      addressesDeleted: this.formBuilder.array([]),
      companies: this.formBuilder.array([]),
      companiesDeleted: this.formBuilder.array([]),
      emailAddresses: this.formBuilder.array([]),
      emailAddressesDeleted: this.formBuilder.array([]),
      socials: this.formBuilder.array([]),
      socialsDeleted: this.formBuilder.array([]),
      telephones: this.formBuilder.array([]),
      telephonesDeleted: this.formBuilder.array([]),
      uris: this.formBuilder.array([]),
      urisDeleted: this.formBuilder.array([])
      // opportunities: this.formBuilder.array([])
      // //'uuid', 'department_uuid', 'department', 'position'
      // pivot: this.formBuilder.array([])
      // pivot: FormGroup
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    // Person to company.
    if (person.personToCompany) {
      form.addControl('personToCompany', this.personToCompanyFormService.getForm(new PersonToCompany(person.personToCompany), ''))
    }

    // if (person.person) {
    //   const control: FormArray = form.get('pivot') as FormArray
    //   person.pivot.map((personToCompany: PersonToCompany) => {
    //     const personToCompanyForm: FormGroup = this.personToCompanyFormService.getForm(personToCompany, type)
    //     control.push(personToCompanyForm)
    //   })
    // }

    if (person.addresses) {
      const control: FormArray = form.get('addresses') as FormArray
      person.addresses.map((address: Address) => {
        const addressForm: FormGroup = this.addressFormService.getForm(address, type)
        control.push(addressForm)
      })
    }

    if (person.companies) {
      const control: FormArray = form.get('companies') as FormArray
      person.companies.map((company: Company) => {
        const companyForm: FormGroup = this.companyFormService.getForm(company, type)
        control.push(companyForm)
      })
    }

    if (person.emailAddresses) {
      const control: FormArray = form.get('emailAddresses') as FormArray
      person.emailAddresses.map((emailAddress: EmailAddress) => {
        const emailAddressForm: FormGroup = this.emailAddressFormAService.getForm(emailAddress, type)
        control.push(emailAddressForm)
      })
    }

    if (person.socials) {
      const control: FormArray = form.get('socials') as FormArray
      person.socials.map((social: Social) => {
        const socialForm: FormGroup = this.socialFormService.getForm(social, type)
        control.push(socialForm)
      })
    }

    if (person.telephones) {
      const control: FormArray = form.get('telephones') as FormArray
      person.telephones.map((telephone: Telephone) => {
        const telephoneForm: FormGroup = this.telephoneFormService.getForm(telephone, type)
        control.push(telephoneForm)
      })
    }

    if (person.uris) {
      const control: FormArray = form.get('uris') as FormArray
      person.uris.map((uri: Uri) => {
        const uriForm: FormGroup = this.uriFormService.getForm(uri, type)
        control.push(uriForm)
      })
    }

    // Circular deps
    // if (person.opportunities) {
    //   const control: FormArray = form.get('opportunities') as FormArray
    //   person.opportunities.map((opportunity: Opportunity) => {
    //     const opportunityForm: FormGroup = this.opportunityFormService.getForm(new Opportunity(opportunity), null)
    //     control.push(opportunityForm)
    //   })
    // }

    // Patch the form.
    if (person) {
      form.patchValue(person, { emitEvent: false })
    }

    return form
  }

}
