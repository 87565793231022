import { Injectable } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { LocalStorageService } from 'angular-2-local-storage'
import { ContactGroupApiService } from '@app/modules/contact-group/contact-group-api.service'
import { ContactGroup } from '@app/modules/contact-group/contact-group.model'

@Injectable()

export class ContactGroupService {

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    public myUtil: MyUtilityService,
    public api: ContactGroupApiService
  ) {}

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0,
    itemIds: []
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'contactGroup.name',
    direction: 'ASC'
  }

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      name: [
        null,
        []
      ],
      description: [
        null,
        []
      ],
      displayOrder: [
        null,
        []
      ]
    })

    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public searchItems (searchFilters: any | Object): Promise<any> {
    console.log('searchFilters', searchFilters)
    const filters: Array<any> = []

    if (searchFilters.name) {
      filters.push({
        key: 'name',
        value: searchFilters.name,
        operator: 'ct'
      })
    }

    if (searchFilters.description) {
      filters.push({
        key: 'description',
        value: searchFilters.description,
        operator: 'ct'
      })
    }

    if (searchFilters.displayOrder) {
      filters.push({
        key: 'displayOrder',
        value: searchFilters.displayOrder,
        operator: 'ct'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: false,
          filters: filters
        }
      )
    }

    const params: any | Object = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'contact_group.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // Add pagination and includes for result set.
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Set item ids
   *
   * Store the oportunity ids so we can retrive them from the edit view
   * for prev/next.
   */
  public setItemIds (totalResults: Array<any>): void {
    const itemIds: Array<string> = []
    totalResults.map((item) => {
      itemIds.push(item.uuid)
    })

    this.localStorageService.set('opportunityItemIds', itemIds)

    this.pagination.itemIds = itemIds
  }

  /**
   * Get mini nav
   */
  public getMiniNav (currentUuid: string): any {
    const miniNav: any = {
      prevId: null,
      current: currentUuid,
      nextId: null,
      total: null
    }

    if (this.pagination.itemIds.length === 0) {
      const itemIds: any = this.localStorageService.get('opportunityItemIds')
      if (itemIds !== null) {
        this.pagination.itemIds = itemIds
      }
    }

    miniNav.total = this.pagination.itemIds.length

    let i: number
    i = 0

    this.pagination.itemIds.map((item) => {
      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i + 1])) {
        miniNav.nextId = this.pagination.itemIds[i + 1]
      }
      if (item === currentUuid) {
        miniNav.current = (i + 1)
      }

      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i - 1])) {
        miniNav.prevId = this.pagination.itemIds[i - 1]
      }
      i++
    })

    return miniNav
  }

  /**
   * Save contact group
   */
  public saveContactGroup (contactGroup: ContactGroup): Promise<any> {
    console.log('contactGroupService.saveContactGroup()', contactGroup)

    // Save the contact group.
    return this.api.save(contactGroup)
    .toPromise()
    .then((contactGroupResult) => {
      // const uuid: string = contactGroupResult.uuid
      const promises: Array<Promise<any>> = []
      // Save & or Delete the ...
      return Promise.all(promises)
      .then(() => {
        return contactGroupResult
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })

  }

}
