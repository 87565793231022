import { Pipe, PipeTransform } from '@angular/core'
// import { * as changeCase } from 'change-case/change-case'
import {
  noCase,
  dotCase,
  pathCase,
  upperCase,
  lowerCase,
  camelCase,
  snakeCase,
  titleCase,
  paramCase,
  headerCase,
  pascalCase,
  constantCase,
  sentenceCase,
  upperCaseFirst,
  lowerCaseFirst
} from 'change-case/change-case'

// import { noCase } from 'change-case/change-case'
// import { dotCase } from 'change-case/change-case'
// // import { swapCase } from 'change-case/change-case'
// import { pathCase } from 'change-case/change-case'
// import { upperCase } from 'change-case/change-case'
// import { lowerCase } from 'change-case/change-case'
// import { camelCase } from 'change-case/change-case'
// import { snakeCase } from 'change-case/change-case'
// import { titleCase } from 'change-case/change-case'
// import { paramCase } from 'change-case/change-case'
// import { headerCase } from 'change-case/change-case'
// import { pascalCase } from 'change-case/change-case'
// import { constantCase } from 'change-case/change-case'
// import { sentenceCase } from 'change-case/change-case'
// // import { isUpperCase } from 'change-case/change-case'
// // import { isLowerCase } from 'change-case/change-case'
// import { upperCaseFirst } from 'change-case/change-case'
// import { lowerCaseFirst } from 'change-case/change-case'

// import { noCase } from 'no-case'
// import { dotCase } from 'dot-case'
// import { swapCase } from 'swap-case'
// import { pathCase } from 'path-case'
// import { upperCase } from 'upper-case'
// import { lowerCase } from 'lower-case'
// import { camelCase } from 'camel-case'
// import { snakeCase } from 'snake-case'
// import { titleCase } from 'title-case'
// import { paramCase } from 'param-case'
// import { headerCase } from 'header-case'
// import { pascalCase } from 'pascal-case'
// import { constantCase } from 'constant-case'
// import { sentenceCase } from 'sentence-case'
// import { isUpperCase } from 'is-upper-case'
// import { isLowerCase } from 'is-lower-case'
// import { upperCaseFirst } from 'upper-case-first'
// import { lowerCaseFirst } from 'lower-case-first'

@Pipe({ name: 'changeCase' })

/**
 * https://github.com/catamphetamine/libphonenumber-js
 */
export class ChangeCasePipe implements PipeTransform {

  /**
   * Transform to formatted phone number
   * {{ model : 'lowerCase' }}
   *
   * @param value Value
   * @param arg   Case
   *
   * @returns {string} Formatted telephone number
   */
  public transform (value: string, arg: string): string {

    if (!arg) {
    }

    let newVal: string

    switch (arg) {
      case 'noCase':
        newVal = noCase(value)
        break
      case 'dotCase':
        newVal = dotCase(value)
        break
      case 'pathCase':
        newVal = pathCase(value)
        break
      case 'upperCase':
        newVal = upperCase(value)
        break
      case 'lowerCase':
        newVal = lowerCase(value)
        break
      case 'camelCase':
        newVal = camelCase(value)
        break
      case 'snakeCase':
        newVal = snakeCase(value)
        break
      case 'titleCase':
        newVal = titleCase(value)
        break
      case 'paramCase':
        newVal = paramCase(value)
        break
      case 'headerCase':
        newVal = headerCase(value)
        break
      case 'pascalCase':
        newVal = pascalCase(value)
        break
      case 'constantCase':
        newVal = constantCase(value)
        break
      case 'sentenceCase':
        newVal = sentenceCase(value)
        break
      case 'upperCaseFirst':
        newVal = upperCaseFirst(value)
        break
      case 'lowerCaseFirst':
        newVal = lowerCaseFirst(value)
        break
      default:
        newVal = value
    }

    return newVal
  }
}
