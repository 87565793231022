export class FmNewFolder {
  name: string = null

  /**
   * Constructor
   *
   * @param {Partial<FmNewFolder>} data Initial values
   */
  public constructor (data?: Partial<FmNewFolder>) {
    if (data) {
      Object.assign(this, data)
    }
  }
}
