// Angular.
import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
// Other Services
// import { LocalStorageService } from 'angular-2-local-storage'
// import { MyUtilityService } from '@app/shared/services/my-utility.service'
// Api services.
import { EmailMessageApiService } from '@app/modules/email-message/services/email-message-api.service'
// Models
// import { Person } from '@app/modules/person/person.model'
// import { Address } from '@app/modules/address/address.model'
// import { Company } from '@app/modules/company/company.model'
// import { EmailAddress } from '@app/modules/email-address/email-address.model'
// import { Social } from '@app/modules/social/social.model'
// import { Telephone } from '@app/modules/telephone/telephone.model'
// import { Uri } from '@app/modules/uri/uri.model'
// import { DeleteId } from '@app/modules/delete-id/delete-id.model'

@Injectable()

export class EmailMessageSearchService {

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0,
    itemIds: []
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'emailMessage.sentDateTime',
    direction: 'DESC'
  }

  /**
   * Constructor
   */
  public constructor (
    // Angular.
    private formBuilder: FormBuilder,
    // Other Services.
    // private localStorageService: LocalStorageService,
    // Api services.
    public api: EmailMessageApiService

  ) {}

  // ---------------------------------------------------------------------------
  // Email Message Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      opportunity: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      from: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      toRecipients: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      subject: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      isAllocated: [
        null,
        []
      ],
      isIgnored: [
        null,
        []
      ],
      isSpam: [
        null,
        []
      ],
      isSent: [
        null,
        []
      ],
      opportunityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      fromPersonUuid: [
        null,
        []
      ],
      toPersonUuid: [
        null,
        []
      ]
    })

    return form
  }

  /**
   * Set sort
   *
   * @param column Column
   */
  public setSort (column: any | Object): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   * Note search filters
   * @param data Search values
   */
  public search (searchFilters: any | Object): Promise<any> {
    const filters: Array<any> = []

    if (searchFilters.opportunity) {
      filters.push({
        key: 'opportunity.jobNo',
        value: searchFilters.opportunity,
        operator: 'ct'
      })
    }

    if (searchFilters.from) {
      filters.push({
        key: 'from',
        value: searchFilters.from,
        operator: 'ct'
      })
    }

    if (searchFilters.toRecipients) {
      filters.push({
        key: 'toRecipients',
        value: searchFilters.toRecipients,
        operator: 'ct'
      })
    }

    if (searchFilters.subject) {
      filters.push({
        key: 'subject',
        value: searchFilters.subject,
        operator: 'ct'
      })
    }

    if (searchFilters.opportunityUuid) {
      filters.push({
        key: 'opportunityUuid',
        value: searchFilters.opportunityUuid,
        operator: 'eq'
      })
    }

    if (searchFilters.isAllocated !== null) {
      filters.push({
        key: 'isAllocated',
        value: searchFilters.isAllocated ? 1 : 0,
        operator: 'eq'
      })
    }

    if (searchFilters.isIgnored !== null) {
      filters.push({
        key: 'isIgnored',
        value: searchFilters.isIgnored ? 1 : 0,
        operator: 'eq'
      })
    }

    if (searchFilters.isSpam !== null) {
      filters.push({
        key: 'isSpam',
        value: searchFilters.isSpam ? 1 : 0,
        operator: 'eq'
      })
    }

    if (searchFilters.isSent !== null) {
      filters.push({
        key: 'isSent',
        value: searchFilters.isSent ? 1 : 0,
        operator: 'eq'
      })
    }

    if (searchFilters.fromPersonUuid) {
      filters.push({
        key: 'fromPersonUuid',
        value: searchFilters.fromPersonUuid,
        operator: 'eq'
      })
    }

    if (searchFilters.toPersonUuid) {
      filters.push({
        key: 'to',
        value: searchFilters.toPersonUuid,
        operator: 'eq'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: false,
          filters: filters
        }
      )
    }

    const params: any = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'email_message.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // this.setItemIds(totalResults)

      // Add pagination and includes for result set
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        'opportunity',
        'fromPerson',
        'fromPerson.emailAddresses',
        'to',
        'to.emailAddresses'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })
    })
    .catch((err) => {
      console.error(err)
    })

  }

}
