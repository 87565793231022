import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-fm-spinner',
  templateUrl: './fm-spinner.component.html',
  providers: []
})

export class FmSpinnerComponent implements OnInit, OnDestroy {

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
  }
}
