import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import * as moment from 'moment'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'

// import 'chart.js';
// import { FlotChartDirective } from '@app/core/charts/flotChart';
// import { IboxtoolsModule } from '@app/core/iboxtools/iboxtools.module'
// declare var $: any

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Calendar options
   */
  public calendarOptions: any

  /**
   * Events
   */
  public events: any

  /**
   * Event sources
   */
  public eventSources: Array<any>

  /**
   * Subscriptions
  */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    // Services.
    public appService: AppService,
    public myUtil: MyUtilityService,
    public selectOptionsService: SelectOptionsService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = false
    this.calendarOptions = this.getCalendarOptions()
    this.display = true
  }

  /**
   * Get Calendar Options
   */
  public getCalendarOptions (): any | Object {
    const now: string = moment().toISOString()
    const calendarOptions: any | Object = {
      // height: 'auto',
      // aspectRatio: 2,
      fixedWeekCount : false,
      firstDay: 1,
      defaultDate: now,
      editable: true,
      // Allow "more" link when too many events.
      eventLimit: 8,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      eventSources: [
        {
          events: (start: any, end, timezone, callback): any => {
            this.getEvents(start, end, timezone, callback)
          },
          eventDataTransform: (data): any => {
            // Get service shortnames and use as titles.
            if (data.services && data.services.length > 0) {
              const serviceShortNames: Array<any> = []
              data.services.map(service => {
                serviceShortNames.push(service.shortName)
              })
              data.title = serviceShortNames.join(', ')
            }
            // Add clock icon class.
            data.iconClass = 'fa fa-clock-o'
            data.url = '/#/clients/' + data.clientId
            return data
          }
        }
      ],
      eventRender: function (event: any, element: any): any {
        if (event) {}
        if (element) {}
        // if (event.iconClass) {
        //   element.find('.fc-content').prepend('<i class="' + event.iconClass + '"></i>&nbsp;')
        // }
        // element.find('.fc-content').append(
        //   '<br><span class="text-strong">' + event.client.firstName + ' ' + event.client.lastName + '</span>'
        // )
      },
      views: {
        basic: {
           // options apply to basicWeek and basicDay views
        },
        month: {
          // options apply to month views
          timeFormat: 'H:mm'
        },
        agenda: {
           // options apply to agendaWeek and agendaDay views
        },
        week: {
           // options apply to basicWeek and agendaWeek views
        },
        day: {
           // options apply to basicDay and agendaDay views
        }
      }
    }
    // @todo hidden on ng 5 update

    return calendarOptions
  }

  /**
   * Get events
   */
  public getEvents (start: any, end: any, timeZone: any, cb: any): any {
    if (start) {}
    if (end) {}
    if (timeZone) {}
    const events: Array<any> = [
      {
        title: 'All Day Event',
        start: '2018-03-20'
      },
      {
        title: 'On site',
        start: '2018-03-05',
        end: '2018-03-09'
      },
      {
        id: 999,
        title: 'Meeting',
        start: '2018-03-16T16:00:00'
      },
      // {
      //   id: 999,
      //   title: 'Repeating Event',
      //   start: '2018-03-16T16:00:00'
      // },
      {
        title: 'Conference',
        start: '2018-03-10',
        end: '2018-03-12'
      },
      {
        title: 'Meeting',
        start: '2018-03-12T10:30:00',
        end: '2018-03-12T12:30:00'
      },
      {
        title: 'Lunch',
        start: '2018-03-12T12:00:00'
      }
      // {
      //   title: 'Click for Google',
      //   url: 'http://google.com/',
      //   start: '2018-03-28'
      // }
    ]

    cb(events)
  }

}
