// Don't use barrels inside barrels!
import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { AddressFormService } from '@app/modules/address/address-form.service'
import { EmailAddressFormService } from '@app/modules/email-address/email-address-form.service'
import { SocialFormService } from '@app/modules/social/social-form.service'
import { TelephoneFormService } from '@app/modules/telephone/telephone-form.service'
import { UriFormService } from '@app/modules/uri/uri-form.service'
import { Address } from '@app/modules/address/address.model'
import { Company } from '@app/modules/company/company.model'
import { EmailAddress } from '@app/modules/email-address/email-address.model'
import { Social } from '@app/modules/social/social.model'
import { Telephone } from '@app/modules/telephone/telephone.model'
import { Uri } from '@app/modules/uri/uri.model'

import { PersonToCompany } from '@app/modules/person-to-company/person-to-company.model'
import { PersonToCompanyFormService } from '@app/modules/person-to-company/person-to-company-form.service'

// Using people here is a circular dep!
// import { Person } from '@app/modules/person/person.model'
// import { PersonFormService } from '@app/modules/person/person-form.service'

// import { Opportunity } from '@app/modules/opportunity/opportunity.model'
// import { OpportunityFormService } from '@app/modules/opportunity/opportunity-form.service'

@Injectable()

export class CompanyFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      companyTypeUuid: [
        ValidationService.isUuid
      ]
    },
    {
      suppliesWhatUuid: [
        ValidationService.isUuid
      ]
    },
    {
      name: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.required,
        Validators.maxLength(191)
      ]
    },
    {
      regNumber: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      vatNumber: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private addressFormService: AddressFormService,
    private emailAddressFormAService: EmailAddressFormService,
    private socialFormService: SocialFormService,
    private telephoneFormService: TelephoneFormService,
    private uriFormService: UriFormService,
    private personToCompanyFormService: PersonToCompanyFormService
  ) {}

  /**
   * Get form
   */
  public getForm (company: Company, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      companyTypeUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      suppliesWhatUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      name: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      regNumber: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      vatNumber: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // Relations.
      addresses: this.formBuilder.array([]),
      addressesDeleted: this.formBuilder.array([]),
      emailAddresses: this.formBuilder.array([]),
      emailAddressesDeleted: this.formBuilder.array([]),
      socials: this.formBuilder.array([]),
      socialsDeleted: this.formBuilder.array([]),
      telephones: this.formBuilder.array([]),
      telephonesDeleted: this.formBuilder.array([]),
      uris: this.formBuilder.array([]),
      urisDeleted: this.formBuilder.array([]),
      people: this.formBuilder.array([]),
      peopleDeleted: this.formBuilder.array([])
      // pivot: [
      //   null,
      //   this.formBuilder.group({})
      // ],
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

        // Person to company.
    if (company.personToCompany) {
      form.addControl('personToCompany', this.personToCompanyFormService.getForm(new PersonToCompany(company.personToCompany), ''))
    } else {
      form.addControl('personToCompany', this.personToCompanyFormService.getForm(new PersonToCompany(), ''))
    }

    if (company.addresses) {
      const control: FormArray = form.get('addresses') as FormArray
      company.addresses.map((address: Address) => {
        const addressForm: FormGroup = this.addressFormService.getForm(address, '')
        control.push(addressForm)
      })
    }

    if (company.emailAddresses) {
      const control: FormArray = form.get('emailAddresses') as FormArray
      company.emailAddresses.map((emailAddress: EmailAddress) => {
        const emailAddressForm: FormGroup = this.emailAddressFormAService.getForm(emailAddress, '')
        control.push(emailAddressForm)
      })
    }

    if (company.socials) {
      const control: FormArray = form.get('socials') as FormArray
      company.socials.map((social: Social) => {
        const socialForm: FormGroup = this.socialFormService.getForm(social, '')
        control.push(socialForm)
      })
    }

    if (company.telephones) {
      const control: FormArray = form.get('telephones') as FormArray
      company.telephones.map((telephone: Telephone) => {
        const telephoneForm: FormGroup = this.telephoneFormService.getForm(telephone, '')
        control.push(telephoneForm)
      })
    }

    if (company.uris) {
      const control: FormArray = form.get('uris') as FormArray
      company.uris.map((uri: Uri) => {
        const uriForm: FormGroup = this.uriFormService.getForm(uri, '')
        control.push(uriForm)
      })
    }

    // We cant include these because we'll get circular deps.
    // if (company.opportunities) {
    //   const control: FormArray = form.get('opportunities') as FormArray
    //   company.opportunities.map((opportunity: Opportunity) => {
    //     const opportunityForm: FormGroup = this.opportunityFormService.getForm(opportunity, null)
    //     control.push(opportunityForm)
    //   })
    // }

    // if (company.people) {
    //   const control: FormArray = form.get('people') as FormArray
    //   company.people.map((person: Person) => {
    //     const personForm: FormGroup = this.personFormService.getForm(person, null)
    //     control.push(personForm)
    //   })
    // }

    // Patch the form.
    if (company) {
      form.patchValue(company, { emitEvent: false })
    }

    return form
  }

}
