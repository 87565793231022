import { Injectable } from '@angular/core'
import { FormArray, FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'
import { PersonFormService } from '@app/modules/person/person-form.service'
import { Task } from '@app/modules/task/task.model'
import { Person } from '@app/modules/person/person.model'
import { Company } from '@app/modules/company/company.model'

@Injectable()

export class TaskFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      taskTypeUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      regarding: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private companyFormService: CompanyFormService,
    private personFormService: PersonFormService
  ) {}

  /**
   * Get form
   */
  public getForm (task: Task, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      taskTypeUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      regarding: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      regardingUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      startDatetime: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      endDatetime: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      originalDatetime: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      isTimeless: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      location: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      isPrivate: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      organiserUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      details: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(65535) //3221225471
        ]
      ],
      masterUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      parentUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      recurSourceUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      isEvent: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      recurPeriod: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      recurFreq: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      recurModifier: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      recurDay: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      recurDayType: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      recurMonth: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      recurEndDate: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      isRecurEndless: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      isDeleted: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedDates: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      isInvitationSent: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      duration: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      assignedToUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      assignedByUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      taskPriorityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      isComplete: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // Specials to store selected items.
      selectedContact: [
        null,
        []
      ],
      selectedCompany: [
        null,
        []
      ],
      // Relations
      scheduledWith: this.formBuilder.array([]),
      associatedWith: this.formBuilder.array([])
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (task.taskType) {
      // form.addControl('taskType', this.taskTypeFormService.getForm(task.taskType, type))
    }

    if (task.scheduledWith) {
      const control: FormArray = form.get('scheduledWith') as FormArray
      task.scheduledWith.map((person: Person) => {
        const personForm: FormGroup = this.personFormService.getForm(person, '')
        control.push(personForm)
      })
    }

    if (task.associatedWith) {
      const control: FormArray = form.get('associatedWith') as FormArray
      task.associatedWith.map((company: Company) => {
        const companyForm: FormGroup = this.companyFormService.getForm(company, '')
        control.push(companyForm)
      })
    }

    if (task) {
      form.patchValue(task, { emitEvent: false })
    }

    return form
  }

}
