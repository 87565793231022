import { Injectable } from '@angular/core'
// import { FormGroup, Validators, FormBuilder } from '@angular/forms'
// import { ValidationService } from '@app/shared/services/validation.service'
// import * as moment from 'moment'
// import { TaskApiService } from '@app/modules/task/task-api.service'
// import { Task } from '@app/modules/task/task.model'
// import { Person } from '@app/modules/person/person.model'
// import { Company } from '@app/modules/company/company.model'
import Dexie from 'dexie'
import 'dexie-observable'
import 'dexie-syncable'

@Injectable()

export class SyncService {

  // Ref: http://dexie.org/docs/Syncable/Dexie.Syncable.js.html
  // Ref: http://dexie.org/docs/Syncable/Dexie.Syncable.js#tutorial
  // Ref: https://github.com/dfahlander/Dexie.js
  // Ref: http://dexie.org/docs/Typescript.html
  // Ref: https://github.com/dfahlander/Dexie.js/blob/master/samples/typescript/src/appdb.ts

  public constructor () {
    const db: Dexie = new Dexie('tube_tech')
    db.version(1).stores({
      // tslint:disable:max-line-length
      address: '$$uuid, addressNameUuid, address1, address2, address3, city, state, postcode, countryUuid, latitude, longitude, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpAddressId, tmpContactId, tmpCompanyId',
      addressName: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPicklistItemId',
      agreement: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPicklistItemId',
      alarm: '$$uuid, name, minutes, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      company: '$$uuid, companyTypeUuid, suppliesWhatUuid, name, regNumber, vatNumber, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpCompanyId',
      companyToAddress: '$$uuid, companyUuid, addressUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      companyToEmailAddress: '$$uuid, companyUuid, emailAddressUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      companyToHistory: '$$uuid, companyUuid, historyUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpCompanyId, tmpHistoryId',
      companyToNote: '$$uuid, companyUuid, noteUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpCompanyId, tmpNoteId',
      companyToSocial: '$$uuid, companyUuid, socialUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      companyToTelephone: '$$uuid, companyUuid, telephoneUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      companyToUri: '$$uuid, companyUuid, uriUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      companyType: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPicklistItemId',
      contactGroup: '$$uuid, name, description, customQuery, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpGroupId',
      contactGroupToPerson: '$$uuid, contactGroupUuid, personUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      country: '$$uuid, name, iso2, iso3, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpCountryId',
      department: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      emailAddress: '$$uuid, emailAddressNameUuid, email, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpTypeId, tmpContactId',
      emailAddressName: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPicklistItemId',
      emailMessage: '$$uuid, msid, changeKey, receivedDateTime, sentDateTime, hasAttachments, internetMessageId, internetMessageHeaders, subject, body, importance, parentFolderId, sender, from, toRecipients, ccRecipients, bccRecipients, replyTo, conversationId, isDeliveryReceiptRequested, isReadReceiptRequested, isRead, isDraft, webLink, inferenceClassification, flag, attachments, toPersonUuid, fromPersonUuid, opportunityUuid, isAllocated, isIgnored, isSent, isSpam, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      emailMessageBcc: '$$uuid, emailMessageUuid, personUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      emailMessageCc: '$$uuid, emailMessageUuid, personUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      emailMessageTo: '$$uuid, emailMessageUuid, personUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      gender: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      history: '$$uuid, historyTypeUuid, isPrivate, startTime, endTime, regarding, details, accessorActivityClearedId, manageUserId, duration, outlookId, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpHistoryId, tmpHistoryTypeId, tmpAccessorActivityClearedId',
      historyType: '$$uuid, historyTypeGroupUuid, taskTypeUuid, name, description, isActive, isCustom, isUserRecordable, isDefault, clearTypeNum, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpHistoryTypeUid, tmpHistoryTypeId, tmpHistoryTypeGroupId',
      historyTypeGroup: '$$uuid, historyTypeSuperGroupUuid, name, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpHistoryTypeGroupUid, tmpHistoryTypeGroupId, tmpHistoryTypeSuperGroupId',
      historyTypeSuperGroup: '$$uuid, name, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpHistoryTypeSuperGroupUid, tmpHistoryTypeSuperGroupId',
      lostReason: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPicklistItemId',
      note: '$$uuid, noteTypeUuid, content, displayDate, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpNoteId',
      noteType: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      notification: '$$uuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      opportunity: '$$uuid, num, manual, newJobNo, jobNoRev, newProposalNo, proposalNoRev, name, jobNo, proposalNo, companyUuid, siteUuid, opportunityStatusUuid, opportunityProcessUuid, opportunityStageUuid, salesPersonUserUuid, probabilityUuid, estimatedStart, estimatedCompletion, expectedOrderReceived, orderReceived, finalInvoice, finalInvoiceDate, lostReasonUuid, refIir, shutdownUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpOpportunityId',
      opportunityAgreement: '$$uuid, agreementUuid, opportunityUuid, sent, received, signedByPersonUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpContactId',
      opportunityContact: '$$uuid, opportunityUuid, personUuid, isMain, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpOpportunityId, tmpContactId',
      opportunityItem: '$$uuid, opportunityUuid, name, productServiceUuid, quantity, cost, price, priceAdjusted, discount, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      opportunityProcess: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpProcessId',
      opportunityStage: '$$uuid, name, opportunityProcessUuid, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpStageId, tmpProcessId',
      opportunityStatus: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      opportunityToEmailMessage: '$$uuid, opportunityUuid, emailMessageUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      opportunityToHistory: '$$uuid, opportunityUuid, historyUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpOpportunityId, tmpHistoryId',
      opportunityToNote: '$$uuid, opportunityUuid, noteUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpOpportunityId, tmpNoteId',
      opportunityToProductService: '$$uuid, opportunityUuid, productServiceUuid, tmpOpportunityId, tmpProductServiceId, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      opportunityToTelephoneCall: '$$uuid, opportunityUuid, telephoneCallUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      person: '$$uuid, titleUuid, firstName, middleNames, lastName, suffixes, shortName, genderUuid, avatarUri, dob, occupation, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpContactId, tmpCompanyId',
      personToAddress: '$$uuid, personUuid, addressUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      personToCompany: '$$uuid, personUuid, companyUuid, departmentUuid, department, position, isPrimary, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      personToEmailAddress: '$$uuid, personUuid, emailAddressUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      personToHistory: '$$uuid, personUuid, historyUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPersonId, tmpHistoryId',
      personToNote: '$$uuid, personUuid, noteUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpContactId, tmpNoteId',
      personToSocial: '$$uuid, personUuid, socialUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      personToTelephone: '$$uuid, personUuid, telephoneUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      personToUri: '$$uuid, personUuid, uriUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      probability: '$$uuid, name, value, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      product: '$$uuid, name, description, code, type, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpProductId',
      productService: '$$uuid, name, productUuid, itemCode, itemType, quantity, unitCost, unitPrice, unitDiscount, discountType, discountPrice, extendedAmount, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpProductServiceId, tmpProductId',
      reminder: '$$uuid',
      resource: '$$uuid, parentUuid, name, displayOrder, description, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      role: '$$uuid, name, description, isGroup, isProtected, parentUuid, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      rule: '$$uuid, roleUuid, resourceUuid, privilege, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      savedQuery: '$$uuid, name, description, jsQuery, sqlQuery, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      shutdown: '$$uuid, personUuid, chaseDate, shutdownDate, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpContactId',
      site: '$$uuid, name, addressUuid, companyUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      social: '$$uuid, socialNameUuid, accountName, accountLink, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpContactId',
      socialName: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpSocialMediaId',
      storageDrive: '$$uuid, parentUuid, path, name, mimeType, size, storageFolderDefaultUuid, content, opportunityUuid, opportunityNum, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      storageFolderDefault: '$$uuid, parentUuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      suppliesWhat: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      task: '$$uuid, taskTypeUuid, regarding, startDatetime, endDatetime, originalDatetime, isTimeless, location, isPrivate, organiserUserUuid, details, masterUuid, parentUuid, recurSourceId, isEvent, recurPeriod, recurFreq, recurModifier, recurDay, recurDayType, recurMonth, recurEndDate, isRecurEndless, isDeleted, deletedDates, isInvitationSent, duration, isComplete, assignedToUserUuid, assignedByUserUuid, taskPriorityUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpTaskId',
      taskDuration: '$$uuid, name, minutes, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      taskPriority: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpActivityPriorityId',
      taskRegarding: '$$uuid, taskTypeUuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPickListItemId',
      taskToCompany: '$$uuid, taskUuid, companyUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpTaskId, tmpCompanyId',
      taskToHistory: '$$uuid, taskUuid, historyUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpTaskId, tmpHistoryId',
      taskToOpportunity: '$$uuid, taskUuid, opportunityUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpTaskId, tmpOpportunityId',
      taskToPerson: '$$uuid, taskUuid, personUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpTaskId, tmpContactId',
      taskType: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpActivityTypeId, tmpPickListId',
      telephone: '$$uuid, telephoneNameUuid, number, extension, iso2, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpTypeId, tmpContactId, tmpCompanyId',
      telephoneCall: '$$uuid, toPersonUuid, fromPersonUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      telephoneName: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPicklistItemId',
      title: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpPicklistItemId',
      uri: '$$uuid, uriNameUuid, url, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpContactId',
      uriName: '$$uuid, name, displayOrder, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      user: '$$uuid, username, password, email, firstName, lastName, personUuid, enabled, lastIp, lastLogin, lastActive, rememberToken, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid, tmpUserId, tmpContactId',
      userToContactGroup: '$$uuid, userUuid, contactGroupUuid, write, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid',
      userToRole: '$$uuid, userUuid, roleUuid, createdAt, updatedAt, deletedAt, createdByUuid, updatedByUuid'
      // tslint:enable:max-line-length
    })

    db.open()
    .then((result) => {
      // Database opened successfully
      console.log(result)
    })
    .catch((err) => {
      // Error occurred
      console.error(err)
    })
    // db.version(2).stores({})
  }
  /**
   * Sync
   */
  public sync (): void {
    console.log('sync')
    const db: Dexie = new Dexie('tube_tech_test')
    db.version(1).stores({
      friends: '$$uuid, name, shoeSize',
      pets: '$$uuid, name, kind'
    })
    db.version(2).stores({})
    // This example uses the WebSocketSyncProtocol included in earlier steps.
    db.syncable.connect('websocket', 'https://api-crm.tubetech.loc/api/v1/sync')
    db.syncable.on('statusChanged', function (newStatus: number, url: string): any {
      if (url) {}
      console.log('Sync Status changed: ' + Dexie.Syncable.StatusTexts[newStatus])
    })
  }

}
