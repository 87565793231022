import { EventEmitter, Injectable } from '@angular/core'

@Injectable()

export class EventService {

  public events: EventEmitter<any>

  /**
   * Constructor
   *
   * @returns void
   */
  public constructor () {
    this.events = new EventEmitter<any>()
  }
}
