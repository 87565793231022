import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { Site } from '@app/modules/site/site.model'
import { AddressFormService } from '@app/modules/address/address-form.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'

@Injectable()

export class SiteFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      name: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191),
        Validators.required
      ]
    },
    {
      addressUuid: [
        // Cant be required because the address needs saving first.
        // Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      companyUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private addressFormService: AddressFormService,
    private companyFormService: CompanyFormService
  ) {}

  /**
   * Get form
   */
  public getForm (site: Site, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      name: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      addressUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // address: this.addressFormService.getForm(null, ''),
      companyUuid: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      // company: this.companyFormService.getForm(null, ''),
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (site.address) {
      form.addControl('address', this.addressFormService.getForm(site.address, ''))
    }

    if (site.company) {
      form.addControl('company', this.companyFormService.getForm(site.company, ''))
    }

    // Patch the form.
    if (site) {
      form.patchValue(site, { emitEvent: false })
    }

    return form
  }

}
