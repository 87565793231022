import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { AddressNameApiService } from '@app/modules/address-name/address-name-api.service'

@Injectable()

export class AddressNameService {

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder,
    public myUtil: MyUtilityService,
    public api: AddressNameApiService
  ) {}

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'addressName.name',
    direction: 'ASC'
  }

  /**
   * Get form
   */
  public getForm (data?: any | Object): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      name: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.required,
          Validators.maxLength(191)
        ]
      ],
      displayOrder: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.isNumeric
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (!this.myUtil.isEmpty(data)) {
      if (Object.keys(data).length !== 0) {
        form.patchValue(data, { emitEvent: false })
      }
    }

    return form
  }

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      name: [
        null,
        []
      ],
      displayOrder: [
        null,
        []
      ]
    })

    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public searchItems (searchFilters: any | Object): Promise<any> {
    console.log('searchFilters', searchFilters)
    const filters: Array<any> = []

    if (searchFilters.name) {
      filters.push({
        key: 'name',
        value: searchFilters.name,
        operator: 'ct'
      })
    }

    if (searchFilters.displayOrder) {
      filters.push({
        key: 'displayOrder',
        value: searchFilters.displayOrder,
        operator: 'ct'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: false,
          filters: filters
        }
      )
    }

    const params: any | Object = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'address_name.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // Add pagination and includes for result set.
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })
  }

}
