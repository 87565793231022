import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { QueryBuilderApiService } from '@app/modules/query-builder/services/query-builder-api.service'
import { QueryItem } from '@app/modules/query-builder/query-item.model'
import { SelectItem } from '@app/modules/query-builder/select-item.model'
import { NgbModal, NgbModalRef, NgbTabset } from '@ng-bootstrap/ng-bootstrap'
// import { TableJoinSelectorComponent } from '@app/modules/query-builder/components/table-join-selector/table-join-selector.component'
import { QueryBuilderService } from '../../services'
import { ChangeCasePipe } from '@app/shared/pipes/change-case.pipe'
import {
  SavedQuery,
  SavedQueryService,
  SavedQueryFormService
} from '@app/modules/saved-query'
import * as moment from 'moment'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html'
})

/**
 * QueryBuilderComponent
 *
 * https://github.com/mistic100/jQuery-QueryBuilder/issues?page=22&q=is%3Aissue+is%3Aclosed
 * https://github.com/zebzhao/Angular-QueryBuilder
 * https://github.com/swimlane/ngx-datatable
 *
 * @type {[type]}
 */
export class QueryBuilderComponent implements OnInit, OnDestroy {

  @ViewChild('t') tab: NgbTabset

  /**
   * Display page
   */
  public display: boolean

  public columns: Array<any>
  public rows: Array<any>

  public fks: Array<any> = []

  public selects: Array<SelectItem> = []

  // public columnsAvailable: Array<any> = []

  public whereGroups: Array<any> = []

  public whereGroupOperators: Array<any> = [
    {
      name: 'and'
    },
    {
      name: 'or'
    }
  ]

  public offset: number

  public limit: number

  public lastQuery: string

  public results: Array<any>

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  public firstQueryItem: QueryItem

  public rootJoinsAvailable: Array<QueryItem>

  public savedQueries: Array<SavedQuery>

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  public saveDisabled: boolean

  public saveAsDisabled: boolean

  public savedQueryForm: FormGroup

  /**
   * Where columns available
   */
  public whereColumnsAvailable: Array<any> = []

  /**
   * Constructor
   */
  public constructor (
    private alertService: AlertService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public selectOptionsService: SelectOptionsService,
    private modalService: NgbModal,
    public validationService: ValidationService,
    private queryBuilderApiService: QueryBuilderApiService,
    private savedQueryService: SavedQueryService,
    private savedQueryFormService: SavedQueryFormService,
    private queryBuilderService: QueryBuilderService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.savedQueryForm = this.savedQueryFormService.getForm(new SavedQuery(), 'new')

    this.saveDisabled = true
    this.saveAsDisabled = true

    this.setUp()
    .then(() => {
      this.watchLoadQuery()
      this.watchDeletedQuery()
      this.display = true
    })
    .catch((err) => {
      console.error('set up failed', err)
    })
  }

  /**
   * Set up
   */
  public setUp (): Promise<any> {
    return this.getInitialData()
    .then(() => {
      this.savedQueryForm = this.savedQueryFormService.getForm(new SavedQuery(), 'new')
      this.lastQuery = null
      this.rows = []
      this.columns = []
      this.results = []

      this.clearColumnsAvailable()
      this.clearSelects()
      this.clearWhereGroups()

      this.offset = null
      this.limit = 1000
      this.firstQueryItem = new QueryItem({
        uuid: uuidv4(),
        tableName: 'root',
        joins: [
          new QueryItem({
            uuid: uuidv4()
          })
        ],
        joinsAvailable: this.rootJoinsAvailable
      })
      this.saveDisabled = true
      this.saveAsDisabled = true
      return true
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Get initial data
   */
  public getInitialData (): Promise<any> {
    const promises: Array<Promise<any>> = []

    let promise: Promise<any>

    promise = this.getSavedQueries()
    .then(() => {
      return true
    })
    .catch((err) => {
      throw err
    })
    promises.push(promise)

    promise = this.getAllFks()
    .then((results) => {
      this.fks = results
      this.queryBuilderService.fks = results
      return true
    })
    .catch((err) => {
      throw err
    })

    promises.push(promise)

    promise = this.getTables()
    .then((results) => {
      const rootJoinsAvailable: Array<QueryItem> = []
      results.map((table) => {
        rootJoinsAvailable.push(new QueryItem({
          uuid: uuidv4(),
          displayName: new ChangeCasePipe().transform(table.tableName, 'titleCase'),
          refTableName: table.tableName
        }))
      })

      this.rootJoinsAvailable = rootJoinsAvailable
      return true
    })
    .catch((err) => {
      throw err
    })

    promises.push(promise)

    return Promise.all(promises)
    .then((promisesResult) => {
      return promisesResult
    })
    .catch((err) => {
      throw err
    })
  }

  public loadAvailableJoins (queryItem: QueryItem): Promise<any> {
    const promises: Array<Promise<any>> = []
    queryItem.joins.map((join: QueryItem) => {
      const promise: Promise<any> = this.queryBuilderService.changeJoin(join, queryItem)
      .then(() => {
        return this.loadAvailableJoins(join)
      })
      .catch((err) => {
        throw err
      })
      promises.push(promise)
    })

    return Promise.all(promises)
    .then((res) => {
      return res
    })
    .catch((err) => {
      throw err
    })
  }

  public loadWhereOptions (whereGroups: any): Promise<any> {
    const promises: Array<Promise<any>> = []
    whereGroups.map(whereGroup => {

      whereGroup.wheres.map((where: QueryItem) => {
        const promise: Promise<any> = this.queryBuilderService.changeWhere(where, true)
        .then((result) => {
          return result
        })
        .catch((err) => {
          throw err
        })
        promises.push(promise)
      })
    })

    return Promise.all(promises)
    .then((res) => {
      return res
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Watch load query
   */
  private watchLoadQuery (): void {
    const sub: Subscription = this.savedQueryService.loadedQuery
    .subscribe((data) => {
      if (!data) {
        return
      }
      console.log('loaded saved query', data)

      const queryData: any = JSON.parse(data.jsQuery)

      queryData.query.joinsAvailable = this.rootJoinsAvailable

      // ok we need to rebuild the query
      console.log('query', queryData.query)

      // First get available joins.
      this.loadAvailableJoins(queryData.query)
      .then(() => {
        return this.loadWhereOptions(queryData.whereGroups)
      })
      .then(() => {
        // then we're ready to proceed.

        this.selects = queryData.selects

        this.whereGroups = queryData.whereGroups // add options

        console.log('queryData.whereGroups', queryData.whereGroups)

        this.offset = queryData.offset
        this.limit = queryData.limit

        this.firstQueryItem = queryData.query

        // this.queryBuilderService.columnsAvailable = queryData.columnsAvailable
        // this.queryBuilderService.whereColumnsAvailable = queryData.whereColumnsAvailable
        // this.savedQueryForm = this.savedQueryFormService.getForm(new SavedQuery(data), 'edit')
        this.saveDisabled = false
        this.saveAsDisabled = false
        this.tab.select('builder-tab')

      })

    })

    this.subscriptions.push(sub)
  }

  /**
   * Watch deletedQuery
   */
  private watchDeletedQuery (): void {
    const sub: Subscription = this.savedQueryService.deletedQuery
    .subscribe((data) => {
      if (!data) {
        return
      }
      console.log('deleted saved query', data)

      if (this.savedQueryForm.value.uuid === data) {
        this.resetQuery()
      }

    })

    this.subscriptions.push(sub)
  }

  /**
   * Reset query
   */
  public resetQuery (): void {
    this.setUp()
  }

  /**
   * Clear selects available
   */
  public clearColumnsAvailable (): void {
    this.queryBuilderService.columnsAvailable = []
    this.queryBuilderService.whereColumnsAvailable = []
  }

  /**
   * Clear selects
   */
  public clearSelects (): void {
    this.selects = []
  }

  /**
   * Clear selects
   */
  public clearWhereGroups (): void {
    this.whereGroups = []
  }

  /**
   * Get all foreign keys
   */
  private getAllFks (): Promise<any> {
    return this.queryBuilderApiService.allFks({})
    .toPromise()
    .then((results) => {
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get tables
   */
  private getTables (): Promise<any> {
    return this.queryBuilderApiService.tables({})
    .toPromise()
    .then((results) => {
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Construct joins
   */
  public constructJoins (queryItem: QueryItem, joins: Array<QueryItem>): Array<QueryItem> {
    queryItem.joins.map((qiItem: QueryItem) => {
      console.log('qiItem', qiItem)
      const slimmed: QueryItem = new QueryItem(qiItem)
      slimmed.joins = []
      slimmed.joinsAvailable = []
      joins.push(slimmed)
      if (qiItem.joins.length > 0) {
        joins = this.constructJoins(qiItem, joins)
      }
    })
    return joins
  }

  /**
   * Construct query
   */
  public constructQuery (stripJoinsAvalable?: boolean): any {
    if (stripJoinsAvalable) {}

    const joins: any = []

    this.constructJoins(this.firstQueryItem, joins)

    // Check tables.
    if (this.firstQueryItem.joins.length === 0 || !this.firstQueryItem.joins[0].refTableName) {
      this.alertService.error('The first \'Table\' select is empty.', 10000)
      return
    }

    let invalidJoin: boolean
    invalidJoin = false

    joins.map((join) => {
      if (!join.refTableName) {
        invalidJoin = true
      }
    })

    if (invalidJoin) {
      this.alertService.error('You have an empty \'Table\' selector.', 10000)
      return false
    }

    // Check columns.
    if (this.selects.length === 0) {
      this.alertService.error('Please add a \'Column\'.', 10000)
      return
    }

    let invalidSelect: boolean
    invalidSelect = false

    this.selects.map((select) => {
      if (!select.column) {
        invalidSelect = true
      }
    })

    if (invalidSelect) {
      this.alertService.error('You have an empty \'Column\' selector.', 10000)
      return false
    }

    // Check where groups and wheres.
    let invalidWhereGroup: boolean
    invalidWhereGroup = false

    let invalidWhere: boolean
    invalidWhere = false

    this.whereGroups.map((whereGroup) => {
      if (whereGroup.wheres.length === 0) {
        invalidWhereGroup = true
      }

      whereGroup.wheres.map((where) => {
        if (!where.column || !where.operator || !where.value) {
          invalidWhere = true
        }
      })
    })

    if (invalidWhereGroup) {
      this.alertService.error('Please add a \'Where\' to the \'Where Group\'.', 10000)
      return false
    }

    if (invalidWhere) {
      this.alertService.error('You have empty\'Where\' field(s).', 10000)
      return false
    }

    const data: any = {
      selects: this.selects,
      joins: joins,
      whereGroups: this.whereGroups,
      offset: this.offset,
      limit: this.limit
    }

    return data
  }

  /**
   * Run query
   */
  public runQuery (): void {
    const data: any = this.constructQuery()

    if (!data) {
      return
    }

    this.queryBuilderApiService.runQuery(data)
    .toPromise()
    .then((results) => {
      this.results = results
      this.toTable(results.data)
      this.lastQuery = results.query
      this.saveDisabled = false
      this.saveAsDisabled = false
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err, 10000)
    })
  }

  /**
   * Download csv
   */
  public downloadCsv (): void {
    const data: any = this.constructQuery()

    if (!data) {
      return
    }

    this.queryBuilderApiService.csv(data)
    .toPromise()
    .then((result) => {
      const now: string = moment().local().format('YYYYMMDDHHmmss')

      let filename: string
      filename = now + ' query.csv'
      if (this.savedQueryForm.value.name) {
        filename = now + ' ' + this.savedQueryForm.value.name + '.csv'
      }

      const url: any = window.URL.createObjectURL(result)
      const a: HTMLAnchorElement = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('style', 'display: none')
      a.href = url
      a.download = filename
      a.click()
      window.URL.revokeObjectURL(url)
      a.remove()
      this.alertService.success('Downloaded: ' + filename, 10000)
      return
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err, 10000)
    })
  }

  /**
   * To table
   *
   * Convert for ngx-datatable
   */
  public toTable (results: Array<any>): void {
    let columns: Array<any>
    columns = []

    if (results.length > 0) {
      const firstRow: any = results[0]
      const keyNames: any = Object.keys(firstRow)
      keyNames.map((key) => {
        columns.push({ prop: key })
      })
    }

    this.columns = columns
    this.rows = results
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    if (data) {}
    event.preventDefault()
    event.stopPropagation()

    // Compile the assets.
    const jsQuery: any = {
      query: this.firstQueryItem,
      whereGroups: this.whereGroups,
      offset: this.offset,
      limit: this.limit,
      selects: this.selects,
      columnsAvailable: this.queryBuilderService.columnsAvailable,
      whereColumnsAvailable: this.queryBuilderService.whereColumnsAvailable
    }

    if (type === 'saveQueryModal') {
      if (this.savedQueryForm.value.uuid) {
        this.savedQueryForm.patchValue({
          jsQuery: JSON.stringify(jsQuery),
          sqlQuery: this.lastQuery
        })
      } else {
        this.savedQueryForm = this.savedQueryFormService.getForm(new SavedQuery({
          jsQuery: JSON.stringify(jsQuery),
          sqlQuery: this.lastQuery
        }), 'new')
      }
    }

    if (type === 'saveAsNewQueryModal') {
      const name: string = this.savedQueryForm.value.name + ' Copy'
      this.savedQueryForm = this.savedQueryFormService.getForm(new SavedQuery({
        name: name,
        jsQuery: JSON.stringify(jsQuery),
        sqlQuery: this.lastQuery
      }), 'new')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Get saved queries
   */
  public getSavedQueries (): Promise<any> {
    return this.savedQueryService.api.getMany()
    .toPromise()
    .then((results) => {
      this.savedQueries = results
      return this.savedQueries
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err, 10000)
    })
  }

  /**
   * Prep query for save
   */
  public prepQueryForSave (x: any): void {
    delete(x.joinsAvailable)
    delete(x.whereColumnsAvailable)
    x.joins.map((join) => {
      delete(join.joinsAvailable)
      this.prepQueryForSave(join)
    })
  }

  /**
   * Prep where groups for save
   */
  public prepWhereGroupsForSave (x: any): void {
    x.whereGroups.map((whereGroup) => {
      whereGroup.wheres.map((where) => {
        delete(where.options)
      })
    })
  }

  /**
   * Get saved queries
   */
  public saveSavedQuery (): void {
    // strip out joins available

    const jsQuery: any = JSON.parse(cloneDeep(this.savedQueryForm.value.jsQuery))

    delete(jsQuery.whereColumnsAvailable)

    delete(jsQuery.columnsAvailable)

    console.log('saveSavedQuery', jsQuery)

    this.prepQueryForSave(jsQuery.query)

    this.prepWhereGroupsForSave(jsQuery)

    console.log('cleaned', jsQuery.query)

    this.savedQueryForm.patchValue({
      jsQuery: JSON.stringify(jsQuery)
    }, { emitEvent: true })

    if (!this.savedQueryForm.valid) {

    }

    console.log('form to save', this.savedQueryForm.value)

    this.savedQueryService.api.save(this.savedQueryForm.value)
    .toPromise()
    .then((result) => {
      this.savedQueryForm.patchValue({ uuid: result.uuid, createdAt: result.createdAt, updatedAt: result.updatedAt }, { emitEvent: true })
      this.currentModal.close()
      this.getSavedQueries()
      .then(() => {
      })
      .catch((err) => {
        throw err
      })
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err, 10000)
    })
  }

}
