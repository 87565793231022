import { Router, NavigationEnd } from '@angular/router'
import { Injectable } from '@angular/core'
import { filter } from 'rxjs/operators'

@Injectable()

/**
 * Routing sttate service
 *
 * Ref: https://blog.hackages.io/our-solution-to-get-a-previous-route-with-angular-5-601c16621cf0
 */
export class RoutingStateService {
  private history: Array<any> = []

  constructor (
    private router: Router
  ) {}

  public loadRouting (): void {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    )
    .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      this.history = [...this.history, urlAfterRedirects]
    })
  }

  public getHistory (): Array<string> {
    return this.history
  }

  public getPreviousUrl (): string {
    return this.history[this.history.length - 2] || null
  }

  public goBack (elseGoTo?: Array<string>): void {
    const prevRoute: string = this.getPreviousUrl()

    if (prevRoute) {
      this.router.navigate([prevRoute])
      return
    }

    if (elseGoTo) {
      this.router.navigate(elseGoTo)
    }

  }
}
