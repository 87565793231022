import { Injectable } from '@angular/core'
import { FormArray, FormGroup, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { Person } from '@app/modules/person/person.model'
import { PersonFormService } from '@app/modules/person/person-form.service'

@Injectable()

export class ExistingPeopleFormService {

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private personFormService: PersonFormService,
  ) {}

  /**
   * Contacts
   *
   * @param contacts Contacts
   */
  public getForm (contacts: Array<Person>): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      contacts: this.formBuilder.array([]),
      contactsDeleted: this.formBuilder.array([]),
    })

    if (contacts) {
      const control: FormArray = form.get('contacts') as FormArray
      contacts.map((person: Person) => {
        const personForm: FormGroup = this.personFormService.getForm(
          new Person(person),
          ''
        )
        control.push(personForm)
      })
    }

    return form
  }

}
