/**
 * Select item
 *
 * Stores select columns for the query builder.
 */
export class SelectItem {
  /**
   * Id
   */
  uuid: string = null

  /**
   * Display name
   */
  displayName: string = null

  /**
   * Column name
   */
  column: string = null

  /**
   * Constructor
   *
   * @param {Partial<SelectItem>} data Initial values
   */
  public constructor (data?: Partial<SelectItem>) {
    if (data) {
      Object.assign(this, data)
    }
  }

}
