import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { Observable } from 'rxjs'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

@Injectable()

export class FmApiService extends ApiService {

  public inprocess: boolean
  public error: string
  public asyncSuccess: boolean

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/file-manager'
  }

  /**
   * Dummy
   */
  public dummy (uuid: string, data?: any): Observable<any> {
    if (data) {}
    return this.getOne(uuid)
  }

  /**
   * List
   */
  public list (data: any): Observable<any> {
    return this.postRaw(this.endPoint + '/list', data)
  }

  /**
   * Remove
   */
  public remove (data: any): Observable<any> {
    return this.postRaw(this.endPoint + '/remove', data)
  }

  /**
   * Download
   */
  public download (data: any): Observable<any> {
    console.log('should fire', data)
    return this.postDownload(this.endPoint + '/download', data)
  }

  /**
   * Rename
   */
  public rename (data: any): Observable<any> {
    return this.postRaw(this.endPoint + '/rename', data)
  }

  /**
   * Create folder
   */
  public createFolder (data: any): Observable<any> {
    return this.postRaw(this.endPoint + '/create-folder', data)
  }

}
