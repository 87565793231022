// Angular
import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
// Other
import { Subscription } from 'rxjs'
// Services.
import { AlertService } from '@app/modules/alert/alert.service'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
// Service models.
import { EmailAddressNameService } from '@app/modules/email-address-name/email-address-name.service'
// Related models.
import { EmailAddressService } from '@app/modules/email-address/email-address.service'

@Component({
  selector: 'app-new-email-address',
  templateUrl: '../../new-list-item.component.html'
})

export class NewEmailAddressNameComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Display vars
   *
   * On these list pages we should only have to change this and the model,
   * the view is shared.
   */
  public displayVars: any = {
    singular: 'Email Address Name',
    plural: 'Email Address Names',
    route: 'email-address-names',
    replaceOptions: 'emailAddressNameOptions'
  }

  /**
   * Main form
   */
  public mainForm: FormGroup

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public alertService: AlertService,
    public appService: AppService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public routingStateService: RoutingStateService,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public validationService: ValidationService,
    public mainService: EmailAddressNameService,
    public emailAddressService: EmailAddressService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.setUp()
  }

  /**
   * Route sub
   */
  public setUp (): void {
    this.mainForm = this.mainService.getForm({})
    this.display = true
  }

  /**
   * On replace and delete
   */
  public onSubmit (): void {
    this.mainService.api.save(this.mainForm.value)
    .toPromise()
    .then(() => {
      this.selectOptionsService.getEmailAddressNameOptions()
      .then(() => {
        this.router.navigate(['/lists', this.displayVars.route])
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
        console.error(err)
      })
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
      console.error(err)
    })
  }

}
