import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { Product } from '@app/modules/product/product.model'

export class ProductServiceModel extends Base {
  uuid: string = null
  name: string = null
  productUuid: string = null
  product: Product = null
  itemCode: string = null
  itemType: string = null
  quality: number = null
  unitCost: number = null
  unitPrice: number = null
  unitDiscount: number = null
  discountType: string = null
  discountPrice: number = null
  extendedAmount: number = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param data Initial values
   */
  public constructor (data?: Partial<ProductServiceModel>) {
    super(data)
    this.init(data)
  }
}
