import { Injectable, OnInit, OnDestroy } from '@angular/core'
import { LocalStorageService } from 'angular-2-local-storage'
import { FmItem } from '../models/fm-item.model'
import { ConfigService } from './config.service'
import { FmApiService } from './fm-api.service'
import { v4 as uuidv4 } from 'uuid'

@Injectable()

export class ApiMiddlewareService implements OnInit, OnDestroy {

  public config: any = {}

  /**
   * Constructor
   */
  public constructor (
    public localStorageService: LocalStorageService,
    private configService: ConfigService,
    public fmApiService: FmApiService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.config = this.configService.config
  }

  public getPath (arrayPath: Array<any>): string {
    return '/' + arrayPath.join('/')
  }

  public getFileList (files: Array<any>): any {
    return (files || []).map((file) => {
      return file && file.fullPath()
    })
  }

  public getFilePath (item: any): any {
    return item && item.fullPath()
  }

  // ApiMiddleware.prototype.list = function(path, customDeferredHandler) {
  //     return this.fmApiService.list(this.config.listUrl, this.getPath(path), customDeferredHandler);
  // };

  /**
   * List
   */
  public list (currentPath: any): Promise<Array<FmItem>> {
    const data: any = {
      path: currentPath
    }
    return this.fmApiService.list(data)
    .toPromise()
    // return this.fmApiService.list(this.config.listUrl, this.getPath(path), customDeferredHandler);
  }

  /**
   * Copy
   */
  public copy (files: any, path: any): any {
    const items: any = this.getFileList(files)
    const singleFilename: string = items.length === 1 ? files[0].tempModel.name : undefined

    const data: any = {
      items: items,
      path: this.getPath(path),
      singleFilename: singleFilename
    }
    // return this.fmApiService.copy(this.config.copyUrl, items, this.getPath(path), singleFilename)
    const uuid: string = uuidv4()
    return this.fmApiService.dummy(uuid, data)
  }

  /**
   * Move
   */
  public move (files: any, path: any): any {
    const items: any = this.getFileList(files)
    // return this.fmApiService.move(this.config.moveUrl, items, this.getPath(path))

    const data: any = {
      items: items,
      path: this.getPath(path)
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  /**
   * Remove
   */
  public remove (files: Array<FmItem>): any {
    const items: any = this.getFileList(files)

    const data: any = {
      action: 'remove',
      items: items
    }
    // return this.fmApiService.remove(this.config.removeUrl, items)
    return this.fmApiService.remove(data).toPromise()
  }

  public upload (files: any, path: any): any {
    // if (! $window.FormData) {
    //   throw new Error('Unsupported browser version')
    // }

    const destination: any = this.getPath(path)

    // return this.fmApiService.upload(this.config.uploadUrl, destination, files)
    const data: any = {
      destination: destination,
      files: files
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  public getContent (item: any): any {
    const itemPath: any = this.getFilePath(item)
    // return this.fmApiService.getContent(this.config.getContentUrl, itemPath)
    const data: any = {
      itemPath: itemPath
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  public edit (item: any): any {
    const itemPath: any = this.getFilePath(item)
    // return this.fmApiService.edit(this.config.editUrl, itemPath, item.tempModel.content)
    const data: any = {
      itemPath: itemPath,
      content: item.tempModel.content
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  /**
   * Rename
   */
  public rename (item: FmItem): Promise<any> {
    const itemPath: any = this.getFilePath(item)
    const newPath: any = item.tempModel.fullPath()
    const data: any = {
      action: 'rename',
      itemPath: itemPath,
      newPath: newPath
    }
    // return this.fmApiService.rename(this.config.renameUrl, itemPath, newPath)
    return this.fmApiService.rename(data).toPromise()
  }

  public getUrl (item: any): any {
    const itemPath: any = this.getFilePath(item)
    // eturn this.fmApiService.getUrl(this.config.downloadFileUrl, itemPath)
    const data: any = {
      itemPath: itemPath
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  /**
   * Download
   */
  public download (item: FmItem, forceNewWindow?: boolean): any {
    // TODO: add spinner to indicate file is downloading
    const itemPath: any = this.getFilePath(item)
    const toFilename: any = item.name

    if (item.isFolder()) {
      return
    }

    // return this.fmApiService.download(
    //     this.config.downloadFileUrl,
    //     itemPath,
    //     toFilename,
    //     this.config.downloadFilesByAjax,
    //     forceNewWindow
    // )
    const data: any = {
      itemPath: itemPath,
      toFilename: toFilename,
      forceNewWindow: forceNewWindow
    }
    console.log('data', data)

    return this.fmApiService.download(data)
  }

  public downloadMultiple (files: any, forceNewWindow?: boolean): any {
    const items: any = this.getFileList(files)
    const timestamp: any = new Date().getTime().toString().substr(8, 13)
    const toFilename: any = timestamp + '-' + this.config.multipleDownloadFileName

    // return this.fmApiService.downloadMultiple(
    //     this.config.downloadMultipleUrl,
    //     items,
    //     toFilename,
    //     this.config.downloadFilesByAjax,
    //     forceNewWindow
    // )
    const data: any = {
      items: items,
      toFilename: toFilename,
      forceNewWindow: forceNewWindow
    }
    return this.fmApiService.download(data).toPromise()
  }

  public compress (files: any, compressedFilename: any, path: any): any {
    const items: any = this.getFileList(files)
    // return this.fmApiService.compress(this.config.compressUrl, items, compressedFilename, this.getPath(path))
    const data: any = {
      items: items,
      compressedFilename: compressedFilename,
      path: this.getPath(path)
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  public extract (item: any, folderName: any, path: any): any {
    const itemPath: any = this.getFilePath(item)
    // return this.fmApiService.extract(this.config.extractUrl, itemPath, folderName, this.getPath(path))
    const data: any = {
      itemPath: itemPath,
      folderName: folderName,
      path: this.getPath(path)
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  public changePermissions (files: any, dataFmItem: any): any {
    const items: any = this.getFileList(files)
    const code: any = dataFmItem.tempModel.perms.toCode()
    const octal: any = dataFmItem.tempModel.perms.toOctal()
    const recursive: any = !!dataFmItem.tempModel.recursive

    // return this.fmApiService.changePermissions(this.config.permissionsUrl, items, code, octal, recursive)
    const data: any = {
      items: items,
      code: code,
      octal: octal,
      recursive: recursive
    }
    return this.fmApiService.dummy(uuidv4(), data)
  }

  /**
   * Create folder
   */
  public createFolder (item: FmItem): Promise<any> {
    const path: string = item.tempModel.fullPath()
    const data: any = {
      action: 'createFolder',
      newPath: path
    }
    // return this.fmApiService.createFolder(this.config.createFolderUrl, path)
    return this.fmApiService.createFolder(data).toPromise()
  }
}
