import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-table-employee-display',
  templateUrl: './table-employee-display.component.html',
  providers: []
})

export class TableEmployeeDisplayComponent implements OnInit, OnDestroy {

  /**
   * Telephones
   */
  @Input() employees: any

  /**
   * Display
   */
  public display: boolean

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * First item
   */
  public firstItem: any

  /**
   * Constructor
   */
  public constructor () {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    this.display = false

    if (this.employees && this.employees.length > 0) {
      this.employees.sort((a, b) => {
        if (a.firstName < b.firstName) {
          return -1
        }
        if (a.firstName > b.firstName) {
          return 1
        }
        return 0
      })
    }

    this.firstItem = this.employees.find((x) => x.isPrimary === true)

    if (!this.firstItem && this.employees && this.employees.length > 0) {
      this.firstItem = this.employees[0]
    }

    if (this.employees.length > 0) {
      this.display = true
    }
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

}
