import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'

@Component({
  selector: 'app-product-service-form',
  templateUrl: './product-service-form.component.html'
})

export class ProductServiceFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Product changed
   *
   * They haven't used any defaults in products
   * so no need top populate anything here.
   */
  public productChanged (): void {
    // console.log('productChanged', this.mainForm.value.productUuid)
    // const product: Product = this.selectOptionsService.productOptions.find(x => x.uuid === this.mainForm.value.productUuid)
    // console.log(product)
  }

  /**
   * Product changed
   *
   * They haven't used any defaults in products
   * so no need top populate anything here.
   */
  public priceChanged (): void {
    this.mainForm.patchValue({
      unitDiscount: 0
    })

    let discountPrice: number
    discountPrice = 0
    if (this.mainForm.value.unitPrice && this.mainForm.value.unitPrice > 0) {
      discountPrice = this.mainForm.value.unitPrice
    }

    this.mainForm.patchValue({
      discountPrice: Math.round(discountPrice * 100) / 100
    })

    this.calcSubTotal()
  }

  /**
   * Adjusted price changed
   *
   * Set the discount % (unitDiscount) and extendedAmount (subtotal).
   */
  public adjustedPriceChanged (): void {
    if (!this.mainForm.value.unitPrice) {
      this.mainForm.patchValue({
        unitPrice: this.mainForm.value.discountPrice
      })
    }

    if (this.mainForm.value.unitPrice && this.mainForm.value.unitPrice > 0) {
      const unitDiscount: number = (((this.mainForm.value.unitPrice - this.mainForm.value.discountPrice) /
      (this.mainForm.value.unitPrice)) * 100)

      this.mainForm.patchValue({
        unitDiscount: Math.round(unitDiscount * 100) / 100
      }, { emitEvent: false })

      this.discountChanged()
    }
  }

  /**
   * Discount changed
   *
   * Set the adjusted price (discountPrice) and extendedAmount (subtotal).
   */
  public discountChanged (): void {
    if (this.mainForm.value.unitDiscount > 0) {
      const discountAmount: number = ((this.mainForm.value.unitPrice / 100) *
      this.mainForm.value.unitDiscount)
      this.mainForm.patchValue({
        discountPrice: Math.round((this.mainForm.value.unitPrice - discountAmount) * 100) / 100
      })
    }
    this.calcSubTotal()
  }

  /**
   * Discount changed
   *
   * Set the adjusted price (discountPrice) and extendedAmount (subtotal).
   */
  public calcSubTotal (): void {
      const extendedAmount: number = this.mainForm.value.discountPrice
      this.mainForm.patchValue({
        extendedAmount: Math.round(extendedAmount * 100) / 100
      })
  }

}
