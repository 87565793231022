import { Base } from '@app/shared/models/base.model'
import { Address } from '@app/modules/address/address.model'
import { Company } from '@app/modules/company/company.model'
import { EmailAddress } from '@app/modules/email-address/email-address.model'
import { Social } from '@app/modules/social/social.model'
import { Telephone } from '@app/modules/telephone/telephone.model'
import { Uri } from '@app/modules/uri/uri.model'
import { Task } from '@app/modules/task/task.model'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { PersonToCompany } from '@app/modules/person-to-company/person-to-company.model'
import { DeleteId } from '@app/modules/delete-id/delete-id.model'

export class Person extends Base {
  uuid: string = null
  titleUuid: string = null
  firstName: string = null
  middleNames: string = null
  lastName: string = null
  suffixes: string = null
  shortname: string = null
  genderUuid: string = null
  avatarUri: string = null
  dob: Date = null
  occupation: string = null
  // Info.
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  updatedByUuid: string = null
  // Relations.
  addresses: Array<Address> = []
  companies: Array<Company> = []
  personToCompany: PersonToCompany = null
  emailAddresses: Array<EmailAddress> = []
  socials: Array<Social> = []
  telephones: Array<Telephone> = []
  uris: Array<Uri> = []
  tasks: Array<Task> = []
  opportunities: Array<Opportunity> = []
  // Deletions.
  // We need these to keep track of items we want to delete
  // when they are removed from the form.
  addressesDeleted: Array<DeleteId> = []
  companiesDeleted: Array<DeleteId> = []
  emailAddressesDeleted: Array<DeleteId> = []
  socialsDeleted: Array<DeleteId> = []
  telephonesDeleted: Array<DeleteId> = []
  urisDeleted: Array<DeleteId> = []
  tasksDeleted: Array<DeleteId> = []

  /**
   * Properties with type Date
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt',
    'dob'
  ]

  /**
   * Constructor
   *
   * @param data Initial values
   */
  public constructor (data?: Partial<Person>) {
    super(data)
    this.init(data)
  }
}
