import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { Company } from '@app/modules/company/company.model'
import { Site } from '@app/modules/site/site.model'
import { OpportunityStatus } from '@app/modules/opportunity-status/opportunity-status.model'
import { OpportunityProcess } from '@app/modules/opportunity-process/opportunity-process.model'
import { OpportunityStage } from '@app/modules/opportunity-stage/opportunity-stage.model'
import { OpportunityAgreement } from '@app/modules/opportunity-agreement/opportunity-agreement.model'
import { Probability } from '@app/modules/probability/probability.model'
import { LostReason } from '@app/modules/lost-reason/lost-reason.model'
import { Shutdown } from '@app/modules/shutdown/shutdown.model'
import { ProductServiceModel } from '@app/modules/product-service'
import { Person } from '@app/modules/person/person.model'
// import { DeleteId } from '@app/modules/delete-id/delete-id.model'

export class Opportunity extends Base {
  public uuid: string = null
  num: number = null
  name: string = null
  proposalNo: string = null // Old
  newProposalNo: string = null // New just number
  proposalNoRev: string = null // Rev only
  jobNo: string = null // Old
  newJobNo: string = null // New just number
  jobNoRev: string = null // Rev only
  companyUuid: string = null
  company: Company = null
  siteUuid: string = null
  site: Site = null
  opportunityStatusUuid: string = null
  opportunityStatus: OpportunityStatus = null
  opportunityProcessUuid: string = null
  opportunityProcess: OpportunityProcess = null
  opportunityStageUuid: string = null
  opportunityStage: OpportunityStage = null
  opportunityAgreements: Array<OpportunityAgreement> = []
  contacts: Array<Person> = []
  contactsDeleted: Array<Person> = []
  productServices: Array<ProductServiceModel> = []
  salesPersonUserUuid: string = null
  salesPersonUser: User = null
  probabilityUuid: string = null
  probability: Probability = null
  estimatedStart: Date = null
  estimatedCompletion: Date = null
  expectedOrderReceived: Date = null
  orderReceived: Date = null
  finalInvoice: number = null
  finalInvoiceDate: Date = null
  lostReasonUuid: string = null
  lostReason: LostReason = null
  refIir: boolean = null
  shutdownUuid: string = null // ??
  shutdown: Shutdown = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt',
    'estimatedStart',
    'estimatedCompletion',
    'expectedOrderReceived',
    'orderReceived',
    'finalInvoiceDate'
  ]

  /**
   * Constructor
   *
   * @param data Initial values
   */
  public constructor (data?: Partial<Opportunity>) {
    super(data)
    this.init(data)
  }
}
