import { Routes } from '@angular/router'
import { HomeComponent } from '@app/core/home/home.component'
import { BasicLayoutComponent } from '@app/core/basic-layout/basic-layout.component'
import { BlankLayoutComponent } from '@app/core/blank-layout/blank-layout.component'
import { LoginComponent } from '@app/modules/auth/components/login/login.component'
import { DashboardComponent } from '@app/modules/dashboard/components/dashboard/dashboard.component'
import { PageNotFoundComponent } from '@app/core/errors/page-not-found/page-not-found.component'

import {
  EditOpportunityComponent,
  ViewOpportunitiesComponent
} from '@app/modules/opportunity/components'

// Contacts.
import { EditCompanyComponent } from '@app/modules/company/components/edit-company/edit-company.component'
import { ViewCompaniesComponent } from '@app/modules/company/components/view-companies/view-companies.component'

import { EditPersonComponent } from '@app/modules/person/components/edit-person/edit-person.component'
import { ViewPeopleComponent } from '@app/modules/person/components/view-people/view-people.component'

import { EditSiteComponent } from '@app/modules/site/components/edit-site/edit-site.component'
import { ViewSitesComponent } from '@app/modules/site/components/view-sites/view-sites.component'

// Contacts old.
// import { ViewContactsComponent } from './contacts/view-contacts/view-contacts.component'
// import { NewContactComponent } from './contacts/new-contact/new-contact.component'
// import { EditContactComponent } from './contacts/edit-contact/edit-contact.component'

// Admin
import { EditUserComponent } from '@app/modules/user/components/edit-user/edit-user.component'
// import { NewUserComponent } from '@app/modules/user/components/new-user/new-user.component'
import { ViewUsersComponent } from '@app/modules/user/components/view-users/view-users.component'

// Lists.
import { EditAddressNameComponent } from '@app/lists/address-names/edit-address-name/edit-address-name.component'
import { NewAddressNameComponent } from '@app/lists/address-names/new-address-name/new-address-name.component'
import { ViewAddressNamesComponent } from '@app/lists/address-names/view-address-names/view-address-names.component'
import { EditAgreementComponent } from '@app/lists/agreements/edit-agreement/edit-agreement.component'
import { NewAgreementComponent } from '@app/lists/agreements/new-agreement/new-agreement.component'
import { ViewAgreementsComponent } from '@app/lists/agreements/view-agreements/view-agreements.component'
import { EditCompanyTypeComponent } from '@app/lists/company-types/edit-company-type/edit-company-type.component'
import { NewCompanyTypeComponent } from '@app/lists/company-types/new-company-type/new-company-type.component'
import { ViewCompanyTypesComponent } from '@app/lists/company-types/view-company-types/view-company-types.component'
import { EditCountryComponent } from '@app/lists/countries/edit-country/edit-country.component'
import { NewCountryComponent } from '@app/lists/countries/new-country/new-country.component'
import { ViewCountriesComponent } from '@app/lists/countries/view-countries/view-countries.component'
import { EditDepartmentComponent } from '@app/lists/departments/edit-department/edit-department.component'
import { NewDepartmentComponent } from '@app/lists/departments/new-department/new-department.component'
import { ViewDepartmentsComponent } from '@app/lists/departments/view-departments/view-departments.component'
import { EditEmailAddressNameComponent } from '@app/lists/email-address-names/edit-email-address-name/edit-email-address-name.component'
import { NewEmailAddressNameComponent } from '@app/lists/email-address-names/new-email-address-name/new-email-address-name.component'
import { ViewEmailAddressNamesComponent } from '@app/lists/email-address-names/view-email-address-names/view-email-address-names.component'
import { EditGenderComponent } from '@app/lists/genders/edit-gender/edit-gender.component'
import { NewGenderComponent } from '@app/lists/genders/new-gender/new-gender.component'
import { ViewGendersComponent } from '@app/lists/genders/view-genders/view-genders.component'
import { EditLostReasonComponent } from '@app/lists/lost-reasons/edit-lost-reason/edit-lost-reason.component'
import { NewLostReasonComponent } from '@app/lists/lost-reasons/new-lost-reason/new-lost-reason.component'
import { ViewLostReasonsComponent } from '@app/lists/lost-reasons/view-lost-reasons/view-lost-reasons.component'
import { EditNoteTypeComponent } from '@app/lists/note-types/edit-note-type/edit-note-type.component'
import { NewNoteTypeComponent } from '@app/lists/note-types/new-note-type/new-note-type.component'
import { ViewNoteTypesComponent } from '@app/lists/note-types/view-note-types/view-note-types.component'
import {
  EditOpportunityProcessComponent
} from '@app/lists/opportunity-processes/edit-opportunity-process/edit-opportunity-process.component'
import { NewOpportunityProcessComponent } from '@app/lists/opportunity-processes/new-opportunity-process/new-opportunity-process.component'
import {
  ViewOpportunityProcessesComponent
} from '@app/lists/opportunity-processes/view-opportunity-processes/view-opportunity-processes.component'
import { EditOpportunityStageComponent } from '@app/lists/opportunity-stages/edit-opportunity-stage/edit-opportunity-stage.component'
import { NewOpportunityStageComponent } from '@app/lists/opportunity-stages/new-opportunity-stage/new-opportunity-stage.component'
import { ViewOpportunityStagesComponent } from '@app/lists/opportunity-stages/view-opportunity-stages/view-opportunity-stages.component'
import { EditOpportunityStatusComponent } from '@app/lists/opportunity-statuses/edit-opportunity-status/edit-opportunity-status.component'
import { NewOpportunityStatusComponent } from '@app/lists/opportunity-statuses/new-opportunity-status/new-opportunity-status.component'
import {
  ViewOpportunityStatusesComponent
} from '@app/lists/opportunity-statuses/view-opportunity-statuses/view-opportunity-statuses.component'
import { EditProbabilityComponent } from '@app/lists/probabilities/edit-probability/edit-probability.component'
import { NewProbabilityComponent } from '@app/lists/probabilities/new-probability/new-probability.component'
import { ViewProbabilitiesComponent } from '@app/lists/probabilities/view-probabilities/view-probabilities.component'
import { EditProductComponent } from '@app/lists/products/edit-product/edit-product.component'
import { NewProductComponent } from '@app/lists/products/new-product/new-product.component'
import { ViewProductsComponent } from '@app/lists/products/view-products/view-products.component'
import { EditSocialNameComponent } from '@app/lists/social-names/edit-social-name/edit-social-name.component'
import { NewSocialNameComponent } from '@app/lists/social-names/new-social-name/new-social-name.component'
import { ViewSocialNamesComponent } from '@app/lists/social-names/view-social-names/view-social-names.component'
import { EditSuppliesWhatComponent } from '@app/lists/supplies-what/edit-supplies-what/edit-supplies-what.component'
import { NewSuppliesWhatComponent } from '@app/lists/supplies-what/new-supplies-what/new-supplies-what.component'
import { ViewSuppliesWhatComponent } from '@app/lists/supplies-what/view-supplies-what/view-supplies-what.component'
import { EditTelephoneNameComponent } from '@app/lists/telephone-names/edit-telephone-name/edit-telephone-name.component'
import { NewTelephoneNameComponent } from '@app/lists/telephone-names/new-telephone-name/new-telephone-name.component'
import { ViewTelephoneNamesComponent } from '@app/lists/telephone-names/view-telephone-names/view-telephone-names.component'
import { EditTitleComponent } from '@app/lists/titles/edit-title/edit-title.component'
import { NewTitleComponent } from '@app/lists/titles/new-title/new-title.component'
import { ViewTitlesComponent } from '@app/lists/titles/view-titles/view-titles.component'
import { EditUriNameComponent } from '@app/lists/uri-names/edit-uri-name/edit-uri-name.component'
import { NewUriNameComponent } from '@app/lists/uri-names/new-uri-name/new-uri-name.component'
import { ViewUriNamesComponent } from '@app/lists/uri-names/view-uri-names/view-uri-names.component'

import { PendingChangesGuard } from '@app/shared/guards/pending-changes.guard'

import {
  EditContactGroupComponent,
  ViewContactGroupsComponent
} from '@app/modules/contact-group/components'

import {
  QueryBuilderComponent
} from '@app/modules/query-builder/components'

import {
    ViewCalendarComponent,
    EditTaskComponent,
    ViewTasksComponent
} from '@app/modules/task/components'

import {
    FmComponent
} from '@app/modules/filemanager/components'

import {
    ViewUnallocatedEmailComponent
} from '@app/modules/unallocated-email/components'

import {
  SyncDatabaseComponent
} from '@app/modules/sync/components'

import { MsalGuard } from '@azure/msal-angular'

import { AuthGuard } from '@app/shared/guards/auth.guard'

export const ROUTES: Routes = [

  // Remember something/new before something/:uuid (view).
  // Main redirect
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'file-manager',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        component: FmComponent
      }
    ]
  },
  // Home
  {
    path: 'home', component: HomeComponent,
    canActivate: [AuthGuard]
  },
  // App views
  {
    path: '', component: BlankLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'page-not-found', component: PageNotFoundComponent }
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent }
    ]
  },
  {
    path: 'authorize', component: BasicLayoutComponent,
    canActivate : [AuthGuard, MsalGuard]
  },
  // {
  //   path: '', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'contacts', component: ViewContactsComponent }
  //   ]
  // },
  // {
  //   path: '', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'contacts/new', component: NewContactComponent }
  //   ]
  // },
  // {
  //   path: '', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'clients/:uuid', component: ViewContactComponent }
  //   ]
  // },
  // {
  //   path: '', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'contacts/:uuid/edit', component: EditContactComponent }
  //   ]
  // },
  {
    path: 'opportunities',

    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ViewOpportunitiesComponent
      },
      {
        path: ':uuid',
        component: EditOpportunityComponent,
        canDeactivate: [
          PendingChangesGuard
        ]
      }
    ]
  },
  // {
  //   path: 'contacts',
  //   children: [

  //   ]
  // },
  {
    path: 'companies',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ViewCompaniesComponent
      },
      {
        path: ':uuid',
        component: EditCompanyComponent,
        canDeactivate: [
          PendingChangesGuard
        ]
      }
    ]
  },
  {
    path: 'people',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ViewPeopleComponent
      },
      {
        path: ':uuid',
        component: EditPersonComponent,
        canDeactivate: [
          PendingChangesGuard
        ]
      }
    ]
  },
  {
    path: 'sites',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ViewSitesComponent
      },
      {
        path: ':uuid',
        component: EditSiteComponent,
        canDeactivate: [
          PendingChangesGuard
        ]
      }
    ]
  },
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'calendar', component: ViewCalendarComponent
      }
    ]
  },
  // Tasks.NewTaskComponent
  {
    path: 'tasks',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ViewTasksComponent
      },
      {
        path: ':uuid',
        component: EditTaskComponent,
        canDeactivate: [
          PendingChangesGuard
        ]
      }
    ]
  },
  // Tasks.NewTaskComponent
  {
    path: '', component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'unallocated-email',
        component: ViewUnallocatedEmailComponent
      }
    ]
  },
  // Admin.
  {
    path: 'admin',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: ''
      //   // component: AdminHomeComponent
      // },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: ViewUsersComponent
          },
          // {
          //   path: 'new',
          //   component: NewUserComponent
          // },
          {
            path: ':uuid',
            component: EditUserComponent
          }
        ]
      },
      {
        path: 'query-builder',
        children: [
          {
            path: '',
            component: QueryBuilderComponent
          },
          {
            path: ':uuid',
            component: QueryBuilderComponent
          }
        ]
      },
      {
        path: 'contact-groups',
        children: [
          {
            path: '',
            component: ViewContactGroupsComponent
          },
          {
            path: ':uuid',
            component: EditContactGroupComponent
          }
        ]
      },
      {
        path: 'sync',
        children: [
          {
            path: '',
            component: SyncDatabaseComponent
          }
        ]
      }
    ]
  },
  // Lists.
  {
    path: 'lists',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: ''
      //   // component: AdminHomeComponent
      // },
      {
        path: 'address-names',
        children: [
          {
            path: '',
            component: ViewAddressNamesComponent
          },
          {
            path: 'new',
            component: NewAddressNameComponent
          },
          {
            path: ':uuid',
            component: EditAddressNameComponent
          }
        ]
      },
      {
        path: 'agreements',
        children: [
          {
            path: '',
            component: ViewAgreementsComponent
          },
          {
            path: 'new',
            component: NewAgreementComponent
          },
          {
            path: ':uuid',
            component: EditAgreementComponent
          }
        ]
      },
      {
        path: 'company-types',
        children: [
          {
            path: '',
            component: ViewCompanyTypesComponent
          },
          {
            path: 'new',
            component: NewCompanyTypeComponent
          },
          {
            path: ':uuid',
            component: EditCompanyTypeComponent
          }
        ]
      },
      {
        path: 'countries',
        children: [
          {
            path: '',
            component: ViewCountriesComponent
          },
          {
            path: 'new',
            component: NewCountryComponent
          },
          {
            path: ':uuid',
            component: EditCountryComponent
          }
        ]
      },
      {
        path: 'departments',
        children: [
          {
            path: '',
            component: ViewDepartmentsComponent
          },
          {
            path: 'new',
            component: NewDepartmentComponent
          },
          {
            path: ':uuid',
            component: EditDepartmentComponent
          }
        ]
      },
      {
        path: 'email-address-names',
        children: [
          {
            path: '',
            component: ViewEmailAddressNamesComponent
          },
          {
            path: 'new',
            component: NewEmailAddressNameComponent
          },
          {
            path: ':uuid',
            component: EditEmailAddressNameComponent
          }
        ]
      },
      {
        path: 'genders',
        children: [
          {
            path: '',
            component: ViewGendersComponent
          },
          {
            path: 'new',
            component: NewGenderComponent
          },
          {
            path: ':uuid',
            component: EditGenderComponent
          }
        ]
      },
      {
        path: 'lost-reasons',
        children: [
          {
            path: '',
            component: ViewLostReasonsComponent
          },
          {
            path: 'new',
            component: NewLostReasonComponent
          },
          {
            path: ':uuid',
            component: EditLostReasonComponent
          }
        ]
      },
      {
        path: 'note-types',
        children: [
          {
            path: '',
            component: ViewNoteTypesComponent
          },
          {
            path: 'new',
            component: NewNoteTypeComponent
          },
          {
            path: ':uuid',
            component: EditNoteTypeComponent
          }
        ]
      },
      {
        path: 'opportunity-processes',
        children: [
          {
            path: '',
            component: ViewOpportunityProcessesComponent
          },
          {
            path: 'new',
            component: NewOpportunityProcessComponent
          },
          {
            path: ':uuid',
            component: EditOpportunityProcessComponent
          }
        ]
      },
      {
        path: 'opportunity-stages',
        children: [
          {
            path: '',
            component: ViewOpportunityStagesComponent
          },
          {
            path: 'new',
            component: NewOpportunityStageComponent
          },
          {
            path: ':uuid',
            component: EditOpportunityStageComponent
          }
        ]
      },
      {
        path: 'opportunity-statuses',
        children: [
          {
            path: '',
            component: ViewOpportunityStatusesComponent
          },
          {
            path: 'new',
            component: NewOpportunityStatusComponent
          },
          {
            path: ':uuid',
            component: EditOpportunityStatusComponent
          }
        ]
      },
      {
        path: 'probabilities',
        children: [
          {
            path: '',
            component: ViewProbabilitiesComponent
          },
          {
            path: 'new',
            component: NewProbabilityComponent
          },
          {
            path: ':uuid',
            component: EditProbabilityComponent
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            component: ViewProductsComponent
          },
          {
            path: 'new',
            component: NewProductComponent
          },
          {
            path: ':uuid',
            component: EditProductComponent
          }
        ]
      },
      {
        path: 'social-names',
        children: [
          {
            path: '',
            component: ViewSocialNamesComponent
          },
          {
            path: 'new',
            component: NewSocialNameComponent
          },
          {
            path: ':uuid',
            component: EditSocialNameComponent
          }
        ]
      },
      {
        path: 'supplies-what',
        children: [
          {
            path: '',
            component: ViewSuppliesWhatComponent
          },
          {
            path: 'new',
            component: NewSuppliesWhatComponent
          },
          {
            path: ':uuid',
            component: EditSuppliesWhatComponent
          }
        ]
      },
      {
        path: 'telephone-names',
        children: [
          {
            path: '',
            component: ViewTelephoneNamesComponent
          },
          {
            path: 'new',
            component: NewTelephoneNameComponent
          },
          {
            path: ':uuid',
            component: EditTelephoneNameComponent
          }
        ]
      },
      {
        path: 'titles',
        children: [
          {
            path: '',
            component: ViewTitlesComponent
          },
          {
            path: 'new',
            component: NewTitleComponent
          },
          {
            path: ':uuid',
            component: EditTitleComponent
          }
        ]
      },
      {
        path: 'uri-names',
        children: [
          {
            path: '',
            component: ViewUriNamesComponent
          },
          {
            path: 'new',
            component: NewUriNameComponent
          },
          {
            path: ':uuid',
            component: EditUriNameComponent
          }
        ]
      }
    ]
  },
  // Handle all other routes
  { path: '**', redirectTo: 'page-not-found' }
]
