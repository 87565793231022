import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { SocialApiService } from '@app/modules/social/social-api.service'
import { SocialNameService } from '@app/modules/social-name/social-name.service'

@Injectable()

export class SocialService {

  /**
   * To delete
   *
   * Id's marked for deleting.
   */
  public toDelete: Array<number> = []

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder,
    public myUtil: MyUtilityService,
    public api: SocialApiService,
    public socialNameService: SocialNameService
  ) {}

  /**
   * Get form
   */
  public getForm (social?: any | Object): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      socialNameUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      accountName: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      accountLink: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (!this.myUtil.isEmpty(social)) {
      if (Object.keys(social).length !== 0) {
        form.patchValue(social, { emitEvent: false })
      }
    }

    return form
  }

}
