import { Injectable, OnInit, OnDestroy } from '@angular/core'

@Injectable()

export class TranslateService implements OnInit, OnDestroy {

  public translations: any = {}
  public currentLanguage: any

  /**
   * Constructor
   */
  public constructor () {
    this.translations.en = {
      filemanager: 'File Manager',
      language: 'Language',
      english: 'English',
      spanish: 'Spanish',
      portuguese: 'Portuguese',
      french: 'French',
      german: 'German',
      hebrew: 'Hebrew',
      italian: 'Italian',
      slovak: 'Slovak',
      chinese_tw: 'Traditional Chinese',
      chinese_cn: 'Simple Chinese',
      russian: 'Russian',
      ukrainian: 'Ukrainian',
      turkish: 'Turkish',
      persian: 'Persian',
      polish: 'Polish',
      dutch:  'Dutch',
      confirm: 'Confirm',
      cancel: 'Cancel',
      close: 'Close',
      upload_files: 'Upload files',
      files_will_uploaded_to: 'Files will be uploaded to',
      select_files: 'Select files',
      uploading: 'Uploading',
      permissions: 'Permissions',
      select_destination_folder: 'Select the destination folder',
      source: 'Source',
      destination: 'Destination',
      copy_file: 'Copy file',
      sure_to_delete: 'Are you sure you want to delete',
      change_name_move: 'Change name / move',
      enter_new_name_for: 'Enter new name for',
      extract_item: 'Extract item',
      extraction_started: 'Extraction started in a background process',
      compression_started: 'Compression started in a background process',
      enter_folder_name_for_extraction: 'Enter the folder name for the extraction of',
      enter_file_name_for_compression: 'Enter the file name for the compression of',
      toggle_fullscreen: 'Toggle fullscreen',
      edit_file: 'Edit file',
      file_content: 'File content',
      loading: 'Loading',
      search: 'Search',
      create_folder: 'Create folder',
      create: 'Create',
      folder_name: 'Folder name',
      upload: 'Upload',
      change_permissions: 'Change permissions',
      change: 'Change',
      details: 'Details',
      icons: 'Icons',
      list: 'List',
      name: 'Name',
      size: 'Size',
      actions: 'Actions',
      date: 'Date',
      selection: 'Selection',
      no_files_in_folder: 'No files in this folder',
      no_folders_in_folder: 'This folder not contains children folders',
      select_this: 'Select this',
      go_back: 'Go back',
      wait: 'Wait',
      move: 'Move',
      download: 'Download',
      view_item: 'View item',
      remove: 'Delete',
      edit: 'Edit',
      save: 'Save',
      copy: 'Copy',
      rename: 'Rename',
      extract: 'Extract',
      compress: 'Compress',
      error_invalid_filename: 'Invalid filename or already exists, specify another name',
      error_modifying: 'An error occurred modifying the file',
      error_deleting: 'An error occurred deleting the file or folder',
      error_renaming: 'An error occurred renaming the file',
      error_copying: 'An error occurred copying the file',
      error_compressing: 'An error occurred compressing the file or folder',
      error_extracting: 'An error occurred extracting the file',
      error_creating_folder: 'An error occurred creating the folder',
      error_getting_content: 'An error occurred getting the content of the file',
      error_changing_perms: 'An error occurred changing the permissions of the file',
      error_uploading_files: 'An error occurred uploading files',
      sure_to_start_compression_with: 'Are you sure you want to compress',
      owner: 'Owner',
      group: 'Group',
      others: 'Others',
      read: 'Read',
      write: 'Write',
      exec: 'Exec',
      original: 'Original',
      changes: 'Changes',
      recursive: 'Recursive',
      preview: 'FmItem preview',
      open: 'Open',
      these_elements: 'these {{total}} elements',
      new_folder: 'New folder',
      download_as_zip: 'Download as ZIP',
      back_one_directory: 'Back',
      more: 'More'
    }

    this.currentLanguage = this.translations['en']
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
  }

  /**
   * On init
   */
  public ngOnInit (): void {
  }

  public use (value: string): void {
    this.currentLanguage = this.translations[value]
  }

  public instant (value: string): string {
    return value
  }

  public translate (value: string): string {
    return this.currentLanguage[value]
  }

}
