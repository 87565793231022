import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { CompanyService } from '@app/modules/company/company.service'

import { LocalStorageService } from 'angular-2-local-storage'

import {
  OpportunityFormService,
  Opportunity,
  OpportunityService
} from '@app/modules/opportunity'

@Component({
  selector: 'app-view-opportunities',
  templateUrl: './view-opportunities.component.html'
})

export class ViewOpportunitiesComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Opportunities
   */
  public opportunities: Array<any> = []

  /**
   * Opportunity form
   */
  public opportunityForm: FormGroup

  /**
   * Search form
   */
  public searchForm: FormGroup

  /**
   * Page options
   */
  public pageOptions: Array<any>

  /**
   * Company options
   */
  public companyOptions: Array<any>

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    private renderer: Renderer,
    private router: Router,
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    public opportunityService: OpportunityService,
    private opportunityFormService: OpportunityFormService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = true
    this.searchForm = this.opportunityService.getSearchForm()
    const lastSearch: any = this.localStorageService.get('opportunities.search')
    if (lastSearch) {
      this.searchForm.patchValue(lastSearch, { emitEvent: false })
    }
    this.searchSub()
    this.searchOpportunities()
    this.getCompanyOptions()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.opportunityService.pagination.currentPage = 1
      this.searchOpportunities()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search opportunities
   */
  public searchOpportunities (): Promise<any> {
    this.localStorageService.set('opportunities.search', this.searchForm.value)
    return this.opportunityService.search(this.searchForm.value)
    .then((results) => {
      this.opportunityService.pagination.currentPageSize = results.length

      this.getPagesOptions()
      this.opportunities = results
      this.display = true
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get pages options
   */
  public getPagesOptions (): void {
    const pageOptions: Array<any> = []
    const totalItems: number = this.opportunityService.pagination.totalItems
    const pageSize: number = this.opportunityService.pagination.pageSize
    const pageCount: number = Math.ceil(totalItems / pageSize)
    let i: number
    for (i = 1; i <= pageCount; i++) {
      pageOptions.push({
        key: i,
        val: i
      })
    }

    this.pageOptions = pageOptions
  }

  /**
   * Go to page
   */
  public goToPage (event: Event): void {
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.searchOpportunities()
  }
  /**
   * Change page size
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.opportunityService.pagination.currentPage = 1
    this.searchOpportunities()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.localStorageService.remove('opportunities.search')
    this.opportunityService.sort = {
      column: 'opportunity.updatedAt',
      direction: 'DESC'
    }

    this.searchForm.reset()
    this.searchForm.patchValue({
      finalInvoiceOperator: 'eq'
    })
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    this.opportunityService.setSort(column)
    this.searchOpportunities()
  }

  /**
   * Get company options
   */
  public getCompanyOptions (): void {
    const filter: any | Object = {
      page: 0,
      limit: 1000000,
      sort: [
        {
          key: 'updated_at',
          direction: 'ASC'
        }
      ]
    }
    this.companyService.api.getMany(filter)
    .toPromise()
    .then((results) => {
      this.companyOptions = results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newOpportunity') {
      const opportunity: Opportunity = new Opportunity()
      this.opportunityForm = this.opportunityFormService.getForm(opportunity, 'new')
    }

    if (type === 'editOpportunity') {
      const opportunity: Opportunity = new Opportunity(data)
      this.opportunityForm = this.opportunityFormService.getForm(opportunity, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save Opportunity
   */
  public saveOpportunity (): void {
    console.log('saveOpportunity', this.opportunityForm.value)
    this.validationService.runValidation(this.opportunityForm)

    if (!this.opportunityForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.opportunityForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.opportunityService.saveOpportunity(this.opportunityForm.value)
    .then((result) => {
      this.currentModal.close()
      this.router.navigate(['/opportunities', result.uuid])

    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

}
