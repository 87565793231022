import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { LocalStorageService } from 'angular-2-local-storage'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { PersonApiService } from '@app/modules/person/person-api.service'
import { Person } from '@app/modules/person/person.model'
import { Address } from '@app/modules/address/address.model'
import { Company } from '@app/modules/company/company.model'
import { EmailAddress } from '@app/modules/email-address/email-address.model'
import { Social } from '@app/modules/social/social.model'
import { Telephone } from '@app/modules/telephone/telephone.model'
import { Uri } from '@app/modules/uri/uri.model'
import { DeleteId } from '@app/modules/delete-id/delete-id.model'

@Injectable()

export class PersonService {

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0,
    itemIds: []
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'person.firstName',
    direction: 'ASC'
  }

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private myUtil: MyUtilityService,
    public api: PersonApiService
  ) {}

  // ---------------------------------------------------------------------------
  // Person Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      name: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      firstName: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      lastName: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      company: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      email: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      telephone: [
        null,
        [
          Validators.maxLength(191)
        ]
      ]
    })

    return form
  }

  /**
   * Set sort
   */
  public setSort (column: any | Object): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   * Note search filters
   */
  public search (searchFilters: any | Object): Promise<any> {
    const filters: Array<any> = []

    if (searchFilters.fullName) {
      filters.push({
        key: 'fullName',
        value: searchFilters.fullName,
        operator: 'ct'
      })
    }

    if (searchFilters.firstName) {
      filters.push({
        key: 'firstName',
        value: searchFilters.firstName,
        operator: 'ct'
      })
    }

    if (searchFilters.lastName) {
      filters.push({
        key: 'lastName',
        value: searchFilters.lastName,
        operator: 'ct'
      })
    }

    if (searchFilters.company) {
      filters.push({
        key: 'companies.name',
        value: searchFilters.company,
        operator: 'ct'
      })
    }

    if (searchFilters.email) {
      filters.push({
        key: 'emailAddresses.email',
        value: searchFilters.email,
        operator: 'ct'
      })
    }

    if (searchFilters.telephone) {
      filters.push({
        key: 'telephones.number',
        value: searchFilters.telephone,
        operator: 'ct'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: false,
          filters: filters
        }
      )
    }

    // Set up ordering.
    // let sortKey: string = this.sort.column

    // if (this.sort.column === 'person.firstName') {
    //   sortKey = 'person.firstName'
    // }

    const params: any = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'person.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // this.setItemIds(totalResults)

      // Add pagination and includes for result set
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        'addresses',
        'addresses.addressName',
        'companies',
        'companies.companyType',
        'emailAddresses',
        'emailAddresses.emailAddressName',
        'telephones',
        'telephones.telephoneName',
        'socials',
        'socials.socialName',
        'uris',
        'uris.uriName'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })
    })
    .catch((err) => {
      console.error(err)
    })

  }

  /**
   * Set item ids
   *
   * Store the oportunity ids so we can retrive them from the edit view
   * for prev/next.
   */
  public setItemIds (totalResults: Array<any>): void {
    const itemIds: Array<number> = []
    totalResults.map((item) => {
      itemIds.push(item.uuid)
    })

    this.localStorageService.set('opportunityItemIds', itemIds)

    this.pagination.itemIds = itemIds
  }

  /**
   * Get mini nav
   */
  public getMiniNav (currentUuid: string): any {
    const miniNav: any = {
      prevId: null,
      current: currentUuid,
      nextId: null,
      total: null
    }

    if (this.pagination.itemIds.length === 0) {
      const itemIds: any = this.localStorageService.get('opportunityItemIds')
      if (itemIds !== null) {
        this.pagination.itemIds = itemIds
      }
    }

    miniNav.total = this.pagination.itemIds.length

    let i: number
    i = 0

    this.pagination.itemIds.map((item) => {
      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i + 1])) {
        miniNav.nextId = this.pagination.itemIds[i + 1]
      }
      if (item === currentUuid) {
        miniNav.current = (i + 1)
      }

      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i - 1])) {
        miniNav.prevId = this.pagination.itemIds[i - 1]
      }
      i++
    })

    return miniNav
  }

  /**
   * Save person
   */
  public savePerson (person: Person): Promise<any> {
    console.log('personService.savePerson()', person)

    // Save the person.
    return this.api.save(person)
    .toPromise()
    .then((personResult) => {
      const personUuid: string = personResult.uuid
      const promises: Array<Promise<any>> = []

      if (person.addresses) {
        person.addresses.map((address: Address) => {
          const promise: Promise<any> = this.api.saveAddress(personUuid, address)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.addressesDeleted) {
        person.addressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteAddress(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.companies) {
        const uuids: Array<any> = []
        person.companies.map((company: Company) => {
          uuids.push(company.uuid)
          const prom: Promise<any> = this.api.attachChild(personResult.uuid, 'companies',  company.uuid, company.personToCompany)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(prom)
        })

        // Detach others true takes care of deletions here.
        const promise: Promise<any> = this.api.syncCompanies(personUuid, uuids, true)
        .toPromise()
        .then((result) => {
          return result
        })
        .catch((err) => {
          throw err
        })

        promises.push(promise)
      }

      if (person.emailAddresses) {
        person.emailAddresses.map((emailAddress: EmailAddress) => {
          const promise: Promise<any> = this.api.saveEmailAddress(personUuid, emailAddress)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.emailAddressesDeleted) {
        person.emailAddressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteEmailAddress(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.socials) {
        person.socials.map((social: Social) => {
          const promise: Promise<any> = this.api.saveSocial(personUuid, social)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.socialsDeleted) {
        person.socialsDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteSocial(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.telephones) {
        person.telephones.map((telephone: Telephone) => {
          const promise: Promise<any> = this.api.saveTelephone(personUuid, telephone)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.telephonesDeleted) {
        person.telephonesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteTelephone(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.uris) {
        person.uris.map((uri: Uri) => {
          const promise: Promise<any> = this.api.saveUri(personUuid, uri)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.urisDeleted) {
        person.urisDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteUri(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      // Save & or Delete the persons addresses, companies, emailAddresses, socials, telephones, uris
      return Promise.all(promises)
      .then(() => {
        return personResult
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })

  }

}
