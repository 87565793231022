import { Injectable } from '@angular/core'
// Api services.
import { AlarmApiService } from '@app/modules/alarm/alarm-api.service'
import { AddressNameApiService } from '@app/modules/address-name/address-name-api.service'
import { AgreementApiService } from '@app/modules/agreement/agreement-api.service'
// import { AddressApiService } from '@app/modules/address/address-api.service'
import { CompanyTypeApiService } from '@app/modules/company-type/company-type-api.service'
// import { CompanyApiService } from '@app/modules/company/company-api.service'
import { CountryApiService } from '@app/modules/country/country-api.service'
import { DepartmentApiService } from '@app/modules/department/department-api.service'
import { EmailAddressNameApiService } from '@app/modules/email-address-name/email-address-name-api.service'
import { GenderApiService } from '@app/modules/gender/gender-api.service'
import { LostReasonApiService } from '@app/modules/lost-reason/lost-reason-api.service'
import { NoteTypeApiService } from '@app/modules/note-type/note-type-api.service'
import { OpportunityProcessApiService } from '@app/modules/opportunity-process/opportunity-process-api.service'
import { OpportunityStageApiService } from '@app/modules/opportunity-stage/opportunity-stage-api.service'
import { OpportunityStatusApiService } from '@app/modules/opportunity-status/opportunity-status-api.service'
// import { OpportunityApiService } from '@app/modules/opportunity/api-opportunity.service'
// import { PersonApiService } from '@app/modules/person/person-api.service'
import { ProductApiService } from '@app/modules/product/product-api.service'
// import { ProductServiceApiService } from '@app/modules/product-service/product-service-api.service'
import { ProbabilityApiService } from '@app/modules/probability/probability-api.service'
import { ResourceApiService } from '@app/modules/resource/resource-api.service'
import { RoleApiService } from '@app/modules/role/role-api.service'
import { SocialNameApiService } from '@app/modules/social-name/social-name-api.service'
import { SuppliesWhatApiService } from '@app/modules/supplies-what/supplies-what-api.service'

import { TaskDurationApiService } from '@app/modules/task-duration/task-duration-api.service'
import { TaskPriorityApiService } from '@app/modules/task-priority/task-priority-api.service'
// import { TaskRegardingApiService } from '@app/modules/task-regarding/task-regarding-api.service'
import { TaskTypeApiService } from '@app/modules/task-type/task-type-api.service'

import { TelephoneNameApiService } from '@app/modules/telephone-name/telephone-name-api.service'
import { TitleApiService } from '@app/modules/title/title-api.service'
import { UriNameApiService } from '@app/modules/uri-name/uri-name-api.service'
import { UserApiService } from '@app/modules/user/user-api.service'

@Injectable()

export class SelectOptionsService {

  /**
   * Address name options
   */
  public alarmOptions: Array<any> = []

  /**
   * Address name options
   */
  public addressNameOptions: Array<any> = []

  /**
   * Aggreement options
   */
  public agreementOptions: Array<any> = []

  /**
   * Boolean options
   */
  public booleanOptions: Array<any> = []

  /**
   * Company type options
   */
  public companyTypeOptions: Array<any> = []

  /**
   * Country options
   */
  public countryOptions: Array<any> = []

  /**
   * Department options
   */
  public departmentOptions: Array<any> = []

  /**
   * Email address name options
   */
  public emailAddressNameOptions: Array<any> = []

  /**
   * Gender options
   */
  public genderOptions: Array<any> = []

  /**
   * Lost reason options
   */
  public lostReasonOptions: Array<any> = []

  /**
   * Note type options
   */
  public noteTypeOptions: Array<any> = []

  /**
   * Operator options
   */
  public operatorOptions: Array<any> = []

  /**
   * Opportunity process options
   */
  public opportunityProcessOptions: Array<any> = []

  /**
   * Opportunity type options
   */
  public opportunityTypeOptions: Array<any> = []

  /**
   * Opportunity stage options
   */
  public opportunityStageOptions: Array<any> = []

  /**
   * Opportunity status options
   */
  public opportunityStatusOptions: Array<any> = []

  /**
   * Per page options
   */
  public perPageOptions: Array<any> = []

  /**
   * Probability options
   */
  public probabilityOptions: Array<any> = []

  /**
   * Product service options
   */
  // public productServiceOptions: Array<any> = []

  /**
   * Product options
   */
  public productOptions: Array<any> = []

  /**
   * Resource options
   */
  public resourceOptions: Array<any> = []

  /**
   * Role options
   */
  public roleOptions: Array<any> = []

  /**
   * Social name
   */
  public socialNameOptions: Array<any> = []

  /**
   * Supplies what
   */
  public suppliesWhatOptions: Array<any> = []

  /**
   * Task duration
   */
  public taskDurationOptions: Array<any> = []

  /**
   * Task priority
   */
  public taskPriorityOptions: Array<any> = []

  /**
   * Task type
   */
  public taskTypeOptions: Array<any> = []

  /**
   * Telephone name
   */
  public telephoneNameOptions: Array<any> = []

  /**
   * Title  options
   */
  public titleOptions: Array<any> = []

  /**
   * Uri name options
   */
  public uriNameOptions: Array<any> = []

  /**
   * User options
   */
  public userOptions: Array<any> = []

  /**
   * Inited
   */
  public inited: boolean

  /**
   * Constructor
   */
  public constructor (
    private alarmApiService: AlarmApiService,
    private apiAddressNameService: AddressNameApiService,
    private agreementApiService: AgreementApiService,
    private companyTypeApiService: CompanyTypeApiService,
    private countryApiService: CountryApiService,
    private departmentApiService: DepartmentApiService,
    private emailAddressNameApiService: EmailAddressNameApiService,
    private genderApiService: GenderApiService,
    private lostReasonApiService: LostReasonApiService,
    private noteTypeApiService: NoteTypeApiService,
    private opportunityProcessApiService: OpportunityProcessApiService,
    private opportunityStageApiService: OpportunityStageApiService,
    private opportunityStatusApiService: OpportunityStatusApiService,
    private productApiService: ProductApiService,
    // private productServiceApiService: ProductServiceApiService,
    private probabilityApiService: ProbabilityApiService,
    private resourceApiService: ResourceApiService,
    private roleApiService: RoleApiService,
    private socialNameApiService: SocialNameApiService,
    private suppliesWhatApiService: SuppliesWhatApiService,
    private taskDurationApiService: TaskDurationApiService,
    private taskPriorityApiService: TaskPriorityApiService,
    // private taskRegardingApiService: TaskRegardingApiService,
    private taskTypeApiService: TaskTypeApiService,
    private telephoneNameApiService: TelephoneNameApiService,
    private titleApiService: TitleApiService,
    private uriNameApiService: UriNameApiService,
    private userApiService: UserApiService
  ) {}

  /**
   * Init
   */
  public init (reload?: boolean): void {
    if (this.inited && !reload) {
      return
    }
    this.getAlarmOptions()
    this.getAddressNameOptions()
    this.getAgreementOptions()
    this.getBooleanOptions()
    this.getCompanyTypeOptions()
    this.getCountryOptions()
    this.getDepartmentOptions()
    this.getEmailAddressNameOptions()
    this.getGenderOptions()
    this.getLostReasonOptions()
    this.getNoteTypeOptions()
    this.getOperatorOptions()
    this.getOpportunityProcessOptions()
    this.getOpportunityStageOptions()
    this.getOpportunityStatusOptions()
    this.getPerPageOptions()
    this.getProbabilityOptions()
    // this.getProductServiceOptions()
    this.getProductOptions()
    this.getResourceOptions()
    this.getRoleOptions()
    this.getSocialNameOptions()
    this.getSuppliesWhatOptions()
    this.getTelephoneNameOptions()
    this.getTaskDurationOptions()
    this.getTaskPriorityOptions()
    this.getTaskTypeOptions()
    this.getTitleOptions()
    this.getUriNameOptions()
    this.getUserOptions()
    this.inited = true
  }

  /**
   * Get address name options
   */
  public getAlarmOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.alarmApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.alarmOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get address name options
   */
  public getAddressNameOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.apiAddressNameService.getMany(params)
    .toPromise()
    .then((results) => {
      this.addressNameOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get agreement options
   */
  public getAgreementOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.agreementApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.agreementOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get boolean options
   */
  public getBooleanOptions (): void {
    this.booleanOptions = [
      {
        value: 'false',
        name: 'No'
      },
      {
        value: 'true',
        name: 'Yes'
      }
    ]
  }

  /**
   * Get address name options
   */
  public getCompanyTypeOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.companyTypeApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.companyTypeOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get country options
   */
  public getCountryOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.countryApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.countryOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get department options
   */
  public getDepartmentOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.departmentApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.departmentOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get email address name options
   */
  public getEmailAddressNameOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.emailAddressNameApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.emailAddressNameOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get gender options
   */
  // public getGenderOptions (): void {
  //   this.genderOptions = [
  //     {
  //       id: 'm',
  //       name: 'Male'
  //     },
  //     {
  //       id: 'f',
  //       name: 'Female'
  //     }
  //   ]
  // }

  /**
   * Get gender options
   */
  public getGenderOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.genderApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.genderOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get lost reason options
   */
  public getLostReasonOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.lostReasonApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.lostReasonOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get note type options
   */
  public getNoteTypeOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.noteTypeApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.noteTypeOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get operator options
   */
  public getOperatorOptions (): void {
    this.operatorOptions = [
      {
        key: 'eq',
        name: '=',
        description: 'Is equal to'
      },
      {
        key: 'lt',
        name: '<',
        description: 'Less than'
      },
      {
        key: 'gt',
        name: '>',
        description: 'Greater than'
      },
      {
        key: 'lte',
        name: '<=',
        description: 'Less than or equal to'
      },
      {
        key: 'gte',
        name: '>=',
        description: 'Greater than or equal to'
      },
      {
        key: 'neq',
        name: '!=',
        description: 'Is not'
      }
    ]
  }

  /**
   * Get opportunity process options
   */
  public getOpportunityProcessOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.opportunityProcessApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.opportunityProcessOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get opportunity stage options
   */
  public getOpportunityStageOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.opportunityStageApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.opportunityStageOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get opportunity status options
   */
  public getOpportunityStatusOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.opportunityStatusApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.opportunityStatusOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get probability options
   */
  public getPerPageOptions (): void {

    const perPageOptions: Array<any> = [
      // {
      //   key: null,
      //   value: 'All'
      // },
      {
        key: 10,
        val: 10
      },
      {
        key: 20,
        val: 20
      },
      {
        key: 30,
        val: 30
      },
      {
        key: 40,
        val: 40
      },
      {
        key: 50,
        val: 50
      },
      {
        key: 100,
        val: 100
      }
      // {
      //   key: 200,
      //   val: 200
      // },
      // {
      //   key: 300,
      //   val: 300
      // },
      // {
      //   key: 400,
      //   val: 400
      // },
      // {
      //   key: 500,
      //   val: 500
      // },
      // {
      //   key: 1000,
      //   val: 1000
      // }
    ]

    this.perPageOptions = perPageOptions
  }

  /**
   * Get probability options
   */
  public getProbabilityOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.probabilityApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.probabilityOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get product service options
   */
  // public getProductServiceOptions (): void {
  //   const params: any | Object = {
  //     // page: 0,
  //     // limit: 1000,
  //     sort: [
  //       {
  //         key: 'displayOrder',
  //         direction: 'ASC'
  //       },
  //       {
  //         key: 'name',
  //         direction: 'ASC'
  //       }
  //     ]
  //   }

  //   this.productServiceApiService.getMany(params)
  //   .toPromise()
  //   .then((results) => {
  //     this.productServiceOptions = results
  //   })
  //   .catch((err) => {
  //     console.error(err)
  //   })
  // }

  /**
   * Get product options
   */
  public getProductOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.productApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.productOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get resource options
   */
  public getResourceOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        // {
        //   key: 'displayOrder',
        //   direction: 'ASC'
        // },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.resourceApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.resourceOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get role options
   */
  public getRoleOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'createdAt',
          direction: 'ASC'
        }
      ]
    }

    return this.roleApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.roleOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get social type options
   */
  public getSocialNameOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.socialNameApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.socialNameOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get supplies what options
   */
  public getSuppliesWhatOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.suppliesWhatApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.suppliesWhatOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get task duration options
   */
  public getTaskDurationOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.taskDurationApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.taskDurationOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get task priority options
   */
  public getTaskPriorityOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.taskPriorityApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.taskPriorityOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get task type options
   */
  public getTaskTypeOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.taskTypeApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.taskTypeOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get telephone name options
   */
  public getTelephoneNameOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.telephoneNameApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.telephoneNameOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get title options
   */
  public getTitleOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.titleApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.titleOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get uri name options
   */
  public getUriNameOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.uriNameApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.uriNameOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get user options
   */
  public getUserOptions (): Promise<any> {
    const params: any | Object = {
      page: 0,
      limit: 1000,
      sort: [
        {
          key: 'createdAt',
          direction: 'ASC'
        }
      ]
    }

    return this.userApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.userOptions = results
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

}
