import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

@Injectable()

export class TitleApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/titles'
  }

}
