import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { LocalStorageService } from 'angular-2-local-storage'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { ActivityLogApiService } from './activity-log-api.service'

@Injectable()

export class ActivityLogSearchService {

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0,
    itemIds: []
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'activity_log.createdAt',
    direction: 'DESC'
  }

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private myUtil: MyUtilityService,
    private api: ActivityLogApiService
  ) {}

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      proposalNo: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      jobNo: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      name: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      company: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      companyUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityStageUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityStatusUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      finalInvoice: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      finalInvoiceOperator: [
        'eq',
        [
          Validators.maxLength(3)
        ]
      ],
      salesPersonUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      probabilityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })
    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public search (searchFilters: any | Object): Promise<any> {
    const filters: Array<any> = []

    if (!this.myUtil.isEmpty(searchFilters.proposalNo)) {
      filters.push({
        key: 'proposalNo',
        value: searchFilters.proposalNo,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.jobNo)) {
      filters.push({
        key: 'jobNo',
        value: searchFilters.jobNo,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.name)) {
      filters.push({
        key: 'name',
        value: searchFilters.name,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.company)) {
      filters.push({
        key: 'company.name',
        value: searchFilters.company,
        operator: 'ct'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.companyUuid)) {
      filters.push({
        key: 'companyUuid',
        value: searchFilters.companyUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.opportunityStatusUuid)) {
      filters.push({
        key: 'opportunityStatusUuid',
        value: searchFilters.opportunityStatusUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.opportunityStageUuid)) {
      filters.push({
        key: 'opportunityStageUuid',
        value: searchFilters.opportunityStageUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.finalInvoice)) {
      filters.push({
        key: 'finalInvoice',
        value: searchFilters.finalInvoice,
        operator: searchFilters.finalInvoiceOperator
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.salesPersonUserUuid)) {
      filters.push({
        key: 'salesPersonUserUuid',
        value: searchFilters.salesPersonUserUuid,
        operator: 'eq'
      })
    }

    if (!this.myUtil.isEmpty(searchFilters.probabilityUuid)) {
      filters.push({
        key: 'probabilityUuid',
        value: searchFilters.probabilityUuid,
        operator: 'eq'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: false,
          filters: filters
        }
      )
    }

    const params: any = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'activity_log.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // this.setItemIds(totalResults)

      // Add pagination and includes for result set
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        // 'salesPersonUser',
        // 'productServices',
        // 'company',
        // 'opportunityStatus',
        // 'opportunityStage',
        // 'probability'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Set item ids
   *
   * Store the oportunity ids so we can retrive them from the edit view
   * for prev/next.
   */
  public setItemIds (totalResults: Array<any>): void {
    const itemIds: Array<number> = []
    totalResults.map((item) => {
      itemIds.push(item.uuid)
    })

    this.localStorageService.set('activityLogItemIds', itemIds)

    this.pagination.itemIds = itemIds
  }

  /**
   * Get mini nav
   */
  public getMiniNav (currentUuid: string): any {
    const miniNav: any = {
      prevId: null,
      current: currentUuid,
      nextId: null,
      total: null
    }

    if (this.pagination.itemIds.length === 0) {
      const itemIds: any = this.localStorageService.get('activityLogItemIds')
      if (itemIds !== null) {
        this.pagination.itemIds = itemIds
      }
    }

    miniNav.total = this.pagination.itemIds.length

    let i: number
    i = 0

    this.pagination.itemIds.map((item) => {
      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i + 1])) {
        miniNav.nextId = this.pagination.itemIds[i + 1]
      }
      if (item === currentUuid) {
        miniNav.current = (i + 1)
      }

      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i - 1])) {
        miniNav.prevId = this.pagination.itemIds[i - 1]
      }
      i++
    })

    return miniNav
  }

}
