import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { EmailAddressApiService } from '@app/modules/email-address/email-address-api.service'
import { EmailAddressNameService } from '@app/modules/email-address-name/email-address-name.service'

@Injectable()

export class EmailAddressService {

  /**
   * To delete
   *
   * Id's marked for deleting.
   */
  public toDelete: Array<number> = []

  /**
   * Constructor
   */
  public constructor (
    // Angular.
    public formBuilder: FormBuilder,
    // Other Services.
    public myUtil: MyUtilityService,
    // Api.
    public api: EmailAddressApiService,
    // Related model services.
    public emailAddressNameService: EmailAddressNameService
  ) {}

  /**
   * Get form
   */
  public getForm (emailAddress?: any | Object): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      emailAddressNameUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      email: [
        null,
        [
          Validators.maxLength(191),
          ValidationService.isValidEmail
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (!this.myUtil.isEmpty(emailAddress)) {
      if (Object.keys(emailAddress).length !== 0) {
        form.patchValue(emailAddress, { emitEvent: false })
      }
    }

    return form
  }

}
