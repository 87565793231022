import * as moment from 'moment'

export class Base {

  /**
   * Date type properties
   */
  protected _dates: Array<string> = []

  /**
   * Constructor
   */
  public constructor (data?: Partial<any>) {
    if (data) {}
  }

  /**
   * Init
   */
  protected init (data?: Partial<any>): void {
    if (data) {
      if (this._dates.length > 0) {
        Object.assign(this, this.makeDates(data))
      }
    }
  }

  /**
   * Set dates
   */
  protected setDates (dates: Array<any>): void {
    this._dates = dates
  }

  /**
   * Make dates
   */
  protected makeDates (init: Partial<any>): Partial<any> {
    this._dates.map((dt: string) => {
      if (init[dt] && typeof init[dt] === 'string' && init[dt] !== '') {
        init[dt] = moment(init[dt]).toDate()
      }
    })

    return init
  }

}
