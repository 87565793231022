import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { UserService } from '@app/modules/user/user.service'
import { ContactGroupApiService } from '@app/modules/contact-group/contact-group-api.service'
import { OfficeSyncPerm } from '@app/database/models/office-sync-perm.model'
import { AlertService } from '@app/modules/alert/alert.service'
import { cloneDeep } from 'lodash'

@Component({
  selector: 'app-office-permissions',
  templateUrl: './office-permissions.component.html'
})

export class OfficePermissionsComponent implements OnInit, OnDestroy {

  /**
   * User primary key
   */
  @Input() userUuid: string

  /**
   * Display page
   */
  public permissions: Array<any> = []

  /**
   * Office Sync Perm
   */
  public officeSyncPerm: OfficeSyncPerm

  /**
   * Office Sync Perm Backup
   *
   * Stores last saved version.
   */
  public officeSyncPermBackup: OfficeSyncPerm

  /**
   * Display page
   */
  public display: boolean

  /**
   * Contact groups assigned
   */
  public contactGroupsAssigned: Array<any> = []

  /**
   * Contact groups available
   */
  public contactGroupsAvailable: Array<any> = []

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public appService: AppService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public userService: UserService,
    public contactGroupApiService: ContactGroupApiService,
    private alertService: AlertService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {

    this.userService.api.getManyChildren(this.userUuid, 'office-sync-perms')
    .toPromise()
    .then((result: Array<OfficeSyncPerm>) => {

      this.officeSyncPerm = result[0]

      this.officeSyncPermBackup = cloneDeep(this.officeSyncPerm)

      this.permissions = [
        {
          name: 'addNewContactsToOutlook',
          description: 'Contacts - CRM to Outlook (Create)'
        },
        {
          name: 'updateOutlookContacts',
          description: 'Contacts - CRM to Outlook (Update)'
        },
        {
          name: 'removeOutlookContacts',
          description: 'Contacts - CRM to Outlook (Delete)'
        },
        {
          name: 'addNewContactsToCrm',
          description: 'Contacts - Outlook to CRM (Create)'
        },
        {
          name: 'updateCrmContacts',
          description: 'Contacts - Outlook to CRM (Update)'
        },
        {
          name: 'removeCrmContacts',
          description: 'Contacts - Outlook to CRM (Delete)'
        },
        {
          name: 'getUnallocatedEmail',
          description: 'Email - Get unallocated'
        }
      ]
    })
    .catch((err) => {
      console.error(err)
    })
    this.display = true
  }

  /**
   * Change permission
   */
  public changePermission (name: string): void {
    console.log('changePermission', name)
    if (this.officeSyncPerm[name] === false) {
      this.officeSyncPerm[name] = true
    } else if (this.officeSyncPerm[name] === true) {
      this.officeSyncPerm[name] = false
    }
  }

  /**
   * On save
   */
  public onSave (): void {
    console.log('onSave', this.officeSyncPerm)
    this.userService.api.updateChild(this.userUuid, 'office-sync-perms', this.officeSyncPerm.uuid, this.officeSyncPerm)
    .toPromise()
    .then(() => {
      this.officeSyncPermBackup = cloneDeep(this.officeSyncPerm)
      this.alertService.success('Saved Office Sync permissions', 4000)
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.officeSyncPerm = cloneDeep(this.officeSyncPermBackup)
    this.alertService.success('Reverted Office Sync permissions to last save', 4000)
  }
}
