// Angular
import { Component, OnInit, OnDestroy } from '@angular/core'
import { ValidationService } from '@app/shared/services/validation.service'
import { ConfigService, FmService } from '../../services'

@Component({
  selector: 'app-fm-selected-files-msg',
  templateUrl: './fm-selected-files-msg.component.html'
})

export class FmSelectedFilesMsgComponent implements OnInit, OnDestroy {

  /**
   * Display
   */
  public display: boolean

  /**
   * Show details
   */
  public showDetails: boolean

  /**
   * Constructor
   */
  public constructor (
    public validationService: ValidationService,
    public configService: ConfigService,
    public fmService: FmService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.showDetails = false
    this.display = true
  }
}
