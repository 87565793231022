import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { Person } from '@app/modules/person/person.model'

export class OpportunityAgreement extends Base {
  uuid: string = null
  agreementUuid: string = null
  opportunityUuid: string = null
  sent: Date = null
  received: Date = null
  signedByPersonUuid: string = null
  signedByPerson: Person = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt',
    'sent',
    'received'
  ]

  /**
   * Constructor
   *
   * @param {Partial<OpportunityAgreement>} data Initial values
   */
  public constructor (data?: Partial<OpportunityAgreement>) {
    super(data)
    this.init(data)
  }
}
