import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { SocialName } from '@app/modules/social-name/social-name.model'

export class Social extends Base {
  uuid: string = null
  socialNameUuid: string = null
  accountName: string = null
  accountLink: string = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null
  // Relations.
  socialName: SocialName = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param {Partial<Social>} data Initial values
   */
  public constructor (data?: Partial<Social>) {
    super(data)
    this.init(data)
  }
}
