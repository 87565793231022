import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { LocalStorageService } from 'angular-2-local-storage'
import { ValidationService } from '@app/shared/services/validation.service'
import { SiteApiService } from '@app/modules/site/site-api.service'
import { Site } from '@app/modules/site/site.model'
import { Address } from '@app/modules/address/address.model'

@Injectable()

export class SiteService {

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0,
    itemIds: []
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'company.name',
    direction: 'ASC'
  }

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    public api: SiteApiService
  ) {}

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      name: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      companyUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      companyName: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      address: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      city: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      countryUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      postcode: [
        null,
        [
          Validators.maxLength(191)
        ]
      ]
    })

    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public search (searchFilters: any | Object): Promise<any> {
    console.log('searchFilters', searchFilters)
    // this.getClients()
    const filters: Array<any> = []

    if (searchFilters.companyName) {
      filters.push({
        key: 'company.name',
        value: searchFilters.companyName,
        operator: 'ct'
      })
    }

    if (searchFilters.name) {
      filters.push({
        key: 'site.name',
        value: searchFilters.name,
        operator: 'ct'
      })
    }

    if (searchFilters.address) {
      filters.push({
        key: 'address.address_1',
        value: searchFilters.address,
        operator: 'ct'
      })
      // filters.push({
      //     key: 'address_2',
      //     value: searchFilters.address,
      //     operator: 'ct'
      // })
      // filters.push({
      //     key: 'address_3',
      //     value: searchFilters.address,
      //     operator: 'ct'
      // })
    }

    if (searchFilters.city) {
      filters.push({
        key: 'address.city',
        value: searchFilters.city,
        operator: 'ct'
      })
    }

    if (searchFilters.postcode) {
      filters.push({
        key: 'address.postcode',
        value: searchFilters.postcode,
        operator: 'ct'
      })
    }

    if (searchFilters.countryUuid) {
      filters.push({
        key: 'address.country_uuid',
        value: searchFilters.countryUuid,
        operator: 'eq'
      })
    }

    const filterGroups: Array<any> = []

    if (filters.length > 0) {
      filterGroups.push(
        {
          or: true,
          filters: filters
        }
      )
    }

    const params: any = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'site.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // this.setItemIds(totalResults)

      // Add pagination and includes for result set
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        'address',
        'address.country',
        'company'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Set item ids
   *
   * Store the oportunity ids so we can retrive them from the edit view
   * for prev/next.
   */
  public setItemIds (totalResults: Array<any>): void {
    const itemIds: Array<number> = []
    totalResults.map((item) => {
      itemIds.push(item.uuid)
    })

    this.localStorageService.set('siteItemIds', itemIds)

    this.pagination.itemIds = itemIds
  }

  /**
   * Get mini nav
   */
  public getMiniNav (currentUuid: string): any {
    const miniNav: any = {
      prevId: null,
      current: currentUuid,
      nextId: null,
      total: null
    }

    if (this.pagination.itemIds.length === 0) {
      const itemIds: any = this.localStorageService.get('siteItemIds')
      if (itemIds !== null) {
        this.pagination.itemIds = itemIds
      }
    }

    miniNav.total = this.pagination.itemIds.length

    let i: number
    i = 0

    this.pagination.itemIds.map((item) => {
      if (item === currentUuid && this.pagination.itemIds[i + 1]) {
        miniNav.nextId = this.pagination.itemIds[i + 1]
      }
      if (item === currentUuid) {
        miniNav.current = (i + 1)
      }

      if (item === currentUuid && this.pagination.itemIds[i - 1]) {
        miniNav.prevId = this.pagination.itemIds[i - 1]
      }

      i++
    })

    return miniNav
  }

  /**
   * Save site
   */
  public saveSite (site: Site): Promise<any> {
    console.log('siteService.saveSite()', site)

    // Save the site.
    return this.api.save(site)
    .toPromise()
    .then((siteResult) => {

      site.uuid = siteResult.uuid

      const promises: Array<Promise<any>> = []

      if (site.address) {
        const address: Address = new Address(site.address)
        const promise: Promise<any> = this.api.saveAddress(site.uuid , address)
        .toPromise()
        .then((addressResult) => {
          return addressResult
        })
        .catch((err) => {
          throw err
        })

        promises.push(promise)
      }

      return Promise.all(promises)
      .then((promisesResult) => {
        if (promisesResult.length > 0) {
          site.addressUuid = promisesResult[0].uuid
          site.address = promisesResult[0]
        }

        // One final save after address is set.
        return this.api.save(site)
        .toPromise()
        .then((finalResult) => {
          return finalResult
        })
        .catch((err) => {
          throw err
        })

      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })
  }
}
