import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  templateUrl: './confirm-modal.component.html'
})

export class ConfirmModalComponent {

  /**
   * Constructor
   */
  public constructor (
    private ngbActiveModal: NgbActiveModal
  ) {}

  /**
   * On confirm
   */
  public onConfirm (): void {
    this.ngbActiveModal.close(true)
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.ngbActiveModal.close(false)
  }
}
