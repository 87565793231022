export const environment = {
  environment: 'Staging',
  name: 'Tube Tech CRM',
  version: require('../../package.json').version,
  production: true,
  apiUrl: 'https://tubetech-api.mydemoversion.com/api',
  config: {
    tenant: 'greynoisedesign.onmicrosoft.com',
    clientId: '333d36fd-2556-44a9-8a68-03cc121b63d6',
    endpoints: {
      'https://graph.microsoft.com': 'https://graph.microsoft.com'
    }
  }
}
