// Angular
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
// Other.
import { from as observableFrom, merge as observableMerge, Subscription, Subject, Observable } from 'rxjs'
import { switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
// Service models.
import { Person } from '@app/modules/person/person.model'
import { PersonService } from '@app/modules/person/person.service'
import { PersonFormService } from '@app/modules/person/person-form.service'

@Component({
  selector: 'app-person-typeahead',
  templateUrl: './person-typeahead.component.html'
})

export class PersonTypeaheadComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Button text
   */
  @Input() mainFormControlName: string

  /**
   * Button text
   */
  @Input() buttonText: string

  /**
   * Selected form
   */
  public selectedForm: FormGroup

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  @ViewChild('personSearchTypeAhead') personSearchTypeAhead: NgbTypeahead
  public focus$: Subject<string> = new Subject<string>()
  public click$: Subject<string> = new Subject<string>()

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private personService: PersonService,
    private personFormService: PersonFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.selectedForm = this.formBuilder.group({
      selectedItem: [
        null,
        [
          Validators.required
        ]
      ]
    })
    this.display = true
  }

  /**
   * Person search
   *
   * @type {function}
   *
   * @returns {Array<any>}
   */
  public search: any = (text$: Observable<string>): Observable<any> => {
    const debouncedText: Observable<string> = text$.pipe(debounceTime(500), distinctUntilChanged())

    return observableMerge(debouncedText)
    .pipe(
      switchMap(term => observableFrom(this.getResults(term)))
    )
  }

  /**
   * Search result formatter
   *
   * Converts the object into a readable string.
   *
   * @type {any}
   *
   * @returns {string}
   */
  public formatResults: any = (item: any): any => {
    const strParts: Array<any> = []
    const name: Array<any> = []

    // if (!this.myUtil.isEmpty(x.title)) {
    //   name.push(x.title.name)
    // }

    if (item.firstName) {
      name.push(item.firstName)
    }

    if (item.lastName) {
      name.push(item.lastName)
    }

    if (name.length > 0) {
      strParts.push(name.join(' '))
    }

    if (item.companies && item.companies.length > 0) {
      strParts.push(item.companies[0].name)
    }

    return strParts.join(', ')
  }

  /**
   * Select item
   *
   * @param {Event} $event Event
   */
  public selectItem ($event: Event): void {
    if ($event) {}
    // console.log('selectItem', $event)
  }

  /**
   * Is contact search valid
   *
   * @returns {boolean}
   */
  public isSearchValid (): boolean {
    if (this.selectedForm.get('selectedItem').value && this.selectedForm.get('selectedItem').value.uuid) {
      return true
    }
    return false
  }

  /**
   * Add schedule contact
   */
  public add (): void {
    const control: FormArray = this.mainForm.get(this.mainFormControlName) as FormArray
    const person: Person = new Person(this.selectedForm.get('selectedItem').value)
    const personForm: FormGroup = this.personFormService.getForm(person, 'new')
    control.push(personForm)

    this.selectedForm.patchValue({
      selectedItem: null
    })
  }

  /**
   * Searcher
   *
   * @param term Search term
   */
  public getResults (term: string): Promise<any> {
    const params: any = {
      limit: 50,
      sort: [
        {
          key: 'first_name',
          direction: 'ASC'
        }
      ],
      filter_groups: [
        {
          or: true,
          filters: [
            {
              key: 'fullName',
              value: term,
              operator: 'ct'
            },
            {
              key: 'company.name',
              value: term,
              operator: 'ct'
            }
          ]
        }
      ],
      includes: [
        'addresses',
        'addresses.addressName',
        'companies',
        'emailAddresses',
        'emailAddresses.emailAddressName',
        'telephones',
        'telephones.telephoneName',
        'socials',
        'socials.socialName',
        'uris',
        'uris.uriName'
      ]
    }

    return this.personService.api.getMany(params)
    .toPromise()
    .then((results) => {
      return results
    })
  }
}
