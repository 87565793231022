import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormArray, FormGroup, FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
import * as moment from 'moment'
import { AlertService } from '@app/modules/alert/alert.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ValidationService } from '@app/shared/services/validation.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { Company } from '@app/modules/company/company.model'
import { CompanyService } from '@app/modules/company/company.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'
import { OpportunityStageApiService } from '@app/modules/opportunity-stage/opportunity-stage-api.service'
import { OpportunityAgreementFormService } from '@app/modules/opportunity-agreement/opportunity-agreement-form.service'
import { OpportunityAgreement } from '@app/modules/opportunity-agreement/opportunity-agreement.model'
import {
  OpportunityService
} from '@app/modules/opportunity'
import {
  Person,
  PersonService,
  PersonFormService
} from '@app/modules/person'
import {
  Site,
  SiteService,
  SiteFormService
} from '@app/modules/site/'
import {
  Address
} from '@app/modules/address/'
import { Shutdown } from '@app/modules/shutdown/shutdown.model'
import { ShutdownFormService } from '@app/modules/shutdown/shutdown-form.service'
import { Probability } from '@app/modules/probability/probability.model'
import { ProbabilityFormService } from '@app/modules/probability/probability-form.service'
import { RatingChangeEvent } from 'angular-star-rating'
import { AddressName } from '@app/modules/address-name'
import { OpportunityStatus } from '@app/modules/opportunity-status'

// declare var $: any

@Component({
  selector: 'app-opportunity-form',
  templateUrl: './opportunity-form.component.html'
})

export class OpportunityFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Opportunity process Id
   *
   * Store the current opportunityStageUuid. When this changes clear
   * the opportunityStageUuid and reload opportunityStageOptions.
   */
  public prevMainForm: any

  /**
   * Opportunity stage options
   */
  public opportunityStageOptions: Array<any>

  /**
   * Percent complete
   */
  public percentComplete: number

  /**
   * Agreements complete
   */
  public agreementsComplete: number

  /**
   * Days open
   */
  public daysOpen: string

  /**
   * Current agreement form to display
   */
  public currentAgreementUuid: string

  /**
   * Company options
   *
   * @todo remove.
   */
  public companyOptions: Array<any> = []

  /**
   * Site options
   *
   * @todo TMP remove !!!!.
   */
  public siteOptions: Array<any> = []

  /**
   * Company search term
   */
  public companySearchTerm: string

  // ---------------------------------------------------------------------------
  // Modal stuff
  // ---------------------------------------------------------------------------

  /**
   * Company form for modal
   */
  public companyForm: FormGroup

  /**
   * Site form for modal
   */
  public siteForm: FormGroup

  /**
   * Person form
   */
  public personForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  // ---------------------------------------------------------------------------
  // End Modal stuff
  // ---------------------------------------------------------------------------

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    private opportunityService: OpportunityService,
    private companyService: CompanyService,
    private companyFormService: CompanyFormService,
    private opportunityStageApiService: OpportunityStageApiService,
    private opportunityAgreementFormService: OpportunityAgreementFormService,
    private shutdownFormService: ShutdownFormService,
    private siteService: SiteService,
    private siteFormService: SiteFormService,
    private probabilityFormService: ProbabilityFormService,
    private personService: PersonService,
    private personFormService: PersonFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.currentAgreementUuid = null

    if (!this.mainForm.value.shutdown) {
      const shutdown: Shutdown = new Shutdown()
      // const shutdownForm: FormGroup = this.shutdownFormService.getForm(shutdown, 'new')
      this.mainForm.addControl('shutdown', this.shutdownFormService.getForm(shutdown, 'new'))
    }

    this.prevMainForm = Object.assign({}, this.mainForm.value)

    if (this.mainForm.value.opportunityProcessUuid) {
      this.getOpportunityStageOptions(this.mainForm.value.opportunityProcessUuid)
      .then(() => {
        this.percentageComplete()
      })
      .catch((err) => {
        console.error(err)
      })
    }

    this.agreementsPercComplete()
    if (this.mainForm.value.companyUuid) {
      this.getSiteOptions(this.mainForm.value.companyUuid)
    }

    const createdAt: FormControl = this.mainForm.get('createdAt') as FormControl
    createdAt.disable()

    const proposalNo: FormControl = this.mainForm.get('proposalNo') as FormControl
    proposalNo.disable()

    const jobNo: FormControl = this.mainForm.get('jobNo') as FormControl
    jobNo.disable()

    this.setLostReasonIdEnabled(this.mainForm.value.opportunityStatusUuid)

    this.setDaysOpen()

    // this.setFinalInvoiceAmount()

    this.watchformSub()

    this.display = true
  }

  /**
   * Watch form sub
   */
  private watchformSub (): void {
    const sub: Subscription = this.mainForm
    .valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    )
    .subscribe((data) => {
      if (data.opportunityProcessUuid !== this.prevMainForm.opportunityProcessUuid) {
        console.log('opportunityProcessUuid changed', this.prevMainForm.opportunityProcessUuid, data.opportunityProcessUuid)
        if (data.opportunityProcessUuid) {
          this.getOpportunityStageOptions(data.opportunityProcessUuid)
          .then(() => {
            this.percentageComplete()

            // If we're on an unavailable stage set to null.
            const stage: any = this.opportunityStageOptions.find(x => x.uuid === this.prevMainForm.opportunityStageUuid)
            if (!stage) {
              this.mainForm.patchValue({
                opportunityStageUuid: null
              }, { emitEvent: true })
            }
          })
          .catch((err) => {
            console.error(err)
          })
        }

      }

      if (data.opportunityStageUuid !== this.prevMainForm.opportunityStageUuid) {
        console.log('opportunityStageUuid changed', this.prevMainForm.opportunityStageUuid, data.opportunityStageUuid)
        this.percentageComplete()
      }

      if (data.opportunityStageUuid !== this.prevMainForm.opportunityStageUuid) {
        console.log('opportunityStageUuid changed', this.prevMainForm.opportunityStageUuid, data.opportunityStageUuid)
        this.percentageComplete()
      }
      if (data.opportunityAgreements.length !== this.prevMainForm.opportunityAgreements.length) {
        this.agreementsPercComplete()
      }

      if (data.opportunityStatusUuid !== this.prevMainForm.opportunityStatusUuid) {
        console.log('opportunityStatusUuid changed', this.prevMainForm.opportunityStatusUuid, data.opportunityStatusUuid)
        this.setLostReasonIdEnabled(data.opportunityStatusUuid)
      }

      if (data.companyUuid !== this.prevMainForm.companyUuid && data.companyUuid) {
        console.log('companyUuid changed', this.prevMainForm.companyUuid, data.companyUuid)
        this.getSiteOptions(data.companyUuid)
        .then(() => {
          const control: FormGroup = this.mainForm.get('site') as FormGroup
          if (control) {
            control.reset()
          }
          this.mainForm.patchValue({
            siteUuid: null
          })
        })
      }

      if (data.siteUuid !== this.prevMainForm.siteUuid && data.companyUuid) {
        this.getSiteOptions(data.companyUuid)
        .then(() => {
          const site: Site = this.siteOptions.find(x => x.uuid === data.siteUuid)
          this.mainForm.removeControl('site')
          if (site) {
            this.mainForm.addControl('site', this.siteFormService.getForm(site, ''))
          }
        })
      }

      if (!data.siteUuid) {
        this.mainForm.removeControl('site')
      }

      // this.setFinalInvoiceAmount()

      if (data.finalInvoice !== this.prevMainForm.finalInvoice) {
        // this.opportunityService.api.save(data)
        // .toPromise()
        // .then(() => {
        // })
        // .catch((err) => {
        // })
      }

      this.mainForm.markAsDirty()
      this.prevMainForm = Object.assign({}, this.mainForm.value)
    })

    this.subscriptions.push(sub)
  }

  /**
   * Set company search term
   */
  public setCompanySearchTerm (str: string): void {
    this.companySearchTerm = str
  }

  /**
   * Get opportunity stage options
   */
  private getOpportunityStageOptions (opportunityProcessUuid: string): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      filter_groups: [
        {
          filters: [
            {
              key: 'opportunity_process_uuid',
              value: opportunityProcessUuid,
              operator: 'eq'
            }
          ]
        }
      ],
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.opportunityStageApiService.getMany(params)
    .toPromise()
    .then((results) => {
      this.opportunityStageOptions = results
      return results
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Searcher
   */
  public getSiteOptions (companyUuid: number): Promise<any> {
    const params: any = {
      limit: 50,
      sort: [
        {
          key: 'name',
          direction: 'ASC'
        }
      ],
      filter_groups: [
        {
          filters: [
            {
              key: 'company_uuid',
              value: companyUuid,
              operator: 'eq'
            }
          ]
        }
      ],
      includes: [
        'address',
        'address.country'
      ]
    }

    return this.siteService.api.getMany(params)
    .toPromise()
    .then((results) => {
      this.siteOptions = results
      return results
    })
  }

  /**
   * Percentage complete
   *
   * When we know what opportunity process we are on we can use
   * the opportunity stages to work out our % complete.
   */
  private percentageComplete (): void {
    const stages: number = this.opportunityStageOptions.length

    let perc: number
    perc = 0

    let i: number
    i = 1

    if (stages > 0) {
      this.opportunityStageOptions.map((stage) => {
        if (stage.uuid === this.mainForm.value.opportunityStageUuid) {
          perc = ((100 / stages) * i)
        }

        i++
      })
    }

    this.percentComplete = perc
  }

  /**
   * Percentage complete
   *
   * When we know what opportunity process we are on we can use
   * the opportunity stages to work out our % complete.
   */
  private agreementsPercComplete (): void {
    const agreementsTotal: number = this.selectOptionsService.agreementOptions.length

    let perc: number
    perc = 0

    let completedCount: number
    completedCount = 0

    this.mainForm.value.opportunityAgreements.map((opportunityAgreement) => {
      if (opportunityAgreement.received) {
        completedCount++
      }
    })

    perc = (completedCount / agreementsTotal) * 100

    this.agreementsComplete = perc
  }

  /**
   * Set days open
   */
  public setDaysOpen (): void {
    const now: any = moment(new Date())
    const start: any = moment(this.mainForm.value.createdAt)
    const duration: any = moment.duration(start.diff(now))
    const days: any = duration.asDays()
    if (days) {}
    // this.daysOpen = days // .humanize()
    this.daysOpen = '0'
  }

  /**
   * Child changed
   *
   * This tells us something changed so reload.
   */
  public childChanged (event: Event): void {
    console.log('childChanged', event)
    if (event['name'] === 'companyChanged') {

      const control: FormControl = this.mainForm.get('company') as FormControl
      control.reset()

      const company: Company = new Company(event['data'])
      this.mainForm.patchValue({
        company: company
      })

      this.mainForm.markAsDirty()
    }

  }

  /**
   * Set lost reason selector enabled
   *
   * @param opportunityStatusUuid Opportunity status primary key
   */
  private setLostReasonIdEnabled (opportunityStatusUuid: string): void {
    const opportunityStatus: OpportunityStatus = this.selectOptionsService.opportunityStatusOptions.find(x => x.name === 'Closed - Lost')
    const lostReasonUuid: FormControl = this.mainForm.get('lostReasonUuid') as FormControl
    if (opportunityStatus && opportunityStatusUuid === opportunityStatus.uuid) {
      lostReasonUuid.enable()
    } else {
      this.mainForm.patchValue({
        lostReasonUuid: null
      })
      lostReasonUuid.disable()
    }
  }

  /**
   * Set current agreement
   */
  public setCurrentAgreement (event: Event): void {
    if (event) {}
    const agreement: OpportunityAgreement = this.mainForm.value.opportunityAgreements
    .find(x => x.agreementUuid === this.currentAgreementUuid)
    if (this.currentAgreementUuid && !agreement) {
      const control: FormArray = this.mainForm.get('opportunityAgreements') as FormArray
      const opportunityAgreement: OpportunityAgreement = new OpportunityAgreement({
        opportunityUuid: this.mainForm.value.uuid,
        agreementUuid: this.currentAgreementUuid
      })
      const form: FormGroup = this.opportunityAgreementFormService.getForm(opportunityAgreement, 'new')
      control.push(form)
    }
    this.mainForm.markAsDirty()
  }

  /**
   * Set final invoice amount
   */
  public setFinalInvoiceAmount (): void {
    let total: number
    total = 0
    this.mainForm.value.productServices.map((productService) => {
      if (productService.extendedAmount) {
        total = (total * 1) + (productService.extendedAmount * 1)
      }
    })

    this.mainForm.patchValue({
      finalInvoice: total
    })
  }

  /**
   * On rating change
   */
  public onRatingChange (event: RatingChangeEvent): any {
    const value: number = (event.rating * 20)

    let index: number = this.selectOptionsService.probabilityOptions.findIndex(x => parseFloat(x.value) === value)
    let probabilityUuid: number

    if (index === -1) {
      probabilityUuid = 1
    } else {
      probabilityUuid = this.selectOptionsService.probabilityOptions[index].uuid
    }

    // If it's a second click on the same star set 0
    if (probabilityUuid === this.mainForm.value.probabilityUuid) {
      probabilityUuid = 1
      index = 0
    }

    const control: FormGroup = this.mainForm.get('probability') as FormGroup

    if (!control) {
      const probabilityForm: FormGroup = this.probabilityFormService.getForm(new Probability(), '')
      this.mainForm.addControl('probability', probabilityForm)
    }

    this.mainForm.patchValue({
      probabilityUuid: probabilityUuid,
      probability: this.selectOptionsService.probabilityOptions[index]
    }, { emitEvent: true })
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newCompany') {
      const company: Company = new Company()
      if (this.companySearchTerm) {
        company.name = this.companySearchTerm
      }
      this.companyForm = this.companyFormService.getForm(company, 'new')
    }

    if (type === 'editCompany') {
      const company: Company = new Company(data)
      this.companyForm = this.companyFormService.getForm(company, 'edit')
    }

    if (type === 'newSite') {
      const siteAddressName: AddressName = this.selectOptionsService.addressNameOptions.find(x => x.name === 'Site')
      const site: Site = new Site({
        companyUuid: this.mainForm.value.companyUuid,
        company: new Company(this.mainForm.value.company),
        address: new Address({
          addressNameUuid: siteAddressName.uuid // 5 - Site Uuid Preset.
        })
      })
      this.siteForm = this.siteFormService.getForm(site, 'new')
    }

    if (type === 'newContact') {
      const person: Person = new Person({
        companies: [
          new Company(this.mainForm.value.company)
        ]
      })

      this.personForm = this.personFormService.getForm(person, 'new')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })

    this.currentModal.result.then(() => {

      // if ($('.modal').hasClass('in')) {
      //   $('body').addClass('modal-open')
      // }

      console.log('When user closes')
    }, () => {
      console.log('Backdrop click')
    })
  }

  /**
   * Get company
   */
  public getCompany (uuid: string): Promise<any> {
    return this.companyService.api.getOne(uuid, {
      includes: [
        'addresses',
        'addresses.addressName',
        'addresses.country',
        'emailAddresses'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save new company
   */
  public saveCompany (): void {
    console.log('saveCompany', this.companyForm.value)
    this.validationService.runValidation(this.companyForm)

    if (!this.companyForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.companyForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.companyService.saveCompany(this.companyForm.value)
    .then((result) => {
      // Get the new company and update the form.
      this.getCompany(result.uuid)
      .then((newResult: Company) => {
        const newCompany: Company = new Company(newResult)
        this.companyForm = this.companyFormService.getForm(newCompany, '')
        this.mainForm.patchValue({
          companyUuid: newCompany.uuid
        }, { emitEvent: true })
        this.mainForm.removeControl('company')
        this.mainForm.addControl('company', this.companyForm)
      })
      .catch((err) => {
        console.error(err)
      })

      this.currentModal.close()
      this.alertService.success('Saved: ' + this.companyForm.value.name, 10000)
      this.mainForm.markAsPristine()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Get site
   */
  public getSite (uuid: string): Promise<any> {
    return this.siteService.api.getOne(uuid, {
      includes: [
        'address',
        'address.addressName',
        'address.country'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save new site
   */
  public saveSite (): void {
    console.log('saveSite', this.siteForm.value)
    this.validationService.runValidation(this.siteForm)

    if (!this.siteForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.siteForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.siteService.saveSite(this.siteForm.value)
    .then((result) => {
      // Get the new site and update the form.
      this.getSite(result.uuid)
      .then((newResult: Site) => {
        const newSite: Site = new Site(newResult)

        console.log('newSite', newSite)

        this.siteForm = this.siteFormService.getForm(newSite, '')
        this.mainForm.patchValue({
          siteUuid: newSite.uuid
        }, { emitEvent: true })
        this.mainForm.removeControl('site')
        this.mainForm.addControl('site', this.siteForm)

      })
      .catch((err) => {
        console.error(err)
      })

      this.currentModal.close()
      this.alertService.success('Saved: ' + this.siteForm.value.name, 10000)
      this.mainForm.markAsPristine()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Save contact
   */
  public saveContact (): void {
    console.log('saveContact', this.personForm.value)
    this.validationService.runValidation(this.personForm)

    if (!this.personForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.personForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.opportunityService.saveContact(this.mainForm.value.uuid, this.personForm.value)
    .then((result) => {
      this.personService.api.getOne(result.uuid, {
        includes: [
          'companies',
          'companies.addresses',
          'companies.addresses.country',
          'telephones',
          'telephones.telephoneName',
          'emailAddresses',
          'emailAddresses.emailAddressName'
        ]
      })
      .toPromise()
      .then((newPerson) => {
        const control: FormArray = this.mainForm.get('contacts') as FormArray

        // If person exists remove the old FormGroup
        const index: number = control.value.findIndex(x => x.uuid === newPerson.uuid)

        if (index !== -1) {
          control.removeAt(index)
        }

        const personForm: FormGroup = this.personFormService.getForm(new Person(newPerson), '')
        control.push(personForm)
        this.alertService.success('Saved: ' + (personForm.value.firstName ? personForm.value.firstName : '') +
        ' ' + (personForm.value.lastName ? personForm.value.lastName : ''), 10000)
        this.mainForm.markAsPristine()
        this.currentModal.close()

        // const control: FormGroup = this.mainForm.get('site') as FormGroup
        this.mainForm.patchValue({
          shutdown: {
            personUuid: newPerson.uuid
          }
        })

        // this.eventService.events.emit({
        //   name: 'OPPORTUNITY.newContact',
        //   from: 'TabContactsTableComponent.saveContact',
        //   desc: 'Added a new contact.',
        //   data: null
        // })
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
      })
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

}
