import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { Observable } from 'rxjs'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

@Injectable()

export class UserApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/users'
  }

  // ---------------------------------------------------------------------------
  // User roles (Has many attached through user_to_role)
  // ---------------------------------------------------------------------------

  /**
   * Get role
   */
  public getRole (parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'roles', childUuid, params)
  }

  /**
   * Get many roles
   */
  public getRoles (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'roles', params)
  }

  /**
   * Attach role
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public attachRole (parentUuid: string, childUuid: string): Observable<any> {
    return this.attachChild(parentUuid, 'roles', childUuid)
  }

  /**
   * Detach role
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public detachRole (parentUuid: string, childUuid: string): Observable<any> {
    return this.detachChild(parentUuid, 'roles', childUuid)
  }

  /**
   * Sync roles
   *
   * @param parentUuid     Parent primary key
   * @param childUuids     Child primary key
   * @param detachOthers Detach others not in childUuids array
   */
  public syncRoles (parentUuid: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    return this.syncChildren(parentUuid, 'roles', childUuids, detachOthers)
  }

  // ---------------------------------------------------------------------------
  // User contact groups (Has many attached through user_to_contact_group)
  // ---------------------------------------------------------------------------

  /**
   * Get contact group
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   * @param params   Params
   */
  public getContactGroup (parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'contact-groups', childUuid, params)
  }

  /**
   * Get many contact groups
   */
  public getContactGroups (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'contact-groups', params)
  }

  /**
   * Attach contact group
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public attachContactGroup (parentUuid: string, childUuid: string): Observable<any> {
    return this.attachChild(parentUuid, 'contact-groups', childUuid)
  }

  /**
   * Detach contact group
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public detachContactGroup (parentUuid: string, childUuid: string): Observable<any> {
    return this.detachChild(parentUuid, 'contact-groups', childUuid)
  }

  /**
   * Sync contact groups
   *
   * @param parentUuid     Parent primary key
   * @param childUuids     Child primary key
   * @param detachOthers Detach others not in childUuids array
   */
  public syncContactGroups (parentUuid: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    return this.syncChildren(parentUuid, 'contact-groups', childUuids, detachOthers)
  }
}
