import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, Observable } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AlertService } from '@app/modules/alert/alert.service'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { AddressService } from '@app/modules/address/address.service'
import { EmailAddressService } from '@app/modules/email-address/email-address.service'
import { SocialService } from '@app/modules/social/social.service'
import { TelephoneService } from '@app/modules/telephone/telephone.service'
import { PersonService } from '@app/modules/person/person.service'
import { UriService } from '@app/modules/uri/uri.service'
import { Company } from '@app/modules/company/company.model'
import { CompanyService } from '@app/modules/company/company.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'
import { Person } from '@app/modules/person/person.model'
import { PersonFormService } from '@app/modules/person/person-form.service'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { OpportunityFormService } from '@app/modules/opportunity/opportunity-form.service'
import { ComponentCanDeactivate } from '@app/shared/guards/pending-changes.guard'
@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html'
})

export class EditCompanyComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Company form
   */
  public mainForm: FormGroup

  /**
   * Opportunities
   */
  public opportunitiesForm: FormGroup

  /**
   * Employees
   */
  public employeesForm: FormGroup

  /**
   * Person form
   */
  public personForm: FormGroup

  /**
   * Company
   */
  public company: Company

  /**
   * Mini nav
   *
   * For prev next.
   */
  public miniNav: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload') canDeactivate (): Observable<boolean> | boolean {
    if (this.mainForm) {
      return this.mainForm.pristine
    }
    return true
  }

  /**
   * Constructor
   */
  public constructor (
    // Angular
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private formBuilder: FormBuilder,
    // Services.
    private alertService: AlertService,
    public appService: AppService,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public routingStateService: RoutingStateService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    // Service models.
    public addressService: AddressService,
    public companyService: CompanyService,
    public emailAddressService: EmailAddressService,
    public socialService: SocialService,
    public telephoneService: TelephoneService,
    public personService: PersonService,
    public uriService: UriService,
    private companyFormService: CompanyFormService,
    private personFormService: PersonFormService,
    private opportunityFormService: OpportunityFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.routeSub()
  }

  /**
   * Route sub
   */
  public routeSub (): void {
    const sub: Subscription = this.activatedRoute
    .params
    .subscribe((routeParams) => {
      this.getCompany(routeParams['uuid'])
      .then((result: Company) => {
        this.setUpCompany(result)

        this.miniNav = this.companyService.getMiniNav(this.company.uuid)

        console.log('this.mainForm', this.mainForm.value)
        this.display = true
      })
      .catch((err) => {
        console.error(err)
      })
    })

    this.subscriptions.push(sub)

  }

  /**
   * Set up opportunity
   */
  private setUpCompany (data: any): void {
    this.company = new Company(data)
    this.mainForm = this.companyFormService.getForm(this.company, 'edit')

    // Cant link directly to person because of circular deps so build....
    this.opportunitiesForm = this.formBuilder.group({
      opportunities: this.formBuilder.array([])
    })

    // Circular deps
    if (this.company.opportunities) {
      const control: FormArray = this.opportunitiesForm.get('opportunities') as FormArray
      this.company.opportunities.map((opportunity: Opportunity) => {
        const opportunityForm: FormGroup = this.opportunityFormService.getForm(new Opportunity(opportunity), null)
        control.push(opportunityForm)
      })
    }

    this.employeesForm = this.formBuilder.group({
      people: this.formBuilder.array([]),
      peopleDeleted: this.formBuilder.array([])
    })

    if (this.company.people) {
      const control: FormArray = this.employeesForm.get('people') as FormArray
      this.company.people.map((person: Person) => {
        const personForm: FormGroup = this.personFormService.getForm(person, null)
        control.push(personForm)
      })
    }
  }

  /**
   * Get company
   */
  public getCompany (uuid: string): Promise<any> {
    return this.companyService.api.getOne(uuid, {
      includes: [
        'addresses',
        'addresses.country',
        'emailAddresses',
        'emailAddresses.emailAddressName',
        'telephones',
        'telephones.telephoneName',
        'socials',
        'uris',
        'opportunities',
        'opportunities.company',
        'opportunities.opportunityStatus',
        'opportunities.opportunityStage',
        'opportunities.salesPersonUser',
        'opportunities.probability',
        // 'people',
        // 'people.telephones',
        // 'people.telephones.telephoneName',
        // 'people.emailAddresses',
        // 'people.emailAddresses.emailAddressName',
        // 'people.companies',
        // 'people.companies.addresses',
        // 'people.companies.addresses.country',
        // 'tasks',
        // 'tasks.taskType',
        // 'tasks.organiserUser'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save company
   */
  public saveCompany (): void {
    console.log('saveCompany', this.mainForm.value)
    this.validationService.runValidation(this.mainForm)

    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.companyService.saveCompany(this.mainForm.value)
    .then((result) => {
      // Get the updated company.
      this.getCompany(result.uuid)
      .then((newResult: Company) => {
        this.setUpCompany(newResult)
        this.alertService.success('Saved: ' + this.mainForm.value.name, 10000)
        this.mainForm.markAsPristine()
      })
      .catch((err) => {
        console.error(err)
      })

    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.getCompany(this.company.uuid)
    .then((newResult: Company) => {
      this.setUpCompany(newResult)
      this.alertService.warning('Reverted: ' + this.mainForm.value.name, 10000)
      this.mainForm.markAsPristine()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Child changed
   *
   * This tells us something changed so reload.
   */
  public childChanged (event: Event): void {
    if (event) {}
    this.getCompany(this.company.uuid)
    .then((newResult: Company) => {
      this.setUpCompany(newResult)
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Delete company
   */
  public deleteCompany (uuid: string): void {
    console.log('deleteCompany', uuid)
    this.companyService.api.delete(uuid)
    .toPromise()
    .then((result) => {
      console.log('result', result)
      this.router.navigate(['/companies'])
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newEmployee') {
      console.log('newEmployee')
      const person: Person = new Person({
        companies: [
          new Company(this.mainForm.value)
        ]
      })

      this.personForm = this.personFormService.getForm(person, 'new')
    }

    if (type === 'editEmployee') {
      const person: Person = new Person(data)
      this.personForm = this.personFormService.getForm(person, 'edit')
    }

    this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save employee
   */
  public saveEmployee (): void {
    console.log('saveEmployee....', this.personForm.value)
    // this.validationService.runValidation(this.personForm)

    // if (!this.personForm.valid) {
    //   const formErrors: Array<any> = this.validationService.extractErrors(this.personForm)
    //   console.log('Validation errors', formErrors)
    //   this.alertService.error('Validation errors: Please check the form.', 10000)
    //   return
    // }

    // this.companyService.saveEmployee(this.company.uuid, this.personForm.value)
    // .then((result) => {
    //   // Update employees
    //   // Close modal
    // })
    // .catch((err) => {
    //   // Alert errors
    //   console.error(err)
    // })
  }
}
