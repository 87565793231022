import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { Observable } from 'rxjs'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
@Injectable()

export class ContactGroupApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/contact-groups'
  }

  // ---------------------------------------------------------------------------
  // Contacts (child through pivot)
  // ---------------------------------------------------------------------------

  /**
   * Get contact
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   * @param params   Params
   */
  public getContact (parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'contacts', childUuid, params)
  }

  /**
   * Get contacts
   */
  public getContacts (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'contacts', params)
  }

  /**
   * Attach contact
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public attachContact (parentUuid: string, childUuid: string): Observable<any> {
    return this.attachChild(parentUuid, 'contacts', childUuid)
  }

  /**
   * Detach contact
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public detachContact (parentUuid: string, childUuid: string): Observable<any> {
    return this.detachChild(parentUuid, 'contacts', childUuid)
  }

  /**
   * Sync contacts
   *
   * @param parentUuid     Parent primary key
   * @param childUuids     Child primary key
   * @param {boolean} detachOthers Detach others not in childUuids array
   */
  public syncContacts (parentUuid: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    return this.syncChildren(parentUuid, 'contacts', childUuids, detachOthers)
  }

  /**
   * Get custom contacts
   */
  public customQuery (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'custom-query', params)
  }
}
