import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-table-associated-with-display',
  templateUrl: './table-associated-with-display.component.html',
  providers: []
})

export class TableAssociatedWithDisplayComponent implements OnInit, OnDestroy {

  /**
   * Telephones
   */
  @Input() associatedWith: any

  /**
   * Display
   */
  public display: boolean

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * First item
   */
  public firstItem: any

  /**
   * Constructor
   */
  public constructor () {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    this.display = false
    if (this.associatedWith && this.associatedWith.length > 0) {
      // this.with.sort((a, b) => a.telephoneName.displayOrder - b.telephoneName.displayOrder)
      // this.firstItem = this.with.find((x) => x.telephoneNameUuid === this.telephoneNameUuid)

      if (!this.firstItem) {
        this.firstItem = this.associatedWith[0]
      }

      this.display = true
    }
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

}
