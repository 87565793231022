import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { TelephoneNameService } from '@app/modules/telephone-name/telephone-name.service'
import { TelephoneService } from '@app/modules/telephone/telephone.service'
@Component({
  selector: 'app-edit-telephone-name',
  templateUrl: '../../edit-list-item.component.html'
})

export class EditTelephoneNameComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Replace id
   */
  public replaceUuid: string

  /**
   * Display vars
   *
   * On these list pages we should only have to change this and the model,
   * the view is shared.
   */
  public displayVars: any = {
    singular: 'Telephone Name',
    plural: 'Telephone Names',
    route: 'telephone-names',
    replaceOptions: 'telephoneNameOptions'
  }

  /**
   * Main form
   */
  public mainForm: FormGroup

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public alertService: AlertService,
    public appService: AppService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public routingStateService: RoutingStateService,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public validationService: ValidationService,
    public mainService: TelephoneNameService,
    public telephoneService: TelephoneService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.replaceUuid = null
    this.routeSub()
  }

  /**
   * Route sub
   */
  public routeSub (): void {
    const sub: Subscription = this.activatedRoute
    .params
    .subscribe((routeParams) => {
      this.mainService.api.getOne(routeParams['uuid'], {})
      .toPromise()
      .then((result) => {
        this.mainForm = this.mainService.getForm(result)
        this.display = true
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
        console.error(err)
      })
    })

    this.subscriptions.push(sub)
  }

  /**
   * On submit
   */
  public onSubmit (): void {
    this.mainService.api.save(this.mainForm.value)
    .toPromise()
    .then(() => {
      this.selectOptionsService.getTelephoneNameOptions()
      .then(() => {
        this.alertService.success('Saved: ' + this.mainForm.value.name, 4000)
        this.mainForm.markAsPristine()
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
        console.error(err)
      })
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
      console.error(err)
    })
  }

  /**
   * Replace item
   */
  public replaceItem (): Promise<any> {
    const params: any | Object = {
      filter_groups: [
        {
          filters: [
            {
              key: 'telephone_name_uuid',
              value: this.mainForm.value.uuid,
              operator: 'eq'
            }
          ]
        }
      ]
    }

    const data: any = {
      telephoneNameUuid: this.replaceUuid
    }

    return this.telephoneService.api.updateAll(data, params)
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Check usage
   */
  public checkUsage (): Promise<any> {
    const params: any | Object = {
      filter_groups: [
        {
          filters: [
            {
              key: 'telephone_name_uuid',
              value: this.mainForm.value.uuid,
              operator: 'eq'
            }
          ]
        }
      ]
    }

    return this.telephoneService.api.getMany(params)
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * On replace and delete
   */
  public onReplaceAndDelete (): void {
    if (this.replaceUuid !== null) {
      this.replaceItem()
      .then(() => {
        this.mainService.api.delete(this.mainForm.value.uuid)
        .toPromise()
        .then(() => {
          this.selectOptionsService.getTelephoneNameOptions()
          this.router.navigate(['/lists', this.displayVars.route])
        })
        .catch((err) => {
          this.alertService.error('Error: ' + err)
          console.error(err)
        })
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
        console.error(err)
      })
    } else {
      this.checkUsage()
      .then((result) => {
        if (result.length > 0) {
          this.alertService.error('Warning: Cannot delete this because it is in use.')
        } else {
          this.mainService.api.delete(this.mainForm.value.uuid)
          .toPromise()
          .then(() => {
            this.selectOptionsService.getTelephoneNameOptions()
            this.router.navigate(['/lists', this.displayVars.route])
          })
          .catch((err) => {
            this.alertService.error('Error: ' + err)
            console.error(err)
          })
        }

      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
        console.error(err)
      })
    }
  }

}
