import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'

@Injectable()
export class LoginService {

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder
  ) {}

  /**
   * Get form
   */
  public getForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          ValidationService.isValidEmail,
          Validators.maxLength(255)
        ]
      ],
      password: [
        null,
        [
          Validators.required,
          Validators.maxLength(255)
        ]
      ],
      remember: [
        0,
        []
      ]
    })

    return form
  }

}
