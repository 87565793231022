import { Component, OnInit, OnDestroy, Input } from '@angular/core'
// import { SecurityContext } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Subscription } from 'rxjs'
import { Alert } from '@app/modules/alert/alert.model'
import { AlertType } from '@app/modules/alert/alert-type.model'
import { AlertService } from '@app/modules/alert/alert.service'

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  providers: []
})

export class AlertComponent implements OnInit, OnDestroy {

  /**
   * Id
   */
  @Input() uuid: string

  /**
   * Alerts
   *
   * @type {Array<Alert>}
   */
  public alerts: Array<Alert> = []

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   *
   * @param {AlertService} private alertService [description]
   */
  public constructor (
    private alertService: AlertService,
    public domSanitizer: DomSanitizer
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.alertService.getAlert(this.uuid)
    .subscribe((alert: Alert) => {
      if (!alert.message) {
        // Clear alerts when an empty alert is received.
        this.alerts = []
        return
      }

      if (alert.timeout) {
        setTimeout(() => {
          this.removeAlert(alert)
        }, alert.timeout)
      }

      // alert.safeMessage = this.domSanitizer.bypassSecurityTrustHtml(alert.message)

      // alert.safeMessage = this.domSanitizer.sanitize(SecurityContext.HTML, alert.message)

      // add alert to array
      this.alerts.push(alert)
    })
  }

  /**
   * Remove alert
   *
   * @param {Alert} alert Alert
   */
  public removeAlert (alert: Alert): void {
    this.alerts = this.alerts.filter(x => x !== alert)
  }

  /**
   * Css class
   *
   * @param {Alert} alert Alert
   *
   * @return {string}
   */
  public cssClass (alert: Alert): string {
    if (!alert) {
      return
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Primary:
        return 'alert alert-primary'
      case AlertType.Success:
        return 'alert alert-success'
      case AlertType.Error:
        return 'alert alert-danger'
      case AlertType.Info:
        return 'alert alert-info'
      case AlertType.Warning:
        return 'alert alert-warning'
    }
  }
}
