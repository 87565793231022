import { Component, OnInit, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { LocalStorageService } from 'angular-2-local-storage'
// import 'jquery-slimscroll'
import { AppService } from '@app/shared/services/app.service'

declare var $: any

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html'
})

export class LeftNavigationComponent implements OnInit, AfterViewInit {

  public display: boolean

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    public appService: AppService,
    public localStorageService: LocalStorageService
  ) {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Ng after view init
   */
  public ngAfterViewInit (): void {
    $('#side-menu').metisMenu()
    if ($('body').hasClass('fixed-sidebar')) {
      console.log('fixed')
      $('.sidebar-collapse').slimScroll({
        height: '100%'
      })
    }
  }

  /**
   * Active route
   */
  public activeRoute (routename: string): boolean {
    return this.router.url.indexOf(routename) > -1
  }

  /**
   * On logout
   */
  public onLogout (): void {
    this.localStorageService.remove('apiKey')
    this.localStorageService.remove('currentUser')
  }

}
