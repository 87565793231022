import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import * as moment from 'moment'
import { TaskApiService } from '@app/modules/task/task-api.service'
import { Task } from '@app/modules/task/task.model'
import { Person } from '@app/modules/person/person.model'
// import { Company } from '@app/modules/company/company.model'

@Injectable()

export class TaskService {

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'task.startDatetime',
    direction: 'DESC'
  }

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public api: TaskApiService
  ) {}

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   *
   * https://ng-bootstrap.github.io/#/components/datepicker/api
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      showCompleted: [
        false,
        []
      ],
      scheduledWith: [
        null,
        []
      ],
      associatedWith: [
        null,
        []
      ],
      startDatetimeOperator: [
        'eq',
        []
      ],
      taskTypeUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      regarding: [
        null,
        []
      ],
      startDatetime: [
        null,
        []
      ],
      endDatetime: [
        null,
        []
      ],
      originalDatetime: [
        null,
        []
      ],
      isTimeless: [
        false,
        []
      ],
      location: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      isPrivate: [
        false,
        []
      ],
      organiserUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      details: [
        null,
        []
      ],
      masterUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      parentUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      recurSourceUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      isEvent: [
        null,
        []
      ],
      recurPeriod: [
        null,
        []
      ],
      recurFreq: [
        null,
        []
      ],
      recurModifier: [
        null,
        []
      ],
      recurDay: [
        null,
        []
      ],
      recurDayType: [
        null,
        []
      ],
      recurMonth: [
        null,
        []
      ],
      recurEndDate: [
        null,
        []
      ],
      isRecurEndless: [
        null,
        []
      ],
      isDeleted: [
        false,
        []
      ],
      deletedDates: [
        null,
        []
      ],
      isInvitationSent: [
        null,
        []
      ],
      duration: [
        null,
        []
      ],
      assignedToUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      assignedByUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      taskPriorityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // Info.
      createdAt: [
        null,
        []
      ],
      updatedAt: [
        null,
        []
      ],
      deletedAt: [
        null,
        []
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public search (searchFilters: any | Object): Promise<any> {
    const filterGroups: Array<any> = []

    let filters: Array<any>
    filters = []
    if (searchFilters.startDatetime && searchFilters.startDatetimeOperator) {
      // filters.push({
      //   key: 'start_datetime',
      //   value: [
      //     from,
      //     to
      //   ],
      //   operator: 'bt'
      // })
      // const dob: string = moment(searchFilters.dob).format('YYYY-MM-DD HH:mm:ss')
      // const from: string = moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss')
      // const to: string = moment().add(1, 'week').format('YYYY-MM-DD HH:mm:ss')

      const from: string = moment(searchFilters.startDatetime).utc().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const to: string = moment(searchFilters.startDatetime).utc().endOf('day').format('YYYY-MM-DD HH:mm:ss')

      switch (searchFilters.startDatetimeOperator) {
        case 'eq':
          filters.push({
            key: 'task.startDatetime',
            value: from,
            operator: 'gte'
          })

          filters.push({
            key: 'task.startDatetime',
            value: to,
            operator: 'lte'
          })
          break

        case 'lt':
          filters.push({
            key: 'task.startDatetime',
            value: from,
            operator: 'lt'
          })
          break

        case 'gt':
          filters.push({
            key: 'task.startDatetime',
            value: to,
            operator: 'gt'
          })
          break

        case 'lte':
          filters.push({
            key: 'task.startDatetime',
            value: to,
            operator: 'lte'
          })
          break

        case 'gte':
          filters.push({
            key: 'task.startDatetime',
            value: from,
            operator: 'gte'
          })
          break

        case 'neq':
          filters.push({
            key: 'task.startDatetime',
            value: from,
            operator: 'lt'
          })

          filters.push({
            key: 'task.startDatetime',
            value: to,
            operator: 'gt'
          })
          break
      }

      // Add our startDatetime filters.
      if (filters.length > 0) {
        filterGroups.push(
          {
            filters: filters
          }
        )
        filters = []
      }
    }

    // orWhereBetween

    if (searchFilters.scheduledWith) {
      filters.push({
        key: 'scheduledWith',
        value: searchFilters.scheduledWith,
        operator: 'ct'
      })
    }

    if (searchFilters.associatedWith) {
      filters.push({
        key: 'associatedWith',
        value: searchFilters.associatedWith,
        operator: 'ct'
      })
    }

    if (searchFilters.regarding) {
      filters.push({
        key: 'task.regarding',
        value: searchFilters.regarding,
        operator: 'ct'
      })
    }

    if (searchFilters.location) {
      filters.push({
        key: 'task.location',
        value: searchFilters.location,
        operator: 'ct'
      })
    }

    if (searchFilters.isPrivate) {
      filters.push({
        key: 'task.isPrivate',
        value: 1,
        operator: 'eq'
      })
    }

    if (searchFilters.isTimeless) {
      filters.push({
        key: 'task.isTimeless',
        value: 1,
        operator: 'eq'
      })
    }

    if (searchFilters.showCompleted) {
      filters.push({
        key: 'isComplete',
        value: 3,
        not: true,
        operator: 'eq'
      })
    } else {
      filters.push({
        key: 'isComplete',
        value: 0,
        operator: 'eq'
      })
    }

    if (searchFilters.taskTypeUuid) {
      filters.push({
        key: 'task.taskTypeUuid',
        value: searchFilters.taskTypeUuid,
        operator: 'eq'
      })
    }

    if (searchFilters.taskPriorityUuid) {
      filters.push({
        key: 'task.taskPriorityUuid',
        value: searchFilters.taskPriorityUuid,
        operator: 'eq'
      })
    }

    if (searchFilters.organiserUserUuid) {
      filters.push({
        key: 'task.organiserUserUuid',
        value: searchFilters.organiserUserUuid,
        operator: 'eq'
      })
    }

    // Add our other filters
    if (filters.length > 0) {
      filterGroups.push(
        {
          or: false,
          filters: filters
        }
      )
    }

    const params: any | Object = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'task.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count
      // Add pagination and includes for result set.
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        'taskType',
        'taskPriority',
        'assignedToUser',
        'scheduledWith',
        'associatedWith',
        'organiserUser'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      console.error(err)
    })

  }

  /**
   * Save task
   */
  public saveTask (task: Task): Promise<any> {
    console.log('taskService.saveTask()', task)
    // Save the task.
    return this.api.save(task)
    .toPromise()
    .then((taskResult) => {
      // const taskUuid: string = taskResult.uuid
      const promises: Array<Promise<any>> = []

      const scheduledWithIds: Array<string> = []
      if (task.scheduledWith) {
        task.scheduledWith.map((person: Person) => {
          scheduledWithIds.push(person.uuid)

          // const promise: Promise<any> = this.api.syncScheduledWith(taskUuid, scheduledWithIds, true)
          // .toPromise()
          // .then((result) => {
          //   return result
          // })
          // .catch((err) => {
          //   throw err
          // })

          // promises.push(promise)
        })
      }

      return Promise.all(promises)
      .then(() => {
        return taskResult
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })
  }

}
