import { Base } from '@app/shared/models/base.model'

export class PersonToCompany extends Base {
  uuid: string = null
  personUuid: string = null
  companyUuid: string = null
  departmentUuid: string = null
  department: string = null
  position: string = null
  isPrimary: boolean = null
  // Info.
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  updatedByUuid: string = null
  // Relations.

  /**
   * Properties with type Date
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param data Person
   */
  public constructor (data?: Partial<PersonToCompany>) {
    super(data)
    this.init(data)
  }
}
