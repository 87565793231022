import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from '@app/modules/alert/alert.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ValidationService } from '@app/shared/services/validation.service'
import { OpportunityService } from '@app/modules/opportunity/opportunity.service'
import { Person } from '@app/modules/person/person.model'
import { PersonFormService } from '@app/modules/person/person-form.service'
import { ExistingPeopleFormService } from '@app/modules/person/services/existing-people-form.service'

@Component({
  selector: 'app-tab-contacts-table',
  templateUrl: './tab-contacts-table.component.html'
})

export class TabContactsTableComponent implements OnInit, OnDestroy {

  /**
   * Opportunity primary key
   */
  @Input() opportunityUuid: string

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  public mainForm: FormGroup

  /**
   * Contacts
   */
  public contacts: Array<Person> = []

  /**
   * Person form
   */
  public personForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Existing people form
   */
  public existingPeopleForm: FormGroup

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    // private formBuilder: FormBuilder,
    private alertService: AlertService,
    private modalService: NgbModal,
    public validationService: ValidationService,
    private opportunityService: OpportunityService,
    private personFormService: PersonFormService,
    private existingPeopleFormService: ExistingPeopleFormService,
    // private personService: PersonService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    console.log('init')
    this.getOpportunityContacts(this.opportunityUuid)
    .then((results: Array<Person>) => {
      this.contacts = results
      this.display = true
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err.statusText)
    })
  }

  /**
   * Get opportunity contacts
   *
   * @param opportunityUuid Opportunity primary key
   */
  public getOpportunityContacts (opportunityUuid: string): Promise<any> {
    return this.opportunityService.api.getContacts(opportunityUuid, {
        includes: [
          'companies',
          'companies.addresses',
          'companies.addresses.country',
          'telephones',
          'telephones.telephoneName',
          'emailAddresses',
          'emailAddresses.emailAddressName'
        ]
      })
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'existingContactModal') {
      this.existingPeopleForm = this.existingPeopleFormService.getForm(null)
    }

    if (type === 'newContact') {
      const person: Person = new Person({
        companies: []
      })

      this.personForm = this.personFormService.getForm(person, 'new')
    }

    if (type === 'editContact') {
      const person: Person = new Person(data)
      this.personForm = this.personFormService.getForm(person, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })

  }

  // ---------------------------------------------------------------------------
  // Opportunity contacts
  // ---------------------------------------------------------------------------

    /**
   * Save employee
   */
  public saveContact (): void {
    this.validationService.runValidation(this.personForm)
    if (!this.personForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.personForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.opportunityService.saveContact(this.opportunityUuid, this.personForm.value)
    .then((result) => {
      if (result) {
      }
      this.alertService.success('Saved: ' + (this.personForm.value.firstName ? this.personForm.value.firstName : '') +
      ' ' + (this.personForm.value.lastName ? this.personForm.value.lastName : ''), 10000)
      // this.mainForm.markAsPristine()
      this.currentModal.close()
      this.ngOnInit()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err.message)
    })
  }

  /**
   * Delete contact
   */
  public deleteContact (uuid: string): void {
    this.opportunityService.deleteContact(this.opportunityUuid, uuid)
    .then(() => {
      this.alertService.success('Deleted: ' + (this.personForm.value.firstName ? this.personForm.value.firstName : '') +
      ' ' + (this.personForm.value.lastName ? this.personForm.value.lastName : ''), 10000)
      this.currentModal.close()
      this.ngOnInit()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err.message)
    })
  }

  /**
   * Remove contact
   *
   * @param personUuid Person primary key
   */
  public removeContact(personUuid: string): void {
    this.opportunityService.api.detachContact(this.opportunityUuid, personUuid)
      .toPromise()
      .then(() => {
        if (this.currentModal) {
          this.currentModal.close()
        }
        this.ngOnInit()
      })
      .catch((err) => {
        // console.error(err)
        this.alertService.error('Error: ' + err.message)
      })
  }

  /**
   * Save existing contacts
   */
  public saveExistingContacts (): void {
    const promises: Array<Promise<any>> = []
    let promise: Promise<any>

    this.existingPeopleForm.value.contacts.map((person: Person) => {
      promise = this.opportunityService.api.attachContact(this.opportunityUuid, person.uuid)
      .toPromise()
      .then(() => {
      })
      .catch((err) => {
        throw err
      })
      promises.push(promise)
    })

    Promise.all(promises)
    .then(() => {
      this.alertService.success('Added contacts', 10000)
      this.currentModal.close()
      this.ngOnInit()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err.message)
    })
  }

  /**
   * Done adding contacts
   */
  public cancelAddingContacts (): void {
  }

  /**
   * Stopper
   */
  public stopper (event: Event): void {
    event.preventDefault()
    event.stopPropagation()
  }
}
