import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { cloneDeep } from 'lodash'

export class FmItem extends Base {
  uuid: string = null
  name: string = null
  path: Array<any>
  type: string = null
  size: number = null
  date: Date = null
  perms: string = null
  content: string = null
  recursive: boolean
  public tempModel: FmItem

  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param {Partial<Address>} data Initial values
   */
  public constructor (data?: Partial<FmItem>) {
    super(data)
    this.recursive = false
    this.init(data)
    this.tempModel = cloneDeep(this)
    // this.tempModel = JSON.parse(JSON.stringify(this))
  }

  /**
   * Full path
   *
   * @returns {string}
   */
  public fullPath (): string {
    const path: any = this.path.filter(Boolean)
    return ('/' + path.join('/') + '/' + this.name).replace(/\/\//, '/')
  }

  /**
   * Is folder
   *
   * @returns {boolean}
   */
  public isFolder (): boolean {
    return this.type === 'dir'
  }

  public isEditable (): boolean {
    // return !this.isFolder() && fileManagerConfig.isEditableFilePattern.test(this.name)
    return false
  }

  public isImage (): boolean {
    // return fileManagerConfig.isImageFilePattern.test(this.name)
    return false
  }

  public isCompressible (): boolean {
    return this.isFolder()
  }

  public isExtractable (): boolean {
    // return !this.isFolder() && fileManagerConfig.isExtractableFilePattern.test(this.name)
    return false
  }

  public isSelectable (): boolean {
    // return (this.isFolder() && fileManagerConfig.allowedActions.pickFolders)
    // || (!this.isFolder() && fileManagerConfig.allowedActions.pickFiles);
    return true
  }

}

// (function(angular) {
//     'use strict';
//     angular.module('FileManagerApp').factory('item', ['fileManagerConfig', 'chmod', function(fileManagerConfig, Chmod) {

//         var FmItem = function(model, path) {
//             var rawModel = {
//                 name: model && model.name || '',
//                 path: path || [],
//                 type: model && model.type || 'file',
//                 size: model && parseInt(model.size || 0),
//                 date: parseMySQLDate(model && model.date),
//                 perms: new Chmod(model && model.rights),
//                 content: model && model.content || '',
//                 recursive: false,
//                 fullPath: function() {
//                     var path = this.path.filter(Boolean);
//                     return ('/' + path.join('/') + '/' + this.name).replace(/\/\//, '/');
//                 }
//             };

//             this.error = '';
//             this.processing = false;

//             this.model = angular.copy(rawModel);
//             this.tempModel = angular.copy(rawModel);

//             function parseMySQLDate(mysqlDate) {
//                 var d = (mysqlDate || '').toString().split(/[- :]/);
//                 return new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]);
//             }
//         };

//         FmItem.prototype.update = function() {
//             angular.extend(this.model, angular.copy(this.tempModel));
//         };

//         FmItem.prototype.revert = function() {
//             angular.extend(this.tempModel, angular.copy(this.model));
//             this.error = '';
//         };

//         FmItem.prototype.isFolder = function() {
//             return this.model.type === 'dir';
//         };

//         FmItem.prototype.isEditable = function() {
//             return !this.isFolder() && fileManagerConfig.isEditableFilePattern.test(this.model.name);
//         };

//         FmItem.prototype.isImage = function() {
//             return fileManagerConfig.isImageFilePattern.test(this.model.name);
//         };

//         FmItem.prototype.isCompressible = function() {
//             return this.isFolder();
//         };

//         FmItem.prototype.isExtractable = function() {
//             return !this.isFolder() && fileManagerConfig.isExtractableFilePattern.test(this.model.name);
//         };

//         FmItem.prototype.isSelectable = function() {
//             return (this.isFolder() && fileManagerConfig.allowedActions.pickFolders) ||
//              (!this.isFolder() && fileManagerConfig.allowedActions.pickFiles);
//         };

//         return FmItem;
//     }]);
// })(angular);
