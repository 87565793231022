import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { ConfigService, FileNavigatorService } from '../../services'

@Component({
  selector: 'app-fm-current-folder-breadcrumb',
  templateUrl: './fm-current-folder-breadcrumb.component.html',
  providers: []
})

export class FmCurrentFolderBreadcrumbComponent implements OnInit, OnDestroy {

  /**
   * Config
   */
  public config: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public configService: ConfigService,
    public fileNavigatorService: FileNavigatorService

  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.config = this.configService.config
  }

}
