import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { Observable } from 'rxjs'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
@Injectable()

export class CompanyApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/companies'
  }

  // ---------------------------------------------------------------------------
  // Company Addresses
  // ---------------------------------------------------------------------------

  /**
   * Save address
   *
   * @param id        Parent primary key
   * @param childData Child data
   *
   */
  public saveAddress (uuid: string, childData: Object): Observable<any> {
    return this.saveChild(uuid, 'addresses', childData)
  }

  /**
   * Delete address
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public deleteAddress (uuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(uuid, 'addresses', childUuid)
  }

  /**
   * Destroy address
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyAddress (uuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(uuid, 'addresses', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Company email addresses
  // ---------------------------------------------------------------------------

  /**
   * Save email address
   *
   * @param parentUuid  Parent primary key
   * @param childData Child data
   *
   */
  public saveEmailAddress (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'email-addresses', childData)
  }

  /**
   * Delete address
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteEmailAddress (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'email-addresses', childUuid)
  }

  /**
   * Destroy address
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyEmailAddress (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'email-addresses', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Company social accounts
  // ---------------------------------------------------------------------------

  /**
   * Save social
   *
   * @param parentUuid  Parent primary key
   * @param childData Child data
   *
   */
  public saveSocial (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'socials', childData)
  }

  /**
   * Delete social
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteSocial (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'socials', childUuid)
  }

  /**
   * Destroy social
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroySocial (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'socials', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Company telephone numbers
  // ---------------------------------------------------------------------------

  /**
   * Save telephone
   *
   * @param parentUuid  Parent primary key
   * @param childData Child data
   *
   */
  public saveTelephone (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'telephones', childData)
  }

  /**
   * Delete telephone
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteTelephone (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'telephones', childUuid)
  }

  /**
   * Destroy telephone
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyTelephone (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'telephones', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Company uri's
  // ---------------------------------------------------------------------------

  /**
   * Save uri
   *
   * @param parentUuid  Parent primary key
   * @param childData Child data
   *
   */
  public saveUri (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'uris', childData)
  }

  /**
   * Delete uri
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   *
   */
  public deleteUri (parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'uris', childUuid)
  }

  /**
   * Destroy uri
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyUri (parentUuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(parentUuid, 'uris', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Employees (child through pivot)
  // ---------------------------------------------------------------------------

  /**
   * Get employee
   */
  public getEmployee (parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'people', childUuid, params)
  }

  /**
   * Get employees
   */
  public getEmployees (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'people', params)
  }

  /**
   * Save employee
   *
   * @param parentUuid  Parent primary key
   * @param childData Child data
   *
   */
  public saveEmployee (parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'people', childData)
  }

  /**
   * Attach employee
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public attachEmployee (parentUuid: string, childUuid: string): Observable<any> {
    return this.attachChild(parentUuid, 'people', childUuid)
  }

  /**
   * Detatach employee
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public detachEmployee (parentUuid: string, childUuid: string): Observable<any> {
    return this.detachChild(parentUuid, 'people', childUuid)
  }

  /**
   * Sync employees
   *
   * @param parentUuid     Parent primary key
   * @param childUuids     Child primary key
   * @param detachOthers Detach others not in childUuids array
   */
  public syncEmployees (parentUuid: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    return this.syncChildren(parentUuid, 'people', childUuids, detachOthers)
  }

}
