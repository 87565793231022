import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, Observable } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { ContactGroupService } from '@app/modules/contact-group/contact-group.service'
import { ContactGroupFormService } from '@app/modules/contact-group/contact-group-form.service'
import { ContactGroup } from '@app/modules/contact-group/contact-group.model'
import { Person } from '@app/modules/person/person.model'
import { ComponentCanDeactivate } from '@app/shared/guards/pending-changes.guard'

@Component({
  selector: 'app-edit-contact-group',
  templateUrl: './edit-contact-group.component.html'
})

export class EditContactGroupComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Person form
   */
  public mainForm: FormGroup

  /**
   * Contact group
   */
  public contactGroup: ContactGroup

  /**
   * Contacts
   */
  public contacts: Array<Person> = []

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload') canDeactivate (): Observable<boolean> | boolean {
    if (this.mainForm) {
      return this.mainForm.pristine
    }
    return true
  }

  /**
   * Constructor
   */
  public constructor (
    // Angular
    public activatedRoute: ActivatedRoute,
    public router: Router,
    // private formBuilder: FormBuilder,
    // Services.
    public alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public routingStateService: RoutingStateService,
    public validationService: ValidationService,
    // Service models.
    public contactGroupService: ContactGroupService,
    private contactGroupFormService: ContactGroupFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.routeSub()
  }

  /**
   * Route sub
   */
  public routeSub (): void {
    const sub: Subscription = this.activatedRoute
    .params
    .subscribe((routeParams) => {
      this.getContactGroup(routeParams['uuid'])
      .then((result) => {
        this.setUp(result)
        // this.miniNav = this.contactGroupService.getMiniNav(result.uuid)
        this.display = true
      })
      .catch((err) => {
        console.error(err)
      })
    })

    this.subscriptions.push(sub)
  }

  /**
   * Set up contact group
   */
  public setUp (data: any): void {
    this.contactGroup = new ContactGroup(data)
    this.mainForm = this.contactGroupFormService.getForm(this.contactGroup, 'edit')
  }

  /**
   * Get contact group
   */
  public getContactGroup (personUuid: string): Promise<any> {
    return this.contactGroupService.api.getOne(personUuid, {
      includes: [
        'contacts',
        'contacts.telephones',
        'contacts.telephones.telephoneName',
        'contacts.emailAddresses',
        'contacts.emailAddresses.emailAddressName',
        'contacts.companies',
        'contacts.companies.addresses',
        'contacts.companies.addresses.country'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save contact group
   */
  public saveContactGroup (): Promise<any> {
    console.log('saveContactGroup', this.mainForm.value)
    this.validationService.runValidation(this.mainForm)

    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.contactGroupService.saveContactGroup(this.mainForm.value)
    .then(() => {
      this.alertService.success('Saved: ' + this.mainForm.value.name, 10000)
      this.mainForm.markAsPristine()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.getContactGroup(this.contactGroup.uuid)
    .then((newResult: ContactGroup) => {
      this.setUp(newResult)
      this.alertService.warning('Reverted: ' + this.mainForm.value.name, 10000)
      this.mainForm.markAsPristine()
    })
    .catch((err) => {
      console.error(err)
    })
  }
}
