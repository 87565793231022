import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, Observable } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { EventService } from '@app/shared/services/event.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { CompanyService } from '@app/modules/company/company.service'
import { PersonService } from '@app/modules/person/person.service'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { OpportunityService } from '@app/modules/opportunity/opportunity.service'
import { OpportunityFormService } from '@app/modules/opportunity/opportunity-form.service'
import { ProductServiceModel } from '@app/modules/product-service'
import { Task } from '@app/modules/task/task.model'
import { History } from '@app/modules/history/history.model'
import { ComponentCanDeactivate } from '@app/shared/guards/pending-changes.guard'
import { ConfigService, FmService } from '@app/modules/filemanager/services'

@Component({
  selector: 'app-edit-opportunity',
  templateUrl: './edit-opportunity.component.html'
})

export class EditOpportunityComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  /**
   * Opportunity primary key
   */
  public opportunityUuid: string

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  public mainForm: FormGroup

  /**
   * Opportunity
   */
  public opportunity: Opportunity

  /**
   * Activities
   */
  public activities: Array<Task> = []

  /**
   * History
   */
  public history: Array<History> = []

  /**
   * History
   */
  public productServices: Array<ProductServiceModel> = []

  /**
   * Storages
   */
  public storages: Array<any> = []

  /**
   * Company
   */
  public company: any | Object

  /**
   * Company form
   */
  public companyForm: FormGroup

  /**
   * Edit product service Id
   */
  public editProductServiceId: number

  /**
   * Mini nav
   *
   * For prev next.
   */
  public miniNav: any

  /**
   * Project id
   */
  public projectId: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload') canDeactivate (): Observable<boolean> | boolean {
    if (this.mainForm) {
      return this.mainForm.pristine
    }
    return true
  }

  /**
   * Constructor
   */
  public constructor (
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private alertService: AlertService,
    private eventService: EventService,
    public routingStateService: RoutingStateService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    public opportunityService: OpportunityService,
    public companyService: CompanyService,
    public personService: PersonService,
    private opportunityFormService: OpportunityFormService,
    private configService: ConfigService,
    private fmService: FmService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.routeSub()
  }

  /**
   * Event sub
   */
  private eventSub (): void {
    const sub: Subscription = this.eventService.events.subscribe((event: any) => {
      if (event.name === 'opportunity.changed') {
        this.ngOnInit()
      }
    })

    this.subscriptions.push(sub)
  }

  /**
   * Route sub
   */
  public routeSub (): void {
    const sub: Subscription = this.activatedRoute
    .params
    .subscribe((routeParams) => {
      this.opportunityUuid = routeParams['uuid']

      // @todo deprecate this
      this.getOpportunity(routeParams['uuid'])
      .then((result: Opportunity) => {
        this.opportunity = result
        this.configService.config.basePath = result.num
        this.fmService.init()
        this.setUpOpportunity(result)
        this.eventSub()
        this.miniNav = this.opportunityService.getMiniNav(this.opportunity.uuid)
        this.display = true
      })
      .catch((err) => {
        console.error(err)
      })


    })

    this.subscriptions.push(sub)
  }

  /**
   * Set up opportunity
   */
  private setUpOpportunity (data: any): void {
    this.opportunity = new Opportunity(data)

    if (this.opportunity.num && this.opportunity.num > 0) {
      this.projectId = this.opportunity.num >= 10000 ? this.opportunity.jobNo : this.opportunity.proposalNo
    } else {
      this.projectId = 'NEW'
    }

    this.mainForm = this.opportunityFormService.getForm(this.opportunity, 'edit')

    this.productServices = this.opportunity.productServices

    // this.activities = data.tasks
  }

  /**
   * Get opportunity
   *
   * @param opportunityUuid Opportunity primary key
   */
  public getOpportunity (opportunityUuid: string): Promise<any> {
    return this.opportunityService.api.getOne(opportunityUuid, {
      includes: [
        'salesPersonUser',
        'opportunityAgreements',
        'productServices',
        'company',
        'company.addresses',
        'company.addresses.country',
        'site',
        'site.address',
        'site.address.country',
        'shutdown',
        'shutdown.person',
        'probability',
        'contacts',
        'contacts.companies',
        'contacts.companies.addresses',
        'contacts.companies.addresses.country',
        'contacts.telephones',
        'contacts.telephones.telephoneName',
        'contacts.emailAddresses',
        'contacts.emailAddresses.emailAddressName'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save opportunity
   */
  public saveOpportunity (): void {
    this.validationService.runValidation(this.mainForm)
    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.opportunityService.saveOpportunity(this.mainForm.value)
    .then((result) => {
      // Get the updated opportunity.
      this.getOpportunity(result.uuid)
      .then((newResult: any) => {
        this.setUpOpportunity(newResult)
        this.alertService.success('Saved: ' + this.mainForm.value.name, 10000)
        this.mainForm.markAsPristine()
      })
      .catch((err) => {
        console.error(err)
      })
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.getOpportunity(this.opportunity.uuid)
    .then((newResult: Opportunity) => {
      this.setUpOpportunity(newResult)
      this.alertService.warning('Reverted: ' + this.mainForm.value.name, 10000)
      this.mainForm.markAsPristine()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Child changed
   *
   * This tells us something changed so reload.
   */
  public childChanged (event: Event): void {
    console.log('childChanged', event)
    this.getOpportunity(this.opportunity.uuid)
    .then((newResult: Opportunity) => {
      this.setUpOpportunity(newResult)
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Delete opportunity
   *
   * @param opportunityUuid Opportunity primary key
   */
  public deleteOpportunity (opportunityUuid: string): void {
    this.opportunityService.api.delete(opportunityUuid)
    .toPromise()
    .then(() => {
      this.alertService.success('Deleted: ' + this.mainForm.value.name, 10000)
      this.routingStateService.goBack(['/opportunities'])
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Open modal
   */
  // public openModal (event: Event, content: any, type: string, data?: any): void {
  //   event.preventDefault()
  //   event.stopPropagation()

  //   if (type === 'newOpportunity') {
  //     const opportunity: Opportunity = new Opportunity({
  //       // companies: [
  //       //   new Company(this.mainForm.value)
  //       // ]
  //     })

  //     this.opportunityForm = this.opportunityFormService.getForm(opportunity, 'new')
  //   }

  //   if (type === 'editOpportunity') {
  //     const opportunity: Opportunity = new Opportunity(data)
  //     this.opportunityForm = this.opportunityFormService.getForm(opportunity, 'edit')
  //   }

  //   this.currentModal = this.modalService.open(content, {
  //     size: 'lg',
  //     windowClass: 'fade modal-xl'
  //   })
  //   .result
  //   .then((result) => {
  //     if (result) {}
  //     // this.closeResult = `Closed with: ${result}`
  //   }, (reason) => {
  //     if (reason) {}
  //     // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
  //   })
  // }
}
