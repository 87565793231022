import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '../services/translate.service'

@Pipe({ name: 'translate' })

export class TranslatePipe implements PipeTransform {

  /**
   * Constructor
   */
  public constructor (
    private translateService: TranslateService
  ) {}

  /**
   * Will be a translation pipe
   *
   * @param value Value
   *
   * @returns {string}
   */
  public transform (value: string): string {
    return this.translateService.translate(value)
  }

}
