import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpService } from '@app/shared/services/http.service'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

@Injectable()

export class OpportunityApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor(
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init(): void {
    this.endPoint = 'v1/opportunities'
  }

  // ---------------------------------------------------------------------------
  // Companies
  // ---------------------------------------------------------------------------

  /**
   * Get company
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   * @param params   Params
   */
  public getCompany(parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'company', childUuid, params)
  }

  /**
   * Get many companies
   */
  public getCompanies(parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'company', params)
  }

  // ---------------------------------------------------------------------------
  // Product services
  // ---------------------------------------------------------------------------

  /**
   * Get product service
   *
   * @param parentUuid Parent primary key
   * @param params   Params
   */
  public getProductService(parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'product-services', childUuid, params)
  }

  /**
   * Get many product services
   */
  public getProductServices(parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'product-services', params)
  }

  /**
   * Save product service
   *
   * @param id        Parent primary key
   * @param childData Child data
   *
   */
  public saveProductService(uuid: string, childData: Object): Observable<any> {
    return this.saveChild(uuid, 'product-services', childData)
  }

  /**
   * Delete product service
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public deleteProductService(uuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(uuid, 'product-services', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Opportunity agreements
  // ---------------------------------------------------------------------------

  /**
   * Save opportunity agreement
   *
   * @param id        Parent primary key
   * @param childData Child data
   *
   */
  public saveOpportunityAgreement(uuid: string, childData: Object): Observable<any> {
    return this.saveChild(uuid, 'opportunity-agreements', childData)
  }

  /**
   * Delete opportunity agreement
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public deleteOpportunityAgreement(uuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(uuid, 'opportunity-agreements', childUuid)
  }

  /**
   * Destroy opportunity agreement
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyOpportunityAgreement(uuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(uuid, 'opportunity-agreements', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Opportunity shutdown
  // ---------------------------------------------------------------------------

  /**
   * Save shutdown
   *
   * @param id        Parent primary key
   * @param childData Child data
   *
   */
  public saveShutdown(uuid: string, childData: Object): Observable<any> {
    return this.saveChild(uuid, 'shutdown', childData)
  }

  /**
   * Delete shutdown
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public deleteShutdown(uuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(uuid, 'shutdown', childUuid)
  }

  /**
   * Destroy shutdown
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyShutdown(uuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(uuid, 'shutdown', childUuid)
  }

  // ---------------------------------------------------------------------------
  // Opportunity contacts
  // ---------------------------------------------------------------------------

  /**
   * Get many contacts
   */
  public getContacts(parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'contacts', params)
  }

  /**
   * Save contacts
   *
   * @param id        Parent primary key
   * @param childData Child data
   *
   */
  public saveContact(uuid: string, childData: Object): Observable<any> {
    return this.saveChild(uuid, 'contacts', childData)
  }

  /**
   * Delete contacts
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public deleteContact(uuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(uuid, 'contacts', childUuid)
  }

  /**
   * Destroy contacts
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyContact(uuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(uuid, 'contacts', childUuid)
  }

  /**
   * Attach contact
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public attachContact(parentUuid: string, childUuid: string, data?: Object): Observable<any> {
    return this.attachChild(parentUuid, 'contacts', childUuid, data)
  }

  /**
   * Detatach contact
   *
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   */
  public detachContact(parentUuid: string, childUuid: string): Observable<any> {
    return this.detachChild(parentUuid, 'contacts', childUuid)
  }

  /**
   * Sync contacts
   *
   * @param parentUuid     Parent primary key
   * @param childUuids     Child primary key
   * @param detachOthers Detach others not in childUuids array
   */
  public syncContacts(parentUuid: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    return this.syncChildren(parentUuid, 'contacts', childUuids, detachOthers)
  }

  // ---------------------------------------------------------------------------
  // Tasks
  // ---------------------------------------------------------------------------

  /**
   * Get task
   *
   * @param parentUuid Parent primary key
   * @param params     Params
   */
  public getTask(parentUuid: string, childUuid: string, params?: Object): Observable<any> {
    return this.getOneChild(parentUuid, 'tasks', childUuid, params)
  }

  /**
   * Get many tasks
   *
   * @param parentUuid Parent primary key
   * @param params     Params
   */
  public getTasks(parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'tasks', params)
  }

  /**
   * Save task
   *
   * @param id        Parent primary key
   * @param childData Child data
   */
  public saveTask(parentUuid: string, childData: Object): Observable<any> {
    return this.saveChild(parentUuid, 'tasks', childData)
  }

  /**
   * Delete task
   *
   * @param parentUuid      Parent primary key
   * @param childUuid Child primary key
   */
  public deleteTask(parentUuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(parentUuid, 'tasks', childUuid)
  }
}
