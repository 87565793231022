import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { LocalStorageService } from 'angular-2-local-storage'
import { environment } from '@env/environment'

@Injectable()

export class AppService {

  /**
   * Ready
   */
  public ready: boolean

  /**
   * Current user
   */
  public currentUser: any

  /**
   * Debug
   */
  public debug: boolean

  /**
   * Environment
   */
  public env: any | Object

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    public authService: AuthService,
    public localStorageService: LocalStorageService
  ) {
    this.debug = false
    this.env = environment
    this.getCurrentUser()
    const apiKey: any = this.authService.getApiKey()

    console.log('env', this.env)
    console.log('apiKey', apiKey)
    console.log('currentUser', this.currentUser)

    if (this.currentUser === null || apiKey === null) {
      // this.authService.logout()
      // this.router.navigate(['/login'])
    }
  }

  /**
   * Set current user
   *
   * @param currentUser Current user
   */
  public setCurrentUser (currentUser: any | Object): boolean {
    this.currentUser = currentUser
    return this.localStorageService.set('currentUser', this.currentUser)
  }

  /**
   * Get current user
   */
  public getCurrentUser (): any | Object {
    this.currentUser = this.localStorageService.get('currentUser')
    return this.currentUser
  }

}
