import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { AddressApiService } from '@app/modules/address/address-api.service'

@Injectable()

export class AddressService {

  /**
   * To delete
   *
   * Id's marked for deleting.
   */
  public toDelete: Array<number> = []

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder,
    public myUtil: MyUtilityService,
    public api: AddressApiService
  ) {}

  /**
   * Get form
   */
  public getForm (address?: any | Object): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      addressNameUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      address1: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.required,
          Validators.maxLength(191)
        ]
      ],
      address2: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      address3: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      city: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      state: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      postcode: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      countryUuid: [
        222,
        [
          ValidationService.isUuid,
          Validators.required
        ]
      ],
      latitude: [
        null,
        [
          ValidationService.isNumeric,
        ]
      ],
      longitude: [
        null,
        [
          ValidationService.isNumeric
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (!this.myUtil.isEmpty(address)) {
      if (Object.keys(address).length !== 0) {
        form.patchValue(address, { emitEvent: false })
      }
    }

    return form
  }

}
