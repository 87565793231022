import { Injectable } from '@angular/core'
import { LocalStorageService } from 'angular-2-local-storage'
import { environment } from '@env/environment'

@Injectable()

export class ConfigService {

  public config: any

  /**
   * Constructor
   */
  public constructor (
    public localStorageService: LocalStorageService
  ) {

    this.config = {
      navBarTitle: 'L Drive',
      appName: 'angular-filemanager v1.5',
      defaultLang: 'en',
      multiLang: false, // true
      listUrl: environment.apiUrl + '/v1/file-manager/list',
      uploadUrl: environment.apiUrl + '/v1/file-manager/upload',
      renameUrl: environment.apiUrl + '/v1/file-manager/rename',
      copyUrl: 'bridges/php/handler.php',
      moveUrl: 'bridges/php/handler.php',
      removeUrl: 'bridges/php/handler.php',
      editUrl: 'bridges/php/handler.php',
      getContentUrl: 'bridges/php/handler.php',
      createFolderUrl: environment.apiUrl + '/v1/file-manager/create-folder',
      downloadFileUrl: 'bridges/php/handler.php',
      downloadMultipleUrl: 'bridges/php/handler.php',
      compressUrl: 'bridges/php/handler.php',
      extractUrl: 'bridges/php/handler.php',
      permissionsUrl: 'bridges/php/handler.php',
      basePath: '/',

      searchForm: true,
      sidebar: true,
      breadcrumb: true,
      allowedActions: {
        upload: true,
        rename: true,
        move: false,
        copy: false,
        edit: false,
        changePermissions: false,
        compress: false,
        compressChooseName: false,
        extract: false,
        download: true,
        downloadMultiple: false,
        preview: false,
        remove: true,
        createFolder: true,
        pickFiles: false,
        pickFolders: false,
        openLocalFile: false
      },

      multipleDownloadFileName: 'angular-filemanager.zip',
      filterFileExtensions: [],
      showExtensionIcons: true,
      showSizeForDirectories: false,
      useBinarySizePrefixes: false,
      downloadFilesByAjax: true,
      previewImagesInModal: true,
      enablePermissionsRecursive: true,
      compressAsync: false,
      extractAsync: false,
      pickCallback: null,

      // tslint:disable-next-line:max-line-length
      isEditableFilePattern: /\.(txt|diff?|patch|svg|asc|cnf|cfg|conf|html?|.html|cfm|cgi|aspx?|ini|pl|py|md|css|cs|js|jsp|log|htaccess|htpasswd|gitignore|gitattributes|env|json|atom|eml|rss|markdown|sql|xml|xslt?|sh|rb|as|bat|cmd|cob|for|ftn|frm|frx|inc|lisp|scm|coffee|php[3-6]?|java|c|cbl|go|h|scala|vb|tmpl|lock|go|yml|yaml|tsv|lst)$/i,
      isImageFilePattern: /\.(jpe?g|gif|bmp|png|svg|tiff?)$/i,
      isExtractableFilePattern: /\.(gz|tar|rar|g?zip)$/i,
      tplPath: 'src/templates',
      localVolume: 'file:///Users/louislinehan/ownCloud/Documents/Projects/Tube%20Tech/tube-tech-crm-api/storage/app/l_drive'
    }

  }
}
