import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { EmailAddressName } from '@app/modules/email-address-name/email-address-name.model'

export class EmailAddress extends Base {
  uuid: string = null
  emailAddressNameUuid: string = null
  emailAddressName: EmailAddressName = null
  email: string = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param {Partial<EmailAddress>} data Initial values
   */
  public constructor (data?: Partial<EmailAddress>) {
    super(data)
    this.init(data)
  }
}
