/**
 * JQuery
 *
 * @type {jQuery}
 */
declare var jQuery: any

/**
 * Correct height
 *
 * Fix the height of main wrapper.
 *
 * @returns {void}
 */
export function correctHeight (): any {
  const pageWrapper: any = jQuery('#page-wrapper')
  const navbarHeight: any = jQuery('nav.navbar-light').height()
  const wrapperHeight: any = pageWrapper.height()

  if (navbarHeight > wrapperHeight) {
    pageWrapper.css('min-height', navbarHeight + 'px')
  }

  if (navbarHeight <= wrapperHeight) {
    if (navbarHeight < jQuery(window).height()) {
      pageWrapper.css('min-height', jQuery(window).height() + 'px')
    } else {
      pageWrapper.css('min-height', navbarHeight + 'px')
    }
  }

  if (jQuery('body').hasClass('fixed-nav')) {
    if (navbarHeight > wrapperHeight) {
      pageWrapper.css('min-height', navbarHeight + 'px')
    } else {
      pageWrapper.css('min-height', jQuery(window).height() - 60 + 'px')
    }
  }
}

/**
 * Detect body
 *
 * Detect windows size.
 *
 * @returns {void}
 */
export function detectBody (): any {
  if (jQuery(document).width() < 769) {
    jQuery('body').addClass('body-small')
  } else {
    jQuery('body').removeClass('body-small')
  }
}

/**
 * Smoothly menu
 *
 * Add smooth fade in/out on navigation show/hide.
 *
 * @returns {void}
 */
export function smoothlyMenu (): any {
  if (!jQuery('body').hasClass('mini-navbar') || jQuery('body').hasClass('body-small')) {
    // Hide menu in order to smoothly turn on when maximize menu
    jQuery('#side-menu').hide()
    // For smoothly turn on menu
    setTimeout(() => {
      jQuery('#side-menu').fadeIn(400)
    }, 200)
  } else if (jQuery('body').hasClass('fixed-sidebar')) {
    jQuery('#side-menu').hide()
    setTimeout(() => {
      jQuery('#side-menu').fadeIn(400)
    }, 100)
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    jQuery('#side-menu').removeAttr('style')
  }
}
