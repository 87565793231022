import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ValidationService } from '@app/shared/services/validation.service'
import { Company } from '@app/modules/company/company.model'
import { CompanyService } from '@app/modules/company/company.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'

@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html'
})

export class SiteFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  // ---------------------------------------------------------------------------
  // Modal stuff
  // ---------------------------------------------------------------------------

  /**
   * Company form for modal
   */
  public companyForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  // ---------------------------------------------------------------------------
  // End Modal stuff
  // ---------------------------------------------------------------------------

  /**
   * Constructor
   */
  public constructor (
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private companyFormService: CompanyFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.companyService.lastSearchTerm = null
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Child changed
   *
   * This tells us something changed so reload.
   */
  public childChanged (event: Event): void {
    console.log('childChanged', event)
    if (event['name'] === 'companyChanged') {

      const control: FormControl = this.mainForm.get('company') as FormControl
      control.reset()

      const company: Company = new Company(event['data'])
      this.mainForm.patchValue({
        company: company
      })
    }

  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    if (data) {}
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newCompany') {
      const company: Company = new Company()
      if (this.companyService.lastSearchTerm) {
        company.name = this.companyService.lastSearchTerm
      }
      this.companyForm = this.companyFormService.getForm(company, 'new')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save new company
   */
  public saveCompany (): void {
    console.log('saveCompany', this.companyForm.value)
    this.validationService.runValidation(this.companyForm)
    if (!this.companyForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.companyForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.companyService.saveCompany(this.companyForm.value)
    .then((result) => {

      this.companyService.api.getOne(result.uuid, {
        includes: [
          'addresses',
          'addresses.country',
          'addresses.addressName'
        ]
      })
      .toPromise()
      .then((newResult) => {
        const control: FormControl = this.mainForm.get('company') as FormControl
        control.reset()

        const company: Company = new Company(newResult)
        this.mainForm.patchValue({
          companyUuid: company.uuid,
          company: company
        })
        this.companyService.lastSearchTerm = null
        this.currentModal.close()
        this.alertService.success('Saved: ' + this.companyForm.value.name, 10000)
        this.mainForm.markAsPristine()

      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
      })

    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

}
