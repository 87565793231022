import { Pipe, PipeTransform } from '@angular/core'
import * as libphonenumber from 'google-libphonenumber'

@Pipe({
  name: 'phone'
})
/**
 * Phone pipe
 *
 * Uses https://www.npmjs.com/package/google-libphonenumber
 */
export class PhonePipe implements PipeTransform {

  /**
   * Transform to formatted phone number
   *
   * @param telephone   Telephone number
   * @param countryIso2 ISO2 Country code
   * @param type        international, e164, national (default)
   *
   * @returns {string} Formatted telephone number
   */
  transform (value: string, countryIso2: string, type?: any): string {

    const matches: any = value.match(/\d+/g)

    if (!value || !countryIso2 || matches === null) {
      return value
    }

    // Default type to 'national' if not set.
    if (!type) {
      type = 'national'
    }

    // Get an instance of PhoneNumberFormat.
    const pnf: any = libphonenumber.PhoneNumberFormat

    let format: any
    let parsed: any

    // Set format depending on type.
    switch (type.toLowerCase(type)) {
      case 'international':
      case 'i':
        format = pnf.INTERNATIONAL
        break
      case 'e164':
        format = pnf.E164
        break
      case 'national':
      case 'n':
      default:
        format = pnf.NATIONAL
        break
    }

    let formatted: any

    // Get an instance of PhoneNumberUtil.
    const phoneUtil: libphonenumber = libphonenumber.PhoneNumberUtil.getInstance()

    try {
      // Parse the number to an object.
      parsed = phoneUtil.parse(value, countryIso2)

      // Format the number.
      formatted = phoneUtil.format(parsed, format)
    } catch (e) {
      if (e) {}
    }

    return formatted ? formatted : value
  }

}
