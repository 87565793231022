import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import {
  Company,
  CompanyService,
  CompanyFormService
} from '@app/modules/company'

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html'
})

export class PersonFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  // ---------------------------------------------------------------------------
  // Modal stuff
  // ---------------------------------------------------------------------------

  /**
   * Company form for modal
   */
  public companyForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  // ---------------------------------------------------------------------------
  // End Modal stuff
  // ---------------------------------------------------------------------------

  /**
   * Constructor
   */
  public constructor (
    public alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private companyFormService: CompanyFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.companyService.lastSearchTerm = null
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()
    if (type === 'newCompany') {
      const company: Company = new Company()
      if (this.companyService.lastSearchTerm) {
        company.name = this.companyService.lastSearchTerm
      }
      this.companyForm = this.companyFormService.getForm(company, 'new')
    }

    if (type === 'editCompany') {
      const company: Company = new Company(data)
      this.companyForm = this.companyFormService.getForm(company, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save new company
   */
  public saveCompany (): void {
    console.log('saveCompany', this.companyForm.value)
    this.validationService.runValidation(this.companyForm)

    if (!this.companyForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.companyForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.companyService.saveCompany(this.companyForm.value)
    .then((result) => {

      const control: FormArray = this.mainForm.get('companies') as FormArray

      // const company: Company = new Company(this.selectedForm.get('selectedItem').value)
      const companyForm: FormGroup = this.companyFormService.getForm(new Company(result), '')
      control.push(companyForm)

      // this.getCompany(result.uuid)
      // .then((newResult: Company) => {
      //   this.company = new Company(newResult)
      //   this.mainForm.patchValue(newResult, { emitEvent: true })
      //   this.alertService.success('Saved: ' + this.mainForm.value.name, 10000)
      // })
      // .catch((err) => {
      //   console.error(err)
      // })

      this.companyService.lastSearchTerm = null
      this.currentModal.close()
      this.alertService.success('Saved: ' + this.companyForm.value.name, 10000)
      this.mainForm.markAsPristine()

    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

}
