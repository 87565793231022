import { Component, OnInit, HostListener } from '@angular/core'
import { detectBody } from '@app/app.helpers'
import { AppService } from '@app/shared/services/app.service'

// declare var $: any

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.css'],
  providers: []
})

export class BasicLayoutComponent implements OnInit {

  /**
   * Constructor
   */
  public constructor (
    public appService: AppService
  ) {}

  /**
   * On init
   */
  public ngOnInit (): void {
    detectBody()
  }

  /**
   * HostListener resize
   *
   * Must come after @HostListener
   *
   * @param {object} event Event
   */
  @HostListener('window:resize', ['$event']) onResize (event: Event): void {
    if (event) {}
    // console.log('onResize', event)
    detectBody()
  }

}
