import { Injectable } from '@angular/core'
import { LocalStorageService } from 'angular-2-local-storage'
import { ApiMiddlewareService } from './api-middleware.service'
import { FmItem } from '../models/fm-item.model'
import { ConfigService } from './config.service'

@Injectable()

export class FileNavigatorService {

  public config: any

  /**
   * Requesting
   */
  public requesting: boolean

  /**
   * Debug
   */
  public fileList: Array<FmItem> = []

  /**
   * Current path
   */
  public currentPath: Array<string> = []

  /**
   * History
   */
  public history: Array<any> = []

  /**
   * Error
   */
  public error: string

  /**
   * Constructor
   */
  public constructor (
    public localStorageService: LocalStorageService,
    private apiMiddlewareService: ApiMiddlewareService,
    private configService: ConfigService
  ) {}

  /**
   * Init
   */
  public init (): void {
    this.fileList = []
    this.currentPath = []
    this.history = []
    this.config = this.configService.config
    this.currentPath = this.getBasePath()
  }

  /**
   * Get base path
   */
  public getBasePath (): Array<string> {
    let path: string
    path = ''
    if (this.config.basePath) {
      path = this.config.basePath.toString()
    }
    path.replace(/^\//, '')
    return path.trim() ? path.split('/') : []
  }

  /**
   * On refresh
   */
  public onRefresh (): void {
  }

  /**
   * Deferred handler
   *
   * @todo deprecated ?
   */
  public deferredHandler (data: any, deferred: any, code: any, defaultMsg: any): Promise<any> {
    if (!data || typeof data !== 'object') {
      this.error = 'Error %s - Bridge response error, please check the API docs or this ajax response.'.replace('%s', code)
    }
    if (code === 404) {
      this.error = 'Error 404 - Backend bridge is not working, please check the ajax response.'
    }
    if (code === 200) {
      this.error = null
    }
    if (!this.error && data.result && data.result.error) {
      this.error = data.result.error
    }
    if (!this.error && data.error) {
      this.error = data.error.message
    }
    if (!this.error && defaultMsg) {
      this.error = defaultMsg
    }
    if (this.error) {
      return deferred.reject(data)
    }
    return deferred.resolve(data)
  }

  /**
   * List
   */
  public list (): Promise<any> {
    // return this.apiMiddlewareService.list(this.currentPath, this.deferredHandler.bind(this))
    return this.apiMiddlewareService.list(this.currentPath)
  }

  /**
   * Refresh
   */
  public refresh (): void {
    if (!this.currentPath.length) {
      this.currentPath = this.getBasePath()
    }
    const path: string = this.currentPath.join('/')
    this.requesting = true
    this.fileList = []
    this.list()
    .then((data) => {
      data.result.map((file) => {
        file.path = this.currentPath
        // return new FmItem(file)
        this.fileList.push(new FmItem(file))
      })
      this.buildTree(path)
      this.onRefresh()
      this.requesting = false
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Build tree
   */
  public buildTree (path: string): void {
    const flatNodes: Array<any> = [] // (Should this be a history type?)
    let selectedNode: any = {}

    // Populate empty history ?
    if (this.history.length === 0) {
      this.history.push({
        name: this.getBasePath()[0] || '',
        nodes: []
      })
    }

    this.flatten(this.history[0], flatNodes)

    selectedNode = this.findNode(flatNodes, path)

    if (selectedNode) {
      selectedNode.nodes = []
    }

    for (const o of Object.keys(this.fileList)) {
      const item: FmItem = this.fileList[o]
      if (item.isFolder()) {
        this.recursive(this.history[0], item, path)
      }
    }
  }

  /**
   * Recursive
   */
  public recursive (parent: any, item: FmItem, path: any): void {
    const absName: string = path ? (path + '/' + item.name) : item.name

    if (parent.name && parent.name.trim() && path.trim().indexOf(parent.name) !== 0) {
      parent.nodes = []
    }
    if (parent.name !== path) {
      parent.nodes.forEach((nd) => {
        this.recursive(nd, item, path)
      })
    } else {
      for (const e in parent.nodes) {
        if (parent.nodes[e].name === absName) {
          return
        }
      }
      parent.nodes.push({ item: item, name: absName, nodes: [] })
    }

    parent.nodes = parent.nodes.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() === b.name.toLowerCase() ? 0 : 1
    })
  }

  /**
   * Flatten
   */
  public flatten (node: any, array: Array<any>): void {
    array.push(node)
    for (const n of Object.keys(node.nodes)) {
      this.flatten(node.nodes[n], array)
    }
  }

  /**
   * Find node
   */
  public findNode (data: any, path: any): any {
    return data.filter((n) => {
      return n.name === path
    })[0]
  }

  /**
   * Folder click
   */
  public folderClick (item: FmItem): void {
    this.currentPath = []
    if (item && item.isFolder()) {
      this.currentPath = item.fullPath().split('/').splice(1)
      console.log('folderClick.currentPath', this.currentPath)
    }
    this.refresh()
  }

  /**
   * Up dir
   */
  public upDir (): void {
    if (this.currentPath[0]) {
      this.currentPath = this.currentPath.slice(0, -1)
      this.refresh()
    }
  }

  /**
   * Go to
   */
  public goTo (index: number): void {
    this.currentPath = this.currentPath.slice(0, index + 1)
    this.refresh()
  }

  /**
   * File name exists
   */
  public fileNameExists (fileName: string): boolean {
    const index: number = this.fileList.findIndex((x) => x.name.trim() === fileName.trim())
    return index !== -1
  }

  /**
   * List has folders
   */
  public listHasFolders (): boolean {
    const index: number = this.fileList.findIndex((x) => x.type === 'dir')
    return index !== -1
  }

  /**
   * Get current folder name
   */
  public getCurrentFolderName (): string {
    return this.currentPath.slice(-1)[0] || '/'
  }

}
