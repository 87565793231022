import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { EmailMessage } from '@app/modules/email-message/models/email-message.model'

@Injectable()

export class EmailMessageFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService
    // private telephoneNameFormService: TelephoneNameFormService
  ) {}

  /**
   * Get form
   */
  public getForm (emailMessage: EmailMessage, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      msid: [
        null,
        []
      ],
      changeKey: [
        null,
        []
      ],
      recivedDateTime: [
        null,
        []
      ],
      sentDateTime: [
        null,
        []
      ],
      hasAttachments: [
        null,
        []
      ],
      internetMessageId: [
        null,
        []
      ],
      internetMessageHeaders: [
        null,
        []
      ],
      subject: [
        null,
        []
      ],
      body: [
        null,
        []
      ],
      importance: [
        null,
        []
      ],
      parentFolderId: [
        null,
        []
      ],
      sender: [
        null,
        []
      ],
      from: [
        null,
        []
      ],
      toRecipients: [
        null,
        []
      ],
      ccRecipients: [
        null,
        []
      ],
      bccRecipients: [
        null,
        []
      ],
      replyTo: [
        null,
        []
      ],
      conversationId: [
        null,
        []
      ],
      isDeliveryRecieptRequested: [
        null,
        []
      ],
      isReadRecieptRequested: [
        null,
        []
      ],
      isRead: [
        null,
        []
      ],
      isDraft: [
        null,
        []
      ],
      webLink: [
        null,
        []
      ],
      inferanceClassification: [
        null,
        []
      ],
      flag: [
        null,
        []
      ],
      attachments: [
        null,
        []
      ],
      toPersonUuid: [
        null,
        []
      ],
      fromPersonUuid: [
        null,
        []
      ],
      opportunityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      isAllocated: [
        null,
        []
      ],
      isIgnored: [
        null,
        []
      ],
      isSpam: [
        null,
        []
      ],
      isSent: [
        null,
        []
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    // if (telephone.telephoneName) {
    //   form.addControl('telephoneName', this.telephoneNameFormService.getForm(telephone.telephoneName, ''))
    // }

    if (emailMessage) {
      form.patchValue(emailMessage, { emitEvent: false })
    }

    return form
  }

}
