import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'

import { SavedQuery, SavedQueryService } from '@app/modules/saved-query'

@Component({
  selector: 'app-contact-group-form',
  templateUrl: './contact-group-form.component.html'
})

export class ContactGroupFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  public savedQueryOptions: Array<SavedQuery>
  /**
   * Constructor
   */
  public constructor (
    public alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private savedQueryService: SavedQueryService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.getSavedQueryOptions()
    .then(() => {
      this.display = true
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get address name options
   */
  public getSavedQueryOptions (): Promise<any> {
    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'name',
          direction: 'ASC'
        }
      ]
    }

    return this.savedQueryService.api.getMany(params)
    .toPromise()
    .then((results) => {
      this.savedQueryOptions = results
      return results
    })
    .catch((err) => {
      throw err
    })
  }

  public changeQuery (event: Event): void {
    if (event) {}
    const query: SavedQuery = this.savedQueryOptions.find(x => x.uuid === this.mainForm.value.savedQueryUuid)
    let converted: string
    converted = ''
    if (query.sqlQuery && typeof query.sqlQuery === 'string') {
      converted = query.sqlQuery.replace('select `person`.`uuid` as `person.uuid` from', 'select `person`.`uuid` from')
    }

    this.mainForm.patchValue({
      description: query.name,
      savedQueryUuid: null,
      customQuery: converted
    })
  }

  /**
   * Save new company
   */
  // public saveCompany (): void {
  //   console.log('saveCompany', this.companyForm.value)
  //   this.validationService.runValidation(this.companyForm)

  //   if (!this.companyForm.valid) {
  //     const formErrors: Array<any> = this.validationService.extractErrors(this.companyForm)
  //     console.log('Validation errors', formErrors)
  //     this.alertService.error('Validation errors: Please check the form.', 10000)
  //     return
  //   }

  //   this.companyService.saveCompany(this.companyForm.value)
  //   .then((result) => {

  //     const control: FormArray = this.mainForm.get('companies') as FormArray

  //     // const company: Company = new Company(this.selectedForm.get('selectedItem').value)
  //     const companyForm: FormGroup = this.companyFormService.getForm(new Company(result), '')
  //     control.push(companyForm)

  //     // this.getCompany(result.uuid)
  //     // .then((newResult: Company) => {
  //     //   this.company = new Company(newResult)
  //     //   this.mainForm.patchValue(newResult, { emitEvent: true })
  //     //   this.alertService.success('Saved: ' + this.mainForm.value.name, 10000)
  //     // })
  //     // .catch((err) => {
  //     //   console.error(err)
  //     // })

  //     this.currentModal.close()
  //     this.alertService.success('Saved: ' + this.companyForm.value.name, 10000)

  //   })
  //   .catch((err) => {
  //     this.alertService.error('Error: ' + err)
  //   })
  // }

}
