import { Injectable } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { toInteger, padNumber } from '@app/shared/ngb-util'
import * as moment from 'moment'

@Injectable()

export class MyUtilityService {

  /**
   * Constructor
   */
  public constructor () {}

  /**
   * Is empty
   *
   * @todo typscript for loop
   *
   * @param {any} item Mixed variable type
   *
   * @returns {boolean}
   */
  public isEmpty (item: any): boolean {
    const emptyArr: Array<any> = [
      'undefined',
      undefined,
      null,
      false,
      0,
      '',
      '0'
    ]

    // for (let i = 0; i < emptyArr.length; i++) {
    let i: number
    i = 0
    while (i < emptyArr.length) {
      if (item === emptyArr[i]) {
        return true
      }
      i++
    }

    if (typeof item === 'object') {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          return false
        }
      }
      return true
    }

    return false
  }

  /**
   * Patch values
   *
   * @param from Copy from object
   * @param to   Copy to object
   *
   * @return {Object}
   */
  public patchValues (from: any, to: any): any {
    for (const key in from) {
      if (from.hasOwnProperty(key)) {
        to[key] = from[key]
      }
    }
    return to
  }

  /**
   * To date object
   *
   * Convert dates to NgbDateStruct.
   *
   * @param dateStr date
   *
   * @returns {any}
   */
  public toDateObject (dateStr: string): any | Object {
    let dateObj: any

    dateObj = {
      day: null,
      month: null,
      year: null
    }

    if (!this.isEmpty(dateObj)) {
      const dt: any = moment(dateStr).local()
      if (dt.isValid()) {
        dateObj = {
          day: toInteger(dt.format('D')),
          month: toInteger(dt.format('M')),
          year: toInteger(dt.format('YYYY'))
        }
      }
    }

    return dateObj
  }

  /**
   * To my sql date
   *
   * @param {any} dateObj NgbDateStruct
   *
   * @returns {string}
   */
  public toMySqlDate (dateObj: any): string {
    if (this.isEmpty(dateObj)) {
      return null
    }
    const dt: string = dateObj.year + '-' + padNumber(dateObj.month) + '-' + padNumber(dateObj.day) + ' 00:00:00'
    return dt
  }

  /**
   * Find invalid controls
   *
   * @param {FormGroup} formGroup Form group
   */
  public findInvalidControls (formGroup: FormGroup): boolean {
    const invalid: Array<any> = []
    const controls: any = formGroup.controls
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name)
      }
    }
    return invalid.length > 0
  }

}
