import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env/environment'
import { LocalStorageService } from 'angular-2-local-storage'

declare var $: any

@Injectable()

export class HttpService {

  /**
   * Api url
   */
  public apiUrl: string

  /**
   * Constructor
   */
  public constructor (
    public httpClient: HttpClient,
    public localStorageService: LocalStorageService,
    public router: Router
  ) {
    this.apiUrl = environment.apiUrl
  }

  /**
   * Get api key
   */
  public getApiKey (): any {
    return this.localStorageService.get('apiKey')
  }

  /**
   * Get headers
   *
   * The Authorization header needs to be set for various API's eg.
   * .set('Authorization', this.getApiKey())
   * .set('Authorization', 'Bearer ' + this.getApiKey())
   */
  public getHeaders (): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders()
    .append('Authorization', 'Bearer ' + this.getApiKey())
    .append('Content-Type', 'application/json')
    //.append('Accept', '*/*')
    .append('Accept', 'application/json, text/html, text/plain, */*')
    // .append('Accept', 'text/html')

    return headers
  }

  /**
   * Create
   *
   * Sends POST data to api server.
   */
  public postDownload (url: string, data: Object): Observable<any> {
    const options: any | Object = {
      headers: this.getHeaders(),
      responseType: 'blob'
    }

    return this.httpClient.post(this.apiUrl + '/' + url, data, options)
  }

  /**
   * Create
   *
   * Sends POST data to api server.
   */
  public insert (url: string, data: Object): Observable<any> {
    const options: any | Object = {
      headers: this.getHeaders()
    }

    return this.httpClient.post(this.apiUrl + '/' + url, data, options)
  }

  /**
   * Get
   *
   * Sends GET request to api server.
   *
   * Using jQuery for params as Angular 5's handling is bad. (HttpParams)
   * https://github.com/angular/angular/issues/19071.
   */
  public get (url: string, filter?: any): Observable<any> {
    let params: String = ''

    if (filter) {
      params = '?' + $.param(filter)
    }

    const options: any | Object = {
      headers: this.getHeaders()
    }

    return this.httpClient.get(this.apiUrl + '/' + url + params, options)
  }

  /**
   * Update
   *
   * Sends PUT request to api server.
   */
  public update (url: string, data: Object): Observable<any> {
    const options: any | Object = {
      headers: this.getHeaders()
    }

    return this.httpClient.put(this.apiUrl + '/' + url, data, options)
  }

  /**
   * Delete (Soft)
   *
   * Sends DELETE request to api server.
   */
  public delete (url: string): Observable<any> {
    const options: any | Object = {
      headers: this.getHeaders()
    }

    return this.httpClient.delete(this.apiUrl + '/' + url + '', options)
  }

  /**
   * Delete (Hard)
   *
   * Sends DELETE request to api server.
   */
  public destroy (url: string): Observable<any> {
    const params: string = '?' + $.param({
      destroy: true
    })

    const options: any | Object = {
      headers: this.getHeaders()
    }

    return this.httpClient.delete(this.apiUrl + '/' + url + params, options)
  }

  /**
   * Upsert
   *
   * Checks if an 'uuid' exists then calls either update or create.
   */
  public upsert (url: string, data: any): Observable<any> {
    if (typeof data.uuid !== 'undefined' && data.uuid !== '' && data.uuid !== null) {
      return this.update(url + '/' + data.uuid, data)
    } else {
      return this.insert(url, data)
    }
  }

  /**
   * Post
   *
   * Sends POST data to api server.
   */
  public post (url: string, data: any): Observable<any> {
    return this.insert(url, data)
  }

  /**
   * Login
   *
   * Sends POST data to api server.
   */
  public login (url: string, data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json, text/plain, */*')

    const options: any | Object = {
      headers: headers
    }

    return this.httpClient.post(this.apiUrl + '/' + url, data, options)
  }
}
