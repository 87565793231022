import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'fileExtension' })

/**
 * https://github.com/Nightapes/HumanizeDuration.ts
 */
export class FileExtensionPipe implements PipeTransform {

  /**
   * Transform milliseconds to human readable format
   *
   * @param value Value
   *
   * @returns {string} Formatted telephone number
   */
  public transform (value: string): string {
    const arr: Array<string> = value.split('.')
    const ext: string = arr[arr.length - 1]
    return ext.length === 3 ? ext : ''
  }

}

// app.filter('fileExtension', ['$filter', function($filter) {
//     return function(input) {
//         return /\./.test(input) && $filter('strLimit')(input.split('.').pop(), 3, '..') || '';
//     };
// }]);
