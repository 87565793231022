import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { Observable } from 'rxjs'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

declare var $: any

@Injectable()

export class ApiService {

  /**
   * End point
   */
  public endPoint: string

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    this.init()
  }

  /**
   * Parse params
   */
  private parseParams (params?: Object): string {
    let append: string
    append = ''

    if (!this.myUtil.isEmpty(params)) {
      if (params) {
        append = '?' + $.param(params)
      }
    }

    return append
  }

  /**
   * Init
   */
  public init (): void {
  }

  /**
   * Get many revisions
   */
  public getRevisions (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'revisions', params)
  }

  /**
   * Get many activities
   */
  public getActivityLog (parentUuid: string, params?: Object): Observable<any> {
    return this.getManyChildren(parentUuid, 'activity-log', params)
  }

  // ---------------------------------------------------------------------------
  // Raw
  // ---------------------------------------------------------------------------

  /**
   * Post raw
   *
   */
  public postDownload (endPoint: string, data: Object): Observable<any> {
    return this.httpService.postDownload(endPoint, data)
  }

  /**
   * Post raw
   *
   */
  public postRaw (endPoint: string, data: Object): Observable<any> {
    return this.httpService.post(endPoint, data)
  }

  /**
   * Get raw
   *
   */
  public getRaw (endPoint: string, data: Object): Observable<any> {
    return this.httpService.get(endPoint, data)
  }

  /**
   * Put raw
   *
   */
  public putRaw (endPoint: string, data: Object): Observable<any> {
    return this.httpService.get(endPoint, data)
  }

  /**
   * Delete raw
   *
   */
  public deleteRaw (endPoint: string): Observable<any> {
    return this.httpService.delete(endPoint)
  }

  // ---------------------------------------------------------------------------
  // Parents
  // ---------------------------------------------------------------------------

  /**
   * Get one
   */
  public getOne (uuid: string, params?: Object): Observable<any> {
    return this.httpService.get(this.endPoint + '/' + uuid + this.parseParams(params))
  }

  /**
   * Create
   */
  public insert (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint, data)
  }

  /**
   * Get many
   */
  public getMany (params?: Object): Observable<any> {
    return this.httpService.get(this.endPoint + this.parseParams(params))
  }

  /**
   * Count
   */
  public count (params?: Object): Observable<any> {
    return this.httpService.get(this.endPoint + '/count' + this.parseParams(params))
  }

  /**
   * Update
   *
   */
  public update (uuid: string, data: Object): Observable<any> {
    return this.httpService.update(this.endPoint + '/' + uuid, data)
  }

  /**
   * Update All
   *
   */
  public updateAll (data: Object, params?: Object): Observable<any> {
    return this.httpService.update(this.endPoint + this.parseParams(params), data)
  }

  /**
   * Delete
   *
   */
  public delete (uuid: string): Observable<any> {
    return this.httpService.delete(this.endPoint + '/' + uuid)
  }

  /**
   * Destroy
   *
   */
  public destroy (uuid: string): Observable<any> {
    return this.httpService.destroy(this.endPoint + '/' + uuid)
  }

  /**
   * Save
   */
  public save (data: Object): Observable<any> {
    return this.httpService.upsert(this.endPoint, data)
  }

  // ---------------------------------------------------------------------------
  // Children
  // ---------------------------------------------------------------------------

  /**
   * Get one child
   *
   * @param parentUuid Primary key
   * @param child    Child url part
   * @param childUuid  Child primary key
   * @param params   Params
   *
   */
  public getOneChild (parentUuid: string, child: string, childUuid: string, params?: Object): Observable<any> {
    return this.httpService.get(this.endPoint + '/' + parentUuid + '/' + child + '/' + childUuid + this.parseParams(params))
  }

  /**
   * Create child
   *
   * @param parentUuid Primary key
   * @param child    Child url part
   * @param data     Data
   *
   */
  public insertChild (parentUuid: string, child: string, data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint + '/' + parentUuid + '/' + child, data)
  }

  /**
   * Get many childen
   *
   * @param parentUuid Primary key
   * @param child    Child url part
   * @param params   Params
   *
   */
  public getManyChildren (parentUuid: string, child: string, params?: Object): Observable<any> {
    return this.httpService.get(this.endPoint + '/' + parentUuid + '/' + child + this.parseParams(params))
  }

  /**
   * Update child
   *
   * @param child    Child url part
   * @param parentUuid Parent primary key
   * @param childUuid  Child primary key
   * @param data     Child data
   *
   */
  public updateChild (parentUuid: string, child: string, childUuid: string, data: Object): Observable<any> {
    return this.httpService.update(this.endPoint + '/' + parentUuid + '/' + child + '/' + childUuid, data)
  }

  /**
   * Delete child
   *
   * @param parentUuid Parent primary key
   * @param child    Child url part
   * @param childUuid  Child primary key
   *
   */
  public deleteChild (parentUuid: string, child: string, childUuid: string): Observable<any> {
    return this.httpService.delete(this.endPoint + '/' + parentUuid + '/' + child + '/' + childUuid)
  }

  /**
   * Destroy child
   *
   * @param parentUuid Parent primary key
   * @param child     Child url part
   * @param childUuid   Child primary key
   *
   */
  public destroyChild (parentUuid: string, child: string, childUuid: string): Observable<any> {
    return this.httpService.destroy(this.endPoint + '/' + parentUuid + '/' + child + '/' + childUuid)
  }

  /**
   * Save child
   *
   * @param parentUuid Parent primary key
   * @param child    Child url part
   * @param data     Child data
   *
   */
  public saveChild (parentUuid: string, child: string, data: Object): Observable<any> {
    return this.httpService.upsert(this.endPoint + '/' + parentUuid + '/' + child, data)
  }

  /**
   * Attach child
   *
   * @param parentUuid Primary key
   * @param child    Child url part
   * @param data     Data
   *
   */
  public attachChild (parentUuid: string, child: string, childUuid: string, data?: Object): Observable<any> {
    if (!data) {
      data = {}
    }
    return this.httpService.insert(this.endPoint + '/' + parentUuid + '/' + child + '/' + childUuid + '/attach', data)
  }

  /**
   * Detach child
   *
   * @param parentUuid Parent primary key
   * @param child    Child url part
   * @param childUuid  Child primary key
   *
   */
  public detachChild (parentUuid: string, child: string, childUuid: string): Observable<any> {
    return this.httpService.delete(this.endPoint + '/' + parentUuid + '/' + child + '/' + childUuid + '/detach')
  }

  /**
   * Sync children
   *
   * @param parentUuid   Parent primary key
   * @param child        Child url part
   * @param childUuids   Child primary key
   * @param detachOthers Detach others not in childUuids array, defaults to true
   */
  public syncChildren (parentUuid: string, child: string, childUuids: Array<string>, detachOthers?: boolean): Observable<any> {
    const data: any | Object = {
      uuids: childUuids,
      detachOthers: (detachOthers === false) ? false : true
    }
    return this.httpService.update(this.endPoint + '/' + parentUuid + '/' + child + '/sync', data)
  }
}
