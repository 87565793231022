import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormArray, FormGroup } from '@angular/forms'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { Social } from '@app/modules/social/social.model'
import { SocialFormService } from '@app/modules/social/social-form.service'
import { DeleteIdFormService } from '@app/modules/delete-id/delete-id-form.service'
import { ValidationService } from '@app/shared/services/validation.service'

@Component({
  selector: 'app-socials-form-array',
  templateUrl: './socials-form-array.component.html'
})

export class SocialsFormArrayComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Heading text
   */
  @Input() headingText: string

  /**
   * Button text
   */
  @Input() buttonText: string

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Constructor
   */
  public constructor (
    public selectOptionsService: SelectOptionsService,
    private socialFormService: SocialFormService,
    private deleteIdFormService: DeleteIdFormService,
    public validationService: ValidationService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Add
   */
  public add (): void {
    const control: FormArray = this.mainForm.get('socials') as FormArray
    const social: Social = new Social()
    const form: FormGroup = this.socialFormService.getForm(social, 'new')
    control.push(form)
    this.mainForm.markAsDirty()
  }

  /**
   * Remove
   *
   * @param {FormGroup} item Social
   */
  public remove (item: FormGroup): void {
    const control: FormArray = this.mainForm.get('socials') as FormArray
    const index: number = control.controls.findIndex(x => x === item)
    const social: Social = new Social(item.value)

    if (social.uuid) {
      // Mark it for delete.
      const deleted: FormArray = this.mainForm.get('socialsDeleted') as FormArray
      deleted.push(this.deleteIdFormService.getForm({ uuid: social.uuid }))
    }

    control.removeAt(index)
    this.mainForm.markAsDirty()
  }
}
