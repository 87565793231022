import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap'
import { SelectOptionsService } from '@app/shared/services/select-options.service'

@Component({
  selector: 'app-my-date-searcher',
  templateUrl: './my-date-searcher.component.html'
})

export class MyDateSearcherComponent implements OnInit {

  /**
   * Display component
   */
  public display: boolean

  /**
   * Form group
   */
  @Input() parentFormGroup: FormGroup

  /**
   * Form group change
   */
  @Output() parentFormGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Form control name
   */
  @Input() fieldFormControlName: string

  /**
   * Operator form control name
   */
  @Input() operatorFormControlName: string

  // ---------------------------------------------------------------------------
  // Settings
  // ---------------------------------------------------------------------------

  /**
   * Element id
   */
  @Input() id: string

  /**
   * Element id
   */
  @Input() placeholder: string

  /**
   * Size
   */
  @Input() size: string

  /**
   * Constructor
   */
  public constructor (
    public config: NgbDatepickerConfig,
    public selectOptionsService: SelectOptionsService
  ) {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    this.display = false
    this.display = true
  }

}
