import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
import * as moment from 'moment'
import { AppService } from '@app/shared/services/app.service'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Task } from '@app/modules/task/task.model'
import { TaskService } from '@app/modules/task/task.service'
import { TaskFormService } from '@app/modules/task/task-form.service'
import { LocalStorageService } from 'angular-2-local-storage'
import { TaskType } from '@app/modules/task-type'

@Component({
  selector: 'app-view-tasks',
  templateUrl: './view-tasks.component.html',
  providers: []
})

export class ViewTasksComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Opportunities
   */
  public tasks: Array<Task> = []

  /**
   * Search form
   */
  public taskForm: FormGroup

  /**
   * Search form
   */
  public searchForm: FormGroup

  /**
   * Page options
   */
  public pageOptions: Array<any>

  /**
   * Company options
   */
  public companyOptions: Array<any>

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    private renderer: Renderer,
    private appService: AppService,
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    public mainService: TaskService,
    private taskFormService: TaskFormService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = true
    this.searchForm = this.mainService.getSearchForm()
    const lastSearch: any = this.localStorageService.get('tasks.search')

    console.log(lastSearch)
    if (lastSearch) {

      if (lastSearch.startDatetime) {
        lastSearch.startDatetime = new Date(lastSearch.startDatetime)
      }
      this.searchForm.patchValue(lastSearch, { emitEvent: false })
    }
    this.searchSub()
    this.searchItems()
    // this.getCompanyOptions()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe((data) => {
      console.log('searchSub', data)
      if (!this.searchForm.valid) {
        const formErrors: Array<any> = this.validationService.extractErrors(this.searchForm)
        console.log('Validation errors', formErrors)
        // this.alertService.error('Validation errors: Please check the form.', 10000)
        return
      }

      this.mainService.pagination.currentPage = 1
      this.searchItems()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search opportunities
   */
  public searchItems (): Promise<any> {
    const searchData: any = this.searchForm.value
    this.localStorageService.set('tasks.search', this.searchForm.value)
    return this.mainService.search(searchData)
    .then((results) => {
      this.mainService.pagination.currentPageSize = results.length
      this.getPagesOptions()
      this.tasks = results
      this.display = true
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get pages options
   */
  public getPagesOptions (): void {
    const pageOptions: Array<any> = []
    const totalItems: number = this.mainService.pagination.totalItems
    const pageSize: number = this.mainService.pagination.pageSize
    const pageCount: number = Math.ceil(totalItems / pageSize)
    let i: number
    for (i = 1; i <= pageCount; i++) {
      pageOptions.push({
        key: i,
        val: i
      })
    }

    this.pageOptions = pageOptions
  }

  /**
   * Go to page
   */
  public goToPage (event: Event): void {
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.searchItems()
  }
  /**
   * Change page size
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.mainService.pagination.currentPage = 1
    this.searchItems()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.localStorageService.remove('tasks.search')
    this.mainService.sort = {
      column: 'start_datetime',
      direction: 'DESC'
    }

    this.searchForm.reset()
    this.searchForm.patchValue({
      showCompleted: false,
      isTimeless: false,
      isDeleted: false,
      startDatetimeOperator: 'eq'
    }, { emitEvent: false })
    this.searchItems()
  }

  /**
   * Set sort companies
   */
  public setSort (column: string): void {
    this.mainService.setSort(column)
    this.searchItems()
  }

  /**
   * On click in date picker
   */
  public onClickInDatePicker (event: Event): void {
    console.log('onClickInDatePicker')
    event.preventDefault()
    event.stopPropagation()
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newTask') {
      const task: Task = new Task()
      const taskType: TaskType = this.selectOptionsService.taskTypeOptions.find(x => x.name === 'Call')

      if (taskType) {
        task.taskTypeUuid = taskType.uuid
      }
      const startDatetime: Date = moment().toDate()
      // moment(startDatetime).add(60, 'minutes').toDate()
      const endDatetime: Date = startDatetime
      task.startDatetime = startDatetime
      task.endDatetime = endDatetime
      task.organiserUserUuid = this.appService.currentUser.uuid
      // task.isTimeless = false
      // task.isPrivate = false
      const taskPriority: any = this.selectOptionsService.taskPriorityOptions.find(x => x.name === 'Medium')
      task.taskPriorityUuid = taskPriority.uuid // 2 - Medium Uuid preset.
      this.taskForm = this.taskFormService.getForm(task, 'new')
    }

    if (type === 'editTask') {
      const task: Task = new Task(data)
      this.taskForm = this.taskFormService.getForm(task, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save task
   */
  public saveTask (): void {
    console.log('saveTask', this.taskForm.value)
    this.validationService.runValidation(this.taskForm)

    if (!this.taskForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.taskForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.mainService.saveTask(this.taskForm.value)
    .then(() => {
      this.currentModal.close()
      this.searchItems()
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }
}
