// Angular.
import { Injectable } from '@angular/core'
import { FormGroup, FormBuilder } from '@angular/forms'
import { DeleteId } from '@app/modules/delete-id/delete-id.model'

@Injectable()

export class DeleteIdFormService {

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder
  ) {}

  /**
   * Get form
   */
  public getForm (data: DeleteId): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        data.uuid,
        []
      ]
    })

    return form
  }

}
