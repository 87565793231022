import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { from as observableFrom, merge as observableMerge, Subscription, Subject, Observable } from 'rxjs'
import { switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { TaskRegardingApiService } from '@app/modules/task-regarding/task-regarding-api.service'

@Component({
  selector: 'app-task-subject-typeahead',
  templateUrl: './task-subject-typeahead.component.html'

})

export class TaskSubjectTypeaheadComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Button text
   */
  @Input() mainFormControlName: string

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  @ViewChild('regardingSearchTypeahead') regardingSearchTypeahead: NgbTypeahead
  public focus$: Subject<string> = new Subject<string>()
  public click$: Subject<string> = new Subject<string>()

  /**
   * Constructor
   */
  public constructor (
    private taskRegardingApiService: TaskRegardingApiService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Search
   */
  public search: any = (text$: Observable<string>): Observable<any> => {
    const debouncedText: Observable<string> = text$.pipe(debounceTime(200), distinctUntilChanged())
    return observableMerge(debouncedText)
    .pipe(
      switchMap(term => observableFrom(this.getResults(term)))
    )
  }

  /**
   * Search result formatter
   *
   * Converts the object into a readable string.
   */
  public formatResults: any = (item: any): any => {
    return typeof item === 'string' ? item : item.name
  }

  /**
   * Searcher
   *
   * Note that Personal Activity id 4 and Conference id 5 share the same
   * set so use taskTypeUuid 5 if it's 4.
   */
  public getResults (term: string): Promise<any> {
    if (term) {}
    let taskTypeUuid: string

    taskTypeUuid = this.mainForm.get('taskTypeUuid').value

    if (taskTypeUuid === '303955f2-db06-11e8-bdf2-60f81dbb71a2') { // Task type - 4 Uuid preset.
      taskTypeUuid = '30396de4-db06-11e8-af1c-60f81dbb71a2' // Task type - 5 Uuid preset.
    }

    const params: any | Object = {
      // page: 0,
      // limit: 1000,
      sort: [
        {
          key: 'displayOrder',
          direction: 'ASC'
        },
        {
          key: 'name',
          direction: 'ASC'
        }
      ],
      filter_groups: [
        {
          filters: [
            {
              key: 'task_type_uuid',
              value: taskTypeUuid,
              operator: 'eq'
            }
          ]
        }
      ]
    }

    return this.taskRegardingApiService.getMany(params)
    .toPromise()
    .then((results) => {
      const arr: Array<any> = []

      results.map(r => {
        arr.push(this.formatResults(r))
      })

      return arr
    })
  }

}
