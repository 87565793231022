import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
// import * as moment from 'moment'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { SyncService } from '@app/modules/sync/services/sync.service'

@Component({
  selector: 'app-sync-database',
  templateUrl: './sync-database.component.html'
})

export class SyncDatabaseComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    // Services.
    public appService: AppService,
    public myUtil: MyUtilityService,
    public selectOptionsService: SelectOptionsService,
    public syncService: SyncService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = true
  }

}
