import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'humanizeFileSize' })

/**
 * https://github.com/Nightapes/HumanizeDuration.ts
 */
export class HumanizeFileSizePipe implements PipeTransform {

  /**
   * Transform milliseconds to human readable format
   *
   * @param value Value
   * @param limit Limit to chars
   * @param more  More text
   *
   * @returns {string} Formatted telephone number
   */
  public transform (value: string): string {
    return value
  }

}

// app.filter('humanReadableFileSize', ['$filter', 'fileManagerConfig', function($filter, fileManagerConfig) {
//   // See https://en.wikipedia.org/wiki/Binary_prefix
//   var decimalByteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
//   var binaryByteUnits = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

//   return function(input) {
//     var i = -1;
//     var fileSizeInBytes = input;

//     do {
//       fileSizeInBytes = fileSizeInBytes / 1024;
//       i++;
//     } while (fileSizeInBytes > 1024);

//     var result = fileManagerConfig.useBinarySizePrefixes ? binaryByteUnits[i] : decimalByteUnits[i];
//     return Math.max(fileSizeInBytes, 0.1).toFixed(1) + ' ' + result;
//   };
// }]);
