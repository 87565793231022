import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { AddressName } from '@app/modules/address-name/address-name.model'
import { Country } from '@app/modules/country/country.model'

export class Address extends Base {
  uuid: string = null
  addressNameUuid: string = null
  addressName: AddressName = null
  address1: string = null
  address2: string = null
  address3: string = null
  city: string = null
  state: string = null
  postcode: number = null
  countryUuid: string = null
  country: Country = null
  latitude: string = null
  longitude: string = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param {Partial<Address>} data Initial values
   */
  public constructor (data?: Partial<Address>) {
    super(data)
    this.init(data)
  }
}
