import { Pipe, PipeTransform } from '@angular/core'
import { SlicePipe } from '@angular/common'

@Pipe({ name: 'strLimit' })

/**
 * https://github.com/Nightapes/HumanizeDuration.ts
 */
export class StrLimitPipe implements PipeTransform {

  /**
   * Transform milliseconds to human readable format
   *
   * @param value Value
   * @param limit Limit to chars
   * @param more  More text
   *
   * @returns {string} Formatted telephone number
   */
  public transform (value: string, limit: number, more?: string): string {
    if (!more) {
      more = '...'
    }

    return (value.length > limit) ? new SlicePipe().transform(value, 0, limit) + more : value
  }

}

// app.filter('strLimit', ['$filter', function($filter) {
//     return function(input, limit, more) {
//         if (input.length <= limit) {
//             return input;
//         }
//         return $filter('limitTo')(input, limit) + (more || '...');
//     };
// }]);
