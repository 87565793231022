import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { User } from '@app/modules/user/user.model'
import { Person } from '@app/modules/person/person.model'
import { PersonFormService } from '@app/modules/person/person-form.service'

@Injectable()

export class UserFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      username: [
        ValidationService.noWhiteSpace,
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(191)
      ]
    },
    {
      password: [
        ValidationService.noWhiteSpace,
        Validators.minLength(6),
        Validators.maxLength(191)
      ]
    },
    {
      email: [
        ValidationService.noWhiteSpace,
        Validators.required,
        ValidationService.isValidEmail
      ]
    },
    {
      personUuid: [
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private personFormService: PersonFormService
  ) {}

  /**
   * Get form
   */
  public getForm (user: User, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      username: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      password: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      email: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      firstName: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      lastName: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      personUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      enabled: [
        false,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      lastIp: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      lastLogin: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      lastActive: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      rememberToken: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (user.person) {
      form.addControl('person', this.personFormService.getForm(new Person(user.person), ''))
    }

    if (user) {
      form.patchValue(user, { emitEvent: false })
    }

    return form
  }

}
