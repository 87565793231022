import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { Observable } from 'rxjs'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
@Injectable()

export class SiteApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/sites'
  }

  // ---------------------------------------------------------------------------
  // Site addresses
  // ---------------------------------------------------------------------------

  /**
   * Save address
   *
   * @param id        Parent primary key
   * @param childData Child data
   *
   */
  public saveAddress (uuid: string, childData: Object): Observable<any> {
    return this.saveChild(uuid, 'addresses', childData)
  }

  /**
   * Delete address
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public deleteAddress (uuid: string, childUuid: string): Observable<any> {
    return this.deleteChild(uuid, 'addresses', childUuid)
  }

  /**
   * Destroy address
   *
   * @param id      Parent primary key
   * @param childUuid Child primary key
   *
   */
  public destroyAddress (uuid: string, childUuid: string): Observable<any> {
    return this.destroyChild(uuid, 'addresses', childUuid)
  }

}
