import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { Address } from '@app/modules/address/address.model'
import { Company } from '@app/modules/company/company.model'

export class Site extends Base {
  uuid: string = null
  name: string = null
  addressUuid: string = null
  address: Address = null
  companyUuid: string = null
  company: Company = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   */
  public constructor (data?: Partial<Site>) {
    super(data)
    this.init(data)
  }
}
