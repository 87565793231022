import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { UriNameFormService } from '@app/modules/uri-name/uri-name-form.service'
import { Uri } from '@app/modules/uri/uri.model'

@Injectable()

export class UriFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      uriNameUuid: [
        ValidationService.isUuid
      ]
    },
    {
      url: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private uriNameFormService: UriNameFormService
  ) {}

  /**
   * Get form
   */
  public getForm (uri: Uri, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      uriNameUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      url: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (uri.uriName) {
      form.addControl('uriName', this.uriNameFormService.getForm(uri.uriName, ''))
    }

    if (uri) {
      form.patchValue(uri, { emitEvent: false })
    }

    return form
  }

}
