import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from '@app/modules/alert/alert.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ValidationService } from '@app/shared/services/validation.service'
import { Company, CompanyService } from '@app/modules/company'
import { Person, PersonService, PersonFormService } from '@app/modules/person'
import { cloneDeep } from 'lodash'
import { PersonToCompany } from '@app/modules/person-to-company'
import { ExistingPeopleFormService } from '@app/modules/person/services/existing-people-form.service'

@Component({
  selector: 'app-tab-employees-table',
  templateUrl: './tab-employees-table.component.html'
})

export class TabEmployeesTableComponent implements OnInit, OnDestroy {

  /**
   * Employees
   */
  @Input() employees: Array<Person>

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Company
   *
   * We cant include company in the form because we'll get circular deps.
   */
  @Input() company: Company

  /**
   * Person form
   */
  public personForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Existing people form
   */
  public existingPeopleForm: FormGroup

  /**
   * Existing people config
   */
  public existingPeopleConfig: any = {
    controlName: 'people',
    deleteControlName: 'peopleDeleted'
  }

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    public validationService: ValidationService,
    private personFormService: PersonFormService,
    private personService: PersonService,
    private companyService: CompanyService,
    private existingPeopleFormService: ExistingPeopleFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
    this.getEmployees(this.company.uuid)
    .then((results) => {
      this.employees = results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Open modal
   *
   * @param event   Event
   * @param content Content
   * @param type    Type
   * @param data    Data
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newEmployee') {
      const company: Company = cloneDeep(this.company)

      company.personToCompany = new PersonToCompany({
        companyUuid: this.company.uuid
      })

      const person: Person = new Person({
        companies: [
          company
        ]
      })

      this.personForm = this.personFormService.getForm(person, 'new')
    }

    if (type === 'editEmployee') {
      console.log('editEmployee', data)
      const person: Person = new Person(data)
      this.personForm = this.personFormService.getForm(person, 'edit')
    }

    if (type === 'existingContactModal') {
      this.existingPeopleForm = this.existingPeopleFormService.getForm(null)
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save employee
   */
  public saveEmployee (): void {
    this.validationService.runValidation(this.personForm)
    if (!this.personForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.personForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.companyService.saveEmployee(this.company.uuid, this.personForm.value)
    .then((result) => {
      if (result) {
      }

      this.personForm.value.companies.map((company: Company) => {
        console.log('company', company)
        if (company.uuid === this.company.uuid) {

          if (company.personToCompany && company.personToCompany.companyUuid === this.company.uuid) {
            this.personService.api.attachChild(result.uuid, 'companies',  this.company.uuid, company.personToCompany)
            .toPromise()
            .then((r) => {
              console.log('r', r)
            })
            .catch((err) => {
              console.error(err)
            })
          }

        }
      })

      this.alertService.success('Saved: ' + (this.personForm.value.firstName ? this.personForm.value.firstName : '') +
      ' ' + (this.personForm.value.lastName ? this.personForm.value.lastName : ''), 10000)
      this.mainForm.markAsPristine()
      this.currentModal.close()
      this.ngOnInit()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Get company
   *
   * @param companyUuid Company primary key
   */
  public getEmployees (companyUuid: string): Promise<any> {
    return this.companyService.api.getEmployees(companyUuid, {
      includes: [
        'addresses',
        'addresses.country',
        'emailAddresses',
        'emailAddresses.emailAddressName',
        'telephones',
        'telephones.telephoneName',
        'socials',
        'socials.socialName',
        'uris',
        'uris.uriName',
        'companies',
        'companies.addresses',
        'companies.addresses.country',
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Delete employee
   *
   * @param personUuid Person primary key
   */
  public deleteEmployee (personUuid: string): void {
    this.personService.api.delete(personUuid)
    .toPromise()
    .then(() => {
      this.alertService.success('Deleted: ' + (this.personForm.value.firstName ? this.personForm.value.firstName : '') +
      ' ' + (this.personForm.value.lastName ? this.personForm.value.lastName : '') , 10000)
      this.currentModal.close()
      this.ngOnInit()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Remove employee
   *
   * @param personUuid Person primary key
   */
  public removeEmployee(personUuid: string): void {
    this.companyService.api.detachEmployee(this.company.uuid, personUuid)
      .toPromise()
      .then(() => {
        this.currentModal.close()
        this.ngOnInit()
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err.message)
      })
  }

  /**
   * Save existing contacts
   */
  public saveExistingContacts (): void {
    const promises: Array<Promise<any>> = []
    let promise: Promise<any>

    this.existingPeopleForm.value.contacts.map((person: Person) => {
      promise = this.companyService.api.attachEmployee(this.company.uuid, person.uuid)
      .toPromise()
      .then(() => {
      })
      .catch((err) => {
        throw err
      })
      promises.push(promise)
    })

    Promise.all(promises)
    .then(() => {
      this.alertService.success('Added contacts', 10000)
      this.currentModal.close()
      this.ngOnInit()
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err.message)
    })
  }

  /**
   * Done adding contacts
   */
  public cancelAddingContacts (): void {
  }
}
