// Angular
import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
// Other
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
// Services.
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
// Service & models.
import { ContactGroup } from '@app/modules/contact-group/contact-group.model'
import { ContactGroupService } from '@app/modules/contact-group/contact-group.service'
import { ContactGroupFormService } from '@app/modules/contact-group/contact-group-form.service'

import { LocalStorageService } from 'angular-2-local-storage'

@Component({
  selector: 'app-view-contact-groups',
  templateUrl: './view-contact-groups.component.html'
})

export class ViewContactGroupsComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Contact groups
   */
  public contactGroups: Array<any> = []

  /**
   * Company form
   */
  public contactGroupForm: FormGroup

  /**
   * Search form
   */
  public searchForm: FormGroup

  /**
   * Page options
   */
  public pageOptions: Array<any>

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Constructor
   */
  public constructor (
    private renderer: Renderer,
    private router: Router,
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    private contactGroupService: ContactGroupService,
    private contactGroupFormService: ContactGroupFormService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = false
    this.searchForm = this.contactGroupService.getSearchForm()
    const lastSearch: any = this.localStorageService.get('contact-group.search')
    if (lastSearch) {
      this.searchForm.patchValue(lastSearch, { emitEvent: false })
    }
    this.searchSub()
    this.searchContactGroups()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.contactGroupService.pagination.currentPage = 1
      this.searchContactGroups()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search contact groups
   */
  public searchContactGroups (): Promise<any> {
    this.localStorageService.set('contact-group.search', this.searchForm.value)
    return this.contactGroupService.searchItems(this.searchForm.value)
    .then((results) => {
      this.contactGroupService.pagination.currentPageSize = results.length
      this.getPagesOptions()
      this.contactGroups = results
      this.display = true
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get pages options
   */
  public getPagesOptions (): void {
    const pageOptions: Array<any> = []
    const totalItems: number = this.contactGroupService.pagination.totalItems
    const pageSize: number = this.contactGroupService.pagination.pageSize
    const pageCount: number = Math.ceil(totalItems / pageSize)
    let i: number
    for (i = 1; i <= pageCount; i++) {
      pageOptions.push({
        key: i,
        val: i
      })
    }

    this.pageOptions = pageOptions
  }

  /**
   * Go to page
   *
   * @param {Event} event Event
   */
  public goToPage (event: Event): void {
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.searchContactGroups()
  }

  /**
   * Change page size
   *
   * @param {Event} event Event
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.contactGroupService.pagination.currentPage = 1
    this.searchContactGroups()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.localStorageService.remove('contact-group.search')
    this.contactGroupService.sort = {
      column: 'contactGroup.name',
      direction: 'ASC'
    }

    this.searchForm.reset()
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    this.contactGroupService.setSort(column)
    this.searchContactGroups()
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newContactGroup') {
      const contactGroup: ContactGroup = new ContactGroup()
      this.contactGroupForm = this.contactGroupFormService.getForm(contactGroup, 'new')
    }

    if (type === 'editContactGroup') {
      const contactGroup: ContactGroup = new ContactGroup(data)
      this.contactGroupForm = this.contactGroupFormService.getForm(contactGroup, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save new company
   */
  public saveContactGroup (): void {
    console.log('saveContactGroup', this.contactGroupForm.value)
    this.validationService.runValidation(this.contactGroupForm)

    if (!this.contactGroupForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.contactGroupForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    if (this.router) {}
    // this.contactGroupService.saveContactGroup(this.contactGroupForm.value)
    // .then((result) => {
    //   this.currentModal.close()
    //   this.router.navigate(['/contact-groups', result.uuid])
    // })
    // .catch((err) => {
    //   this.alertService.error('Error: ' + err)
    // })
  }

}
