import { Component, OnInit, OnDestroy } from '@angular/core'
import { RoutingStateService } from '@app/shared/services/routing-state.service'

@Component({
  selector: 'app-view-unallocated-email',
  templateUrl: './view-unallocated-email.component.html'
})

export class ViewUnallocatedEmailComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Constructor
   */
  public constructor (
    public routingStateService: RoutingStateService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

}
