import { Component, OnInit } from '@angular/core'
import { smoothlyMenu } from '@app/app.helpers'
import { Router } from '@angular/router'
import { LocalStorageService } from 'angular-2-local-storage'
import { AppService } from '@app/shared/services/app.service'

/**
 * jQuery
 *
 * @type {jQuery}
 */
declare var $: any

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css']
})

export class TopNavigationComponent implements OnInit {

  /**
   * Constructor
   */
  public constructor (
    private appService: AppService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * On init
   */
  public ngOnInit (): void {}

  /**
   * Toggle navigation
   *
   * @deprecated Not using this.
   */
  public toggleNavigation (event: MouseEvent): void {
    event.preventDefault()
    event.stopPropagation()
    $('body').toggleClass('mini-navbar')
    smoothlyMenu()
  }

  /**
   * On logout
   */
  public onLogout (event: MouseEvent): void {
    event.preventDefault()
    event.stopPropagation()
    // this.localStorageService.remove('apiKey')
    // this.localStorageService.remove('currentUser')
    this.appService.authService.logout()
    this.router.navigate(['/login'])
  }

}
