import { Injectable } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
// import { CustomValidators } from 'ngx-custom-validators'

@Injectable()

/**
 * ValidationService
 *
 * https://stackoverflow.com/questions/41449937/angular-2-form-validation-framework-implementation-not-working
 * https://coryrylan.com/blog/angular-form-builder-and-validation-management
 * https://stackoverflow.com/questions/35474991/angular-2-form-validating-for-repeat-password
 */
export class ValidationService {

  /**
   * Get validator error message
   */
  public static getValidatorErrorMessage (validatorName: string, validatorValue?: any): string {
    const messages: any | Object = {
      'min': `This field must be equal or greater than ${validatorValue.min}`,
      'max': `This field must be less than or equal ${validatorValue.max}`,
      'required': 'This field is required.',
      'email': 'This field must be an email address.',
      'minlength': `Minimum ${validatorValue.requiredLength} characters allowed.`,
      'maxlength': `Maximum ${validatorValue.requiredLength} characters allowed.`,
      'isValidEmail': 'This is not a valid email address.',
      'isValidDOBString': 'The date is invalid (DD/MM/YYYY).',
      'isValidDateString': 'The date is invalid (DD/MM/YYYY).',
      'isValidDate': 'The date is invalid.',
      'isNumeric': 'This field must be numeric.',
      'isUserEmailAvailable': 'The email address is unavailable.',
      'isValidCreditCard': 'Is invalid credit card number',
      'isValidPassword': 'Invalid password. Password must be at least 10 characters long, and contain a number.',
      'comparePassword': 'Password fields do not match.',
      'isRange': 'Must be a number or range eg. 2-3',
      'noWhiteSpace': 'Field only contains whitespace chars.',
      'noEmojis': 'Emojis are not allowed.',
      'isUuid': 'This field must be a valid Uuid.'
    }
    return messages[validatorName]
  }

  /**
   * Get validator error message 2
   *
   * Test version.
   */
  public static getValidatorErrorMessage2 (args: any | Object): any {
    console.log('inside getValidatorErrorMessage')
    const props: any | Object = {
      notUnique: `Sorry, this ${args.validatorName} is not unique`
    }
    return props[args.validatorProp]
  }

  /**
   * Is uuid
   */
  public static isUuid (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    // var x = CustomValidators.uuid(1)
    // console.log(x
    return null
    // if (control.value.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')) {
    //   return null
    // } else {
    //   return { 'isUuid': true }
    // }
  }

  /**
   * Is numeric
   */
  public static isNumeric (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    if (!isNaN(control.value - parseFloat(control.value))) {
      return null
    } else {
      return { 'isNumeric': true }
    }
  }

  /**
   * Is valid email
   */
  public static isValidEmail (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null
    } else {
      return { 'isValidEmail': true }
    }
  }

  /**
   * Is valid date object
   */
  public static isValidDate (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    const d: any = control.value
    if (Object.prototype.toString.call(d) === '[object Date]') {
      if (isNaN(d.getTime())) {
        return { 'isValidDate': true }
      } else {
        return null
      }
    } else {
      return { 'isValidDate': true }
    }
  }

  /**
   * Is valid date of birth string
   *
   * Sets a lower limit of 100 years and does not allow future dates.
   */
  public static isValidDOBString (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    const bits: Array<any> = control.value.split('/')
    if (parseInt(bits[2], 10) < 1917) {
      // You're over 100 years old.
      return { 'isValidDOBString': true }
    }
    const now: Date = new Date()
    const d: Date = new Date(bits[2], bits[1] - 1, bits[0])
    if (d > now) {
      // You havent been born yet!
      return { 'isValidDOBString': true }
    }
    const check: boolean = d.getFullYear() === parseInt(bits[2], 10)
      && (d.getMonth() + 1) === parseInt(bits[1], 10)
      && d.getDate() === parseInt(bits[0], 10)
    if (check === true) {
      return null
    } else {
      // ValidationService.messages.isValidDOBString = 'The date is invalid (DD/MM/YYYY).';
      return { 'isValidDOBString': true }
    }
  }

  /**
   * Is valid date string
   */
  public static isValidDateString (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    const bits: Array<any> = control.value.split('/')
    const d: Date = new Date(bits[2], bits[1] - 1, bits[0])
    const check: boolean = d.getFullYear() === parseInt(bits[2], 10)
      && (d.getMonth() + 1) === parseInt(bits[1], 10)
      && d.getDate() === parseInt(bits[0], 10)
    if (check === true) {
      return null
    } else {
      return { 'isValidDateString': true }
    }
  }

  /**
   * Is valid password
   */
  public static isValidPassword (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    // {10,100}           - Assert password is between 10 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{10,100}$/)) {
      return null
    } else {
      return { 'invalidPassword': true }
    }
  }

  /**
   * Is valid credit card
   */
  public static isValidCreditCard (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    // tslint:disable-next-line:max-line-length
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null
    } else {
      return { 'invalidCreditCard': true }
    }
  }

  /**
   * Is user email available
   *
   * @todo Cant get this working.
   */
  public static isUserEmailAvailable (control: FormControl): any {
    if (control) {
    }
    return null
    // @todo cant get this working.
    // if (control.value === '' || control.value === null) {
    //   return null;
    // }
    // const taken = this.httpService.isUserEmailAvailable(control.value);
    // if (taken !== true) {
    //     return null;
    // } else {
    //     return { 'isUserEmailAvailable': true };
    // }
  }

  /**
   * Compare password
   *
   * Compares value to conrol named comparePassword.
   */
  public static comparePassword (control: FormControl): any {
    if (control) {
    }
    return null
    // if (control.value === '' || control.value === null) {
    //   return null
    // }

    // if (!control._parent || control._parent._value.comparePassword) {
    //   return null
    // }

    // if (control.value === control._parent._value.comparePassword) {
    //   return null
    // } else {
    //   return { 'comparePassword': true }
    // }
  }

  public static isRange (control: FormControl): any {
    if (control.value === '' || control.value === null) {
      return null
    }
    return null
    // if (control.value.match(/(^[0-9]+[-]*[0-9]+$)/) {
    //   return null
    // } else {
    //   return { 'invalidCreditCard': true }
    // }

  }

  /**
   * No white space
   */
  public static noWhiteSpace (control: FormControl): any {
    if (!control.value || control.value === '' || typeof control.value !== 'string') {
      return null
    }
    // console.log(typeof control.value)
    if (control.value.trim().length === 0) {
      return { 'noWhiteSpace' : true }
    }
  }

  /**
   * No emojis
   */
  public static noEmojis (control: FormControl): any {
    if (!control.value || control.value === '' || typeof control.value !== 'string') {
      return null
    }
    // tslint:disable-next-line:max-line-length
    const regexp: RegExp = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    if (control.value.match(regexp)) {
      return { 'noEmojis' : true }
    }
    return null
  }

  /**
   * Mark form group touched
   *
   * Marks all controls in a form group as touched.
   */
  public markFormGroupTouched (formGroup: FormGroup): void {
    (Object as any).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        // control is a FormGroup.
        this.markFormGroupTouched(control)
      } else {
        // control is a FormControl.
        control.markAsTouched()
      }
    })
  }

  /**
   * Validate form
   */
  public runValidation (formGroup: FormGroup): void {
    this.markFormGroupTouched(formGroup)
  }

  /**
   * Is field valid
   */
  public isFieldValid (formGroup: FormGroup, field: string): boolean {
    return formGroup.get(field).valid
  }

  /**
   * Get form validation classes for Bootstrap 4
   */
  public formClass (formGroup: FormGroup): any {
    if (formGroup) {}
    return {
      'needs-validation:': true
      // 'was-validated': (formGroup.touched || formGroup.dirty) && formGroup.valid
    }
  }

  /**
   * Get form-control validation classes for Bootstrap 4
   */
  public formControlClass (formGroup: FormGroup, field: string, whenTouched?: boolean): any {
    const control: any = formGroup.get(field)

    if (!control) {
      console.log('control', field + ' not found')
      return {}
    }

    let isValid: boolean
    isValid = formGroup.get(field).valid

    // Only set the classes if the field has been touched.
    if (whenTouched === true) {
      if (!formGroup.get(field).touched) {
        return {}
      }
    }

    if (formGroup.get(field).disabled) {
      return {}
    }

    return {
      // 'is-valid': isValid,
      'is-invalid': !isValid
    }
  }

  /**
   * Get form-group classes for Bootstrap 3
   */
  public formGroupClass (formGroup: FormGroup, field: string): any {
    const isValid: boolean = this.isFieldValid(formGroup, field)
    // if (isValid === 'skip') {
    //   return {}
    // }

    return {
      'has-success': isValid,
      'has-error': isValid
    }
  }

  /**
   * Extract form errors
   */
  public extractErrors (formGroup: FormGroup, formErrors?: Array<any>, parentName?: string): Array<any> {
    if (!formErrors) {
      formErrors = []
    }

    const controls: any = formGroup.controls

    if (controls) {
      for (const name of Object.keys(controls)) {
        let controlName: string

        if (typeof parentName !== 'undefined') {
          controlName = parentName + '.' + name
        } else {
          controlName = name
        }

        // If it's a FormGroup or FormArray then recur.
        if (controls[name].controls) {
          this.extractErrors(controls[name], formErrors, controlName)
        } else {
          // Check the validity.
          if (controls[name].invalid) {
            formErrors.push({
              controlName: controlName,
              errors: controls[name].errors
            })
          }
        }
      }
    }

    return formErrors
  }

  /**
   * Add insert validators
   */
  public addInsertValidators (form: FormGroup, insertValidators: Array<any>): void {
    insertValidators.map((rules) => {
      Object.keys(rules).map((key) => {
        form.get(key).setValidators(rules[key])
      })
    })
  }

  /**
   * Add update validators
   */
  public addUpdateValidators (form: FormGroup, insertValidators: Array<any>, updateValidators: Array<any>): void {
    // const combined: Array<any> = Object.assign({}, insertValidators, updateValidators)
    const combined: Array<any> = insertValidators.concat(updateValidators)
    combined.map((rules) => {
      Object.keys(rules).map((key) => {
        form.get(key).setValidators(rules[key])
      })
    })
  }

}
