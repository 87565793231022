import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { LocalStorageService } from 'angular-2-local-storage'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { CompanyApiService } from '@app/modules/company/company-api.service'
import { AddressService } from '@app/modules/address/address.service'
import { EmailAddressService } from '@app/modules/email-address/email-address.service'
import { SocialService } from '@app/modules/social/social.service'
import { TelephoneService } from '@app/modules/telephone/telephone.service'
import { UriService } from '@app/modules/uri/uri.service'
import { PersonService } from '@app/modules/person/person.service'
import { Person } from '@app/modules/person/person.model'
import { Address } from '@app/modules/address/address.model'
import { Company } from '@app/modules/company/company.model'
import { EmailAddress } from '@app/modules/email-address/email-address.model'
import { Social } from '@app/modules/social/social.model'
import { Telephone } from '@app/modules/telephone/telephone.model'
import { Uri } from '@app/modules/uri/uri.model'
import { DeleteId } from '@app/modules/delete-id/delete-id.model'

@Injectable()

export class CompanyService {

  public lastSearchTerm: string

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0,
    itemIds: []
  }

  /**
   * Sort
   */
  public sort: any | Object = {
    column: 'company.name',
    direction: 'ASC'
  }

  /**
   * Constructor
   */
  public constructor (
    // Angular.
    public formBuilder: FormBuilder,
    // Other Services.
    public localStorageService: LocalStorageService,
    public myUtil: MyUtilityService,
    // Api services.
    public api: CompanyApiService,
    // Related model services.
    public addressService: AddressService,
    public emailAddressService: EmailAddressService,
    public socialService: SocialService,
    public telephoneService: TelephoneService,
    public uriService: UriService,
    public personService: PersonService
  ) {}

  // ---------------------------------------------------------------------------
  // Search
  // ---------------------------------------------------------------------------

  /**
   * Get search form
   */
  public getSearchForm (): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      name: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      address: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      city: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      countryUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      postcode: [
        null,
        [
          Validators.maxLength(191)
        ]
      ],
      employeeFullName: [
        null,
        [
          Validators.maxLength(191)
        ]
      ]
    })

    return form
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    if (this.sort.column === column) {
      if (this.sort.direction === 'DESC') {
        this.sort.direction = 'ASC'
      } else {
        this.sort.direction = 'DESC'
      }
    } else {
      this.sort.column = column
      this.sort.direction = 'ASC'
    }
  }

  /**
   * Search
   */
  public search (searchFilters: any | Object): Promise<any> {
    console.log('searchFilters', searchFilters)

    const filterGroups: Array<any> = []

    const mainFilterGroup: any = {
      or: false,
      filters: []
    }

    const addressFilterGroup: any = {
      or: true,
      filters: []
    }

    if (searchFilters.name) {
      mainFilterGroup.filters.push({
        key: 'company.name',
        value: searchFilters.name,
        operator: 'ct'
      })
    }

    if (searchFilters.address) {
      addressFilterGroup.filters.push({
        key: 'addresses.address_1',
        value: searchFilters.address,
        operator: 'ct'
      })
      addressFilterGroup.filters.push({
        key: 'addresses.address_2',
        value: searchFilters.address,
        operator: 'ct'
      })
      addressFilterGroup.filters.push({
        key: 'addresses.address_3',
        value: searchFilters.address,
        operator: 'ct'
      })
    }

    if (searchFilters.city) {
      mainFilterGroup.filters.push({
        key: 'addresses.city',
        value: searchFilters.city,
        operator: 'ct'
      })
    }

    if (searchFilters.postcode) {
      mainFilterGroup.filters.push({
        key: 'addresses.postcode',
        value: searchFilters.postcode,
        operator: 'ct'
      })
    }

    if (searchFilters.countryUuid) {
      mainFilterGroup.filters.push({
        key: 'addresses.countryUuid',
        value: searchFilters.countryUuid,
        operator: 'eq'
      })
    }

    if (searchFilters.employeeFullName) {
      mainFilterGroup.filters.push({
        key: 'employeeFullName',
        value: searchFilters.employeeFullName,
        operator: 'ct'
      })
    }

    if (mainFilterGroup.filters.length > 0) {
      filterGroups.push(mainFilterGroup)
    }

    if (addressFilterGroup.filters.length > 0) {
      filterGroups.push(addressFilterGroup)
    }

    const params: any = {
      sort: [
        {
          key: this.sort.column,
          direction: this.sort.direction
        }
      ],
      filter_groups: filterGroups,
      fields: [
        'company.uuid'
      ]
    }

    // Get the total results.
    return this.api.count(params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.count

      // this.setItemIds(totalResults)

      // Add pagination and includes for result set
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      params.includes = [
        'addresses',
        'addresses.country',
        'telephones',
        'emailAddresses',
        'uris',
        'socials',
        'people'
      ]
      delete params.fields

      return this.api.getMany(params)
      .toPromise()
      .then((results) => {
        return results
      })
      .catch((err) => {
        throw err
      })
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Set item ids
   *
   * Store the oportunity ids so we can retrive them from the edit view
   * for prev/next.
   */
  public setItemIds (totalResults: Array<any>): void {
    const itemIds: Array<number> = []
    totalResults.map((item) => {
      itemIds.push(item.uuid)
    })

    this.localStorageService.set('opportunityItemIds', itemIds)

    this.pagination.itemIds = itemIds
  }

  /**
   * Get mini nav
   */
  public getMiniNav (currentUuid: string): any {
    const miniNav: any = {
      prevId: null,
      current: currentUuid,
      nextId: null,
      total: null
    }

    if (this.pagination.itemIds.length === 0) {
      const itemIds: any = this.localStorageService.get('opportunityItemIds')
      if (itemIds !== null) {
        this.pagination.itemIds = itemIds
      }
    }

    miniNav.total = this.pagination.itemIds.length

    let i: number
    i = 0

    this.pagination.itemIds.map((item) => {
      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i + 1])) {
        miniNav.nextId = this.pagination.itemIds[i + 1]
      }
      if (item === currentUuid) {
        miniNav.current = (i + 1)
      }

      if (item === currentUuid && !this.myUtil.isEmpty(this.pagination.itemIds[i - 1])) {
        miniNav.prevId = this.pagination.itemIds[i - 1]
      }
      i++
    })

    return miniNav
  }

  /**
   * Save company
   */
  public saveCompany (company: Company): Promise<any> {
    console.log('companyService.saveCompany()', company)
    // Save the company.
    return this.api.save(company)
    .toPromise()
    .then((companyResult) => {
      const companyUuid: string = companyResult.uuid
      const promises: Array<Promise<any>> = []

      if (company.addresses) {
        company.addresses.map((address: Address) => {
          const promise: Promise<any> = this.api.saveAddress(companyUuid, address)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.addressesDeleted) {
        company.addressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteAddress(companyUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.emailAddresses) {
        company.emailAddresses.map((emailAddress: EmailAddress) => {
          const promise: Promise<any> = this.api.saveEmailAddress(companyUuid, emailAddress)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.emailAddressesDeleted) {
        company.emailAddressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteEmailAddress(companyUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.socials) {
        company.socials.map((social: Social) => {
          const promise: Promise<any> = this.api.saveSocial(companyUuid, social)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.socialsDeleted) {
        company.socialsDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteSocial(companyUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.telephones) {
        company.telephones.map((telephone: Telephone) => {
          const promise: Promise<any> = this.api.saveTelephone(companyUuid, telephone)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.telephonesDeleted) {
        company.telephonesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteTelephone(companyUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.uris) {
        company.uris.map((uri: Uri) => {
          const promise: Promise<any> = this.api.saveUri(companyUuid, uri)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (company.urisDeleted) {
        company.urisDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.api.deleteUri(companyUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      // Save & or Delete the persons addresses, companies, emailAddresses, socials, telephones, uris
      return Promise.all(promises)
      .then(() => {
        return companyResult
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save Employee
   *
   * Person with companies.pivot to populate the company details: position, isPrimary etc..
   */
  public saveEmployee (companyUuid: string, person: Person): Promise<any> {
    console.log('companyService.saveEmployee()', person)

    // Save the employee.
    return this.api.saveEmployee(companyUuid, person)
    .toPromise()
    .then((personResult) => {
      const personUuid: string = personResult.uuid
      const promises: Array<Promise<any>> = []

      if (person.addresses) {
        person.addresses.map((address: Address) => {
          const promise: Promise<any> = this.personService.api.saveAddress(personUuid, address)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.addressesDeleted) {
        person.addressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteAddress(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.emailAddresses) {
        person.emailAddresses.map((emailAddress: EmailAddress) => {
          const promise: Promise<any> = this.personService.api.saveEmailAddress(personUuid, emailAddress)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.emailAddressesDeleted) {
        person.emailAddressesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteEmailAddress(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.socials) {
        person.socials.map((social: Social) => {
          const promise: Promise<any> = this.personService.api.saveSocial(personUuid, social)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.socialsDeleted) {
        person.socialsDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteSocial(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.telephones) {
        person.telephones.map((telephone: Telephone) => {
          const promise: Promise<any> = this.personService.api.saveTelephone(personUuid, telephone)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.telephonesDeleted) {
        person.telephonesDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteTelephone(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.uris) {
        person.uris.map((uri: Uri) => {
          const promise: Promise<any> = this.personService.api.saveUri(personUuid, uri)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      if (person.urisDeleted) {
        person.urisDeleted.map((del: DeleteId) => {
          const promise: Promise<any> = this.personService.api.deleteUri(personUuid, del.uuid)
          .toPromise()
          .then((result) => {
            return result
          })
          .catch((err) => {
            throw err
          })

          promises.push(promise)
        })
      }

      // Save & or Delete the persons addresses, emailAddresses, socials, telephones, uris
      return Promise.all(promises)
      .then(() => {
        return personResult
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      throw err
    })

  }

}
