import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { TelephoneName } from '@app/modules/telephone-name/telephone-name.model'

@Injectable()

export class TelephoneNameFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      name: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191),
        Validators.required
      ]
    },
    {
      displayOrder: [
        ValidationService.isNumeric
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService
  ) {}

  /**
   * Get form
   */
  public getForm (telephoneName: TelephoneName, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      name: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      displayOrder: [
        null,
        [
          ValidationService.isNumeric
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (telephoneName) {
      form.patchValue(telephoneName, { emitEvent: false })
    }

    return form
  }

}
