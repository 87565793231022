import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { NgbTimeStruct, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap'

import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

@Component({
  selector: 'app-my-time-picker',
  templateUrl: './my-time-picker.component.html',
  providers: []
})

export class MyTimePickerComponent implements OnInit, OnDestroy {

  /**
   * Element id
   */
  @Input() id: string

  /**
   * Form control name
   */
  @Input() fcName: string

  /**
   * Size
   */
  @Input() size: string

  /**
   * Form group
   */
  @Input() fg: FormGroup

  /**
   * Form group change
   */
  @Output() fgChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Form control
   */
  public fc: FormControl

  /**
   * Model
   *
   * Use this for the component then apply the changes to the
   * form control.
   *
   * @type {NgbTimeStruct}
   */
  public model: NgbTimeStruct

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public config: NgbTimepickerConfig
  ) {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    this.config.seconds = false
    this.config.spinners = true
    this.fc = this.fg.get(this.fcName) as FormControl
    this.updateModel(this.fc)
    this.watchFormControl()
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * Update model
   */
  public updateModel (fc: FormControl): void {
    if (fc.value) {
      const date: Date = fc.value

      const hours: any = date.getHours()
      const minutes: any = date.getMinutes()
      const seconds: any = date.getSeconds()

      this.model = {
        hour: hours,
        minute: minutes,
        second: seconds
      }
    } else {
      this.model = null
    }
  }

  /**
   * Watch form control
   */
  public watchFormControl (): void {
    const sub: Subscription = this.fc
    .valueChanges
    .pipe(
      debounceTime(200),
      distinctUntilChanged()
    )
    .subscribe(() => {
      // If the forms been cleared we need to set the model to 00:00:00.
      if (!this.fc.value && this.model) {
        this.model = {
          hour: 0,
          minute: 0,
          second: 0
        }
      }

      // If we didn't have a value but now do, update the model.
      if (this.fc.value) {
        this.updateModel(this.fc)
      }
    })
    this.subscriptions.push(sub)
  }

  /**
   * Model changed
   */
  public modelChanged (time: NgbTimeStruct): void {
    if (this.fc.value && time) {
      const date: Date = this.fg.get(this.fcName).value
      date.setHours(time.hour)
      date.setMinutes(time.minute)
      date.setSeconds(time.second)

      const newTime: any = {}
      newTime[this.fcName] = date

      this.fg.patchValue(newTime)
      this.fgChange.emit(this.fg)
    }

    if (!time) {
      this.model = {
        hour: 0,
        minute: 0,
        second: 0
      }
    }
  }

}
