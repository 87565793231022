import { Base } from '@app/shared/models/base.model'

export class SavedQuery extends Base {
  uuid: string = null
  name: string = null
  description: string = null
  jsQuery: string = null
  sqlQuery: string = null
  // Info.
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  updatedByUuid: string = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param {Partial<SavedQuery>} data Initial values
   */
  public constructor (data?: Partial<SavedQuery>) {
    super(data)
    this.init(data)
  }
}
