import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Site } from '@app/modules/site/site.model'
import { SiteService } from '@app/modules/site/site.service'
import { SiteFormService } from '@app/modules/site/site-form.service'
import { Address } from '@app/modules/address/address.model'
import { Company } from '@app/modules/company/company.model'
import { LocalStorageService } from 'angular-2-local-storage'
import { AddressName } from '@app/modules/address-name'

@Component({
  selector: 'app-view-sites',
  templateUrl: './view-sites.component.html'
})

export class ViewSitesComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Companies
   */
  public sites: Array<any> = []

  /**
   * Site form
   */
  public siteForm: FormGroup

  /**
   * Search form
   */
  public searchForm: FormGroup

  /**
   * Page options
   */
  public pageOptions: Array<any>

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Constructor
   */
  public constructor (
    private renderer: Renderer,
    private router: Router,
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    private siteService: SiteService,
    private siteFormService: SiteFormService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.searchForm = this.siteService.getSearchForm()
    const lastSearch: any = this.localStorageService.get('sites.search')
    if (lastSearch) {
      this.searchForm.patchValue(lastSearch, { emitEvent: false })
    }
    this.searchSub()
    this.searchSites()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.siteService.pagination.currentPage = 1
      this.searchSites()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search sites
   */
  public searchSites (): Promise<any> {
    this.localStorageService.set('sites.search', this.searchForm.value)
    return this.siteService.search(this.searchForm.value)
    .then((results) => {
      this.siteService.pagination.currentPageSize = results.length
      this.getPagesOptions()
      this.sites = results
      this.display = true
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get pages options
   */
  public getPagesOptions (): void {
    const pageOptions: Array<any> = []
    const totalItems: number = this.siteService.pagination.totalItems
    const pageSize: number = this.siteService.pagination.pageSize
    const pageCount: number = Math.ceil(totalItems / pageSize)
    let i: number
    for (i = 1; i <= pageCount; i++) {
      pageOptions.push({
        key: i,
        val: i
      })
    }

    this.pageOptions = pageOptions
  }

  /**
   * Go to page
   *
   * @param {Event} event Event
   */
  public goToPage (event: Event): void {
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.searchSites()
  }

  /**
   * Change page size
   *
   * @param {Event} event Event
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.siteService.pagination.currentPage = 1
    this.searchSites()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.localStorageService.remove('sites.search')
    this.siteService.sort = {
      column: 'company.name',
      direction: 'DESC'
    }

    this.searchForm.reset()
  }

  /**
   * Set sort companies
   */
  public setSort (column: string): void {
    this.siteService.setSort(column)
    this.searchSites()
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newSite') {
      const siteAddressName: AddressName = this.selectOptionsService.addressNameOptions.find(x => x.name === 'Site')
      const address: Address = new Address({
        addressNameUuid: siteAddressName.uuid // 5 - Site Uuid Preset.
      })

      const company: Company = new Company()

      const site: Site = new Site({
        address: address,
        company: company
      })

      console.log('site', site)

      this.siteForm = this.siteFormService.getForm(site, 'new')

      console.log(this.siteForm.value)

    }

    if (type === 'editSite') {
      const site: Site = new Site(data)
      this.siteForm = this.siteFormService.getForm(site, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save new site
   */
  public saveSite (): void {
    console.log('saveSite', this.siteForm.value)
    this.validationService.runValidation(this.siteForm)

    if (!this.siteForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.siteForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.siteService.saveSite(this.siteForm.value)
    .then((result) => {
      this.currentModal.close()
      this.router.navigate(['/sites', result.uuid])
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

}
