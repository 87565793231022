import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, Observable } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { EventService } from '@app/shared/services/event.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { PersonService } from '@app/modules/person/person.service'
import { PersonFormService } from '@app/modules/person/person-form.service'
import { Person } from '@app/modules/person/person.model'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { OpportunityFormService } from '@app/modules/opportunity/opportunity-form.service'
import { Task } from '@app/modules/task/task.model'
import { History } from '@app/modules/history/history.model'
import { ComponentCanDeactivate } from '@app/shared/guards/pending-changes.guard'

@Component({
  selector: 'app-edit-person',
  templateUrl: './edit-person.component.html'
})

export class EditPersonComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Person form
   */
  public mainForm: FormGroup

  /**
   * Person
   */
  public person: Person

  /**
   * Opportunities
   */
  public opportunitiesForm: FormGroup

  /**
   * Activities
   */
  public activities: Array<Task> = []

  /**
   * History
   */
  public history: Array<History> = []

  /**
   * Mini nav
   *
   * For prev next.
   */
  public miniNav: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload') canDeactivate(): Observable<boolean> | boolean {
    if (this.mainForm) {
      return this.mainForm.pristine
    }
    return true
  }

  /**
   * Constructor
   */
  public constructor(
    public activatedRoute: ActivatedRoute,
    public alertService: AlertService,
    public router: Router,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    public routingStateService: RoutingStateService,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public validationService: ValidationService,
    public personService: PersonService,
    private personFormService: PersonFormService,
    private opportunityFormService: OpportunityFormService
  ) { }

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit(): void {
    this.selectOptionsService.init(false)
    this.routeSub()
  }

  /**
   * Route sub
   */
  private eventSub(): void {
    const sub: Subscription = this.eventService.events.subscribe((event: any) => {
      console.log('eventSub', event)
      if (event.name === 'TASKS.addedTask') {
        this.getPerson(this.person.uuid)
          .then((newResult: Person) => {
            this.setUpPerson(newResult)
          })
          .catch((err) => {
            console.error(err)
          })
      }
    })

    this.subscriptions.push(sub)
  }

  /**
   * Route sub
   */
  public routeSub(): void {
    const sub: Subscription = this.activatedRoute
      .params
      .subscribe((routeParams) => {
        this.getPerson(routeParams['uuid'])
          .then((result) => {
            this.setUpPerson(result)
            this.eventSub()
            this.miniNav = this.personService.getMiniNav(result.uuid)
            this.display = true
          })
          .catch((err) => {
            console.error(err)
          })
      })

    this.subscriptions.push(sub)
  }

  /**
   * Set up person
   */
  public setUpPerson(data: any): void {
    this.person = new Person(data)

    // Cant link directly to person because of circular deps so build....
    this.opportunitiesForm = this.formBuilder.group({
      opportunities: this.formBuilder.array([])
    })

    // Circular deps
    if (this.person.opportunities) {
      const control: FormArray = this.opportunitiesForm.get('opportunities') as FormArray
      this.person.opportunities.map((opportunity: Opportunity) => {
        const opportunityForm: FormGroup = this.opportunityFormService.getForm(new Opportunity(opportunity), null)
        control.push(opportunityForm)
      })
    }

    this.activities = this.person.tasks
    // this.history = something

    this.mainForm = this.personFormService.getForm(this.person, 'edit')
  }

  /**
   * Get person
   *
   * @param personUuid Person primary key
   */
  public getPerson(personUuid: string): Promise<any> {
    return this.personService.api.getOne(personUuid, {
      includes: [
        'addresses',
        'addresses.addressName',
        'companies',
        'companies.companyType',
        'emailAddresses',
        'emailAddresses.emailAddressName',
        'telephones',
        'telephones.telephoneName',
        'socials',
        'socials.socialName',
        'uris',
        'uris.uriName',
        'tasks',
        'tasks.taskType',
        'tasks.organiserUser',
        'tasks.scheduledWith',
        'opportunities',
        'opportunities.company',
        'opportunities.opportunityStatus',
        'opportunities.opportunityStage',
        'opportunities.salesPersonUser',
        'opportunities.probability'
      ]
    })
      .toPromise()
      .then((result) => {
        return result
      })
      .catch((err) => {
        throw err
      })
  }

  /**
   * Save person
   */
  public savePerson(): Promise<any> {
    console.log('savePerson', this.mainForm.value)
    this.validationService.runValidation(this.mainForm)

    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.personService.savePerson(this.mainForm.value)
      .then(() => {
        this.alertService.success('Saved: ' + (this.mainForm.value.firstName ? this.mainForm.value.firstName : '') +
        ' ' + (this.mainForm.value.lastName ? this.mainForm.value.lastName : ''), 10000)
        this.mainForm.markAsPristine()
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err.message)
      })
  }

  /**
   * On cancel
   */
  public onCancel(): void {
    this.getPerson(this.person.uuid)
      .then((newResult: Person) => {
        this.setUpPerson(newResult)
        this.alertService.warning('Reverted: ' + (this.mainForm.value.firstName ? this.mainForm.value.firstName : '') +
        ' ' + (this.mainForm.value.lastName ? this.mainForm.value.lastName : ''), 10000)
        this.mainForm.markAsPristine()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * Delete person
   *
   * @param personUuid Person primary key
   */
  public deletePerson(personUuid: string): void {
    this.personService.api.delete(personUuid)
      .toPromise()
      .then(() => {
        this.alertService.success('Deleted: ' + (this.mainForm.value.firstName ? this.mainForm.value.firstName : '') +
        ' ' + (this.mainForm.value.lastName ? this.mainForm.value.lastName : ''), 10000)
        this.routingStateService.goBack(['/people'])
      })
      .catch((err) => {
        this.alertService.error('Error: ' + err)
      })
  }
}
