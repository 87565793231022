import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from '@app/modules/alert/alert.service'
import { EventService } from '@app/shared/services/event.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ValidationService } from '@app/shared/services/validation.service'
import { OpportunityService } from '@app/modules/opportunity/opportunity.service'
import {
  ProductServiceModel,
  // ProductServiceService,
  ProductServiceFormService
} from '@app/modules/product-service'

@Component({
  selector: 'app-tab-products-services-table',
  templateUrl: './tab-products-services-table.component.html'
})

export class TabProductsServicesTableComponent implements OnInit, OnDestroy {

  /**
   * Opportunity primary key
   */
  @Input() opportunityUuid: string

  /**
   * Product services
   */
  public productServices: Array<ProductServiceModel>

  /**
   * Display page
   */
  public display: boolean
  /**
   * Person form
   */
  public productServiceForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    public validationService: ValidationService,
    private opportunityService: OpportunityService,
    private productServiceFormService: ProductServiceFormService,
    private eventService: EventService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.getProductServices(this.opportunityUuid)
    .then((results) => {
      this.productServices = results
      this.display = true
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Get product services
   *
   * @param opportunityUuid Opportunity primary key
   */
  public getProductServices (opportunityUuid: string): Promise<any> {
    return this.opportunityService.api.getProductServices(opportunityUuid, {
      includes: [
        'product'
      ]
    })
    .toPromise()
    .then((results) => {
      return results
    })
    .catch((err) => {
      throw err
    })
  }
  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'editProductService') {
      const productService: ProductServiceModel = new ProductServiceModel(data)
      this.productServiceForm = this.productServiceFormService.getForm(productService, 'edit')
    }

    if (type === 'newProductService') {
      const productService: ProductServiceModel = new ProductServiceModel()
      this.productServiceForm = this.productServiceFormService.getForm(productService, 'new')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })

  }

  /**
   * Save product service
   */
  public saveProductService (): void {
    this.validationService.runValidation(this.productServiceForm)

    if (!this.productServiceForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.productServiceForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.opportunityService.api.saveProductService(this.opportunityUuid, this.productServiceForm.value)
    .toPromise()
    .then(() => {
      this.alertService.success('Saved: ' + this.productServiceForm.value.name , 10000)
      this.currentModal.close()
      this.ngOnInit()
      this.eventService.events.emit({
        name: 'opportunity.changed'
      })
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err.message)
    })
  }

  /**
   * Delete product service
   *
   * @param productServiceUuid Product service primary key
   */
  public deleteProductService (productServiceUuid: string): void {
    this.opportunityService.api.deleteProductService(this.opportunityUuid, productServiceUuid)
    .toPromise()
    .then(() => {
      this.alertService.success('Deleted: ' + this.productServiceForm.value.name , 10000)
      this.currentModal.close()
      this.ngOnInit()
      this.eventService.events.emit({
        name: 'opportunity.changed'
      })
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err.message)
    })
  }

  /**
   * Stopper
   */
  public stopper (event: Event): void {
    event.preventDefault()
    event.stopPropagation()
  }

}
