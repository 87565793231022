import { Component, Input, OnInit, OnDestroy } from '@angular/core'

@Component({
  selector: 'app-unallocated-email-from-display',
  templateUrl: './unallocated-email-from-display.component.html',
  providers: []
})

export class UnallocatedEmailFromDisplayComponent implements OnInit, OnDestroy {

  /**
   * From
   */
  @Input() from: any

  /**
   * From person in db.
   */
  @Input() fromPerson: any

  /**
   * Recipients
   */
  public items: Array<any>

  /**
   * Display
   */
  public display: boolean

  /**
   * First item
   */
  public firstItem: any

  /**
   * Constructor
   */
  public constructor () {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    this.display = false
    this.firstItem = {
      name: null,
      email: null
    }

    if (this.from) {
      const sp: Array<any> = this.from.split('<')
      this.firstItem = {
        name: sp[0].trim(),
        email: sp[1].replace('>', '').trim()
      }
    }

    this.display = true
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

}
