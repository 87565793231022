import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { OpportunityProcess } from '@app/modules/opportunity-process/opportunity-process.model'

export class OpportunityStage extends Base {
  uuid: string = null
  name: string = null
  opportunityProcessUuid: string = null
  opportunityProcess: OpportunityProcess = null
  displayOrder: number = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param {Partial<OpportunityStage>} data Initial values
   */
  public constructor (data?: Partial<OpportunityStage>) {
    super(data)
    this.init(data)
  }
}
