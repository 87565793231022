// Angular
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
// Other
import { Subscription } from 'rxjs'
import * as moment from 'moment'
// Services.
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html'
})

export class TaskFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Setting duration
   */
  public settingDuration: boolean

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    // this.prevEndDatetime = moment(this.mainForm.value.endDatetime).toDate()
    this.settingDuration = false
    this.watchDates()
  }

  /**
   * Watch dates
   *
   * Why dont this work?
   */
  private watchDates (): void {
    const sub: Subscription = this.mainForm.valueChanges.subscribe((data) => {
      // console.log('this.prevEndDatetime', this.prevEndDatetime)
      // console.log('data.endDatetime', data.endDatetime)
      if (data.endDatetime && data.startDatetime && (data.endDatetime < data.startDatetime)) {
        this.mainForm.patchValue({
          endDatetime: data.startDatetime
        }, { emitEvent: true })
        return
      }

      if (this.settingDuration === false) {
        this.setDuration()
      } else {
        // console.log('dates match')
      }
    })

    this.subscriptions.push(sub)
  }

  // ---------------------------------------------------------------------------
  // Duration changer
  // ---------------------------------------------------------------------------

  /**
   * Change duration
   *
   * @param {any} event Event
   */
  public changeDuration (event: any): void {
    if (!event.target.value || !this.mainForm.get('startDatetime').value) {
      return
    }

    const endDatetime: Date = moment(this.mainForm.get('startDatetime').value).add(parseInt(event.target.value, 10), 'minutes').toDate()

    this.mainForm.patchValue({
      duration: event.target.value,
      endDatetime: endDatetime
    }, { emitEvent: true })
  }

  /**
   * Set duration
   *
   * @param {any} event Event
   */
  public setDuration (): void {
    // console.log('setDuration')
    this.settingDuration = true
    if (!this.mainForm.get('startDatetime').value) {
      return
    }
    const start: any = moment(this.mainForm.get('startDatetime').value)
    const end: any = moment(this.mainForm.get('endDatetime').value)

    const diff: any = end.diff(start)
    const duration: any = moment.duration(diff).asMinutes()

    this.mainForm.patchValue({
      duration: duration
    }, { emitEvent: true })

    this.settingDuration = false
  }

  // ---------------------------------------------------------------------------
  // Display helpers
  // ---------------------------------------------------------------------------

  /**
   * Person search result formatter
   *
   * Converts the object into a readable string.
   */
  public formatPersonResult: any = (item: any): any => {
    const strParts: Array<any> = []
    const name: Array<any> = []

    // if (!this.myUtil.isEmpty(x.title)) {
    //   name.push(x.title.name)
    // }

    if (item.firstName) {
      name.push(item.firstName)
    }

    if (item.lastName) {
      name.push(item.lastName)
    }

    if (name.length > 0) {
      strParts.push(name.join(' '))
    }

    if (item.companies && item.companies.length > 0) {
      strParts.push(item.companies[0].name)
    }

    return strParts.join(', ')
  }

  /**
   * Company search result formatter
   *
   * Converts the object into a readable string.
   */
  public formatCompanyResult: any = (item: any): any => {
    const strParts: Array<any> = []
    // const name: Array<any> = []

    if (item.name) {
      strParts.push(item.name)
    }
    // const addressParts: Array<any> = []
    if (item.addresses && item.addresses.length > 0) {
      if (item.addresses[0].address1) {
        strParts.push(item.addresses[0].address1)
      }
      if (item.addresses[0].city) {
        strParts.push(item.addresses[0].city)
      }
      if (item.addresses[0].state) {
        strParts.push(item.addresses[0].state)
      }
      if (item.addresses[0].postcode) {
        strParts.push(item.addresses[0].postcode)
      }
    }

    return strParts.join(', ')
  }

}
