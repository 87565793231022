import { Router } from '@angular/router'
import { Component, Input, OnInit, OnDestroy
  // ViewChild,
  // ElementRef
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { AppService } from '@app/shared/services/app.service'
import { AlertService } from '@app/modules/alert/alert.service'
// import { EventService } from '@app/shared/services/event.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import * as moment from 'moment'
import { Task } from '@app/modules/task/task.model'
import { TaskService } from '@app/modules/task/task.service'
import { TaskFormService } from '@app/modules/task/task-form.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { OpportunityService } from '@app/modules/opportunity/opportunity.service'
import { TaskType } from '@app/modules/task-type'

@Component({
  selector: 'app-tab-activities-table',
  templateUrl: './tab-activities-table.component.html'
})

export class TabActivitiesTableComponent implements OnInit, OnDestroy {

  /**
   * Tasks
   */
  public tasks: Array<Task>

  /**
   * Opportunity uuid
   */
  @Input() opportunityUuid: string

  /**
   * Person uuid
   */
  @Input() personUuid: string

  // @ViewChild('taskModal') private taskModal: ElementRef // TemplateRef

  /**
   * Display page
   */
  public display: boolean

  /**
   * Task types
   */
  public taskTypes: Array<any>

  /**
   * Task form
   */
  public taskForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Constructor
   */
  public constructor (
    private alertService: AlertService,
    public router: Router,
    public appService: AppService,
    // private eventService: EventService,
    private modalService: NgbModal,
    public taskService: TaskService,
    private taskFormService: TaskFormService,
    public validationService: ValidationService,
    public selectOptionsService: SelectOptionsService,
    private opportunityService: OpportunityService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    if (this.personUuid) {
      this.taskService.api.getMany({
        limit: 50,
        sort: [
          {
            key: 'startDatetime',
            direction: 'DESC'
          }
        ],
        filter_groups: [
          {
            or: false,
            filters: [
              {
                key: 'organiserUserUuid',
                value: this.personUuid,
                operator: 'eq'
              }
              // {
              //   key: 'isComplete',
              //   value: false,
              //   operator: 'eq'
              // }
            ]
          }
        ],
        includes: [
          'taskType',
          'taskPriority',
          'assignedToUser',
          'scheduledWith',
          'associatedWith',
          'organiserUser'
        ]
      })
      .toPromise()
      .then((results) => {
        this.tasks = results
        this.display = true
      })
      .catch((err) => {
        throw err
      })
    }

    if (this.opportunityUuid) {
      this.opportunityService.api.getTasks(this.opportunityUuid, {
        includes: [
          'taskType',
          'organiserUser'
        ]
      })
      .toPromise()
      .then((results) => {
        this.tasks = results
        this.display = true
      })
      .catch((err) => {
        throw err
      })
    }
  }

  /**
   * Open modal
   *
   * @param event        Event
   * @param content      Modal template
   * @param type         Type of modal
   * @param data         Data to apply to form
   * @param taskTypeName Task type name to preset
   */
  public openModal (event: Event, content: any, type: string, data?: any, taskTypeName?: string): void {
    console.log('openModal', data)
    console.log('type', type)

    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (type === 'newTask') {
      const task: Task = new Task()
      let taskType: TaskType
      if (taskTypeName) {
        taskType = this.selectOptionsService.taskTypeOptions.find(x => x.name === taskTypeName)
      } else {
        taskType = this.selectOptionsService.taskTypeOptions.find(x => x.name === 'Call')
      }

      if (taskType) {
        task.taskTypeUuid = taskType.uuid
      }

      const startDatetime: Date = moment().toDate()
      // moment(startDatetime).add(60, 'minutes').toDate()
      const endDatetime: Date = startDatetime
      task.startDatetime = startDatetime
      task.endDatetime = endDatetime

      if (this.personUuid) {
        task.organiserUserUuid = this.personUuid
      } else {
        task.organiserUserUuid = this.appService.currentUser.uuid
      }

      const taskPriority: any = this.selectOptionsService.taskPriorityOptions.find(x => x.name === 'Medium')
      task.taskPriorityUuid = taskPriority.uuid

      this.taskForm = this.taskFormService.getForm(task, 'new')
    }

    if (type === 'editTask') {
      const task: Task = new Task(data)
      this.taskForm = this.taskFormService.getForm(task, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })

    // Trying a jquery hack to fix the css.
    if (type === 'editTask') {
      setTimeout(() => {
        $('body').addClass('modal-open')
        $('ngb-modal-backdrop').addClass('modal-backdrop fade show')
        $('ngb-modal-window').addClass('modal fade show d-block fade modal-xl')
        $('div[role="document"]').addClass('modal-dialog modal-lg')
      }, 500)
    }
  }

  /**
   * Save task
   */
  public saveTask (): void {
    console.log('saveTask', this.taskForm.value)
    this.validationService.runValidation(this.taskForm)

    if (!this.taskForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.taskForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    if (this.opportunityUuid) {
      this.opportunityService.api.saveTask(this.opportunityUuid, this.taskForm.value)
      .toPromise()
      .then(() => {
        this.alertService.success('Saved: Task', 10000)
        this.currentModal.close()
        this.ngOnInit()
      })
      .catch((err) => {
        console.error(err)
        this.alertService.error('Error: ' + err.message)
      })
    }

    if (this.personUuid) {
      this.taskService.saveTask(this.taskForm.value)
      .then(() => {
        this.alertService.success('Saved: Task', 10000)
        this.currentModal.close()
        this.ngOnInit()
      })
      .catch((err) => {
        console.error(err)
        this.alertService.error('Error: ' + err.message)
      })
    }
  }
}
