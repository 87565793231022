import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { Person } from '@app/modules/person/person.model'

export class EmailMessage extends Base {
  uuid: string = null
  msid: string = null
  changeKey: string = null
  recivedDateTime: Date = null
  sentDateTime: Date = null
  hasAttachments: boolean = null
  internetMessageId: string = null
  internetMessageHeaders: string = null
  subject: string = null
  body: string = null
  importance: string = null
  parentFolderId: string = null
  sender: string = null
  from: string = null
  toRecipients: string = null
  ccRecipients: string = null
  bccRecipients: string = null
  replyTo: string = null
  conversationId: string = null
  isDeliveryRecieptRequested: boolean = null
  isReadRecieptRequested: boolean = null
  isRead: boolean = null
  isDraft: boolean = null
  webLink: string = null
  inferanceClassification: string = null
  flag: string = null
  attachments: string = null
  toPersonUuid: string = null
  fromPersonUuid: string = null
  opportunityUuid: string = null
  isAlllocated: boolean = null
  isIgnored: boolean = null
  isSpam: boolean = null
  isSent: boolean = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null
  // Relations.
  opportunity: Opportunity = null
  fromPerson: Person = null
  to: Array<Person> = []
  cc: Array<Person> = []
  bcc: Array<Person> = []

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt',
    'recivedDateTime',
    'sentDateTime'
  ]

  /**
   * Constructor
   *
   * @param {Partial<EmailMessage>} data Initial values
   */
  public constructor (data?: Partial<EmailMessage>) {
    super(data)
    this.init(data)
  }
}
