import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { QueryBuilderApiService } from '@app/modules/query-builder/services/query-builder-api.service'
import { QueryItem } from '@app/modules/query-builder/query-item.model'
import { ChangeCasePipe } from '@app/shared/pipes/change-case.pipe'
import { QueryBuilderService } from '../../services'
import { v4 as uuidv4 } from 'uuid'

@Component({
  selector: 'app-table-join-selector',
  templateUrl: './table-join-selector.component.html'
})

export class TableJoinSelectorComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() first: boolean

  /**
   * Parent query item
   *
   * @type {QueryItem}
   */
  @Input() parentQueryItem: QueryItem

  /**
   * Parent query item change
   *
   * @type {EventEmitter<QueryItem>}
   */
  @Output() parentQueryItemChange: EventEmitter<QueryItem> = new EventEmitter<QueryItem>()

  /**
   * Foreign keys
   */
  @Input() fks: Array<any> = []

  /**
   * Constructor
   */
  public constructor (
    private queryBuilderApiService: QueryBuilderApiService,
    private queryBuilderService: QueryBuilderService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.display = true
  }

  /**
   * Add join
   */
  public addJoin (queryItem: QueryItem): void {
    const newQueryItem: QueryItem = new QueryItem({
      uuid: uuidv4()
    })
    queryItem.joins.push(newQueryItem)
  }

  /**
   * Remove join
   *
   * @param {QueryItem} queryItem Query item
   */
  public removeJoin (queryItem: QueryItem, parentQueryItem: QueryItem): void {
    const index: number = parentQueryItem.joins.findIndex(x => x.uuid === queryItem.uuid)
    if (index !== -1) {
      parentQueryItem.joins.splice(index, 1)
    }
  }

  /**
   * Change join
   *
   * @param {QueryItem} queryItem Query item
   */
  public changeJoin (queryItem: QueryItem, parentQueryItem: QueryItem): void {
    if (!queryItem.refTableName) {
      return
    }

    console.log('queryItem.refTableName', queryItem.refTableName)
    const item: QueryItem = parentQueryItem.joinsAvailable.find(x => x.refTableName === queryItem.refTableName)

    // Copy the join params to the join QueryItem
    Object.assign(queryItem, {
      uuid: item.uuid,
      tableName: item.tableName,
      columnName: item.columnName,
      refTableName: item.refTableName,
      refColumnName: item.refColumnName,
      fkName: item.fkName,
      joinType: item.joinType,
      relationType: item.relationType
    })

    this.getColumnsAvailable(queryItem)
    queryItem.joinsAvailable = this.getJoinsAvailable(queryItem)
  }

  /**
   * Get columns available
   *
   * @param refTableName Table name
   */
  private getColumnsAvailable (queryItem: QueryItem): void {
    const index: number = this.queryBuilderService.columnsAvailable.findIndex(x => x.column === queryItem.refTableName + '.*')
    if (index !== -1) {
      return
    }

    if (queryItem.relationType === 'oneToMany') {
      return
    }

    this.getColumns(queryItem.refTableName)
    .then((results) => {
      // Add a select all.
      this.queryBuilderService.columnsAvailable.push({
        column: queryItem.refTableName + '.*',
        columnType: '*',
        displayName: new ChangeCasePipe().transform(queryItem.refTableName, 'titleCase') + ' (*)'
      })

      // For each column available add a name etc.
      results.map((col) => {
        if (
          col.Field.indexOf('tmp_') === -1
          && col.Field.indexOf('manual') === -1
          && col.Field.indexOf('new_job_no') === -1
          && col.Field.indexOf('job_no_rev') === -1
          && col.Field.indexOf('new_proposal_no') === -1
          && col.Field.indexOf('proposal_no_rev') === -1
          ) {
          this.queryBuilderService.columnsAvailable.push({
            column: queryItem.refTableName + '.' + col.Field,
            columnType: this.getColumnType(col.Type),
            displayName: new ChangeCasePipe().transform(queryItem.refTableName, 'titleCase')
            + ' (' + new ChangeCasePipe().transform(col.Field, 'titleCase') + ')'
          })
        }
      })

      this.queryBuilderService.setWhereColumnsAvailable()
    })
  }

  /**
   * Get column type
   *
   * @param colType Column type
   *
   * @returns {string}
   */
  private getColumnType (colType: string): string {
    // Booleans.
    if (colType.indexOf('tinyint') === 0) {
      return 'boolean'
    }

    if (colType.indexOf('int(1)') === 0) {
      return 'boolean'
    }

    // Numbers.
    if (colType.indexOf('int') !== -1) {
      return 'number'
    }

    if (colType.indexOf('decimal') !== -1) {
      return 'number'
    }

    if (colType.indexOf('float') !== -1) {
      return 'number'
    }

    // Strings.
    if (colType.indexOf('varchar') !== -1) {
      return 'string'
    }
    if (colType.indexOf('text') !== -1) {
      return 'string'
    }

    // Dates.
    if (colType.indexOf('timestamp') !== -1) {
      return 'date'
    }

    if (colType.indexOf('datetime') !== -1) {
      return 'date'
    }
    return 'string'
  }

  /**
   * Get joins available
   *
   * @param tableName Table name
   *
   * @returns {string}
   */
  private getJoinsAvailable (queryItem: QueryItem): Array<QueryItem> {
    const joinsAvailable: Array<QueryItem> = []

    this.fks.map((x) => {
      if (x.REFERENCED_TABLE_NAME === queryItem.refTableName) {
        const newQueryItem: QueryItem = new QueryItem({
          uuid: uuidv4(),
          displayName: new ChangeCasePipe().transform(x.TABLE_NAME, 'titleCase') +
          ' (' + new ChangeCasePipe().transform(x.COLUMN_NAME, 'titleCase') + ')',
          refTableName: x.TABLE_NAME,
          refColumnName: x.COLUMN_NAME,
          fkName: x.CONSTRAINT_NAME,
          tableName: x.REFERENCED_TABLE_NAME,
          columnName: x.REFERENCED_COLUMN_NAME,
          joinType: 'LEFT',
          relationType: 'oneToMany',
          joins: [],
          joinsAvailable: []
        })
        joinsAvailable.push(newQueryItem)
      }

      if (x.TABLE_NAME === queryItem.refTableName) {
        const newQueryItem: QueryItem = new QueryItem({
          uuid: uuidv4(),
          displayName: new ChangeCasePipe().transform(x.REFERENCED_TABLE_NAME, 'titleCase') +
          ' (' + new ChangeCasePipe().transform(x.COLUMN_NAME, 'titleCase') + ')',
          refTableName: x.REFERENCED_TABLE_NAME,
          refColumnName: x.REFERENCED_COLUMN_NAME,
          fkName: x.CONSTRAINT_NAME,
          tableName: x.TABLE_NAME,
          columnName: x.COLUMN_NAME,
          joinType: 'LEFT',
          relationType: 'oneToOne',
          joins: [],
          joinsAvailable: []
        })

        joinsAvailable.push(newQueryItem)
      }
    })

    return joinsAvailable
  }

  /**
   * Get columns
   *
   * @param table Table name
   */
  private getColumns (table: string): Promise<any> {
    const data: any = {
      table: table
    }
    return this.queryBuilderApiService.columns(data)
    .toPromise()
    .then((results) => {
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

}
