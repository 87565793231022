import { Component, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
// import { Subscription } from 'rxjs'
// import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { OperatorSearchModel } from './operator-search.model'

@Component({
  selector: 'app-operator-search',
  templateUrl: './operator-search.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OperatorSearchComponent,
    multi: true
  }]
})

export class OperatorSearchComponent implements ControlValueAccessor {

  @Input() operatorFormControlName: any

  @Input() fieldFormControlName: any

  public onChange: any

  public operatorSearch: OperatorSearchModel

  /**
   * Constructor
   */
  public constructor (
    public selectOptionsService: SelectOptionsService
  ) {
    console.log('operatorFormControlName', this.operatorFormControlName)
    console.log('fieldFormControlName', this.fieldFormControlName)
  }

  public writeValue (value: any): void {
    console.log('writeValue called with:', value)
    if (value) {
      this.operatorSearch = value
    }
  }

  public registerOnChange (fn: any): void {
    this.onChange = (): any => {
      console.log('Propagating change')
      fn(this.operatorSearch)
    }
  }

  public registerOnTouched (fn: any): void {
    if (fn) {}
  }
}
