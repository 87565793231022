import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '@app/modules/auth/services'

@Injectable()

export class AuthGuard implements CanActivate {

  /**
   * Constructor
   */
  constructor (
    private router: Router,
    private authService: AuthService
  ) {}

  /**
   * Can deactivate
   *
   * @param {ComponentCanDeactivate} component Component
   *
   * @return {boolean}
   */
  public canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route) {}
    if (this.authService.isAuthenticated()) {
      // Logged in so return true.
      return true
    }

    // Not logged in so redirect to login page with the return url.
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
    return false
  }

}
