// Angular
import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-my-form-info',
  template: `<pre class="debug">{{ form.value | json }}</pre>`
})

export class MyFormInfoComponent {
  @Input() form: FormGroup
}
