// Angular
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
// Services.
import { ValidationService } from '@app/shared/services/validation.service'
import { ConfigService, FileNavigatorService, FmService, ApiMiddlewareService } from '../../services'

@Component({
  selector: 'app-fm-upload-files-form',
  templateUrl: './fm-upload-files-form.component.html'
})

export class FmUploadFilesFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  // public uploader:FileUploader = new FileUploader({url: URL});
  public hasBaseDropZoneOver: boolean
  public hasAnotherDropZoneOver: boolean

  // public uploader:FileUploader = new FileUploader({
  //   url: URL
  // });

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Constructor
   */
  public constructor (
    public validationService: ValidationService,
    public configService: ConfigService,
    public fmService: FmService,
    public fileNavigatorService: FileNavigatorService,
    public apiMiddlewareService: ApiMiddlewareService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.hasBaseDropZoneOver = false
    this.hasAnotherDropZoneOver = false
    this.display = true
  }

  public fileOverBase (e: any): void {
    this.hasBaseDropZoneOver = e
  }

  public fileOverAnother (e: any): void {
    this.hasAnotherDropZoneOver = e
  }
}
