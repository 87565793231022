// Angular
import { Router } from '@angular/router'
import { Component, Input, OnInit, OnDestroy } from '@angular/core'
// Services.
import { AppService } from '@app/shared/services/app.service'
// import { Opportunity } from '@app/modules/opportunity/opportunity.model'

@Component({
  selector: 'app-tab-revision-table',
  templateUrl: './tab-revision-table.component.html'
})

export class TabRevisionTableComponent implements OnInit, OnDestroy {

  /**
   * Revisions
   */
  public revisions: Array<any>

  /**
   * Api service
   */
  @Input() apiService: any

  /**
   * Results
   */
  @Input() primaryKey: Array<any>

  /**
   * Display page
   */
  public display: boolean

  /**
   * Pagination
   */
  public pagination: any | Object = {
    currentPage: 1,
    pageSize: 20,
    currentPageSize: 0,
    totalItems: 0
  }

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    // Services.
    public appService: AppService
  ) {
    this.revisions = []
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.getRevisions()

    this.display = true
  }

  /**
   * Get revisions
   */
  public getRevisions (): Promise<any> {

    const params: any | Object = {
      // sort: [
      //   {
      //     key: this.sort.column,
      //     direction: this.sort.direction
      //   }
      // ],
      // filter_groups: filterGroups,
      fields: [
        'uuid'
      ]
    }

    // Get the total results.
    return this.apiService.getRevisions(this.primaryKey, params)
    .toPromise()
    .then((totalResults) => {
      this.pagination.totalItems = totalResults.length

      // Add pagination and includes for result set.
      params.page = (this.pagination.currentPage - 1)
      params.limit = this.pagination.pageSize
      // params.includes = [
      //   'person',
      //   'roles'
      // ]
      delete params.fields

      return this.apiService.getRevisions(this.primaryKey, params)
      .toPromise()
      .then((results) => {
        this.revisions = results
        return results
      })
      .catch((err) => {
        throw err
      })

    })
    .catch((err) => {
      console.error(err)
    })

    // if (this.primaryKey) {
    //   this.apiService.getRevisions(this.primaryKey)
    //   .toPromise()
    //   .then((results) => {
    //     this.revisions = results
    //     return results
    //   })
    //   .catch((err) => {
    //     throw err
    //   })
    // }
  }

}
