import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { Person } from '@app/modules/person/person.model'
import { Address } from '@app/modules/address/address.model'
import { EmailAddress } from '@app/modules/email-address/email-address.model'
import { Social } from '@app/modules/social/social.model'
import { Telephone } from '@app/modules/telephone/telephone.model'
import { Uri } from '@app/modules/uri/uri.model'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { DeleteId } from '@app/modules/delete-id/delete-id.model'
export class Company extends Base {
  uuid: string = null
  companyTypeUuid: string = null
  suppliesWhatUuid: string = null
  name: string = null
  regNumber: string = null
  vatNumber: string = null
  addresses: Array<Address> = []
  emailAddresses: Array<EmailAddress> = []
  socials: Array<Social> = []
  telephones: Array<Telephone> = []
  uris: Array<Uri> = []
  people: Array<Person> = []
  opportunities: Array<Opportunity> = []
  personToCompany: any
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null
  // ---------------------------------------------------------------------------
  // Keep track of pivot items we want to delete when they are removed
  // from the form.
  addressesDeleted: Array<DeleteId> = []
  emailAddressesDeleted: Array<DeleteId> = []
  socialsDeleted: Array<DeleteId> = []
  telephonesDeleted: Array<DeleteId> = []
  urisDeleted: Array<DeleteId> = []
  tasksDeleted: Array<DeleteId> = []
  peopleDeleted: Array<DeleteId> = []
  opportunitiesDeleted: Array<DeleteId> = []

  /**
   * Properties with type Date
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt'
  ]

  /**
   * Constructor
   *
   * @param data Initial values
   */
  public constructor (data?: Partial<Company>) {
    super(data)
    this.init(data)
  }
}
