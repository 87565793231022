import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { NgbDateStruct, NgbDatepickerConfig, NgbPopover } from '@ng-bootstrap/ng-bootstrap'

import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

@Component({
  selector: 'app-my-date-picker',
  templateUrl: './my-date-picker.component.html',
  providers: []
})

export class MyDatePickerComponent implements OnInit, OnDestroy {

  /**
   * Element id
   */
  @Input() id: string

  /**
   * Element id
   */
  @Input() placeholder: string

  /**
   * Form control name
   */
  @Input() fcName: string

  /**
   * Size
   */
  @Input() size: string

  /**
   * Size
   */
  @Input() doneButton: boolean

  /**
   * Form group
   */
  @Input() fg: FormGroup

  /**
   * Form group change
   */
  @Output() fgChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Size
   */
  @Input() placement: string

  @ViewChild('myPopover') public popover: NgbPopover

  /**
   * Form control
   */
  public fc: FormControl

  /**
   * Model
   *
   * Use this for the component then apply the changes to the
   * form control.
   *
   * @type {NgbDateStruct}
   */
  public model: NgbDateStruct

  public isOpen: boolean
  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public config: NgbDatepickerConfig
  ) {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    console.log('ngOnInit')
    this.isOpen = false
    if (!this.placement) {
      this. placement = 'bottom'
    }
    if (!this.doneButton) {
      this.doneButton = false
    }

    this.doneButton = true
    this.fc = this.fg.get(this.fcName) as FormControl
    this.updateModel(this.fc)
    this.watchFormControl()
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * Update model
   */
  public updateModel (fc: FormControl): void {

    if (fc && fc.value) {
      const date: Date = fc.value

      const year: any = date.getFullYear()
      const month: any = date.getMonth() + 1
      const day: any = date.getDate()

      this.model = {
        year: year,
        month: month,
        day: day
      }

      this.config.startDate = this.model
    } else {
      this.model = null
    }
  }

  /**
   * Watch form control
   */
  public watchFormControl (): void {
    const sub: Subscription = this.fc
    .valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged()
    )
    .subscribe(() => {
      // If the forms been cleared we need to set the model to null.
      if (!this.fc.value) {
        this.model = null
      }

      if (this.fc.value) {
        this.updateModel(this.fc)
      }
    })
    this.subscriptions.push(sub)
  }

  /**
   * Model changed
   *
   * @param {NgbDateStruct} date Time
   */
  public modelChanged (dt: NgbDateStruct): void {
    console.log('modelChanged')
    const newDate: any = {}
    if (this.fc.value && dt) {
      const date: Date = this.fg.get(this.fcName).value
      date.setFullYear(dt.year)
      date.setMonth(dt.month - 1)
      date.setDate(dt.day)

      newDate[this.fcName] = date

      this.fg.patchValue(newDate)
      this.fgChange.emit(this.fg)
    }

    if (!this.fc.value && dt) {
      const date: Date = new Date()
      date.setFullYear(dt.year)
      date.setMonth(dt.month - 1)
      date.setDate(dt.day)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)

      newDate[this.fcName] = date

      this.fg.patchValue(newDate)
      this.fgChange.emit(this.fg)
    }

    if (!dt) {
      newDate[this.fcName] = null

      this.fg.patchValue(newDate)
      this.fgChange.emit(this.fg)
    }

    // if (!this.doneButton) {
    //   if (this.isOpen) {
    //     this.hide()
    //   }
    // }
  }

  // cant get this woking... ?
  public toggle () {
    if (this.isOpen) {
      this.hide()
    } else {
      this.show()
    }
  }

  public show () {
    this.popover.open()
    this.isOpen = true
  }

  public hide () {
    this.popover.close()
    this.isOpen = false
  }
}
