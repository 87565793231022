import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { PersonFormService } from '@app/modules/person/person-form.service'
import { OpportunityContact } from '@app/modules/opportunity-contact/opportunity-contact.model'

// Using people here is a circular dep!

@Injectable()

export class OpportunityContactFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      opportunityUuid: [
        ValidationService.isUuid
      ]
    },
    {
      personUuid: [
        ValidationService.isUuid
      ]
    },
    {
      displayOrder: [
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private personFormService: PersonFormService
  ) {}

  /**
   * Get form
   */
  public getForm (opportunityContact: OpportunityContact, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      personUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      isMain: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      displayOrder: [
        null,
        [
          ValidationService.isNumeric
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid

        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (opportunityContact.person) {
      form.addControl('person', this.personFormService.getForm(opportunityContact.person, ''))
    }

    // Patch the form.
    if (opportunityContact) {
      form.patchValue(opportunityContact, { emitEvent: false })
    }

    return form
  }

}
