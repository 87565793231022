import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
// import { ChangeCasePipe } from '@app/shared/pipes/change-case.pipe'
import { QueryItem } from '@app/modules/query-builder/query-item.model'
import { ChangeCasePipe } from '@app/shared/pipes/change-case.pipe'
import { QueryBuilderApiService } from '@app/modules/query-builder/services/query-builder-api.service'
import { v4 as uuidv4 } from 'uuid'

@Injectable()

export class QueryBuilderService implements OnDestroy {

  /**
   * Foreign keys
   */
  public fks: Array<any> = []

  /**
   * First query item
   *
   * The top level of the query
   */
  public firstQueryItem: QueryItem

  /**
   * Columns available
   *
   * The columns available depending on tables linked.
   */
  public columnsAvailable: Array<any> = []

  /**
   * Columns available
   *
   * For selects.
   */
  public $columnsAvailable: BehaviorSubject<Array<any>> = new BehaviorSubject([])

  /**
   * Where columns available
   *
   * The columns available for where clauses
   */
  public whereColumnsAvailable: Array<any> = []

  /**
   * Where columns available
   *
   * For filters.
   */
  public $whereColumnsAvailable: BehaviorSubject<Array<any>> = new BehaviorSubject([])

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public api: QueryBuilderApiService
  ) {
    this.watchColumnsAvailable()
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * Watch columns available
   */
  public watchColumnsAvailable (): void {
    const sub: Subscription = this.$columnsAvailable
    .subscribe((data) => {
      if (data) {}
      this.setWhereColumnsAvailable()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Set where columns available
   */
  public setWhereColumnsAvailable (): void {
    const arr: Array<any> = []
    this.columnsAvailable.map((col) => {
      if (col.column.indexOf('*') === -1) {

        const where: any = {
          uuid: uuidv4(),
          displayName: col.displayName,
          column: col.column,
          operator: 'equal',
          columnType: col.columnType,
          value: col.columnType === 'boolean' ? true : null,
          value2: null
        }

        arr.push(where)

      }
    })
    this.whereColumnsAvailable = arr
  }

  /**
   * Change join
   */
  public changeJoin (queryItem: QueryItem, parentQueryItem: QueryItem): Promise<any> {
    if (!queryItem.refTableName) {
      return Promise.resolve(null)
    }
    queryItem.joinsAvailable = this.getJoinsAvailable(queryItem)

    const item: QueryItem = parentQueryItem.joinsAvailable.find(x => x.refTableName === queryItem.refTableName)

    console.log('queryItem.refTableName', queryItem.refTableName)
    console.log('parentQueryItem', parentQueryItem)

    if (item) {
      // Copy the join params to the join QueryItem
      Object.assign(queryItem, {
        uuid: item.uuid,
        tableName: item.tableName,
        columnName: item.columnName,
        refTableName: item.refTableName,
        refColumnName: item.refColumnName,
        fkName: item.fkName,
        joinType: item.joinType,
        relationType: item.relationType
      })
      // const promises: Array<Promise<any>> = []
    }

    return this.getColumnsAvailable(queryItem)
  }

  /**
   * Get columns available
   */
  private getColumnsAvailable (queryItem: QueryItem): Promise<any> {
    const index: number = this.columnsAvailable.findIndex(x => x.column === queryItem.refTableName + '.*')
    if (index !== -1) {
      return Promise.resolve(null)
    }

    if (queryItem.relationType === 'oneToMany') {
      return Promise.resolve(null)
    }

    return this.getColumns(queryItem.refTableName)
    .then((results) => {
      // Add a select all.
      this.columnsAvailable.push({
        column: queryItem.refTableName + '.*',
        columnType: '*',
        displayName: new ChangeCasePipe().transform(queryItem.refTableName, 'titleCase') + ' (*)'
      })

      // For each column available add a name etc.
      results.map((col) => {
        if (
          col.Field.indexOf('tmp_') === -1
          && col.Field.indexOf('manual') === -1
          && col.Field.indexOf('new_job_no') === -1
          && col.Field.indexOf('job_no_rev') === -1
          && col.Field.indexOf('new_proposal_no') === -1
          && col.Field.indexOf('proposal_no_rev') === -1
          ) {
          this.columnsAvailable.push({
            column: queryItem.refTableName + '.' + col.Field,
            columnType: this.getColumnType(col.Type),
            displayName: new ChangeCasePipe().transform(queryItem.refTableName, 'titleCase')
            + ' (' + new ChangeCasePipe().transform(col.Field, 'titleCase') + ')'
          })
        }
      })

      this.setWhereColumnsAvailable()

      return this.columnsAvailable
    })
  }

  /**
   * Get column type
   */
  private getColumnType (colType: string): string {
    // Booleans.
    if (colType.indexOf('tinyint') === 0) {
      return 'boolean'
    }

    if (colType.indexOf('int(1)') === 0) {
      return 'boolean'
    }

    // Numbers.
    if (colType.indexOf('int') !== -1) {
      return 'number'
    }

    if (colType.indexOf('decimal') !== -1) {
      return 'number'
    }

    if (colType.indexOf('float') !== -1) {
      return 'number'
    }

    // Strings.
    if (colType.indexOf('varchar') !== -1) {
      return 'string'
    }
    if (colType.indexOf('text') !== -1) {
      return 'string'
    }

    // Dates.
    if (colType.indexOf('timestamp') !== -1) {
      return 'date'
    }

    if (colType.indexOf('datetime') !== -1) {
      return 'date'
    }
    return 'string'
  }

  /**
   * Get joins available
   */
  public getJoinsAvailable (queryItem: QueryItem): Array<QueryItem> {
    const joinsAvailable: Array<QueryItem> = []

    this.fks.map((x) => {
      if (x.REFERENCED_TABLE_NAME === queryItem.refTableName) {
        const newQueryItem: QueryItem = new QueryItem({
          uuid: uuidv4(),
          displayName: new ChangeCasePipe().transform(x.TABLE_NAME, 'titleCase') +
          ' (' + new ChangeCasePipe().transform(x.COLUMN_NAME, 'titleCase') + ')',
          refTableName: x.TABLE_NAME,
          refColumnName: x.COLUMN_NAME,
          fkName: x.CONSTRAINT_NAME,
          tableName: x.REFERENCED_TABLE_NAME,
          columnName: x.REFERENCED_COLUMN_NAME,
          joinType: 'LEFT',
          relationType: 'oneToMany',
          joins: [],
          joinsAvailable: []
        })
        joinsAvailable.push(newQueryItem)
      }

      if (x.TABLE_NAME === queryItem.refTableName) {
        const newQueryItem: QueryItem = new QueryItem({
          uuid: uuidv4(),
          displayName: new ChangeCasePipe().transform(x.REFERENCED_TABLE_NAME, 'titleCase') +
          ' (' + new ChangeCasePipe().transform(x.COLUMN_NAME, 'titleCase') + ')',
          refTableName: x.REFERENCED_TABLE_NAME,
          refColumnName: x.REFERENCED_COLUMN_NAME,
          fkName: x.CONSTRAINT_NAME,
          tableName: x.TABLE_NAME,
          columnName: x.COLUMN_NAME,
          joinType: 'LEFT',
          relationType: 'oneToOne',
          joins: [],
          joinsAvailable: []
        })

        joinsAvailable.push(newQueryItem)
      }
    })

    return joinsAvailable
  }

  /**
   * Get columns
   */
  private getColumns (table: string): Promise<any> {
    const data: any = {
      table: table
    }
    return this.api.columns(data)
    .toPromise()
    .then((results) => {
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Change where
   */
  public changeWhere (where: any, loading?: boolean): Promise<any> {

    const availableWhere: any = this.whereColumnsAvailable.find(x => x.column === where.column)
    if (!availableWhere) {
      console.error('could not find it')
      return Promise.resolve(null)
    }

    console.log('availableWhere', availableWhere)

    if (availableWhere) {
      where.displayName = availableWhere.displayName
      where.columnType = availableWhere.columnType

      if (!loading) {
        where.operator = 'equal'
        where.value = where.columnType === 'boolean' ? true : null
        where.value2 = null
      }
    }

    const columnJoins: Array<string> = availableWhere.column.split('.')

    if (columnJoins.length === 2) {
      where.optionColumnName = columnJoins[1]
      return this.api.options({
        table: columnJoins[0]
      })
      .toPromise()
      .then((r) => {
        where.options = r
        return where
      })
    }

    return Promise.resolve(where)
  }

}
