import { Component, OnInit, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as moment from 'moment'
import { AppService } from '@app/shared/services/app.service'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { CalendarComponent } from 'ap-angular-fullcalendar'
import { Task } from '@app/modules/task/task.model'
import { TaskService } from '@app/modules/task/task.service'
import { TaskFormService } from '@app/modules/task/task-form.service'

declare var $: any

@Component({
  selector: 'app-view-calendar',
  templateUrl: './view-calendar.component.html'
})

export class ViewCalendarComponent implements OnInit, OnDestroy {

  /**
   * My calendar
   */
  @ViewChild(CalendarComponent) myCalendar: CalendarComponent

  @ViewChild('taskModal') private taskModal: ElementRef // TemplateRef

  /**
   * Display page
   */
  public display: boolean

  /**
   * Calendar options
   */
  public calendarOptions: any

  /**
   * Events
   */
  public events: any

  /**
   * Event sources
   */
  public eventSources: Array<any>

  /**
   * Task types
   */
  public taskTypes: Array<any>

  /**
   * Search form
   */
  public taskForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Show for user uuids
   */
  public showForUserUuid: string

  /**
   * Show for user uuids
   *
   * @todo this won work with arrays to build a  WHERE IN clause, the API is failing.
   */
  public showForUserUuids: Array<string> = []

  /**
   * Constructor
   */
  public constructor (
    // Services.
    private appService: AppService,
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    // Model services.
    public taskService: TaskService,
    private taskFormService: TaskFormService,
    private modalService: NgbModal,
    private ngZone: NgZone
    // private router: Router
  ) {
    // this.appService = appService
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.selectOptionsService.init(false)

    // this.showForUserUuids.push(this.appService.currentUser.uuid)
    this.showForUserUuid = this.appService.currentUser.uuid

    console.log(this.showForUserUuids)

    this.selectOptionsService.getTaskTypeOptions()
    .then(() => {
      this.taskTypes = [
      {
        uuid: this.selectOptionsService.taskTypeOptions.find(x => x.name === 'Call').uuid,
        name: 'Call', // 997FF6AA-620A-4D8C-94B6-2F73F866706D
        show: true,
        backgroundColor: '#4EA735',
        borderColor: '#4EA735',
        textColor: '#FFFFFF',
        iconClass: 'fa fa-phone',
        eventSource: null
      },
      {
        uuid: this.selectOptionsService.taskTypeOptions.find(x => x.name === 'Meeting').uuid,
        name: 'Meeting', // 9AEBEC60-B816-492D-BE32-EF09497755D1
        show: true,
        backgroundColor: '#2076D4',
        borderColor: '#2076D4',
        textColor: '#FFFFFF',
        iconClass: 'fa fa-coffee',
        eventSource: null
      },
      {
        uuid: this.selectOptionsService.taskTypeOptions.find(x => x.name === 'To-do').uuid,
        name: 'To-do', // BDB13A9C-F95B-4E31-858C-CC111001071E
        show: true,
        backgroundColor: '#BABABA',
        borderColor: '#BABABA',
        textColor: '#FFFFFF',
        iconClass: 'fa fa-check-square-o',
        eventSource: null
      },
      {
        uuid: this.selectOptionsService.taskTypeOptions.find(x => x.name === 'Personal Activity').uuid,
        name: 'Personal Activity', // DEA48C62-F5B6-446F-A97C-132432620D42
        show: true,
        backgroundColor: '#FD8C25',
        borderColor: '#FD8C25',
        textColor: '#FFFFFF',
        iconClass: 'fa fa-user-circle-o',
        eventSource: null
      },
      {
        uuid: this.selectOptionsService.taskTypeOptions.find(x => x.name === 'Conference').uuid,
        name: 'Conference', // D0E8D25D-5149-46BC-813A-49E9F359E2F7
        show: true,
        backgroundColor: '#B978DF',
        borderColor: '#B978DF',
        textColor: '#FFFFFF',
        iconClass: 'fa fa-clock-o',
        eventSource: null
      },
      {
        uuid: this.selectOptionsService.taskTypeOptions.find(x => x.name === 'Vacation').uuid, // 835EE263-7991-405B-94DB-74D4A1806968
        name: 'Vacation',
        show: true,
        backgroundColor: '#FEC930',
        borderColor: '#FEC930',
        textColor: '#FFFFFF',
        iconClass: 'fa fa-plane',
        eventSource: null
      }
    ]
    this.calendarOptions = this.getCalendarOptions()
    this.display = true
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get event sources
   */
  public getEventSources (): Array<any> {
    const eventSources: Array<any> = []

    this.taskTypes.map((taskType) => {
      const src: any = {
        id: taskType.uuid,
        events: (start: any, end, timezone, callback): any => {
          this.getEvents(start, end, timezone, callback, taskType.uuid)
        },
        eventDataTransform: (data): any => {
          data.title = data.regarding
          data.start = data.startDatetime
          data.end = data.endDatetime
          // Add con class.
          data.iconClass = taskType.iconClass
          data.backgroundColor = taskType.backgroundColor
          data.borderColor = taskType.borderColor
          data.textColor = taskType.textColor
          // data.url = '/#/clients/' + data.clientId
          // Get service shortnames and use as titles.
          // if (data.services && data.services.length > 0) {
          //   const serviceShortNames: Array<any> = []
          //   data.services.map(service => {
          //     serviceShortNames.push(service.shortName)
          //   })
          //   data.title = serviceShortNames.join(', ')
          // }
          return data
        }
      }
      eventSources.push(src)
      taskType.eventSource = src
    })

    return eventSources
  }

  /**
   * Get Calendar Options
   *
   * https://github.com/lbertenasco/ap-ng2-fullcalendar
   */
  public getCalendarOptions (): any | Object {
    const now: string = moment().toISOString()
    const calendarOptions: any | Object = {
      // height: 'auto',
      // aspectRatio: 2,
      fixedWeekCount : false,
      firstDay: 1,
      defaultDate: now,
      editable: true,
      selectable: true,
      // Allow "more" link when too many events.
      eventLimit: 8,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      // eventSources: [],
      eventSources: this.getEventSources(),
      eventRender: function (event: any, element: any): any {
        if (event.iconClass) {
          element.find('.fc-content').prepend('<i class="' + event.iconClass + '"></i>&nbsp;')
        }

        if (event.organiserUser && event.organiserUser.person) {

          element.find('.fc-content').append(
            '<br><strong>' + (event.organiserUser.person.firstName ? event.organiserUser.person.firstName : '') +
            ' ' + (event.organiserUser.person.lastName ? event.organiserUser.person.lastName : '') + '</strong>'
          )
        }

        if (event.scheduledWith && event.scheduledWith.length > 1) {
          element.find('.fc-content').append(
            '<span> +' + event.scheduledWith.length + ' More</span>'
          )
        }
      },
      // eventClick: (calendarEvent, jsEvent, view): void => {
      //   this.eventClick(calendarEvent, jsEvent, view)
      // },
      eventClick: this.eventClick.bind(this),
      eventMouseover: this.eventMouseover.bind(this),
      dayClick: this.dayClick.bind(this),
      views: {
        basic: {
           // options apply to basicWeek and basicDay views
        },
        month: {
          // options apply to month views
          timeFormat: 'H:mm'
        },
        agenda: {
           // options apply to agendaWeek and agendaDay views
        },
        week: {
           // options apply to basicWeek and agendaWeek views
        },
        day: {
           // options apply to basicDay and agendaDay views
        }
      }
    }

    return calendarOptions
  }

  /**
   * Clicked event
   */
  public eventClick (calendarEvent: any, jsEvent: Event, view: any): void {
    if (view) {}
    this.ngZone.run(() => {
      this.openModal(jsEvent, 'editTask', null, calendarEvent)
    })
  }

  /**
   * Day event
   */
  public dayClick (date: any, jsEvent: Event, view: any): void {
    if (jsEvent) {}
    if (view) {}
    this.myCalendar.fullCalendar('gotoDate', date)
    // eg. this.myCalendar.fullCalendar('changeView', 'agendaDay');

    // No need for this manual change gotoDate takes care of it.
    // $('.fc-day').each(function () {
    //   $(this).removeClass('fc-state-highlight')
    // })
    // $('.fc-day-top').each(function () {
    //   $(this).removeClass('fc-state-highlight')
    // })
    // $('td[data-date=' + date.format() + ']').addClass('fc-state-highlight')
    // $('#calendar').fullCalendar('gotoDate', start);
    // $('#calendar').fullCalendar('changeView', 'agendaDay');
  }

  /**
   * Get tasks
   *
   * @todo get the filters working.
   */
  public getEvents (start: any, end: any, timeZone: any, cb: any, taskTypeUuid: any): any {
    if (timeZone) {}
    const startDate: string = moment(start).local().startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const endDate: string = moment(end).local().endOf('day').format('YYYY-MM-DD HH:mm:ss')

    const filters: Array<any> = [
      {
        key: 'start_datetime',
        value: startDate,
        operator: 'gte'
      },
      {
        key: 'end_datetime',
        value: endDate,
        operator: 'lte'
      },
      {
        key: 'task_type_uuid',
        value: taskTypeUuid,
        operator: 'eq'
      },
      // {
      //   key: 'organiser_user_uuid',
      //   value: this.showForUserUuids,
      //   operator: 'in'
      // },
    ]

    if (this.showForUserUuid) {
      filters.push({
        key: 'organiser_user_uuid',
        value: this.showForUserUuid,
        operator: 'eq'
      })
    }

    this.taskService.api.getMany({
      includes: [
        'taskType',
        'taskPriority',
        'organiserUser',
        'organiserUser.person',
        'scheduledWith',
        // 'scheduledWith.companies',
        'associatedWith'
        // 'associatedWith.addresses'
      ],
      filter_groups: [
        {
          filters: filters
        }
      ]
    })
    .toPromise()
    .then((result) => {
      cb(result)
    })
    .catch((err) => {
      this.alertService.error('Error:' + err.message, 10000)
    })
  }

  /**
   * Set show for
   */
  public setShowFor() {
    console.log('showForUserUuid', this.showForUserUuid)
    this.myCalendar.fullCalendar('refetchEvents')
  }

  /**
   * Toggle events
   */
  public toggleEvents (uuid: string): void {
    if (event) {}
    const taskType: any = this.taskTypes.find(x => x.uuid === uuid)

    if (taskType.show === true) {
      taskType.show = false
      this.myCalendar.fullCalendar('removeEventSource', uuid)
    } else {
      taskType.show = true
      this.myCalendar.fullCalendar('addEventSource', taskType.eventSource)
    }
  }

  /**
   * Is checked
   */
  public isChecked (uuid: string): boolean {
    const taskType: any = this.taskTypes.find(x => x.uuid === uuid)
    return taskType.show
  }

  /**
   * Event mouseover
   */
  public eventMouseover (): void {
     // [ngbPopover]="popoverTemplate" #myPopover="ngbPopover"
     // const ngbPopover: NgbPopover = 'popoverTemplate'
     // myPopover.open()
     // $('.theElement').popover('show')
  }

  /**
   * Open modal
   */
  public openModal (event: Event, type: string, taskTypeUuid?: string, data?: any): void {
    console.log('openModal')

    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    // console.log('content', content)
    console.log('type', type)

    if (type === 'newTask') {
      const task: Task = new Task()
      task.taskTypeUuid = taskTypeUuid
      const startDatetime: Date = moment().toDate()
      // moment(startDatetime).add(60, 'minutes').toDate()
      const endDatetime: Date = startDatetime
      task.startDatetime = startDatetime
      task.endDatetime = endDatetime
      task.organiserUserUuid = this.showForUserUuid
      const taskPriority: any = this.selectOptionsService.taskPriorityOptions.find(x => x.name === 'Medium')
      task.taskPriorityUuid = taskPriority.uuid // 2 - Medium Uuid Preset.
      this.taskForm = this.taskFormService.getForm(task, 'new')
    }

    if (type === 'editTask') {
      // This is all populating fine however the task doesn't
      // seem to be populating the form which makes me think
      // FullCalendar is putting it in a different scope.
      console.log('editTask data', data)
      const task: Task = new Task(data)
      console.log('editTask data', task)
      this.taskForm = this.taskFormService.getForm(task, 'edit')
      console.log('editTask this.taskForm', this.taskForm.value)
    }

    this.currentModal = this.modalService.open(this.taskModal, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })

    // Trying a jquery hack to fix the css.
    if (type === 'editTask') {
      setTimeout(() => {
        $('body').addClass('modal-open')
        $('ngb-modal-backdrop').addClass('modal-backdrop fade show')
        $('ngb-modal-window').addClass('modal fade show d-block fade modal-xl')
        $('div[role="document"]').addClass('modal-dialog modal-lg')
      }, 500)
    }
  }

  /**
   * Save task
   */
  public saveTask (): void {
    console.log('saveTask', this.taskForm.value)
    this.validationService.runValidation(this.taskForm)

    if (!this.taskForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.taskForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.taskService.saveTask(this.taskForm.value)
    .then(() => {
      this.currentModal.close()
      this.myCalendar.fullCalendar('refetchEvents')
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Delete task
   */
  public deleteTask (uuid: string): void {
    this.taskService.api.delete(uuid)
    .toPromise()
    .then(() => {
      this.currentModal.close()
      this.myCalendar.fullCalendar('refetchEvents')
    })
    .catch((err) => {
      console.error(err)
    })
  }

}
