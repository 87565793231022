import { Injectable } from '@angular/core'
import { FormArray, FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { Company } from '@app/modules/company/company.model'
import { CompanyFormService } from '@app/modules/company/company-form.service'
import { Shutdown } from '@app/modules/shutdown/shutdown.model'
import { ShutdownFormService } from '@app/modules/shutdown/shutdown-form.service'
import { OpportunityAgreement } from '@app/modules/opportunity-agreement/opportunity-agreement.model'
import { OpportunityAgreementFormService } from '@app/modules/opportunity-agreement/opportunity-agreement-form.service'
import { Probability } from '@app/modules/probability/probability.model'
import { ProbabilityFormService } from '@app/modules/probability/probability-form.service'
import { Person } from '@app/modules/person/person.model'
import { PersonFormService } from '@app/modules/person/person-form.service'
import { OpportunityStatus } from '@app/modules/opportunity-status/opportunity-status.model'
import { OpportunityStatusFormService } from '@app/modules/opportunity-status/opportunity-status-form.service'
import { OpportunityStageFormService } from '@app/modules/opportunity-stage/opportunity-stage-form.service'
import { OpportunityStage } from '@app/modules/opportunity-stage/opportunity-stage.model'
import { OpportunityProcessFormService } from '@app/modules/opportunity-process/opportunity-process-form.service'
import { OpportunityProcess } from '@app/modules/opportunity-process/opportunity-process.model'
import { User } from '@app/modules/user/user.model'
import { UserFormService } from '@app/modules/user/user-form.service'
import { Site } from '@app/modules/site/site.model'
import { SiteFormService } from '@app/modules/site/site-form.service'
import { ProductServiceModel, ProductServiceFormService } from '@app/modules/product-service'

@Injectable()

export class OpportunityFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      name: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.required,
        Validators.maxLength(191)
      ]
    },
    {
      proposalNo: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      jobNo: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      companyUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      siteUuid: [
        ValidationService.isUuid
      ]
    },
    {
      opportunityStatusUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      opportunityProcessUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      opportunityStageUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      salesPersonUserUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      probabilityUuid: [
        ValidationService.isUuid
      ]
    },
    {
      lostReasonUuid: [
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private companyFormService: CompanyFormService,
    private shutdownFormService: ShutdownFormService,
    private opportunityAgreementFormService: OpportunityAgreementFormService,
    private probabilityFormService: ProbabilityFormService,
    private personFormService: PersonFormService,
    private opportunityStatusFormService: OpportunityStatusFormService,
    private opportunityStageFormService: OpportunityStageFormService,
    private opportunityProcessFormService: OpportunityProcessFormService,
    private userFormService: UserFormService,
    private siteFormService: SiteFormService,
    private productServiceFormService: ProductServiceFormService
  ) {}

  /**
   * Get form
   */
  public getForm (opportunity: Opportunity, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      num: [
        null,
        [
          ValidationService.isNumeric
        ]
      ],
      name: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis
        ]
      ],
      proposalNo: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis
        ]
      ],
      jobNo: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis
        ]
      ],
      companyUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      siteUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityStatusUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityProcessUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      opportunityStageUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      salesPersonUserUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      probabilityUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      estimatedStart: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      estimatedCompletion: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      expectedOrderReceived: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      orderReceived: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      finalInvoice: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      finalInvoiceDate: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      lostReasonUuid: [
        null,
        [
          ValidationService.isNumeric
        ]
      ],
      refIir: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      shutdownUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // Relations.
      opportunityAgreements: this.formBuilder.array([]),
      contacts: this.formBuilder.array([]),
      contactsDeleted: this.formBuilder.array([]),
      productServices: this.formBuilder.array([])
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (opportunity.opportunityAgreements) {
      if (opportunity.opportunityAgreements) {
        const control: FormArray = form.get('opportunityAgreements') as FormArray
        opportunity.opportunityAgreements.map((opportunityAgreement: OpportunityAgreement) => {
          const opportunityAgreementForm: FormGroup = this.opportunityAgreementFormService.getForm(
            new OpportunityAgreement(opportunityAgreement),
            ''
          )
          control.push(opportunityAgreementForm)
        })
      }
    }

    if (opportunity.contacts) {
      if (opportunity.contacts) {
        const control: FormArray = form.get('contacts') as FormArray
        opportunity.contacts.map((person: Person) => {
          const personForm: FormGroup = this.personFormService.getForm(
            new Person(person),
            ''
          )
          control.push(personForm)
        })
      }
    }

    if (opportunity.productServices) {
      if (opportunity.productServices) {
        const control: FormArray = form.get('productServices') as FormArray
        opportunity.productServices.map((productService: ProductServiceModel) => {
          const productServiceForm: FormGroup = this.productServiceFormService.getForm(
            new ProductServiceModel(productService),
            ''
          )
          control.push(productServiceForm)
        })
      }
    }

    if (opportunity.opportunityStage) {
      form.addControl('opportunityStage', this.opportunityStageFormService.getForm(
        new OpportunityStage(opportunity.opportunityStage), '')
      )
    }

    if (opportunity.opportunityStatus) {
      form.addControl('opportunityStatus', this.opportunityStatusFormService.getForm(
        new OpportunityStatus(opportunity.opportunityStatus), '')
      )
    }

    if (opportunity.opportunityProcess) {
      form.addControl('opportunityProcess', this.opportunityProcessFormService.getForm(
        new OpportunityProcess(opportunity.opportunityProcess), '')
      )
    }

    if (opportunity.salesPersonUser) {
      form.addControl('salesPersonUser', this.userFormService.getForm(new User(opportunity.salesPersonUser), ''))
    }

    if (opportunity.company) {
      form.addControl('company', this.companyFormService.getForm(new Company(opportunity.company), ''))
    }

    if (opportunity.shutdown) {
      form.addControl('shutdown', this.shutdownFormService.getForm(new Shutdown(opportunity.shutdown), ''))
    }

    if (opportunity.probability) {
      form.addControl('probability', this.probabilityFormService.getForm(new Probability(opportunity.probability), ''))
    }

    if (opportunity.site) {
      form.addControl('site', this.siteFormService.getForm(new Site(opportunity.site), ''))
    }

    if (opportunity) {
      // form.patchValue(new Opportunity(opportunity), { emitEvent: false })
      form.patchValue(opportunity, { emitEvent: false })
    }

    return form
  }

}
