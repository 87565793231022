import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { PersonToCompany } from '@app/modules/person-to-company/person-to-company.model'

@Injectable()

export class PersonToCompanyFormService {

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder
  ) {}

  /**
   * Get form
   */
  public getForm (personToCompany: PersonToCompany, type: string): FormGroup {
    if (type) {}
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        []
      ],
      personUuid: [
        null,
        []
      ],
      companyUuid: [
        null,
        []
      ],
      department: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      position: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      departmentUuid: [
        null,
        [
          ValidationService.isNumeric
        ]
      ],
      isPrimary: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (personToCompany) {
      form.patchValue(personToCompany, { emitEvent: false })
    }

    return form
  }

}
