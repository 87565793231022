import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { ConfigService, FileNavigatorService, FmService } from '../../services'

@Component({
  selector: 'app-fm-navbar',
  templateUrl: './fm-navbar.component.html',
  providers: []
})

export class FmNavbarComponent implements OnInit, OnDestroy {

  /**
   * Config
   */
  public config: any

  public isNavbarCollapsed: boolean

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public configService: ConfigService,
    public fmService: FmService,
    public fileNavigatorService: FileNavigatorService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.config = this.configService.config
  }

}
