import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-table-telephone-display',
  templateUrl: './table-telephone-display.component.html',
  providers: []
})

export class TableTelephoneDisplayComponent implements OnInit, OnDestroy {

  /**
   * Telephone name Id to use for display
   */
  @Input() telephoneNameUuid: number

  /**
   * Telephones
   */
  @Input() telephones: any

  /**
   * Display
   */
  public display: boolean

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * First item
   */
  public firstItem: any

  /**
   * Constructor
   */
  public constructor () {}

  /**
   * Ng on init
   */
  public ngOnInit (): void {
    this.display = false

    // Make sure we have a display order for sorting.
    this.telephones.map((tel) => {
      if (!tel.telephoneName) {
        tel.telephoneName = {
          displayOrder: 999
        }
      }
    })

    if (this.telephones && this.telephones.length > 0) {
      this.telephones.sort((a, b) => a.telephoneName.displayOrder - b.telephoneName.displayOrder)
    }

    this.firstItem = this.telephones.find((x) => x.telephoneNameUuid === this.telephoneNameUuid)

    if (!this.firstItem && this.telephones && this.telephones.length > 0) {
      this.firstItem = this.telephones[0]
    }

    if (this.telephones.length > 0) {
      this.display = true
    }
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

}
