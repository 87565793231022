import { Pipe, PipeTransform } from '@angular/core';
import * as libphonenumberJs from 'libphonenumber-js' ;

@Pipe({
  name: 'tel'
})
/**
 * https://github.com/catamphetamine/libphonenumber-js
 */
export class TelPipe implements PipeTransform {

  /**
   * Transform to formatted phone number
   *
   * International — e.g. +1 213 373 4253
   * International_plaintext — (aka E.164) e.g. +12133734253
   * National — e.g. (213) 373-4253
   *
   * @param telephone   Telephone number
   * @param countryIso2 ISO2 Country code
   * @param type        International, International_plaintext, National (default)
   *
   * @returns {string} Formatted telephone number
   */
  transform(telephone: string, countryIso2: any, type?: any): string {

    if (!type) {
        type = 'National';
    }
    // console.log('telephone', data.telephone);
    // console.log('parse', parse(data.telephone, 'GB'));
    // console.log('format', format(data.telephone, countryIso2, 'International_plaintext'));
    // console.log('asYouType', new asYouType('GB').input(data.telephone));

    const format = libphonenumberJs.format(telephone, countryIso2, type);

    //return `+44 ${telephone}`;
    return format;
  }

  //constructor(_locale: string);

}
