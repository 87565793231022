import { AlertType } from '@app/modules/alert/alert-type.model'
import { SafeHtml } from '@angular/platform-browser'
export class Alert {
  uuid: string
  type: AlertType
  message: string
  safeMessage: SafeHtml
  timeout: number
  date: Date
  keepAfterRouteChange: boolean

  constructor (init?: Partial<Alert>) {
    Object.assign(this, init)
  }
}
