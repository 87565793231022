import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { AppService } from '@app/shared/services/app.service'
import { ActivityLogChange } from '../../models/activity-log-change.model'
import { ChangeCasePipe } from '@app/shared/pipes/change-case.pipe'

@Component({
  selector: 'app-activity-log-changes',
  templateUrl: './activity-log-changes.component.html'
})

export class ActivityLogChangesComponent implements OnInit, OnDestroy {

  /**
   * Revision
   */
  @Input() revision: any

  /**
   * Changes
   */
  @Input() type: any

  /**
   * Display page
   */
  public changeArray: Array<ActivityLogChange> = []

  /**
   * Display page
   */
  public display: boolean

  /**
   * Constructor
   */
  public constructor (
    public appService: AppService
  ) {
  }

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {

    const changeArray: Array<ActivityLogChange> = []

    const att: any = this.revision.changes.attributes
    const old: any = this.revision.changes.old

    for (const key in att) {
      if (att.hasOwnProperty(key)
        && key !== 'created_at'
        && key !== 'updated_at'
        && key !== 'deleted_at'
        && key !== 'created_by_uuid'
        && key !== 'updated_by_uuid') {
        if (key) {
          // console.log(key, ':', att[key])
          let from: string
          from = ''
          for (const oldkey in old) {
            if (att.hasOwnProperty(oldkey) && oldkey === key) {
              from = old[oldkey]
            }
          }
          const change: ActivityLogChange = new ActivityLogChange({
            field: new ChangeCasePipe().transform(key, 'titleCase'),
            from: this.truncate(from),
            to: this.truncate(att[key])
          })
          changeArray.push(change)
        }
      }
    }

    this.changeArray = changeArray

    this.display = true
  }

  /**
   * Truncate
   *
   * @param  value         Value
   * @param  limit         Char limit
   * @param {boolean} completeWords Use complete words
   * @param  ellipsis      Ellipses
   */
  public truncate (value: string, limit: number = 191, completeWords: boolean = true, ellipsis: string = '...'): string {
    value = value + ''
    if (! value || value === '') {
      return value
    }
    // let lastindex: number = limit;
    if (completeWords) {
      // lastindex = value.substr(0, limit).lastIndexOf(' ');
    }
    let elip: string
    elip = ''
    if (value.length > limit) {
      elip = ellipsis
    }
    return value.substr(0, limit) + elip
  }

}
