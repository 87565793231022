import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { AlertService } from '@app/modules/alert/alert.service'
// import { EventService } from '@app/shared/services/event.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ValidationService } from '@app/shared/services/validation.service'

import {
  SavedQuery,
  SavedQueryService,
  SavedQueryFormService
} from '@app/modules/saved-query'

@Component({
  selector: 'app-tab-saved-queries-table',
  templateUrl: './tab-saved-queries-table.component.html'
})

export class TabSavedQueriesTableComponent implements OnInit, OnDestroy {

  /**
   * Results
   */
  @Input() results: Array<any>

  /**
   * Display page
   */
  public display: boolean

  /**
   * Saved queries
   */
  @Input() savedQueries: Array<SavedQuery>

  /**
   * Saved queries
   */
  @Input() savedQueriesChange: Array<SavedQuery>

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  public savedQueryForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    // private formBuilder: FormBuilder,
    private alertService: AlertService,
    // private eventService: EventService,
    private modalService: NgbModal,
    public validationService: ValidationService,
    private savedQueryService: SavedQueryService,
    private savedQueryFormService: SavedQueryFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'editSavedQuery') {
      this.savedQueryForm = this.savedQueryFormService.getForm(new SavedQuery(data), 'edit')

      // if (this.savedQueryForm.value.uuid) {
      //   this.savedQueryForm.patchValue({
      //     jsQuery: JSON.stringify(this.firstQueryItem)
      //   })
      // } else {
      //   this.savedQueryForm = this.savedQueryFormService.getForm(new SavedQuery({
      //     jsQuery: JSON.stringify(this.firstQueryItem)
      //   }), 'new')
      // }
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })

  }

  /**
   * Load query
   */
  public loadQuery (savedQuery: SavedQuery): void {
    this.savedQueryService.loadSavedQuery(savedQuery)
    // this.mainForm.patchValue(savedQuery)
    // this.mainForm = this.savedQueryForm
  }

  /**
   * Save contact
   */
  public saveSavedQuery (): void {
    console.log('saveSavedQuery', this.mainForm.value)
    this.validationService.runValidation(this.mainForm)

    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }
    this.savedQueryService.saveSavedQuery(this.mainForm.value)
    .then(() => {
      this.currentModal.close()
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err, 10000)
    })
  }

  /**
   * Delete saved query
   */
  public deleteSavedQuery (savedQuery: any, index: number): void {
    this.savedQueryService.api.delete(savedQuery.uuid)
    .toPromise()
    .then(() => {
      this.savedQueries.splice(index, 1)

      this.alertService.success('Deleted query' , 10000)
      this.savedQueryService.deletedSavedQuery(savedQuery.uuid)
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Stopper
   */
  public stopper (event: Event): void {
    event.preventDefault()
    event.stopPropagation()
  }

}
