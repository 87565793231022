import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { from as observableFrom, merge as observableMerge, Subscription, Subject, Observable } from 'rxjs'
import { switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { Company } from '@app/modules/company/company.model'
import { CompanyService } from '@app/modules/company/company.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'
@Component({
  selector: 'app-company-id-typeahead',
  templateUrl: './company-id-typeahead.component.html'

})

export class CompanyIdTypeaheadComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Button text
   */
  @Input() mainFormControlName: string

  /**
   * Selected form
   */
  public selectedForm: FormGroup

  /**
   * Main form change
   */
  @Output() changes: EventEmitter<any> = new EventEmitter<any>()

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  @ViewChild('companyIdSearchTypeAhead') companyIdSearchTypeAhead: NgbTypeahead
  public focus$: Subject<string> = new Subject<string>()
  public click$: Subject<string> = new Subject<string>()

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private companyFormService: CompanyFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false

    this.selectedForm = this.formBuilder.group({
      selectedItem: [
        null,
        [
          Validators.required
        ]
      ]
    })

    if (this.mainFormControlName) {
      const uuid: string = this.mainForm.get(this.mainFormControlName).value
      if (uuid) {
        this.getInitialResult(uuid)
        .then((result) => {
          this.selectedForm.patchValue({
            selectedItem: result
          })
        })
      }
    }

    this.display = true
  }

  /**
   * Search
   *
   * @type {function}
   *
   * @returns {Array<any>}
   */
  public search: any = (text$: Observable<string>): Observable<any> => {
    const debouncedText: Observable<string> = text$.pipe(debounceTime(500), distinctUntilChanged())

    return observableMerge(debouncedText)
    .pipe(
      switchMap(term => observableFrom(this.getResults(term)))
    )
  }

  /**
   * Search result formatter
   *
   * Converts the object into a readable string.
   *
   * @type {any}
   *
   * @returns {string}
   */
  public formatResults: any = (item: any): any => {
    const strParts: Array<any> = []
    // const name: Array<any> = []

    if (item.name) {
      strParts.push(item.name)
    }
    // const addressParts: Array<any> = []
    if (item.addresses && item.addresses.length > 0) {
      if (item.addresses[0].address1) {
        strParts.push(item.addresses[0].address1)
      }
      if (item.addresses[0].city) {
        strParts.push(item.addresses[0].city)
      }
      if (item.addresses[0].state) {
        strParts.push(item.addresses[0].state)
      }
      if (item.addresses[0].postcode) {
        strParts.push(item.addresses[0].postcode)
      }
    }

    return strParts.join(', ')
  }

  /**
   * Search result formatter
   *
   * Converts the object into a readable string.
   *
   * @type {any}
   *
   * @returns {string}
   */
  public formatInput: any = (item: any): any => {
    return item.name
  }

  /**
   * Select item
   *
   * @param {Event} $event Event
   */
  public selectItem ($event: Event): void {
    if ($event) {}
    console.log('selectItem', $event)
    console.log('selectItem', $event['item'].uuid)

    const data: any = {}
    data[this.mainFormControlName] = $event['item'].uuid

    this.mainForm.patchValue(data)

    this.changes.emit({
      name: 'companyChanged',
      data: $event['item']
    })
  }

  /**
   * Is search valid
   *
   * @returns {boolean}
   */
  public isSearchValid (): boolean {
    if (this.selectedForm.get('selectedItem').value && this.selectedForm.get('selectedItem').value.uuid) {
      return true
    }
    return false
  }

  /**
   * Add schedule contact
   */
  public add (): void {
    const control: FormArray = this.mainForm.get(this.mainFormControlName) as FormArray
    const company: Company = new Company(this.selectedForm.get('selectedItem').value)
    const companyForm: FormGroup = this.companyFormService.getForm(company, 'new')
    control.push(companyForm)
  }

  /**
   * Searcher
   *
   * @param term Search term
   */
  public getResults (term: string): Promise<any> {
    const params: any = {
      limit: 50,
      sort: [
        {
          key: 'name',
          direction: 'ASC'
        }
      ],
      filter_groups: [
        {
          or: true,
          filters: [
            {
              key: 'name',
              value: term,
              operator: 'ct'
            }
          ]
        }
      ],
      includes: [
        'addresses'
      ]
    }

    return this.companyService.api.getMany(params)
    .toPromise()
    .then((results) => {
      return results
    })
  }

  /**
   * Get initial result
   *
   * @param term Search term
   */
  public getInitialResult (uuid: string): Promise<any> {
    const params: any = {
      includes: [
        'addresses'
      ]
    }

    return this.companyService.api.getOne(uuid, params)
    .toPromise()
    .then((result) => {

      this.selectedForm.patchValue({
        selectedItem: result
      })
      return result
    })
  }
}
