import { Directive, Input, OnChanges, OnInit, HostListener, SimpleChanges, ElementRef } from '@angular/core'
import { PhonePipe } from '@app/shared/pipes/phone.pipe'

@Directive({
  selector: '[appInputTransform]'
})

export class InputTransformDirective implements OnInit, OnChanges {

  @Input() appInputTransform: string

  @Input() iso2: string

  /**
   * Constructor
   */
  constructor (
    private elementRef: ElementRef
  ) {}

  /**
   * On init
   */
  public ngOnInit (): void {
    if (this.iso2) {
      this.newIso()
    }
  }

  /**
   * On changes
   */
  public ngOnChanges (changes: SimpleChanges): void {
    if (changes.iso2) {
      this.newIso()
    }
  }

  /**
   * New iso
   */
  public newIso (): void {
    let number: string
    number = this.elementRef.nativeElement.value
    if (number.indexOf('+') === 0) {
      const firstSpace: number = number.indexOf(' ')
      if (firstSpace !== -1) {
        this.elementRef.nativeElement.value = this.transform(number.substr(number.indexOf(' ') + 1))
        return
      }
    }
    this.elementRef.nativeElement.value = this.transform(this.elementRef.nativeElement.value)
  }

  /**
   * On blur host listener
   */
  // @HostListener('blur', ['$event']) onBlur (event: FocusEvent): void {
  //   console.log('onBlur', event)
  // }

  /**
   * On focus host listener
   */
  @HostListener('focus', ['$event']) onFocus (event: FocusEvent): void {
    // console.log('onFocus', event)
    this.format(event)
  }

  /**
   * On key down host listener
   */
  // @HostListener('keydown', ['$event']) onKeyDown (event: KeyboardEvent): void {
  //   console.log('onKeyDown', event)
  // }

  /**
   * On key up host listener
   */
  @HostListener('keyup', ['$event']) onKeyUp (event: KeyboardEvent): void {
    // console.log('onKeyUp', event)
    this.format(event)
  }

  /**
   * Format
   */
  public format (event: any): void {
    // if (event.keyCode > 32 && event.keyCode < 128) {}
    const transformed: string = this.transform(event.target['value'])
    if (transformed !== event.target['value']) {
      event.target['value'] = transformed
    }
  }

  /**
   * Transform
   */
  public transform (val: string): string {
    let newVal: string
    switch (this.appInputTransform) {
      case 'upper':
        newVal = val.toUpperCase()
        break
      case 'upperFirst':
        newVal = this.toUppercaseFirstChar(val)
        break
      case 'upperFirstThenLower':
        newVal = this.toUppercaseFirstCharThenLower(val)
        break
      case 'upperFirstWords':
        newVal = this.toUppercaseFirstWords(val)
        break
      case 'lower':
        newVal = val.toLowerCase()
        break
      case 'ukDate':
        newVal = this.toUkDate(val)
        break
      case 'phone':
        newVal = new PhonePipe().transform(val, this.iso2, 'i')
        break
      default:
        console.error('Unknown input transform type', this.appInputTransform)
        newVal = val
    }

    return newVal
  }

  /**
   * To uppercase first char
   *
   * Uppercase first char.
   */
  public toUppercaseFirstChar (val: string): string {
    val = val.charAt(0).toUpperCase() + val.substr(1)
    return val
  }

  /**
   * To uppercase first char
   *
   * Uppercase first char and lower case for the rest of the string.
   */
  public toUppercaseFirstCharThenLower (val: string): string {
    val = val.charAt(0).toUpperCase() + val.substr(1).toLowerCase()
    return val
  }

  /**
   * To uppercase first words
   *
   * Uppercase first char and lower case for the rest of the word on each word.
   */
  public toUppercaseFirstWords (val: string): string {
    val = val.replace(/\w\S*/g, (word) => {
      return this.toUppercaseFirstChar(word)
    })
    return val
  }

  public toUkDate (val: string): string {
    // const dateParts: Array<any> = val.trim().split('/')

    // if (dateParts.length === 1 && isNumber(dateParts[0])) {
    //   if (dateParts.length === 2) {
    //   //   val =  padNumber(dateParts[0]) + '/' + padNumber(dateParts[1])
    //   // } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
    //   //   val =  padNumber(dateParts[0]) + '/' + padNumber(dateParts[1])
    //   }
    // }
    return val
  }

}
