import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule } from '@angular/http'
import { AuthGuard } from '@app/shared/guards/auth.guard'

// -----------------------------------------------------------------------------
// Routes
// -----------------------------------------------------------------------------
import { ROUTES } from '@app/app.routes'

// -----------------------------------------------------------------------------
// 3rd Party
// -----------------------------------------------------------------------------
import { CalendarModule } from 'ap-angular-fullcalendar'
// import { CalendarComponent } from 'ap-angular-fullcalendar'
import {
    NgbModule,
    NgbDateParserFormatter,
    NgbDateAdapter,
    NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap'

import {
    NgbUkDateParserFormatter
} from '@app/shared/formatters/ngb-uk-date-parser.formatter'

import { NgSelectModule } from '@ng-select/ng-select'
import { LocalStorageModule } from 'angular-2-local-storage'
import { StarRatingModule } from 'angular-star-rating'
import { MomentModule } from 'ngx-moment'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { CurrencyMaskModule } from 'ng2-currency-mask' // not working.
import { FileUploadModule } from 'ng2-file-upload'
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/README.md
import { MsalModule, BroadcastService } from '@azure/msal-angular'

// -----------------------------------------------------------------------------
// Guards
// -----------------------------------------------------------------------------
import { PendingChangesGuard } from '@app/shared/guards/pending-changes.guard'
import { ConfirmModalComponent } from '@app/shared/components/confirm-modal/confirm-modal.component.ts'

// -----------------------------------------------------------------------------
// Directives
// -----------------------------------------------------------------------------
import { InputTransformDirective } from '@app/shared/directives/input-transform.directive'

// -----------------------------------------------------------------------------
// Pipes
// -----------------------------------------------------------------------------
// import { SanitizingPipe } from '@app/shared/pipes/sanitizing.pipe'
import { TelPipe } from '@app/shared/pipes/tel.pipe'
import { PhonePipe } from '@app/shared/pipes/phone.pipe'
import { ChangeCasePipe } from '@app/shared/pipes/change-case.pipe'
import { HumanizeDurationPipe } from '@app/shared/pipes/humanize-duration.pipe'
import { HtmlPipe } from '@app/shared/pipes/html.pipe'

// -----------------------------------------------------------------------------
// Core layout
// -----------------------------------------------------------------------------
import { AppComponent } from '@app/app.component'
import { HomeComponent } from '@app/core/home/home.component'
import { BasicLayoutComponent } from '@app/core/basic-layout/basic-layout.component'
import { BlankLayoutComponent } from '@app/core/blank-layout/blank-layout.component'
import { LeftNavigationComponent } from '@app/core/left-navigation/left-navigation.component'
import { TopNavigationComponent } from '@app/core/top-navigation/top-navigation.component'
import { LoginComponent } from '@app/modules/auth/components/login/login.component'
import { DashboardComponent } from '@app/modules/dashboard/components/dashboard/dashboard.component'
import { PageNotFoundComponent } from '@app/core/errors/page-not-found/page-not-found.component'
import { FooterComponent } from '@app/core/footer/footer.component'


// -----------------------------------------------------------------------------
// General services
// -----------------------------------------------------------------------------
import { AlertService } from '@app/modules/alert/alert.service'
import { EventService } from '@app/shared/services/event.service'
import { AppService } from '@app/shared/services/app.service'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { HttpService } from '@app/shared/services/http.service'
import { HttpErrorServiceProvider } from '@app/shared/services/http-error.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'

// -----------------------------------------------------------------------------
// Api services
// -----------------------------------------------------------------------------
import { ApiService } from '@app/shared/api-services/api.service'
import { AlarmApiService } from '@app/modules/alarm/alarm-api.service'
import { AddressNameApiService } from '@app/modules/address-name/address-name-api.service'
import { AddressApiService } from '@app/modules/address/address-api.service'
import { AgreementApiService } from '@app/modules/agreement/agreement-api.service'
import { CompanyTypeApiService } from '@app/modules/company-type/company-type-api.service'
import { CompanyApiService } from '@app/modules/company/company-api.service'
import { CountryApiService } from '@app/modules/country/country-api.service'
import { DepartmentApiService } from '@app/modules/department/department-api.service'
import { EmailAddressNameApiService } from '@app/modules/email-address-name/email-address-name-api.service'
import { EmailAddressApiService } from '@app/modules/email-address/email-address-api.service'
import { GenderApiService } from '@app/modules/gender/gender-api.service'
import { LostReasonApiService } from '@app/modules/lost-reason/lost-reason-api.service'
import { NoteApiService } from '@app/modules/note/note-api.service'
import { NoteTypeApiService } from '@app/modules/note-type/note-type-api.service'
import { OpportunityAgreementApiService } from '@app/modules/opportunity-agreement/opportunity-agreement-api.service'
import { OpportunityProcessApiService } from '@app/modules/opportunity-process/opportunity-process-api.service'
import { OpportunityStageApiService } from '@app/modules/opportunity-stage/opportunity-stage-api.service'
import { OpportunityStatusApiService } from '@app/modules/opportunity-status/opportunity-status-api.service'
import { ProbabilityApiService } from '@app/modules/probability/probability-api.service'

import { ResourceApiService } from '@app/modules/resource/resource-api.service'
import { RoleApiService } from '@app/modules/role/role-api.service'
import { SiteApiService } from '@app/modules/site/site-api.service'
import { SocialNameApiService } from '@app/modules/social-name/social-name-api.service'
import { SocialApiService } from '@app/modules/social/social-api.service'
import { SuppliesWhatApiService } from '@app/modules/supplies-what/supplies-what-api.service'
import { TaskDurationApiService } from '@app/modules/task-duration/task-duration-api.service'
import { TaskPriorityApiService } from '@app/modules/task-priority/task-priority-api.service'
import { TaskRegardingApiService } from '@app/modules/task-regarding/task-regarding-api.service'
import { TaskTypeApiService } from '@app/modules/task-type/task-type-api.service'
import { TaskApiService } from '@app/modules/task/task-api.service'
import { TelephoneNameApiService } from '@app/modules/telephone-name/telephone-name-api.service'
import { TelephoneApiService } from '@app/modules/telephone/telephone-api.service'
import { TitleApiService } from '@app/modules/title/title-api.service'
import { UriNameApiService } from '@app/modules/uri-name/uri-name-api.service'
import { UriApiService } from '@app/modules/uri/uri-api.service'
import { UserApiService } from '@app/modules/user/user-api.service'

// -----------------------------------------------------------------------------
// Model services
// -----------------------------------------------------------------------------
import { AlarmService } from '@app/modules/alarm/alarm.service'
import { AddressService } from '@app/modules/address/address.service'
import { AddressNameService } from '@app/modules/address-name/address-name.service'
import { AgreementService } from '@app/modules/agreement/agreement.service'
import { CompanyService } from '@app/modules/company/company.service'
import { CompanyTypeService } from '@app/modules/company-type/company-type.service'
import { CountryService } from '@app/modules/country/country.service'
import { DepartmentService } from '@app/modules/department/department.service'
import { EmailAddressNameService } from '@app/modules/email-address-name/email-address-name.service'
import { EmailAddressService } from '@app/modules/email-address/email-address.service'
import { GenderService } from '@app/modules/gender/gender.service'
import { LoginService } from '@app/modules/login/login.service'
import { LostReasonService } from '@app/modules/lost-reason/lost-reason.service'
import { NoteService } from '@app/modules/note/note.service'
import { NoteTypeService } from '@app/modules/note-type/note-type.service'
import { OpportunityAgreementService } from '@app/modules/opportunity-agreement/opportunity-agreement.service'
import { OpportunityProcessService } from '@app/modules/opportunity-process/opportunity-process.service'
import { OpportunityStageService } from '@app/modules/opportunity-stage/opportunity-stage.service'
import { OpportunityStatusService } from '@app/modules/opportunity-status/opportunity-status.service'
import { ProbabilityService } from '@app/modules/probability/probability.service'

import { SiteService } from '@app/modules/site/site.service'
import { SocialNameService } from '@app/modules/social-name/social-name.service'
import { SocialService } from '@app/modules/social/social.service'
import { SuppliesWhatService } from '@app/modules/supplies-what/supplies-what.service'
import { TaskDurationService } from '@app/modules/task-duration/task-duration.service'
import { TaskPriorityService } from '@app/modules/task-priority/task-priority.service'
import { TaskRegardingService } from '@app/modules/task-regarding/task-regarding.service'
import { TaskTypeService } from '@app/modules/task-type/task-type.service'
import { TaskService } from '@app/modules/task/task.service'
import { TelephoneNameService } from '@app/modules/telephone-name/telephone-name.service'
import { TelephoneService } from '@app/modules/telephone/telephone.service'
import { TitleService } from '@app/modules/title/title.service'
import { UriNameService } from '@app/modules/uri-name/uri-name.service'
import { UriService } from '@app/modules/uri/uri.service'
import { UserService } from '@app/modules/user/user.service'

// -----------------------------------------------------------------------------
// Form services
// -----------------------------------------------------------------------------
import { DeleteIdFormService } from '@app/modules/delete-id/delete-id-form.service'

import { CompanyFormService } from '@app/modules/company/company-form.service'

import { AddressFormService } from '@app/modules/address/address-form.service'
import { AddressNameFormService } from '@app/modules/address-name/address-name-form.service'

import { EmailAddressFormService } from '@app/modules/email-address/email-address-form.service'
import { EmailAddressNameFormService } from '@app/modules/email-address-name/email-address-name-form.service'

import { SocialFormService } from '@app/modules/social/social-form.service'
import { SocialNameFormService } from '@app/modules/social-name/social-name-form.service'

import { TelephoneFormService } from '@app/modules/telephone/telephone-form.service'
import { TelephoneNameFormService } from '@app/modules/telephone-name/telephone-name-form.service'

import { UriFormService } from '@app/modules/uri/uri-form.service'
import { UriNameFormService } from '@app/modules/uri-name/uri-name-form.service'

import { OpportunityStatusFormService } from '@app/modules/opportunity-status/opportunity-status-form.service'
import { OpportunityStageFormService } from '@app/modules/opportunity-stage/opportunity-stage-form.service'
import { OpportunityProcessFormService } from '@app/modules/opportunity-process/opportunity-process-form.service'
import { OpportunityAgreementFormService } from '@app/modules/opportunity-agreement/opportunity-agreement-form.service'
import { OpportunityContactFormService } from '@app/modules/opportunity-contact/opportunity-contact-form.service'
import { ShutdownFormService } from '@app/modules/shutdown/shutdown-form.service'

import { TaskFormService } from '@app/modules/task/task-form.service'

import { SiteFormService } from '@app/modules/site/site-form.service'
import { ProbabilityFormService } from '@app/modules/probability/probability-form.service'

// -----------------------------------------------------------------------------
// Custom components
// -----------------------------------------------------------------------------
import { AlertComponent } from '@app/modules/alert/components/alert/alert.component'
import { ControlMessagesComponent } from '@app/shared/components/control-messages/control-messages.component'
import { ColumnSortComponent } from '@app/shared/components/column-sort/column-sort.component'
import { ConfirmationPopoverModule } from 'angular-confirmation-popover'

// -----------------------------------------------------------------------------
// Form input components
// -----------------------------------------------------------------------------
import { MyDatePickerComponent } from '@app/shared/components/my-date-picker/my-date-picker.component'
import { MyTimePickerComponent } from '@app/shared/components/my-time-picker/my-time-picker.component'
import { TableTelephoneDisplayComponent } from '@app/modules/telephone/components/table-telephone-display/table-telephone-display.component'
import {
  CompanyFinderFormArrayComponent
} from '@app/modules/company/components/company-finder-form-array/company-finder-form-array.component'
import { CompanyTypeaheadComponent } from '@app/modules/company/components/company-typeahead/company-typeahead.component'
import { CompanyIdTypeaheadComponent } from '@app/modules/company/components/company-id-typeahead/company-id-typeahead.component'

import { TaskSubjectTypeaheadComponent } from '@app/modules/task/components/task-subject-typeahead/task-subject-typeahead.component'
import { MyDateSearcherComponent } from '@app/shared/components/my-date-searcher/my-date-searcher.component'
import { SiteTypeaheadComponent } from '@app/modules/site/components/site-typeahead/site-typeahead.component'

// -----------------------------------------------------------------------------
// Tab table components
// -----------------------------------------------------------------------------

import { TabActivitiesTableComponent } from '@app/modules/task/components/tab-activities-table/tab-activities-table.component'
import { TabHistoryTableComponent } from '@app/modules/history/components/tab-history-table/tab-history-table.component'
import { TabDocumentsTableComponent } from '@app/modules/documents/components/tab-documents-table/tab-documents-table.component'

import {
  TabProductsServicesTableComponent
} from '@app/modules/product-service/components/tab-products-services-table/tab-products-services-table.component'

// -----------------------------------------------------------------------------
// Form components
// -----------------------------------------------------------------------------

import { CompanyFormComponent } from '@app/modules/company/components/company-form/company-form.component'

import { ProductServiceFormComponent } from '@app/modules/product-service/components/product-service-form/product-service-form.component'
import { TaskFormComponent } from '@app/modules/task/components/task-form/task-form.component'
import { MyFormInfoComponent } from '@app/shared/components/my-form-info/my-form-info.component'

import { AddressFormComponent } from '@app/modules/address/components/address-form/address-form.component'
import { SiteFormComponent } from '@app/modules/site/components/site-form/site-form.component'
// -----------------------------------------------------------------------------
// Form component parts
// -----------------------------------------------------------------------------
import { TelephonesFormArrayComponent } from '@app/modules/telephone/components/telephones-form-array/telephones-form-array.component'
import {
  EmailAddressesFormArrayComponent
} from '@app/modules/email-address/components/email-addresses-form-array/email-addresses-form-array.component'
import { AddressesFormArrayComponent } from '@app/modules/address/components/addresses-form-array/addresses-form-array.component'
import { SocialsFormArrayComponent } from '@app/modules/social/components/socials-form-array/socials-form-array.component'
import { UrisFormArrayComponent } from '@app/modules/uri/components/uris-form-array/uris-form-array.component'
import { AgreementPartFormComponent } from '@app/modules/agreement/components/agreement-part-form/agreement-part-form.component'
import { OfficeSyncComponent } from '@app/modules/office/components/office-sync/office-sync.component'

// -----------------------------------------------------------------------------
// Page components
// -----------------------------------------------------------------------------
// Opportunities.

// Contacts - Companies.
import { EditCompanyComponent } from '@app/modules/company/components/edit-company/edit-company.component'
import { ViewCompaniesComponent } from '@app/modules/company/components/view-companies/view-companies.component'

// Contacts - Sites.
import { EditSiteComponent } from '@app/modules/site/components/edit-site/edit-site.component'
import { ViewSitesComponent } from '@app/modules/site/components/view-sites/view-sites.component'

// Admin.

// -----------------------------------------------------------------------------
// Lists components
// -----------------------------------------------------------------------------
import { EditAddressNameComponent } from '@app/lists/address-names/edit-address-name/edit-address-name.component'
import { NewAddressNameComponent } from '@app/lists/address-names/new-address-name/new-address-name.component'
import { ViewAddressNamesComponent } from '@app/lists/address-names/view-address-names/view-address-names.component'

import { EditAgreementComponent } from '@app/lists/agreements/edit-agreement/edit-agreement.component'
import { NewAgreementComponent } from '@app/lists/agreements/new-agreement/new-agreement.component'
import { ViewAgreementsComponent } from '@app/lists/agreements/view-agreements/view-agreements.component'

import { EditCompanyTypeComponent } from '@app/lists/company-types/edit-company-type/edit-company-type.component'
import { NewCompanyTypeComponent } from '@app/lists/company-types/new-company-type/new-company-type.component'
import { ViewCompanyTypesComponent } from '@app/lists/company-types/view-company-types/view-company-types.component'

import { EditCountryComponent } from '@app/lists/countries/edit-country/edit-country.component'
import { NewCountryComponent } from '@app/lists/countries/new-country/new-country.component'
import { ViewCountriesComponent } from '@app/lists/countries/view-countries/view-countries.component'

import { EditDepartmentComponent } from '@app/lists/departments/edit-department/edit-department.component'
import { NewDepartmentComponent } from '@app/lists/departments/new-department/new-department.component'
import { ViewDepartmentsComponent } from '@app/lists/departments/view-departments/view-departments.component'

import { EditEmailAddressNameComponent } from '@app/lists/email-address-names/edit-email-address-name/edit-email-address-name.component'
import { NewEmailAddressNameComponent } from '@app/lists/email-address-names/new-email-address-name/new-email-address-name.component'
import { ViewEmailAddressNamesComponent } from '@app/lists/email-address-names/view-email-address-names/view-email-address-names.component'

import { EditGenderComponent } from '@app/lists/genders/edit-gender/edit-gender.component'
import { NewGenderComponent } from '@app/lists/genders/new-gender/new-gender.component'
import { ViewGendersComponent } from '@app/lists/genders/view-genders/view-genders.component'

import { EditLostReasonComponent } from '@app/lists/lost-reasons/edit-lost-reason/edit-lost-reason.component'
import { NewLostReasonComponent } from '@app/lists/lost-reasons/new-lost-reason/new-lost-reason.component'
import { ViewLostReasonsComponent } from '@app/lists/lost-reasons/view-lost-reasons/view-lost-reasons.component'

import { EditNoteTypeComponent } from '@app/lists/note-types/edit-note-type/edit-note-type.component'
import { NewNoteTypeComponent } from '@app/lists/note-types/new-note-type/new-note-type.component'
import { ViewNoteTypesComponent } from '@app/lists/note-types/view-note-types/view-note-types.component'

import {
  EditOpportunityProcessComponent
} from '@app/lists/opportunity-processes/edit-opportunity-process/edit-opportunity-process.component'
import { NewOpportunityProcessComponent } from '@app/lists/opportunity-processes/new-opportunity-process/new-opportunity-process.component'
import {
  ViewOpportunityProcessesComponent
} from '@app/lists/opportunity-processes/view-opportunity-processes/view-opportunity-processes.component'

import { EditOpportunityStageComponent } from '@app/lists/opportunity-stages/edit-opportunity-stage/edit-opportunity-stage.component'
import { NewOpportunityStageComponent } from '@app/lists/opportunity-stages/new-opportunity-stage/new-opportunity-stage.component'
import { ViewOpportunityStagesComponent } from '@app/lists/opportunity-stages/view-opportunity-stages/view-opportunity-stages.component'

import { EditOpportunityStatusComponent } from '@app/lists/opportunity-statuses/edit-opportunity-status/edit-opportunity-status.component'
import { NewOpportunityStatusComponent } from '@app/lists/opportunity-statuses/new-opportunity-status/new-opportunity-status.component'
import {
  ViewOpportunityStatusesComponent
} from '@app/lists/opportunity-statuses/view-opportunity-statuses/view-opportunity-statuses.component'

import { EditProbabilityComponent } from '@app/lists/probabilities/edit-probability/edit-probability.component'
import { NewProbabilityComponent } from '@app/lists/probabilities/new-probability/new-probability.component'
import { ViewProbabilitiesComponent } from '@app/lists/probabilities/view-probabilities/view-probabilities.component'

import { EditProductComponent } from '@app/lists/products/edit-product/edit-product.component'
import { NewProductComponent } from '@app/lists/products/new-product/new-product.component'
import { ViewProductsComponent } from '@app/lists/products/view-products/view-products.component'

import { EditSocialNameComponent } from '@app/lists/social-names/edit-social-name/edit-social-name.component'
import { NewSocialNameComponent } from '@app/lists/social-names/new-social-name/new-social-name.component'
import { ViewSocialNamesComponent } from '@app/lists/social-names/view-social-names/view-social-names.component'

import { EditSuppliesWhatComponent } from '@app/lists/supplies-what/edit-supplies-what/edit-supplies-what.component'
import { NewSuppliesWhatComponent } from '@app/lists/supplies-what/new-supplies-what/new-supplies-what.component'
import { ViewSuppliesWhatComponent } from '@app/lists/supplies-what/view-supplies-what/view-supplies-what.component'

import { EditTelephoneNameComponent } from '@app/lists/telephone-names/edit-telephone-name/edit-telephone-name.component'
import { NewTelephoneNameComponent } from '@app/lists/telephone-names/new-telephone-name/new-telephone-name.component'
import { ViewTelephoneNamesComponent } from '@app/lists/telephone-names/view-telephone-names/view-telephone-names.component'

import { EditTitleComponent } from '@app/lists/titles/edit-title/edit-title.component'
import { NewTitleComponent } from '@app/lists/titles/new-title/new-title.component'
import { ViewTitlesComponent } from '@app/lists/titles/view-titles/view-titles.component'

import { EditUriNameComponent } from '@app/lists/uri-names/edit-uri-name/edit-uri-name.component'
import { NewUriNameComponent } from '@app/lists/uri-names/new-uri-name/new-uri-name.component'
import { ViewUriNamesComponent } from '@app/lists/uri-names/view-uri-names/view-uri-names.component'

import {
  PersonApiService,
  PersonService,
  PersonFormService
} from '@app/modules/person'

import {
  ExistingPeopleFormService
} from '@app/modules/person/services'

import {
  TabEmployeesTableComponent,
  TabContactsTableComponent,
  PersonTypeaheadComponent,
  PersonFormComponent,
  ExistingPeopleFormComponent,
  EditPersonComponent,
  ViewPeopleComponent,
  TableEmployeeDisplayComponent
} from '@app/modules/person/components'

import {
  ProductApiService,
  ProductService,
  ProductFormService
} from '@app/modules/product'

import {
  ProductServiceApiService,
  ProductServiceService,
  ProductServiceFormService
} from '@app/modules/product-service'

import {
  OpportunityService,
  OpportunityFormService
} from '@app/modules/opportunity'

import {
  EditOpportunityComponent,
  OpportunityFormComponent,
  TabOpportunitiesTableComponent,
  ViewOpportunitiesComponent,
  OpportunitySearchSelectorComponent,
  TabProjectOverviewComponent
} from '@app/modules/opportunity/components'



import {
  TableEmailAddressDisplayComponent
} from '@app/modules/email-address/components'

import {
  ContactGroupApiService,
  ContactGroupService,
  ContactGroupFormService
} from '@app/modules/contact-group'

import {
  EditContactGroupComponent,
  ViewContactGroupsComponent,
  ContactGroupFormComponent,
  CustomQueriedContactsComponent
} from '@app/modules/contact-group/components'

import {
   QueryBuilderApiService,
   QueryBuilderService
} from '@app/modules/query-builder/services'

import {
  QueryBuilderComponent,
  TableJoinSelectorComponent,
  ColumnSelectorComponent,
  WhereGroupSelectorComponent
} from '@app/modules/query-builder/components'

import {
  SavedQueryApiService,
  SavedQueryService,
  SavedQueryFormService
} from '@app/modules/saved-query'

import {
  SavedQueryFormComponent,
  TabSavedQueriesTableComponent
} from '@app/modules/saved-query/components'

import {
  PersonToCompanyApiService,
  PersonToCompanyService,
  PersonToCompanyFormService
} from '@app/modules/person-to-company'

import {
  ViewCalendarComponent,
  EditTaskComponent,
  ViewTasksComponent,
  TableAssociatedWithDisplayComponent,
  TableScheduledWithDisplayComponent
} from '@app/modules/task/components'

import {
  FmComponent,
  FmSidebarComponent,
  FmNavbarComponent,
  FmFolderBranchItemComponent,
  FmCurrentFolderBreadcrumbComponent,
  FmMainTableComponent,
  FmMainIconsComponent,
  FmSpinnerComponent,
  FmUploadFilesFormComponent,
  FmNewFolderFormComponent,
  FmSelectedFilesMsgComponent
} from '@app/modules/filemanager/components'

import {
  ConfigService,
  ApiMiddlewareService,
  FileNavigatorService,
  TranslateService,
  FmApiService,
  FmService,
  FmNewFolderFormService
} from '@app/modules/filemanager/services'

import {
  StrLimitPipe,
  TranslatePipe,
  HumanizeFileSizePipe,
  FileExtensionPipe
} from '@app/modules/filemanager/pipes'

import { UserFormService } from '@app/modules/user/user-form.service'
import {
    EditUserComponent,
    UserFormComponent,
    ViewUsersComponent,
    UserRolesComponent,
    UserContactGroupsComponent,
    OfficePermissionsComponent
} from '@app/modules/user/components'

import {
    ViewUnallocatedEmailComponent,
    TabUnallocatedSentComponent,
    TabUnallocatedReceivedComponent,
    UnallocatedEmailRecipientsDisplayComponent,
    UnallocatedEmailFromDisplayComponent
} from '@app/modules/unallocated-email/components'

import {
    OperatorSearchComponent
} from '@app/shared/components/operator-search/operator-search.component'

import {
    TabRevisionTableComponent
} from '@app/modules/revision/components/tab-revision-table/tab-revision-table.component'

import {
    EmailMessageApiService,
    EmailMessageSearchService,
    EmailMessageFormService
} from '@app/modules/email-message/services'

import {
  OpportunityApiService,
  OpportunitySearchService
} from '@app/modules/opportunity/services'

import {
    TabViewMessagesComponent
} from '@app/modules/email-message/components'

import {
  ActivityLogApiService,
  ActivityLogSearchService
} from '@app/modules/activity-log/services'

import {
  ActivityLogTableTabComponent,
  ActivityLogChangesComponent
} from '@app/modules/activity-log/components'

import {
  SyncService
} from '@app/modules/sync/services'

import {
  SyncDatabaseComponent
} from '@app/modules/sync/components'

@NgModule({
  declarations: [
    SyncDatabaseComponent,
    ActivityLogTableTabComponent,
    ActivityLogChangesComponent,
    // CalendarComponent,
    TabRevisionTableComponent,
    OperatorSearchComponent,
    // Filemanager components.
    FmComponent,
    FmSidebarComponent,
    FmNavbarComponent,
    FmFolderBranchItemComponent,
    FmCurrentFolderBreadcrumbComponent,
    FmMainTableComponent,
    FmMainIconsComponent,
    FmSpinnerComponent,
    FmUploadFilesFormComponent,
    FmNewFolderFormComponent,
    FmSelectedFilesMsgComponent,
    // Filemanager pipes.
    StrLimitPipe,
    TranslatePipe,
    HumanizeFileSizePipe,
    FileExtensionPipe,
    HtmlPipe,

    // Pipes
    // SanitizingPipe,
    PhonePipe,
    TelPipe,
    ChangeCasePipe,
    HumanizeDurationPipe,

    AppComponent,
    HomeComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    LeftNavigationComponent,
    TopNavigationComponent,
    LoginComponent,
    PageNotFoundComponent,
    FooterComponent,
    DashboardComponent,
    ControlMessagesComponent,
    ColumnSortComponent,
    ProductServiceFormComponent,
    // Opportunities.
    ViewOpportunitiesComponent,
    EditOpportunityComponent,

    // Contacts - Companies
    EditCompanyComponent,
    ViewCompaniesComponent,

    // Contacts - Sites
    EditSiteComponent,
    ViewSitesComponent,

    // Users.
    EditUserComponent,
    UserFormComponent,
    ViewUsersComponent,
    UserRolesComponent,
    UserContactGroupsComponent,
    OfficePermissionsComponent,

    QueryBuilderComponent,
    TableJoinSelectorComponent,
    ColumnSelectorComponent,
    WhereGroupSelectorComponent,

    // Lists.
    EditAddressNameComponent,
    NewAddressNameComponent,
    ViewAddressNamesComponent,
    EditAgreementComponent,
    NewAgreementComponent,
    ViewAgreementsComponent,
    EditCompanyTypeComponent,
    NewCompanyTypeComponent,
    ViewCompanyTypesComponent,
    EditCountryComponent,
    NewCountryComponent,
    ViewCountriesComponent,
    EditDepartmentComponent,
    NewDepartmentComponent,
    ViewDepartmentsComponent,
    EditEmailAddressNameComponent,
    NewEmailAddressNameComponent,
    ViewEmailAddressNamesComponent,
    EditGenderComponent,
    NewGenderComponent,
    ViewGendersComponent,
    EditLostReasonComponent,
    NewLostReasonComponent,
    ViewLostReasonsComponent,
    EditNoteTypeComponent,
    NewNoteTypeComponent,
    ViewNoteTypesComponent,
    EditOpportunityProcessComponent,
    NewOpportunityProcessComponent,
    ViewOpportunityProcessesComponent,
    EditOpportunityStageComponent,
    NewOpportunityStageComponent,
    ViewOpportunityStagesComponent,
    EditOpportunityStatusComponent,
    NewOpportunityStatusComponent,
    ViewOpportunityStatusesComponent,
    EditProbabilityComponent,
    NewProbabilityComponent,
    ViewProbabilitiesComponent,
    EditProductComponent,
    NewProductComponent,
    ViewProductsComponent,
    EditSocialNameComponent,
    NewSocialNameComponent,
    ViewSocialNamesComponent,
    EditSuppliesWhatComponent,
    NewSuppliesWhatComponent,
    ViewSuppliesWhatComponent,
    EditTelephoneNameComponent,
    NewTelephoneNameComponent,
    ViewTelephoneNamesComponent,
    EditTitleComponent,
    NewTitleComponent,
    ViewTitlesComponent,
    EditUriNameComponent,
    NewUriNameComponent,
    ViewUriNamesComponent,
    // Other components.
    AlertComponent,
    MyDatePickerComponent,
    MyTimePickerComponent,
    MyDateSearcherComponent,
    InputTransformDirective,
    TableTelephoneDisplayComponent,
    CompanyFinderFormArrayComponent,
    CompanyTypeaheadComponent,
    CompanyIdTypeaheadComponent,

    TaskSubjectTypeaheadComponent,
    // Tab tables
    TabOpportunitiesTableComponent,
    TabActivitiesTableComponent,
    TabHistoryTableComponent,
    TabDocumentsTableComponent,
    TabProductsServicesTableComponent,

    SiteTypeaheadComponent,

    // Form Components.
    OpportunityFormComponent,

    CompanyFormComponent,
    TelephonesFormArrayComponent,
    EmailAddressesFormArrayComponent,
    AddressesFormArrayComponent,
    SocialsFormArrayComponent,
    UrisFormArrayComponent,
    AgreementPartFormComponent,
    OfficeSyncComponent,
    TaskFormComponent,
    MyFormInfoComponent,
    AddressFormComponent,
    SiteFormComponent,
    ConfirmModalComponent,
    TableEmailAddressDisplayComponent,

    // Contact group.
    EditContactGroupComponent,
    ViewContactGroupsComponent,
    ContactGroupFormComponent,
    CustomQueriedContactsComponent,
    // Saved query.
    SavedQueryFormComponent,
    TabSavedQueriesTableComponent,
    // People.
    TabEmployeesTableComponent,
    TabContactsTableComponent,
    PersonTypeaheadComponent,
    PersonFormComponent,
    ExistingPeopleFormComponent,
    EditPersonComponent,
    ViewPeopleComponent,
    TableEmployeeDisplayComponent,
    // Tasks.
    ViewCalendarComponent,
    EditTaskComponent,
    ViewTasksComponent,
    TableAssociatedWithDisplayComponent,
    TableScheduledWithDisplayComponent,

    // Inbox
    ViewUnallocatedEmailComponent,
    TabUnallocatedSentComponent,
    TabUnallocatedReceivedComponent,
    UnallocatedEmailRecipientsDisplayComponent,
    UnallocatedEmailFromDisplayComponent,

    OpportunitySearchSelectorComponent,
    TabProjectOverviewComponent,
    TabViewMessagesComponent
  ],
  imports: [
    BrowserModule,
    CalendarModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    FormsModule,
    HttpClientModule,
    HttpModule,
    LocalStorageModule.withConfig({
      prefix: 'tube-tech',
      storageType: 'localStorage'
    }),
    MomentModule,
    NgbModule.forRoot(),
    NgSelectModule,
    ReactiveFormsModule,
    // RouterModule.forRoot(ROUTES, {
    //   useHash: true
    // }),

    RouterModule.forRoot(ROUTES),
    StarRatingModule.forRoot(),
    CurrencyMaskModule,
    NgxDatatableModule,
    FileUploadModule,
    MsalModule.forRoot({
      clientID: '333d36fd-2556-44a9-8a68-03cc121b63d6'
    })
  ],
  entryComponents: [ConfirmModalComponent],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard,
    RoutingStateService,
    BroadcastService,
    // Guards.
    PendingChangesGuard,
    AlertService,
    EventService,
    AppService,
    AuthService,
    HttpService,
    HttpErrorServiceProvider,
    MyUtilityService,
    SelectOptionsService,
    ValidationService,
    // Api Services.
    ApiService,
    AlarmApiService,
    AddressNameApiService,
    AddressApiService,
    AgreementApiService,
    CompanyTypeApiService,
    CompanyApiService,
    CountryApiService,
    DepartmentApiService,
    EmailAddressNameApiService,
    EmailAddressApiService,
    GenderApiService,
    LostReasonApiService,
    NoteApiService,
    NoteTypeApiService,
    OpportunityAgreementApiService,
    OpportunityProcessApiService,
    OpportunityStageApiService,
    OpportunityStatusApiService,
    OpportunityApiService,

    ProductApiService,
    ProbabilityApiService,
    QueryBuilderApiService,
    QueryBuilderService,
    RoleApiService,
    ResourceApiService,
    SiteApiService,
    SocialNameApiService,
    SocialApiService,
    SuppliesWhatApiService,
    TaskDurationApiService,
    TaskPriorityApiService,
    TaskRegardingApiService,
    TaskTypeApiService,
    TaskApiService,
    TelephoneNameApiService,
    TelephoneApiService,
    TitleApiService,
    UriNameApiService,
    UriApiService,
    UserApiService,
    // Model Services.
    AlarmService,
    AddressService,
    AddressNameService,
    AgreementService,
    CompanyService,
    CompanyTypeService,
    CountryService,
    DepartmentService,
    EmailAddressNameService,
    EmailAddressService,
    GenderService,
    LoginService,
    LostReasonService,
    NoteService,
    NoteTypeService,
    OpportunityAgreementService,
    OpportunityProcessService,
    OpportunityStageService,
    OpportunityStatusService,
    OpportunityService,

    ProbabilityService,
    ProductService,
    SiteService,
    SocialNameService,
    SocialService,
    SuppliesWhatService,
    TaskDurationService,
    TaskPriorityService,
    TaskRegardingService,
    TaskTypeService,
    TaskService,
    TelephoneNameService,
    TelephoneService,
    TitleService,
    UriNameService,
    UriService,
    UserService,
    // Form services
    DeleteIdFormService,
    UserFormService,

    CompanyFormService,
    AddressFormService,
    AddressNameFormService,
    EmailAddressFormService,
    EmailAddressNameFormService,
    SocialFormService,
    SocialNameFormService,
    TelephoneFormService,
    TelephoneNameFormService,
    UriFormService,
    UriNameFormService,
    OpportunityFormService,
    OpportunityStatusFormService,
    OpportunityStageFormService,
    OpportunityProcessFormService,
    OpportunityAgreementFormService,
    OpportunityContactFormService,
    OpportunitySearchService,
    ShutdownFormService,
    TaskFormService,
    SiteFormService,
    ProbabilityFormService,
    // Grouped

    // Product.
    ProductApiService,
    ProductService,
    ProductFormService,
    // Product & Services
    ProductServiceApiService,
    ProductServiceService,
    ProductServiceFormService,
    // Contact group
    ContactGroupApiService,
    ContactGroupService,
    ContactGroupFormService,
    // Saved query
    SavedQueryApiService,
    SavedQueryService,
    SavedQueryFormService,
    // People.
    PersonApiService,
    PersonService,
    PersonToCompanyApiService,
    PersonToCompanyService,
    PersonToCompanyFormService,
    PersonFormService,
    ExistingPeopleFormService,
    // Office mail etc.
    EmailMessageApiService,
    EmailMessageSearchService,
    EmailMessageFormService,

    // Now using custom built components instead.
    // See MyDatePickerComponent & MyTimePickerComponent.
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeAdapter
    },
    // {
    //   provide: NgbTimeAdapter,
    //   useClass: NgbDateNativeAdapter
    // },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbUkDateParserFormatter
    },
    // Filemanager
    ConfigService,
    ApiMiddlewareService,
    FileNavigatorService,
    TranslateService,
    FmApiService,
    FmService,
    FmNewFolderFormService,
    ActivityLogApiService,
    ActivityLogSearchService,
    SyncService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
