import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { QueryItem } from '@app/modules/query-builder/query-item.model'
import { QueryBuilderService, QueryBuilderApiService } from '../../services'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { v4 as uuidv4 } from 'uuid'

@Component({
  selector: 'app-where-group-selector',
  templateUrl: './where-group-selector.component.html'
})

export class WhereGroupSelectorComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * First
   *
   * Some styling changes if this is true, also we don't show the delete
   * button for the first item.
   */
  @Input() first: boolean

  /**
   * Parent query item
   */
  @Input() parentQueryItem: QueryItem

  /**
   * Where groups
   */
  @Input() whereGroups: Array<any> = []

  /**
   * Where groups
   *
   * @type {EventEmitter<Array<any>>}
   */
  @Output() whereGroupsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>()

  /**
   * Where group operators
   */
  public whereGroupOperators: Array<any> = [
    {
      name: 'and'
    },
    {
      name: 'or'
    }
  ]

  /**
   * My operator map
   */
  public operatorMap: any

  /**
   * Subscriptions
   */
  // private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public queryBuilderService: QueryBuilderService,
    public api: QueryBuilderApiService,
    public selectOptionsService: SelectOptionsService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    // this.subscriptions.forEach((subscription) => {
    //   subscription.unsubscribe()
    // })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.operators()
    this.display = true
  }

  /**
   * Add where group
   */
  public addWhereGroup (): void {
    console.log('addWhereGroup')
    this.whereGroups.push({
      uuid: uuidv4(),
      operator: 'and',
      wheres: []
    })
  }

  /**
   * Remove where group
   *
   * @param {any} whereGroup Where group
   */
  public removeWhereGroup (whereGroup: any): void {
    const index: number = this.whereGroups.findIndex(x => x.uuid === whereGroup.uuid)
    console.log(index)
    if (index !== -1) {
      this.whereGroups.splice(index, 1)
    }
  }

  /**
   * Add where
   *
   * @param {any} whereGroup Where group
   */
  public addWhere (whereGroup: any): void {
    whereGroup.wheres.push({
      uuid: uuidv4(),
      displayName: null,
      column: null,
      operator: 'equal',
      columnType: 'string',
      value: null,
      value2: null,
      options: [],
      optionColumnName: null
    })
  }

  /**
   * Remove where
   *
   * @param {any} whereGroup Where group
   * @param {any} where      Where
   */
  public removeWhere (whereGroup: any, where: any): void {
    const wg: any = this.whereGroups.find(x => x.uuid === whereGroup.uuid)
    const index: number = wg.wheres.findIndex(x => x.uuid === where.uuid)
    if (index !== -1) {
      wg.wheres.splice(index, 1)
    }
  }

  /**
   * Change where
   *
   * @param {any} where Where
   */
  public changeWhere (where: any): void {

    const availableWhere: any = this.queryBuilderService.whereColumnsAvailable.find(x => x.column === where.column)
    if (!availableWhere) {
      console.error('could not find it')
      return
    }

    console.log('availableWhere', availableWhere)
    if (availableWhere) {
      where.displayName = availableWhere.displayName
      where.columnType = availableWhere.columnType
      where.operator = 'equal'
      where.value = where.columnType === 'boolean' ? true : null
      where.value2 = null
    }

    const columnJoins: Array<string> = availableWhere.column.split('.')

    if (columnJoins.length === 2) {

      where.optionColumnName = columnJoins[1]

      this.api.options({
        table: columnJoins[0]
      })
      .toPromise()
      .then((r) => {
        where.options = r
      })
    }
  }

  /**
   * Operators
   */
  private operators (): void {
    this.operatorMap = {
      string: [
        'equal',
        'not_equal',
        'begins_with',
        'not_begins_with',
        'contains',
        'not_contains',
        'ends_with',
        'not_ends_with'
      ],
      number: [
        'equal',
        'not_equal',
        'greater',
        'greater_or_equal',
        'between',
        'less',
        'less_or_equal',
        'begins_with',
        'not_begins_with',
        'contains',
        'not_contains',
        'ends_with',
        'not_ends_with'
      ],
      time: [
        'equal',
        'not_equal',
        'greater',
        'greater_or_equal',
        'between',
        'less',
        'less_or_equal',
        'begins_with',
        'not_begins_with',
        'contains',
        'not_contains',
        'ends_with',
        'not_ends_with'
      ],
      date: [
        'equal',
        'not_equal',
        'greater',
        'greater_or_equal',
        'between',
        'less',
        'less_or_equal'
      ],
      category: [
        'equal',
        'not_equal',
        'in',
        'not_in'
      ],
      boolean: [
        'equal'
        // 'not_equal'
      ],
      multiselect: [
        'in',
        'not_in'
      ]
    }
  }

}
