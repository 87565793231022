/**
 * Query item
 *
 * Stores table info for the query builder.
 */
export class QueryItem {
  /**
   * Id
   */
  uuid: string = null

  /**
   * Table name
   */
  tableName: string = null

  /**
   * Column name
   */
  columnName: string = null

  /**
   * Referenced table name
   */
  refTableName: string = null

  /**
   * Referenced column name
   */
  refColumnName: string = null

  /**
   * Foregn key name
   */
  fkName: string = null

  /**
   * Display name
   */
  displayName: string = null

  /**
   * Join type
   *
   * LEFT, INNER, RIGHT
   */
  joinType: string = null

  /**
   * Relation type
   *
   * Type: 'oneToOne' parent.child_id = child.uuid
   * Type: 'oneToMany' parent.uuid = child.parent_id
   * Type: 'manyToMany' parent.uuid = pivot.parent_id, pivot.child_id = child.uuid
   */
  relationType: string = null

  /**
   * Joins
   *
   * @type {Array<QueryItem>}
   */
  joins: Array<QueryItem> = []

  /**
   * Joins
   *
   * @type {Array<QueryItem>}
   */
  joinsAvailable: Array<QueryItem> = []

  /**
   * Constructor
   *
   * @param {Partial<QueryItem>} data Initial values
   */
  public constructor (data?: Partial<QueryItem>) {
    if (data) {
      Object.assign(this, data)
    }
  }

}
