import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import {
  OpportunitySearchService
} from '@app/modules/opportunity/services'

import {
  Opportunity
} from '@app/modules/opportunity'
import { EventService } from '@app/shared/services/event.service'

@Component({
  selector: 'app-opportunity-search-selector',
  templateUrl: './opportunity-search-selector.component.html'
})

export class OpportunitySearchSelectorComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Opportunities
   */
  public opportunities: Array<any> = []

  /**
   * Search form
   */
  public searchForm: FormGroup

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    private renderer: Renderer,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    public opportunitySearchService: OpportunitySearchService,
    private eventService: EventService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.opportunitySearchService.pagination.pageSize = 10,
    this.display = true
    this.searchForm = this.opportunitySearchService.getForm()
    this.searchSub()
    this.searchOpportunities()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.opportunitySearchService.pagination.currentPage = 1
      this.searchOpportunities()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search opportunities
   */
  public searchOpportunities (): Promise<any> {
    return this.opportunitySearchService.search(this.searchForm.value)
    .then((results) => {
      this.opportunitySearchService.pagination.currentPageSize = results.length
      this.opportunities = results
      this.display = true
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.opportunitySearchService.sort = {
      column: 'opportunity.updatedAt',
      direction: 'DESC'
    }
    this.searchForm.reset()
    this.searchForm.patchValue({
      finalInvoiceOperator: 'eq'
    })
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    this.opportunitySearchService.setSort(column)
    this.searchOpportunities()
  }

  /**
   * Change page size
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.opportunitySearchService.pagination.currentPage = 1
    this.searchOpportunities()
  }

  /**
   * Allocate
   */
  public allocate (opportunity: Opportunity): void {
    this.eventService.events.emit({
      name: 'OPPORTUNITY_ALLOCATED',
      from: 'OpportunitySearchSelectorComponent.allocate',
      desc: 'Allocated Oportunity.',
      data: {
        opportunityUuid: opportunity.uuid
      }
    })
  }

}
