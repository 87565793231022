import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'

export class Shutdown extends Base {
  uuid: string = null
  personUuid: string = null
  chaseDate: Date = null
  shutdownDate: Date = null
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null

  /**
   * Properties with type Date
   *
   * @type {Array<string>}
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt',
    'chaseDate',
    'shutdownDate'
  ]

  /**
   * Constructor
   *
   * @param {Partial<Shutdown>} data Initial values
   */
  public constructor (data?: Partial<Shutdown>) {
    super(data)
    this.init(data)
  }
}
