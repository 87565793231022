import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { QueryItem } from '@app/modules/query-builder/query-item.model'
import { SelectItem } from '@app/modules/query-builder/select-item.model'
import { QueryBuilderService } from '../../services'
import { v4 as uuidv4 } from 'uuid'

@Component({
  selector: 'app-column-selector',
  templateUrl: './column-selector.component.html'
})

export class ColumnSelectorComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Parent query item
   *
   * @type {QueryItem}
   */
  @Input() parentQueryItem: QueryItem

  /**
   * Selects
   */
  @Input() selects: Array<SelectItem> = []

  /**
   * Selects change
   *
   * @type {EventEmitter<Array<any>>}
   */
  @Output() selectsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>()

  /**
   * Constructor
   */
  public constructor (
    public queryBuilderService: QueryBuilderService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.display = true
  }

  /**
   * Add select
   */
  public addSelect (): void {
    this.selects.push(new SelectItem({
      uuid: uuidv4()
    }))
  }

  /**
   * Remove select
   *
   * @param {any} select Select
   */
  public removeSelect (select: any): void {
    const index: number = this.selects.findIndex(x => x.uuid === select.uuid)
    if (index !== -1) {
      this.selects.splice(index, 1)
    }
  }

}
