import { Base } from '@app/shared/models/base.model'
import { User } from '@app/modules/user/user.model'
import { TaskType } from '@app/modules/task-type/task-type.model'
import { Person } from '@app/modules/person/person.model'
import { Company } from '@app/modules/company/company.model'

export class Task extends Base {
  uuid: string = null
  selectedContact: string = null // ???
  selectedCompany: string = null // ???
  taskTypeUuid: string = null
  regarding: any = null // ??
  regardingUuid: string = null // ??
  startDatetime: Date = null
  endDatetime: Date = null
  originalDatetime: Date = null
  isTimeless: boolean
  location: string = null
  isPrivate: boolean
  organiserUserUuid: string = null
  details: string = null
  masterUuid: string = null
  parentUuid: string = null
  recurSourceUuid: string = null
  isEvent: boolean
  recurPeriod: string = null
  recurFreq: string = null
  recurModifier: string = null
  recurDay: string = null
  recurDayType: string = null
  recurMonth: string = null
  recurEndDate: Date = null
  isRecurEndless: boolean
  isDeleted: boolean
  deletedDates: string = null
  isInvitationSent: boolean
  duration: number = null
  assignedToUserUuid: string = null // ??
  assignedByUserUuid: string = null // ??
  taskPriorityUuid: string = null
  isComplete: boolean
  // ---------------------------------------------------------------------------
  createdAt: Date = null
  updatedAt: Date = null
  deletedAt: Date = null
  createdByUuid: string = null
  createdByUser: User = null
  updatedByUuid: string = null
  updatedByUser: User = null
  // Relations.
  taskType: TaskType = null
  scheduledWith: Array<Person> = []
  associatedWith: Array<Company> = []

  /**
   * Properties with type Date
   */
  protected _dates: Array<string> = [
    'createdAt',
    'updatedAt',
    'deletedAt',
    'startDatetime',
    'endDatetime',
    'originalDatetime',
    'recurEndDate'
  ]

  /**
   * Constructor
   *
   * @param data Initial values
   */
  public constructor (data?: Partial<Task>) {
    super(data)
    this.isTimeless = false
    this.isPrivate = false
    this.isEvent = false
    this.isRecurEndless = false
    this.isDeleted = false
    this.isInvitationSent = false
    this.isComplete = false
    this.init(data)
  }
}
