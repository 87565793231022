import { Injectable, ElementRef } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { LocalStorageService } from 'angular-2-local-storage'
import { ApiMiddlewareService } from './api-middleware.service'
import { FmItem } from '../models/fm-item.model'
import { ConfigService } from './config.service'
import { FileNavigatorService } from './file-navigator.service'
import { TranslateService } from './translate.service'
import { NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { FileUploader, FileItem } from 'ng2-file-upload'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { FmNewFolder } from '../models/fm-new-folder.model'
import { FmNewFolderFormService } from '../services/fm-new-folder-form.service'

@Injectable()

export class FmService {

  // @ViewChild('modalTemplate') private modalTemplate: ElementRef // TemplateRef

  public config: any

  public uploader: FileUploader

  public reverse: boolean

  public predicate: Array<any>

  public query: string

  public uploadFileList: Array<any>

  public viewTemplate: string

  // public fileList: Array<any>

  public temps: Array<FmItem>

  public isWindows: boolean

  public selectedModalPath: Array<any>

  public temp: any // ??

  public clickInterval: any

  public clickDelay: number

  public clickPrevent: boolean

  public currentDropdownMenu: NgbDropdown

  /**
   * Modal template
   *
   * Is set in fm.component.ts
   */
  public modalTemplate: ElementRef

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Current modal type
   */
  public currentModalType: string

  /**
   * Current modal title
   */
  public currentModalTitle: string

  /**
   * Current modal title
   */
  public currentModalForm: FormGroup

  /**
   * Constructor
   */
  public constructor (
    public authService: AuthService,
    private configService: ConfigService,
    public localStorageService: LocalStorageService,
    private apiMiddlewareService: ApiMiddlewareService,
    public fileNavigatorService: FileNavigatorService,
    public translateService: TranslateService,
    private modalService: NgbModal,
    private fmNewFolderFormService: FmNewFolderFormService
  ) {
    // this.init()
  }

  /**
   * Init
   */
  public init (): void {
    this.clickDelay = 150
    this.clickPrevent = false
    this.config = this.configService.config
    this.reverse = false
    this.predicate = [
      'type',
      'name'
    ]
    this.query = ''
    this.uploadFileList = []
    this.viewTemplate = this.localStorageService.get('viewTemplate') || 'main-icons'
    // this.fileList = []
    this.temps = []
    this.watchTemps()

    this.changeLanguage(this.getQueryParam('lang'))
    this.isWindows = this.getQueryParam('server') === 'Windows'
    this.fileNavigatorService.init()
    this.fileNavigatorService.refresh()
  }

  /**
   * Order
   */
  public order (predicate: string): void {
    this.reverse = (this.predicate[1] === predicate) ? !this.reverse : false
    this.predicate[1] = predicate
  }

  /**
   * Watch temps
   *
   * @todo nNot suew how this works yet.
   */
  public watchTemps (): void {
    // const sub: Subscription = this.temps.subscibe((data) => {
    //   if (this.singleSelection()) {
    //     this.temp = this.singleSelection()
    //   } else {
    //       this.temp = new FmItem({
    //         rights: 644
    //       })
    //       this.temp.multiple = true
    //   }
    //   this.temp.revert()
    // })

    // this.subscriptions.push(sub)
  }

  // $scope.fileNavigatorService.onRefresh = function() {
  //     $scope.temps = [];
  //     $scope.query = '';
  //     $rootScope.selectedModalPath = $scope.fileNavigatorService.currentPath;
  // }

  /**
   * Set template
   */
  public setTemplate (name: string): void {
    this.localStorageService.set('viewTemplate', name)
    this.viewTemplate = name
  }

  /**
   * Change language
   */
  public changeLanguage (locale: string): void {
    if (locale) {
      this.localStorageService.set('language', locale)
      return this.translateService.use(locale)
    }
    this.translateService.use(this.localStorageService.get('language') || this.config.defaultLang)
  }

  /**
   * Is selected
   */
  public isSelected (item: FmItem): boolean {
    return this.temps.indexOf(item) !== -1
  }

  /**
   * Select or unselect
   */
  public selectOrUnselect (event: MouseEvent, item: FmItem, dropdown?: NgbDropdown): void {
    // Set a timeout to wait for a detect a second click to handle double clicks.
    this.clickInterval = setTimeout(() => {
      if (!this.clickPrevent) {
        this.doSelectOrUnselect(event, item, dropdown)
      }
      this.clickPrevent = false
    }, this.clickDelay)
  }

  /**
   * Do select or unselect
   */
  public doSelectOrUnselect (event: MouseEvent, item: any, dropdown?: NgbDropdown): void {
    if (this.currentDropdownMenu) {
      this.currentDropdownMenu.close()
    }

    const indexInTemp: number = this.temps.indexOf(item)
    const isRightClick: boolean = event && event.buttons === 2

    // if (event && event.target.hasAttribute('prevent')) {
    //   this.temps = []
    //   return
    // }

    if (event && event.target['prevent']) {
      console.log('setting temps empty')
      this.temps = []
      return
    }

    // Changed this to select and display the dropdown on right click. ...might change it back.
    // if (!item || (isRightClick && this.isSelected(item))) {
    if (!item || isRightClick) {
      if (dropdown) {
        this.currentDropdownMenu = dropdown
        dropdown.open()
      }
      // return
    }

    if (event && event.shiftKey && !isRightClick) {
      const list: Array<FmItem> = this.fileNavigatorService.fileList
      const indexInList: number = list.indexOf(item)
      const lastSelected: FmItem = this.temps[0]
      let i: number
      i = list.indexOf(lastSelected)
      let current: FmItem
      current = undefined

      if (lastSelected && list.indexOf(lastSelected) < indexInList) {
        this.temps = []
        while (i <= indexInList) {
          current = list[i]
          if (!this.isSelected(current)) {
            this.temps.push(current)
          }
          i++
        }

        return
      }

      if (lastSelected && list.indexOf(lastSelected) > indexInList) {
        this.temps = []
        while (i >= indexInList) {
          current = list[i]
          if (!this.isSelected(current)) {
            this.temps.push(current)
          }
          i--
        }
        return
      }
    }

    if (event && !isRightClick && (event.ctrlKey || event.metaKey)) {
      this.isSelected(item) ? this.temps.splice(indexInTemp, 1) : this.temps.push(item)
      return
    }

    this.temps = [item]
  }

  /**
   * Single selection
   */
  public singleSelection (): FmItem {
    // console.log(this.temps)
    if (this.temps.length === 1) {
      return this.temps[0]
    }
    return
  }

  /**
   * Total selecteds
   */
  public totalSelecteds (): any {
    return {
      total: this.temps.length
    }
  }

  /**
   * Selection has
   *
   * @deprecated Using item.isFolder() instead
   */
  public selectionHas (type: string): boolean {
    const index: number = this.temps.findIndex((x) => x.type === type)
    return index !== -1
  }

  /**
   * Prepare new folder
   *
   * @deprecated We dont want to prepareNewFolder because it creates an
   *             empty item and messes with current path it's handled in
   *             item.tmpModel anyway and appears on refresh.
   */
  public prepareNewFolder (): FmItem {
    const item: FmItem = new FmItem({
      path: this.fileNavigatorService.currentPath
    })

    this.temps = [item]

    return item
  }

  /**
   * Smart click
   */
  public smartClick (item: FmItem): void {
    clearTimeout(this.clickInterval)
    this.clickPrevent = true
    this.doSmartClick(item)
  }

  /**
   * Do smart click
   */
  public doSmartClick (item: FmItem): void {
    const pick: boolean = this.config.allowedActions.pickFiles
    if (item.isFolder()) {
      return this.fileNavigatorService.folderClick(item)
    }

    if (typeof this.config.pickCallback === 'function' && pick) {
      const callbackSuccess: boolean = this.config.pickCallback(item)
      if (callbackSuccess === true) {
        return
      }
    }

    if (item.isImage()) {
      if (this.config.previewImagesInModal) {
        // return this.openImagePreview(item)
        return this.openImagePreview()
      }
      return this.apiMiddlewareService.download(item, true)
    }

    if (item.isEditable()) {
      // return this.openEditFmItem(item)
      return this.openEditFmItem()
    }
  }

  /**
   * Open image preview
   */
  public openImagePreview (): void {
    const item: FmItem = this.singleSelection()
    this.apiMiddlewareService.fmApiService.inprocess = true
    this.modal('imagepreview', null, true)
    // .find('#imagepreview-target')
    // .attr('src', this.getUrl(item))
    // .unbind('load error')
    // .on('load error', () => {
    //   this.apiMiddlewareService.fmApiService.inprocess = false
    //   // $scope.$apply() ???
    // })
    // @todo
    if (item) {}
  }

  /**
   * Open image preview
   */
  public openEditFmItem (): void {
    const item: FmItem = this.singleSelection()
    this.apiMiddlewareService.getContent(item)
    .then((data) => {
      item.tempModel.content = item.content = data.result
    })
    this.modal('edit')
  }

  /**
   * Modal
   *
   * @deprecated See openModal()
   */
  public modal (elmentId: string, hide?: boolean, returnElement?: any): string {
    if (elmentId) {}
    if (hide) {}
    if (returnElement) {}
    // const element = angular.element('#' + uuid)
    // element.modal(hide ? 'hide' : 'show')
    // this.apiMiddlewareService.fmApiService.error = ''
    // this.apiMiddlewareService.fmApiService.asyncSuccess = false
    // return returnElement ? element : true
    return '' // @todo
  }

  /**
   * Open modal
   */
  public openModal (event: Event, modalType: string, data?: any): void {
    if (data) {}
    event.preventDefault()
    event.stopPropagation()

    if (this.currentDropdownMenu) {
      this.currentDropdownMenu.close()
    }

    if (this.currentModal) {
      this.currentModal.close()
    }

    this.currentModalType = modalType

    if (modalType === 'move' || modalType === 'copy') {
      this.selectedModalPath = this.fileNavigatorService.currentPath
    }

    // if (type === 'newCompany') {
    //   const company: Company = new Company()
    //   this.companyForm = this.companyFormService.getForm(company, 'new')
    // }

    // if (type === 'editCompany') {
    //   const company: Company = new Company(data)
    //   this.companyForm = this.companyFormService.getForm(company, 'edit')
    // }
    //
    switch (modalType) {
      case 'imagepreview':
        this.currentModalTitle = 'preview'
        break
      case 'remove':
        this.currentModalTitle = 'confirm'
        break
      case 'move':
        this.currentModalTitle = 'move'
        break
      case 'rename':
        this.currentModalTitle = 'rename'
        this.currentModalForm = this.fmNewFolderFormService.getForm(new FmNewFolder(), null)
        const item: FmItem = this.singleSelection()
        this.currentModalForm.patchValue({ name: item.name })
        break
      case 'copy':
        this.currentModalTitle = 'copy_file'
        break
      case 'compress':
        this.currentModalTitle = 'compress'
        break
      case 'extract':
        this.currentModalTitle = 'extract_item'
        break
      case 'edit':
        this.currentModalTitle = 'edit_file'
        break
      case 'newfolder':
        this.currentModalTitle = 'new_folder'
        // We dont want to prepareNewFolder because it creates an empty item and messes with current path
        // this.prepareNewFolder()
        this.currentModalForm = this.fmNewFolderFormService.getForm(new FmNewFolder(), null)
        break
      case 'uploadfile':
        this.currentModalTitle = 'upload_files'
        // https://github.com/valor-software/ng2-file-upload/blob/development/demo/src/app/components/file-upload/simple-demo.ts
        this.uploader = new FileUploader({
          url: this.config.uploadUrl,
          headers: [
            {
              name: 'Authorization',
              value: 'Bearer ' + this.authService.getApiKey()
            }
          ]
        })

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: FormData): any => {
          const destination: string = '/' + this.fileNavigatorService.currentPath.join('/')
          form.append('destination', destination)
          console.log('item', fileItem)
          console.log('form', form)
        }

        // Have a look at onCompleteAll on the uploader class.
        this.uploader.onCompleteItem = (fileItem: FileItem, response: any, status: any, headers: any): any => {
          if (headers) {}
          // var responsePath = JSON.parse(response);
          console.log(fileItem)
          console.log(response)
          console.log(status)
          // console.log(headers)
          this.fileNavigatorService.refresh()
        }

        break
      case 'changepermissions':
        this.currentModalTitle = 'change_permissions'
        break
      default:
        console.error('Modal type not found', modalType)
    }

    this.currentModal = this.modalService.open(this.modalTemplate, {
      size: 'lg',
      windowClass: 'fade xmodal-xl',
      keyboard: false
    })
  }

  /**
   * Modal save
   */
  public modalSave (event: Event, modalType: string): void {
    console.log('event', event)
    console.log('modalType', modalType)
    switch (modalType) {
      case 'imagepreview':
        this.currentModalTitle = 'preview'
        break
      case 'remove':
        this.currentModalTitle = 'confirm'
        this.remove()
        break
      case 'move':
        this.currentModalTitle = 'move'
        break
      case 'rename':
        this.currentModalTitle = 'rename'
        this.rename()
        break
      case 'copy':
        this.currentModalTitle = 'copy_file'
        break
      case 'compress':
        this.currentModalTitle = 'compress'
        break
      case 'extract':
        this.currentModalTitle = 'extract_item'
        break
      case 'edit':
        this.currentModalTitle = 'edit_file'
        break
      case 'newfolder':
        this.currentModalTitle = 'new_folder'
        this.createFolder()
        break
      case 'uploadfile':
        break
      case 'changepermissions':
        this.currentModalTitle = 'change_permissions'
        break
      default:
        console.error('Modal type not found', modalType)
    }

  }

  /**
   * Modal with path selector
   *
   * @deprecated See openModal()
   */
  public modalWithPathSelector (elementId: string): string {
    this.selectedModalPath = this.fileNavigatorService.currentPath
    return this.modal(elementId)
  }

  /**
   * Is in this path
   */
  public isInThisPath (path: string): boolean {
    const currentPath: string = this.fileNavigatorService.currentPath.join('/') + '/'
    return currentPath.indexOf(path + '/') !== -1
  }

  /**
   * Edit
   */
  public edit (): void {
    this.apiMiddlewareService.edit(this.singleSelection())
    .then(() => {
      this.modal('edit', true)
    })
  }

  public changePermissions (): any {
    this.apiMiddlewareService.changePermissions(this.temps, this.temp).then(() => {
      this.fileNavigatorService.refresh()
      this.modal('changepermissions', true)
    })
  }

  /**
   * Open local file
   *
   * @todo Figure out a way to do this, seems it's blocked in modern browsers.
   *
   * @returns void
   */
  public openLocalFile (): void {
    const item: FmItem = this.singleSelection()
    const itemPath: string = item.fullPath()
    const url: string = this.config.localVolume + itemPath

    if (item.isFolder()) {
      return
    }

    window.open(url, null)
  }

  /**
   * Download
   */
  public download (): void {
    const item: FmItem = this.singleSelection()
    if (item.isFolder()) {
      return
    }

    if (item) {
      this.apiMiddlewareService.download(item)
      .toPromise()
      .then((result) => {
        const url: any = window.URL.createObjectURL(result)
        const a: HTMLAnchorElement = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none')
        a.href = url
        a.download = item.name
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()
        return
      })
      .catch((err) => {
        console.error(err)
      })
    }

   // return this.apiMiddlewareService.downloadMultiple(this.temps)
   //  .then(() => {
   //    return
   //  })
   //  .catch((err) => {
   //    console.error(err)
   //  })
  }

  /**
   * Copy
   */
  public copy (): boolean {
    const item: FmItem = this.singleSelection()
    if (item) {
      const name: string = item.tempModel.name.trim()
      const nameExists: boolean = this.fileNavigatorService.fileNameExists(name)
      if (nameExists && this.validateSamePath(item)) {
        this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
        return false
      }
      if (!name) {
        this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
        return false
      }
    }
    this.apiMiddlewareService.copy(this.temps, this.selectedModalPath).then(() => {
      this.fileNavigatorService.refresh()
      this.modal('copy', true)
    })
  }

  public compress (): any {
    const name: string = this.temp.tempModel.name.trim()
    const nameExists: boolean = this.fileNavigatorService.fileNameExists(name)

    if (nameExists && this.validateSamePath(this.temp)) {
      this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
      return false
    }
    if (!name) {
      this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
      return false
    }

    this.apiMiddlewareService.compress(this.temps, name, this.selectedModalPath)
    .then(() => {
      this.fileNavigatorService.refresh()
      if (!this.config.compressAsync) {
        return this.modal('compress', true)
      }
      this.apiMiddlewareService.fmApiService.asyncSuccess = true
    }, () => {
      this.apiMiddlewareService.fmApiService.asyncSuccess = false
    })
  }

  public extract (): any {
    const item: FmItem = this.temp
    const name: string = this.temp.tempModel.name.trim()
    const nameExists: boolean = this.fileNavigatorService.fileNameExists(name)

    if (nameExists && this.validateSamePath(this.temp)) {
      this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
      return false
    }
    if (!name) {
      this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
      return false
    }

    this.apiMiddlewareService.extract(item, name, this.selectedModalPath).then(() => {
      this.fileNavigatorService.refresh()
      if (!this.config.extractAsync) {
        return this.modal('extract', true)
      }
      this.apiMiddlewareService.fmApiService.asyncSuccess = true
    }, () => {
      this.apiMiddlewareService.fmApiService.asyncSuccess = false
    })
  }

  /**
   * Remove
   */
  public remove (): void {
    // $scope.apiMiddleware.remove($scope.temps).then(function() {
    //     $scope.fileNavigator.refresh();
    //     $scope.modal('remove', true);
    // });

    this.apiMiddlewareService.remove(this.temps)
    .then(() => {
      this.fileNavigatorService.refresh()
      this.currentModal.close()
      // this.modal('remove', true)
    })
    .catch((err) => {
      this.currentModal.close()
      console.error(err)
    })
  }

  /**
   * Move
   */
  public move (): boolean {
    const anyFmItem: FmItem = this.singleSelection() || this.temps[0]
    if (anyFmItem && this.validateSamePath(anyFmItem)) {
      this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_cannot_move_same_path')
      return false
    }
    this.apiMiddlewareService.move(this.temps, this.selectedModalPath).then(() => {
      this.fileNavigatorService.refresh()
      this.modal('move', true)
    })
  }

  /**
   * Rename folder or file
   */
  public rename (): any {

    // var item = $scope.singleSelection();
    // var name = item.tempModel.name;
    // var samePath = item.tempModel.path.join('') === item.model.path.join('');
    // if (!name || (samePath && $scope.fileNavigator.fileNameExists(name))) {
    //     $scope.apiMiddleware.apiHandler.error = $translate.instant('error_invalid_filename');
    //     return false;
    // }
    // $scope.apiMiddleware.rename(item).then(function() {
    //     $scope.fileNavigator.refresh();
    //     $scope.modal('rename', true);
    // });

    const item: FmItem = this.singleSelection()
    const name: string = this.currentModalForm.value.name
    item.tempModel.name = name

    const samePath: boolean = item.tempModel.path.join('') === item.path.join('')

    if (!name || (samePath && this.fileNavigatorService.fileNameExists(name))) {
      this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
      return false
    }

    this.apiMiddlewareService.rename(item)
    .then(() => {
      this.fileNavigatorService.refresh()
      this.currentModal.close()
      // this.modal('rename', true)
    })
    .catch((err) => {
      this.currentModal.close()
      console.error(err)
    })

  }

  /**
   * Create folder
   */
  public createFolder (): any {
    console.log('createFolder.currentPath', this.fileNavigatorService.currentPath)
    // var item = $scope.singleSelection();
    // var name = item.tempModel.name;
    // if (!name || $scope.fileNavigator.fileNameExists(name)) {
    //     return $scope.apiMiddleware.apiHandler.error = $translate.instant('error_invalid_filename');
    // }
    // $scope.apiMiddleware.createFolder(item).then(function() {
    //     $scope.fileNavigator.refresh();
    //     $scope.modal('newfolder', true);
    // });

    // Single selection is failing me must have an error somewhere
    // in the conversion.
    // let item: FmItem
    // item = this.singleSelection()

    // If we dont have a folder selected we should be in the basePath
    // so generate an item for the current path.
    // if (!item) {
    const currPath: Array<any> = []
    this.fileNavigatorService.currentPath.map((x) => {
      currPath.push(x)
    })

    const item: FmItem = new FmItem({
      name: currPath[currPath.length - 1],
      path: currPath,
      type: 'dir'
    })

    console.log(item.path)
    // return
    // Just in case this occurs.
    // @todo handle the error.
    if (!item.isFolder()) {
      console.error('Cant make a child of a file.')
      this.currentModal.close()
      return
    }

    const name: string = this.currentModalForm.value.name
    item.tempModel.name = name

    if (!name || this.fileNavigatorService.fileNameExists(name)) {
      return this.apiMiddlewareService.fmApiService.error = this.translateService.instant('error_invalid_filename')
    }

    this.apiMiddlewareService.createFolder(item)
    .then(() => {
      this.fileNavigatorService.refresh()
      this.currentModal.close()
      // this.modal('newfolder', true)
    })
    .catch((err) => {
      this.currentModal.close()
      console.error(err)
    })
  }

  public addForUpload (files: any): any {
    this.uploadFileList = this.uploadFileList.concat(files)
    this.modal('uploadfile')
  }

  public removeFromUpload (index: number): any {
    this.uploadFileList.splice(index, 1)
  }

  public uploadFiles (): any {
    this.apiMiddlewareService.upload(this.uploadFileList, this.fileNavigatorService.currentPath)
    .then(() => {
      this.fileNavigatorService.refresh()
      this.uploadFileList = []
      this.modal('uploadfile', true)
    }, (data: any) => {
      const errorMsg: string = data.result && data.result.error || this.translateService.instant('error_uploading_files')
      this.apiMiddlewareService.fmApiService.error = errorMsg
    })
  }

  public getUrl (_item: any): any {
    return this.apiMiddlewareService.getUrl(_item)
  }

  public validateSamePath (item: any): boolean {
    const selectedPath: string = this.selectedModalPath.join('')
    const selectedItemsPath: string = item && item.model.path.join('')
    return selectedItemsPath === selectedPath
  }

  public getQueryParam (param: any): any {
    const found: any = window.location.search.substr(1).split('&').filter((item) => {
      return param === item.split('=')[0]
    })
    return found[0] && found[0].split('=')[1] || undefined
  }

}
