// Angular
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
// Other.
import { from as observableFrom, merge as observableMerge, Subscription, Subject, Observable } from 'rxjs'
import { switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
// Service models.
import { Company } from '@app/modules/company/company.model'
import { CompanyService } from '@app/modules/company/company.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'

@Component({
  selector: 'app-company-typeahead',
  templateUrl: './company-typeahead.component.html'

})

export class CompanyTypeaheadComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Button text
   */
  @Input() mainFormControlName: string

  /**
   * Button text
   */
  @Input() buttonText: string

  /**
   * Button text
   */
  @Input() iconClass: string

  /**
   * Selected form
   */
  public selectedForm: FormGroup

  /**
   * Search term
   */
  @Output() searchTerm: EventEmitter<string> = new EventEmitter<string>()

  /**
   * Mode
   *
   * 'single' or 'array'
   */
  @Input() mode?: string

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  @ViewChild('companySearchTypeAhead') companySearchTypeAhead: NgbTypeahead
  public focus$: Subject<string> = new Subject<string>()
  public click$: Subject<string> = new Subject<string>()

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private companyFormService: CompanyFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.selectedForm = this.formBuilder.group({
      selectedItem: [
        null,
        [
          Validators.required
        ]
      ]
    })
    this.display = true
  }

  /**
   * Search
   *
   * @param text Search string
   */
  public search: any = (text$: Observable<string>): Observable<any> => {
    const debouncedText: Observable<string> = text$.pipe(debounceTime(500), distinctUntilChanged())

    return observableMerge(debouncedText)
    .pipe(
      switchMap(term => observableFrom(this.getResults(term)))
    )
  }

  /**
   * Search result formatter
   *
   * Converts the object into a readable string.
   *
   * @param item Company
   */
  public formatResults: any = (item: any): any => {
    const strParts: Array<any> = []
    // const name: Array<any> = []

    if (item.name) {
      strParts.push(item.name)
    }
    // const addressParts: Array<any> = []
    if (item.addresses && item.addresses.length > 0) {
      if (item.addresses[0].address1) {
        strParts.push(item.addresses[0].address1)
      }
      if (item.addresses[0].city) {
        strParts.push(item.addresses[0].city)
      }
      if (item.addresses[0].state) {
        strParts.push(item.addresses[0].state)
      }
      if (item.addresses[0].postcode) {
        strParts.push(item.addresses[0].postcode)
      }
    }

    return strParts.join(', ')
  }

  /**
   * Select item
   *
   * @param $event Event
   */
  public selectItem ($event: Event): void {
    if ($event) {}
    // console.log('selectItem', $event)
  }

  /**
   * Is search valid
   */
  public isSearchValid (): boolean {
    if (this.selectedForm.get('selectedItem').value && this.selectedForm.get('selectedItem').value.uuid) {
      return true
    }
    return false
  }

  /**
   * Add schedule contact
   */
  public add (): void {
    // console.log(this.mode)
    // console.log('selectedItem', this.selectedForm.get('selectedItem').value)

    if (this.mode === 'single') {
      // const control: FormGroup = this.mainForm.get('company') as FormGroup
      const company: Company = new Company(this.selectedForm.get('selectedItem').value)
      const companyForm: FormGroup = this.companyFormService.getForm(company, '')

      this.mainForm.removeControl('company')
      this.mainForm.addControl('company', companyForm)

      this.mainForm.patchValue({
        companyUuid: company.uuid
      })
    } else {
      let control: FormArray
      control = this.mainForm.get(this.mainFormControlName) as FormArray

      if (!control) {
        this.mainForm.addControl(this.mainFormControlName, this.formBuilder.array([]))
        control = this.mainForm.get(this.mainFormControlName) as FormArray
      }

      const company: Company = new Company(this.selectedForm.get('selectedItem').value)
      const companyForm: FormGroup = this.companyFormService.getForm(company, 'new')
      control.push(companyForm)

      this.selectedForm.patchValue({
        selectedItem: null
      })
    }
  }

  /**
   * Searcher
   *
   * @param term Search term
   */
  public getResults (term: string): Promise<any> {
    this.searchTerm.emit(term)

    this.companyService.lastSearchTerm = term

    const params: any = {
      limit: 50,
      sort: [
        {
          key: 'name',
          direction: 'ASC'
        }
      ],
      filter_groups: [
        {
          or: true,
          filters: [
            {
              key: 'name',
              value: term,
              operator: 'ct'
            }
          ]
        }
      ],
      includes: [
        'addresses'
      ]
    }

    return this.companyService.api.getMany(params)
    .toPromise()
    .then((results) => {
      return results
    })
  }
}
