import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { ProductServiceApiService } from '@app/modules/product-service/product-service-api.service'

@Injectable()

export class ProductServiceService {

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder,
    public myUtil: MyUtilityService,
    public api: ProductServiceApiService
  ) {}

  /**
   * Get form
   */
  public getForm (productService?: any | Object): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      name: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      productUuid: [
        null,
        [
          ValidationService.noWhiteSpace,
          Validators.minLength(1)
        ]
      ],
      itemCode: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      itemType: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      quantity: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      unitCost: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      unitPrice: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      unitDiscount: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      discountType: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      discountPrice: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      extendedAmount: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (!this.myUtil.isEmpty(productService)) {
      if (Object.keys(productService).length !== 0) {
        form.patchValue(productService, { emitEvent: false })
      }
    }

    return form
  }

}
