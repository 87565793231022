import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { ComponentCanDeactivate } from '@app/shared/guards/pending-changes.guard'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, Observable } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { Task } from '@app/modules/task/task.model'
import { TaskService } from '@app/modules/task/task.service'
import { TaskFormService } from '@app/modules/task/task-form.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html'

})

export class EditTaskComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Company form
   */
  public mainForm: FormGroup

  /**
   * Task
   */
  public task: Task

  /**
   * Mini nav
   *
   * For prev next.
   */
  public miniNav: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload') canDeactivate (): Observable<boolean> | boolean {
    if (this.mainForm) {
      return this.mainForm.pristine
    }
    return true
  }

  /**
   * Constructor
   */
  public constructor (
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public routingStateService: RoutingStateService,
    private alertService: AlertService,
    public validationService: ValidationService,
    private taskService: TaskService,
    private taskFormService: TaskFormService,
    public selectOptionsService: SelectOptionsService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = false
    this.routeSub()
  }

  /**
   * Route sub
   */
  public routeSub (): void {
    const sub: Subscription = this.activatedRoute
    .params
    .subscribe((routeParams) => {
      this.getTask(routeParams['uuid'])
      .then((result: Task) => {
        if (result) {}
        // this.miniNav = this.taskService.getMiniNav(this.task.uuid)
        this.display = true
      })
      .catch((err) => {
        console.error(err)
      })
    })

    this.subscriptions.push(sub)
  }

  // ---------------------------------------------------------------------------
  // Task CRUD
  // ---------------------------------------------------------------------------

  /**
   * Get task
   */
  public getTask (uuid: string): Promise<any> {
    return this.taskService.api.getOne(uuid, {
      includes: [
        'scheduledWith',
        'scheduledWith.companies',
        'associatedWith',
        'associatedWith.addresses'
      ]
    })
    .toPromise()
    .then((result) => {
      this.task = new Task(result)
      this.mainForm = this.taskFormService.getForm(this.task, 'edit')

      return this.task
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save task
   */
  public saveTask (): void {
    console.log('saveTask', this.mainForm.value)
    this.validationService.runValidation(this.mainForm)

    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.taskService.saveTask(this.mainForm.value)
    .then((result) => {
      // Get the updated task.
      this.getTask(result.uuid)
      .then((newResult: Task) => {
        if (newResult) {}
        this.alertService.success('Saved: ' + this.mainForm.value.regarding, 4000)
        this.mainForm.markAsPristine()
      })
      .catch((err) => {
        console.error(err)
      })

    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Delete task
   */
  public deleteTask (uuid: string): void {
    this.taskService.api.delete(uuid)
    .toPromise()
    .then(() => {
      this.router.navigate(['/tasks'])
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.getTask(this.task.uuid)
    this.alertService.warning('Reverted: ' + this.mainForm.value.regarding, 4000)
    this.mainForm.markAsPristine()
  }

}
