import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpService } from '@app/shared/services/http.service'
import { ApiService } from '@app/shared/api-services/api.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'

@Injectable()

export class QueryBuilderApiService extends ApiService {

  /**
   * Constructor
   */
  public constructor (
    public httpService: HttpService,
    public myUtil: MyUtilityService
  ) {
    super(httpService, myUtil)
  }

  /**
   * Init
   */
  public init (): void {
    this.endPoint = 'v1/query-builder'
  }

  /**
   * Query
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public query (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint, data)
  }

  /**
   * Tables
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public tables (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint + '/tables', data)
  }

  /**
   * Columns
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public columns (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint + '/columns', data)
  }

  /**
   * Foreign keys
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public fks (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint + '/fks', data)
  }

  /**
   * All foreign keys
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public allFks (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint + '/all-fks', data)
  }

  /**
   * Run Query
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public runQuery (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint + '/run-query', data)
  }

  /**
   * Csv sends same data as run query but downloads a csv.
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public csv (data: Object): Observable<any> {
    // return this.httpService.insert(this.endPoint + '/csv', data)
    return this.postDownload(this.endPoint + '/csv', data)
  }

  /**
   * Options
   *
   * This is not really an insert but does need to use POST
   * due to the possible size of data.
   *
   * @param data Query data
   */
  public options (data: Object): Observable<any> {
    return this.httpService.insert(this.endPoint + '/options', data)
  }

}
