import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { Shutdown } from '@app/modules/shutdown/shutdown.model'

@Injectable()

export class ShutdownFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = []

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService
  ) {}

  /**
   * Get form
   */
  public getForm (shutdown: Shutdown, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      personUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      chaseDate: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      shutdownDate: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (shutdown) {
      form.patchValue(shutdown, { emitEvent: false })
    }

    return form
  }

}
