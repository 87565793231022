import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html'
})

export class AddressFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Heading text
   */
  @Input() fixedType: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Constructor
   */
  public constructor (
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }

}
