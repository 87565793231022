import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-column-sort',
  template: `<span>
  <span *ngIf="iconStyle === 'alpha' && orderByColumn === columnName && orderByDirection === 'ASC'" class="fa fa-sort-alpha-asc"></span>
  <span *ngIf="iconStyle === 'alpha' && orderByColumn === columnName && orderByDirection === 'DESC'" class="fa fa-sort-alpha-desc"></span>
  <span *ngIf="iconStyle === 'num' && orderByColumn === columnName && orderByDirection === 'ASC'" class="fa fa-sort-numeric-asc"></span>
  <span *ngIf="iconStyle === 'num' && orderByColumn === columnName && orderByDirection === 'DESC'" class="fa fa-sort-numeric-desc"></span>
  <span *ngIf="iconStyle === 'bar' && orderByColumn === columnName && orderByDirection === 'ASC'" class="fa fa-sort-amount-asc"></span>
  <span *ngIf="iconStyle === 'bar' && orderByColumn === columnName && orderByDirection === 'DESC'" class="fa fa-sort-amount-desc"></span>
  <span *ngIf="orderByColumn !== columnName" class="fa fa-sort opacity-05"></span>
</span>`
})

export class ColumnSortComponent {
  @Input() iconStyle: String
  @Input() columnName: String
  @Input() orderByColumn: String
  @Input() orderByDirection: String

  /**
   * Constructor
   */
  public constructor () { }

}
