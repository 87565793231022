import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ConfigService, FmService, FileNavigatorService } from '../../services'

@Component({
  selector: 'app-fm',
  templateUrl: './fm.component.html',
  providers: []
})

export class FmComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('modalTemplate') private modalTemplate: ElementRef // TemplateRef

  /**
   * Display
   */
  public display: boolean

  /**
   * Config
   */
  public config: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public configService: ConfigService,
    public fmService: FmService,
    public fileNavigatorService: FileNavigatorService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    console.log('fm.component.ngOnInit')
    this.config = this.configService.config
    console.log('this.config.basePath', this.config.basePath)
    this.fmService.init()
    // this.fmService.modalTemplate = this.modalTemplate
    this.display = true
  }

  /**
   * AfterViewInit
   */
  public ngAfterViewInit (): void {
    this.fmService.modalTemplate = this.modalTemplate
  }

}
