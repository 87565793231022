// Angular
import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
// Other
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
// Services.
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
// Service models.
import { CompanyService } from '@app/modules/company/company.service'
import { PersonService } from '@app/modules/person/person.service'

import { PersonFormService } from '@app/modules/person/person-form.service'
// Models.
import { Person } from '@app/modules/person/person.model'
import { LocalStorageService } from 'angular-2-local-storage'

@Component({
  selector: 'app-view-people',
  templateUrl: './view-people.component.html'
})

export class ViewPeopleComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * People
   */
  public people: Array<any> = []

  /**
   * Person form
   */
  public personForm: FormGroup

  /**
   * Search form
   */
  public searchForm: FormGroup

  /**
   * Page options
   */
  public pageOptions: Array<any>

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    private renderer: Renderer,
    private router: Router,
    // Services.
    public alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    // Service models.
    public companyService: CompanyService,
    public personService: PersonService,
    private personFormService: PersonFormService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = true
    this.searchForm = this.personService.getSearchForm()
    const lastSearch: any = this.localStorageService.get('people.search')
    if (lastSearch) {
      this.searchForm.patchValue(lastSearch, { emitEvent: false })
    }
    this.searchSub()
    this.searchPeople()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.personService.pagination.currentPage = 1
      this.searchPeople()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search people
   */
  public searchPeople (): Promise<any> {
    this.localStorageService.set('people.search', this.searchForm.value)
    return this.personService.search(this.searchForm.value)
    .then((results) => {
      this.personService.pagination.currentPageSize = results.length
      this.getPagesOptions()
      this.people = results
      this.display = true
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get pages options
   */
  public getPagesOptions (): void {
    const pageOptions: Array<any> = []
    const totalItems: number = this.personService.pagination.totalItems
    const pageSize: number = this.personService.pagination.pageSize
    const pageCount: number = Math.ceil(totalItems / pageSize)
    let i: number
    for (i = 1; i <= pageCount; i++) {
      pageOptions.push({
        key: i,
        val: i
      })
    }

    this.pageOptions = pageOptions
  }

  /**
   * Go to page
   */
  public goToPage (event: Event): void {
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.searchPeople()
  }

  /**
   * Change page size
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.personService.pagination.currentPage = 1
    this.searchPeople()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.localStorageService.remove('people.search')
    this.personService.sort = {
      column: 'person.firstName',
      direction: 'ASC'
    }

    this.searchForm.reset()
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    this.personService.setSort(column)
    this.searchPeople()
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    // PersonFormService

    if (type === 'newPerson') {
      const person: Person = new Person()
      this.personForm = this.personFormService.getForm(person, 'new')
    }

    if (type === 'editPerson') {
      const person: Person = new Person(data)
      this.personForm = this.personFormService.getForm(person, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Close modal
   */
  public closeModal (): void {
    this.currentModal.close()
  }

  /**
   * Save person
   */
  public savePerson (): Promise<any> {
    console.log('savePerson', this.personForm.value)
    this.validationService.runValidation(this.personForm)

    if (!this.personForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.personForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.personService.savePerson(this.personForm.value)
    .then((result) => {
      this.currentModal.close()
      this.router.navigate(['/people', result.uuid])
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

}
