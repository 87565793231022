// Angular
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { ConfigService } from '../../services'

@Component({
  selector: 'app-fm-new-folder-form',
  templateUrl: './fm-new-folder-form.component.html'
})

export class FmNewFolderFormComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  @Input() mainForm: FormGroup

  /**
   * Main form change
   */
  @Output() mainFormChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  /**
   * Constructor
   */
  public constructor (
    public validationService: ValidationService,
    public configService: ConfigService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {}

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = true
  }
}
