import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { AlertService } from '@app/modules/alert/alert.service'
import {
  NgbModalRef,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap'
import { UserService } from '@app/modules/user/user.service'
import {
  EmailMessageApiService,
  EmailMessageSearchService,
  EmailMessageFormService
} from '@app/modules/email-message/services'
import {
  EmailMessage
} from '@app/modules/email-message/models'
import { EventService } from '@app/shared/services/event.service'

@Component({
  selector: 'app-tab-unallocated-sent',
  templateUrl: './tab-unallocated-sent.component.html'
})

export class TabUnallocatedSentComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Search form group
   */
  public searchForm: FormGroup

  /**
   * Emails
   */
  public emails: Array<any> = []

  /**
   * Email message form
   */
  public emailMessageForm: FormGroup

  public emailMessageBody: string

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    public myUtil: MyUtilityService,
    public userService: UserService,
    public emailMessageApiService: EmailMessageApiService,
    public searchService: EmailMessageSearchService,
    private modalService: NgbModal,
    private emailMessageFormService: EmailMessageFormService,
    private eventService: EventService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   *
   * Ref: https://docs.microsoft.com/en-us/previous-versions/office/unallocated-email-api/api/version-2.0/mail-rest-operations#GetMessages
   */
  public ngOnInit (): void {
    this.display = false
    this.searchForm = this.searchService.getSearchForm()
    this.searchForm.patchValue({
      isAllocated: false,
      isIgnored: false,
      isSpam: false,
      isSent: true
    })

    this.searchSub()
    this.searchEmails()
    this.eventSub()
  }

  /**
   * Event sub
   */
  private eventSub (): void {
    const sub: Subscription = this.eventService.events.subscribe((event: any) => {

      if (event.name === 'OPPORTUNITY_ALLOCATED') {

        this.emailMessageForm.patchValue({
          opportunityUuid: event.data.opportunityUuid
        })

        this.validationService.runValidation(this.emailMessageForm)

        if (!this.emailMessageForm.valid) {
          const formErrors: Array<any> = this.validationService.extractErrors(this.emailMessageForm)
          console.log('Validation errors', formErrors)
          this.alertService.error('Validation errors: Please check the form.', 10000)
          return
        }

        this.emailMessageApiService.save(this.emailMessageForm.value)
        .toPromise()
        .then((result) => {
          console.log('result', result)
          this.currentModal.close()
          this.searchEmails()
        })
        .catch((err) => {
          console.error(err)
        })
      }
    })

    this.subscriptions.push(sub)
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.searchService.pagination.currentPage = 1
      this.searchEmails()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search users
   */
  public searchEmails (): void {
    this.searchService.search(this.searchForm.value)
    .then((results) => {
      console.log('results', results)
      this.searchService.pagination.currentPageSize = results.length
      this.emails = results
      this.display = true
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.searchService.sort = {
      column: 'emailMessage.sentDateTime',
      direction: 'DESC'
    }
    this.searchForm.reset()
    // this.searchForm.patchValue({
    //   finalInvoiceOperator: 'eq'
    // })
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    this.searchService.setSort(column)
    this.searchEmails()
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'allocateOpportunity') {
      const emailMessage: EmailMessage = new EmailMessage(data)
      this.emailMessageForm = this.emailMessageFormService.getForm(emailMessage, 'edit')
    }

    if (type === 'showMessage') {
      // const emailMessage: EmailMessage = new EmailMessage(data)
      this.emailMessageBody = data.body
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Close modal
   */
  public closeModal (): void {
    this.currentModal.close()
  }

  /**
   * Un-allocate
   */
  public unAllocate (emailMessage: EmailMessage): void {
    // const emailMessage: EmailMessage = new EmailMessage(data)
    emailMessage.opportunityUuid = null
    this.emailMessageApiService.save(emailMessage)
    .toPromise()
    .then(() => {
      this.searchEmails()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Spam
   */
  public spam (emailMessage: EmailMessage): void {
    // const emailMessage: EmailMessage = new EmailMessage(data)
    emailMessage.isSpam = true
    this.emailMessageApiService.save(emailMessage)
    .toPromise()
    .then(() => {
      this.searchEmails()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Not spam
   */
  public notSpam (emailMessage: EmailMessage): void {
    // const emailMessage: EmailMessage = new EmailMessage(data)
    emailMessage.isSpam = false
    this.emailMessageApiService.save(emailMessage)
    .toPromise()
    .then(() => {
      this.searchEmails()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Ignore
   */
  public ignore (emailMessage: EmailMessage): void {
    // const emailMessage: EmailMessage = new EmailMessage(data)
    emailMessage.isIgnored = true
    this.emailMessageApiService.save(emailMessage)
    .toPromise()
    .then(() => {
      this.searchEmails()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Un-ignore
   */
  public unIgnore (emailMessage: EmailMessage): void {
    // const emailMessage: EmailMessage = new EmailMessage(data)
    emailMessage.isIgnored = false
    this.emailMessageApiService.save(emailMessage)
    .toPromise()
    .then(() => {
      this.searchEmails()
    })
    .catch((err) => {
      console.error(err)
    })
  }

}
