import { Pipe, PipeTransform } from '@angular/core'
import { HumanizeDurationLanguage, HumanizeDuration } from 'humanize-duration-ts'

@Pipe({ name: 'humanizeDuration' })

/**
 * https://github.com/Nightapes/HumanizeDuration.ts
 */
export class HumanizeDurationPipe implements PipeTransform {

  /**
   * Transform milliseconds to human readable format
   */
  public transform (value: number, arg: string): string {
    if (arg) {}
    const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage()
    const humanizer: HumanizeDuration = new HumanizeDuration(langService)

    return humanizer.humanize(value)
  }

}
