// Angular
import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
// Other
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
// Services.
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
// Service models.
import { CompanyTypeService } from '@app/modules/company-type/company-type.service'

@Component({
  selector: 'app-view-company-types',
  templateUrl: '../../view-list-items.component.html'
})

export class ViewCompanyTypesComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Display vars
   *
   * On these list pages we should only have to change this and the model,
   * the view is shared.
   */
  public displayVars: any = {
    singular: 'Company Type',
    plural: 'Company Types',
    route: 'company-types'
  }

  /**
   * Search form group
   */
  public searchForm: FormGroup

  /**
   * Users
   */
  public results: Array<any> = []

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public appService: AppService,
    public router: Router,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public mainService: CompanyTypeService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = false
    this.searchForm = this.mainService.getSearchForm()
    this.searchSub()
    this.searchItems()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.mainService.pagination.currentPage = 1
      this.searchItems()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search users
   */
  public searchItems (): void {
    this.mainService.searchItems(this.searchForm.value)
    .then((results) => {
      console.log('results', results)
      this.mainService.pagination.currentPageSize = results.length
      this.results = results
      this.display = true
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Set sort users
   */
  public setSort (column: string): void {
    this.mainService.setSort(column)
    this.searchItems()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.mainService.sort = {
      column: 'name',
      direction: 'DESC'
    }
    this.searchForm.reset()
  }

}
