import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { ContactGroupService } from '@app/modules/contact-group/contact-group.service'
// import { ContactGroupFormService } from '@app/modules/contact-group/contact-group-form.service'
// import { ContactGroup } from '@app/modules/contact-group/contact-group.model'
import { Person } from '@app/modules/person/person.model'

@Component({
  selector: 'app-custom-queried-contacts',
  templateUrl: './custom-queried-contacts.component.html'
})

export class CustomQueriedContactsComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Contact group uuid
   */
  @Input() contactGroupUuid: string

  /**
   * Contacts
   */
  public contacts: Array<Person> = []

  /**
   * Mini nav
   *
   * For prev next.
   */
  public miniNav: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public routingStateService: RoutingStateService,
    public validationService: ValidationService,
    public contactGroupService: ContactGroupService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = true
    this.getContacts(this.contactGroupUuid)
    .then((results) => {
      this.contacts = results
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Route sub
   */
  // public routeSub (): void {
  //   const sub: Subscription = this.activatedRoute
  //   .params
  //   .subscribe((routeParams) => {
  //     this.getContactGroup(routeParams['uuid'])
  //     .then((result) => {
  //       this.setUp(result)
  //       this.miniNav = this.contactGroupService.getMiniNav(result.uuid)
  //       this.display = true
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //     })
  //   })

  //   this.subscriptions.push(sub)
  // }

  /**
   * Set up contact group
   */
  // public setUp (data: any): void {
  //   this.contactGroup = new ContactGroup(data)
  //   this.mainForm = this.contactGroupFormService.getForm(this.contactGroup, 'edit')
  // }

  /**
   * Get contact group

   */
  public getContacts (contactGroupUuId: string): Promise<any> {
    return this.contactGroupService.api.customQuery(contactGroupUuId, {
      includes: [
        'contacts',
        'contacts.telephones',
        'contacts.telephones.telephoneName',
        'contacts.emailAddresses',
        'contacts.emailAddresses.emailAddressName',
        'contacts.companies',
        'contacts.companies.addresses',
        'contacts.companies.addresses.country'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

}
