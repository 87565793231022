import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AppService } from '@app/shared/services/app.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { UserService } from '@app/modules/user/user.service'
import { ContactGroupApiService } from '@app/modules/contact-group/contact-group-api.service'

@Component({
  selector: 'app-user-contact-groups',
  templateUrl: './user-contact-groups.component.html'
})

export class UserContactGroupsComponent implements OnInit, OnDestroy {

  /**
   * User primary key
   */
  @Input() userUuid: string

  /**
   * Display page
   */
  public display: boolean

  /**
   * Contact groups assigned
   */
  public contactGroupsAssigned: Array<any> = []

  /**
   * Contact groups available
   */
  public contactGroupsAvailable: Array<any> = []

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    public appService: AppService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public selectOptionsService: SelectOptionsService,
    public myUtil: MyUtilityService,
    public userService: UserService,
    public contactGroupApiService: ContactGroupApiService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.getContactGroupsAssigned()
    this.display = true
  }

  /**
   * Get contact groups assigned
   */
  public getContactGroupsAssigned (): void {
    this.userService.api.getContactGroups(this.userUuid)
    .toPromise()
    .then((result) => {
      this.contactGroupsAssigned = result
      this.getContactGroupsAvailable()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get contact group ids assigned
   */
  public getContactGroupIdsAssigned (): Array<any> {
    const uuids: Array<any> = [
    ]

    this.contactGroupsAssigned.map((contactGroup) => {
      uuids.push(contactGroup.uuid)
    })
    return uuids
  }

  /**
   * Get contact group available
   */
  public getContactGroupsAvailable (): void {
    const params: any | Object = {
      limit: 0,
      filter_groups: [
        {
          filters: []
        }
      ]
    }

    const contactGroupUuidsAssigned: Array<any> = this.getContactGroupIdsAssigned()

    if (contactGroupUuidsAssigned.length > 0) {
      params.filter_groups[0].filters.push({
        key: 'uuid',
        value: [
          contactGroupUuidsAssigned
        ],
        operator: 'in',
        not: true
      })
    }

    this.contactGroupApiService.getMany(params)
    .toPromise()
    .then((result) => {
      this.contactGroupsAvailable = result
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Grant contact group
   */
  public addContactGroup (contactGroup: any | Object): void {
    // @todo Check if already exists.
    this.userService.api.attachContactGroup(this.userUuid, contactGroup.uuid)
    .toPromise()
    .then((result) => {
      console.log('addContactGroup result', result)
      this.getContactGroupsAssigned()
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Remove contact group
   */
  public removeContactGroup (contactGroup: any | Object): void {
    this.userService.api.detachContactGroup(this.userUuid, contactGroup.uuid)
    .toPromise()
    .then((result) => {
      console.log('removeContactGroup result', result)
      this.getContactGroupsAssigned()
    })
    .catch((err) => {
      console.error(err)
    })
  }

}
