import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { PersonToCompanyApiService } from '@app/modules/person-to-company/person-to-company-api.service'

@Injectable()

export class PersonToCompanyService {

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder,
    public myUtil: MyUtilityService,
    public api: PersonToCompanyApiService
  ) {}

  /**
   * Get form
   */
  public getForm (data?: any | Object): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      personUuid: [
        null,
        [
          Validators.required,
          ValidationService.isUuid
        ]
      ],
      companyUuid: [
        null,
        [
          Validators.required,
          ValidationService.isUuid
        ]
      ],
      departmentUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (!this.myUtil.isEmpty(data)) {
      if (Object.keys(data).length !== 0) {
        form.patchValue(data, { emitEvent: false })
      }
    }

    return form
  }

}
