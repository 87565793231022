import { throwError as observableThrowError, Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http'

import { AppService } from '@app/shared/services/app.service'

@Injectable()
export class HttpErrorService implements HttpInterceptor {

  /**
   * Constructor
   */
  public constructor (
    public router: Router,
    public appService: AppService
  ) {}

  /**
   * Intercept HTTP request.
   */
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
    catchError((err: HttpErrorResponse) => {
      return this.handleErrors(err)
    }))
  }

  /**
   * Handle errors
   *
   * @param {HttpErrorResponse} err Error
   */
  public handleErrors (err: HttpErrorResponse): Observable<HttpEvent<any>> {
    switch (err.status) {
      // Unauthorized.
      case 401:
        // Login failure.
        if (err.error && err.error.message) {
          if (err.error.message !== 'Invalid credentials, check your details.') {
            this.appService.authService.logout()
            setTimeout(() => {
              this.router.navigate(['/login'])
            }, 500)
          }
        }

        // Token expired.
        if (err.error && err.error.error) {
          if (err.error.error === 'token_expired') {
            this.appService.authService.logout()
            setTimeout(() => {
              this.router.navigate(['/login'])
            }, 500)
          }
        }
        break
      // Bad Request.
      case 400:
        if (err.error && err.error.error) {
          if (err.error.error === 'token_not_provided') {
            this.appService.authService.logout()
            setTimeout(() => {
              this.router.navigate(['/login'])
            }, 500)
          }
        }
        break
      default:
        // ignore.
    }

    return observableThrowError(err)
  }
}

/**
 * Provider POJO for the interceptor
 */
export const HttpErrorServiceProvider: any = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorService,
  multi: true
}
