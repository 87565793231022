import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
import { AlertService } from '@app/modules/alert/alert.service'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { User } from '@app/modules/user/user.model'
import { UserService } from '@app/modules/user/user.service'
import { UserFormService } from '@app/modules/user/user-form.service'

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html'
})

export class ViewUsersComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Search form group
   */
  public searchForm: FormGroup

  /**
   * Users
   */
  public users: Array<any> = []

  /**
   * User form
   */
  public userForm: FormGroup

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Constructor
   */
  public constructor (
    private alertService: AlertService,
    private renderer: Renderer,
    private router: Router,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    public myUtil: MyUtilityService,
    public userService: UserService,
    private userFormService: UserFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.display = false
    this.searchForm = this.userService.getSearchForm()
    this.searchSub()
    this.searchUsers()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.userService.pagination.currentPage = 1
      this.searchUsers()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search users
   */
  public searchUsers (): void {
    this.userService.search(this.searchForm.value)
    .then((results) => {
      console.log('results', results)
      this.userService.pagination.currentPageSize = results.length
      this.users = results
      this.display = true
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Set sort users
   */
  public setSortUsers (column: string): void {
    this.userService.setSort(column)
    this.searchUsers()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.searchForm.reset()
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    if (data) {}
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newUser') {
      const user: User = new User()
      this.userForm = this.userFormService.getForm(user, 'new')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save User

   */
  public saveUser (): void {
    console.log('saveUser', this.userForm.value)
    this.validationService.runValidation(this.userForm)

    if (!this.userForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.userForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.userService.api.save(this.userForm.value)
    .toPromise()
    .then((result) => {
      this.currentModal.close()
      this.router.navigate(['/admin', 'users', result.uuid])
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * Go to page
   */
  public goToPage (event: Event): void {
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.searchUsers()
  }

  /**
   * Change page size
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.userService.pagination.currentPage = 1
    this.searchUsers()
  }

}
