// Angular
import { Component, OnInit, OnDestroy, Renderer } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
// Other
import { Subscription } from 'rxjs'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'
// Services.
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
// Service & models.
import { Company } from '@app/modules/company/company.model'
import { CompanyService } from '@app/modules/company/company.service'
import { CompanyFormService } from '@app/modules/company/company-form.service'

import { LocalStorageService } from 'angular-2-local-storage'

@Component({
  selector: 'app-view-companies',
  templateUrl: './view-companies.component.html'
})

export class ViewCompaniesComponent implements OnInit, OnDestroy {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Companies
   */
  public companies: Array<any> = []

  /**
   * Company form
   */
  public companyForm: FormGroup

  /**
   * Search form
   */
  public searchForm: FormGroup

  /**
   * Page options
   */
  public pageOptions: Array<any>

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  /**
   * Current modal
   */
  public currentModal: NgbModalRef

  /**
   * Constructor
   */
  public constructor (
    private renderer: Renderer,
    private router: Router,
    private alertService: AlertService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private companyFormService: CompanyFormService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.display = false
    this.searchForm = this.companyService.getSearchForm()
    const lastSearch: any = this.localStorageService.get('companies.search')
    if (lastSearch) {
      this.searchForm.patchValue(lastSearch, { emitEvent: false })
    }
    this.searchSub()
    this.searchCompanies()
  }

  /**
   * Search sub
   */
  public searchSub (): void {
    this.selectOptionsService.init(false)
    const sub: Subscription = this.searchForm
    .valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    )
    .subscribe(() => {
      this.companyService.pagination.currentPage = 1
      this.searchCompanies()
    })
    this.subscriptions.push(sub)
  }

  /**
   * Search companies
   */
  public searchCompanies (): Promise<any> {
    this.localStorageService.set('companies.search', this.searchForm.value)
    return this.companyService.search(this.searchForm.value)
    .then((results) => {
      this.companyService.pagination.currentPageSize = results.length
      this.getPagesOptions()
      this.companies = results
      this.display = true
      return results
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get pages options
   */
  public getPagesOptions (): void {
    const pageOptions: Array<any> = []
    const totalItems: number = this.companyService.pagination.totalItems
    const pageSize: number = this.companyService.pagination.pageSize
    const pageCount: number = Math.ceil(totalItems / pageSize)
    let i: number
    for (i = 1; i <= pageCount; i++) {
      pageOptions.push({
        key: i,
        val: i
      })
    }

    this.pageOptions = pageOptions
  }

  /**
   * Go to page
   */
  public goToPage (event: Event): void {
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.searchCompanies()
  }

  /**
   * Change page size
   */
  public changePageSize (event: Event): void {
    console.log('changePageSize')
    this.renderer.invokeElementMethod(event.target, 'blur')
    this.companyService.pagination.currentPage = 1
    this.searchCompanies()
  }

  /**
   * Reset search
   */
  public resetSearch (): void {
    this.localStorageService.remove('companies.search')
    this.companyService.sort = {
      column: 'company.name',
      direction: 'DESC'
    }

    this.searchForm.reset()
  }

  /**
   * Set sort
   */
  public setSort (column: string): void {
    this.companyService.setSort(column)
    this.searchCompanies()
  }

  /**
   * Open modal
   */
  public openModal (event: Event, content: any, type: string, data?: any): void {
    event.preventDefault()
    event.stopPropagation()

    if (type === 'newCompany') {
      const company: Company = new Company()
      this.companyForm = this.companyFormService.getForm(company, 'new')
    }

    if (type === 'editCompany') {
      const company: Company = new Company(data)
      this.companyForm = this.companyFormService.getForm(company, 'edit')
    }

    this.currentModal = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'fade modal-xl',
      keyboard: false
    })
  }

  /**
   * Save new company
   */
  public saveCompany (): void {
    console.log('saveCompany', this.companyForm.value)
    this.validationService.runValidation(this.companyForm)

    if (!this.companyForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.companyForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.companyService.saveCompany(this.companyForm.value)
    .then((result) => {
      this.currentModal.close()
      this.router.navigate(['/companies', result.uuid])
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }

}
