import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Subscription, Observable } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { Site } from '@app/modules/site/site.model'
import { SiteService } from '@app/modules/site/site.service'
import { SiteFormService } from '@app/modules/site/site-form.service'
import { Address } from '@app/modules/address/address.model'
import { ComponentCanDeactivate } from '@app/shared/guards/pending-changes.guard'
import { AddressName } from '@app/modules/address-name'

@Component({
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html'
})

export class EditSiteComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  /**
   * Display page
   */
  public display: boolean

  /**
   * Company form
   */
  public mainForm: FormGroup

  /**
   * Company
   */
  public site: Site

  /**
   * Mini nav
   *
   * For prev next.
   */
  public miniNav: any

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload') canDeactivate (): Observable<boolean> | boolean {
    if (this.mainForm) {
      return this.mainForm.pristine
    }
    return true
  }

  /**
   * Constructor
   */
  public constructor (
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public routingStateService: RoutingStateService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    private siteService: SiteService,
    public siteFormService: SiteFormService
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    this.selectOptionsService.init(false)
    this.routeSub()
  }

  /**
   * Route sub
   */
  public routeSub (): void {
    const sub: Subscription = this.activatedRoute
    .params
    .subscribe((routeParams) => {

      this.getSite(routeParams['uuid'])
      .then((result: Site) => {
        this.site = new Site(result)

        if (!this.site.address) {
          this.site.address = new Address()
        }
        const siteAddressName: AddressName = this.selectOptionsService.addressNameOptions.find(x => x.name === 'Site')
        this.site.address.addressNameUuid = siteAddressName.uuid // 5 - Site Uuid Preset.

        this.miniNav = this.siteService.getMiniNav(this.site.uuid)
        this.mainForm = this.siteFormService.getForm(this.site, 'edit')

        console.log('this.mainForm', this.mainForm.value)

        this.display = true
      })
      .catch((err) => {
        this.alertService.error('Site: ' + err.statusText, 10000)
      })
    })

    this.subscriptions.push(sub)
  }

  /**
   * Get site
   */
  public getSite (uuid: string): Promise<any> {
    return this.siteService.api.getOne(uuid, {
      includes: [
        'address',
        'address.country',
        'address.addressName',
        'company',
        'company.addresses',
        'company.addresses.country',
        'company.addresses.addressName'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save site
   */
  public saveSite (): void {
    this.validationService.runValidation(this.mainForm)

    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.siteService.saveSite(this.mainForm.value)
    .then((result) => {
      // Get the updated site.
      this.getSite(result.uuid)
      .then((newResult: Site) => {
        this.site = new Site(newResult)
        this.mainForm.patchValue(newResult, { emitEvent: true })
        this.alertService.success('Saved: ' + this.mainForm.value.name, 10000)
        this.mainForm.markAsPristine()
      })
      .catch((err) => {
        console.error(err)
      })

    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.getSite(this.site.uuid)
    .then((newResult: Site) => {
      this.site = new Site(newResult)
      this.mainForm.patchValue(newResult, { emitEvent: true })
      this.alertService.warning('Reverted: ' + this.mainForm.value.name, 10000)
      this.mainForm.markAsPristine()
    })
    .catch((err) => {
      console.error(err)
    })
  }
    /**
   * On cancel
   */

  /**
   * Delete site
   *
   * @param siteUuid Site primary key
   */
  public deleteSite (siteUuid: string): void {
    this.siteService.api.delete(siteUuid)
    .toPromise()
    .then(() => {
      this.alertService.success('Deleted: ' + this.mainForm.value.name, 10000)
      this.routingStateService.goBack(['/sites'])
    })
    .catch((err) => {
      this.alertService.error('Error: ' + err)
    })
  }
}
