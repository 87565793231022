import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'
import { SocialNameFormService } from '@app/modules/social-name/social-name-form.service'
import { Social } from '@app/modules/social/social.model'

@Injectable()

export class SocialFormService {

  /**
   * Insert vaildators
   */
  public insertValidators: Array<any> = [
    {
      socialNameUuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    },
    {
      accountName: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    },
    {
      accountLink: [
        ValidationService.noWhiteSpace,
        ValidationService.noEmojis,
        Validators.maxLength(191)
      ]
    }
  ]

  /**
   * Update validators
   */
  public updateValidators: Array<any> = [
    {
      uuid: [
        Validators.required,
        ValidationService.isUuid
      ]
    }
  ]

  /**
   * Constructor
   */
  public constructor (
    private formBuilder: FormBuilder,
    public validationService: ValidationService,
    private socialNameFormService: SocialNameFormService
  ) {}

  /**
   * Get form
   */
  public getForm (social: Social, type: string): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      socialNameUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      accountName: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      accountLink: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (type === 'new') {
      this.validationService.addInsertValidators(form, this.insertValidators)
    }

    if (type === 'edit') {
      this.validationService.addUpdateValidators(form, this.insertValidators, this.updateValidators)
    }

    if (social.socialName) {
      form.addControl('socialName', this.socialNameFormService.getForm(social.socialName, ''))
    }

    if (social) {
      form.patchValue(social, { emitEvent: false })
    }

    return form
  }

}
