import {} from 'jasmine'
import { Component } from '@angular/core'
import { RoutingStateService } from '@app/shared/services/routing-state.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor (routingStateService: RoutingStateService) {
    routingStateService.loadRouting()
  }
}
