import { Injectable } from '@angular/core'
import { FormGroup, Validators, FormBuilder } from '@angular/forms'
import { MyUtilityService } from '@app/shared/services/my-utility.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { UriApiService } from '@app/modules/uri/uri-api.service'
import { UriNameService } from '@app/modules/uri-name/uri-name.service'

@Injectable()

export class UriService {

  /**
   * To delete
   *
   * Id's marked for deleting.
   */
  public toDelete: Array<number> = []

  /**
   * Constructor
   */
  public constructor (
    public formBuilder: FormBuilder,
    public myUtil: MyUtilityService,
    public api: UriApiService,
    public uriNameService: UriNameService
  ) {}

  /**
   * Get form
   */
  public getForm (uri?: any | Object): FormGroup {
    const form: FormGroup = this.formBuilder.group({
      uuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      uriNameUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      url: [
        null,
        [
          ValidationService.noWhiteSpace,
          ValidationService.noEmojis,
          Validators.maxLength(191)
        ]
      ],
      // Info.
      createdAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      updatedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      deletedAt: [
        null,
        [
          ValidationService.noWhiteSpace
        ]
      ],
      createdByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ],
      updatedByUuid: [
        null,
        [
          ValidationService.isUuid
        ]
      ]
    })

    if (!this.myUtil.isEmpty(uri)) {
      if (Object.keys(uri).length !== 0) {
        form.patchValue(uri, { emitEvent: false })
      }
    }

    return form
  }

}
