import { Injectable } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
// import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Observable, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { Alert } from '@app/modules/alert/alert.model'
import { AlertType } from '@app/modules/alert/alert-type.model'

@Injectable()

export class AlertService {

  /**
   * Subject
   */
  private subject: Subject<Alert>

  /**
   * Keep after route change
   */
  private keepAfterRouteChange: boolean

  /**
   * Constructor
   */
  public constructor (
    private router: Router
    // private domSanitizer: DomSanitizer
  ) {
    this.subject = new Subject<Alert>()
    this.keepAfterRouteChange = false
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events
    .subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false
        } else {
          // clear alert messages
          this.clear()
        }
      }
    })
  }

  /**
   * Get alert
   *
   * Subscribe to alerts
   */
  public getAlert (uuid?: string): Observable<any> {
    return this.subject.asObservable().pipe(filter((x: Alert) => x && x.uuid === uuid))
  }

  /**
   * Primary
   */
  public primary (message: string, timeout?: number): void {
    this.alert(new Alert({ message, timeout: timeout, type: AlertType.Primary }))
  }

  /**
   * Success
   */
  public success (message: string, timeout?: number): void {
    this.alert(new Alert({ message, timeout: timeout, type: AlertType.Success }))
  }

  /**
   * Error
   */
  public error (message: string, timeout?: number): void {
    // const msg: any = this.domSanitizer.bypassSecurityTrustHtml(message)
    this.alert(new Alert({ message, timeout: timeout, type: AlertType.Error }))
  }

  /**
   * info
   */
  public info (message: string, timeout?: number): void {
    this.alert(new Alert({ message, timeout: timeout, type: AlertType.Info }))
  }

  /**
   * Warn
   */
  public warning (message: string, timeout?: number): void {
    this.alert(new Alert({ message, timeout: timeout, type: AlertType.Warning }))
  }

  /**
   * Alert
   *
   * Main alert method
   */
  public alert (alert: Alert): void {
    this.keepAfterRouteChange = alert.keepAfterRouteChange
    this.subject.next(alert)
  }

  /**
   * Clear
   *
   * Clear alerts
   */
  public clear (uuid?: string): void {
    this.subject.next(new Alert({ uuid }))
  }
}
