import { Component, HostListener, Input, OnInit, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, Observable } from 'rxjs'
import { AlertService } from '@app/modules/alert/alert.service'
import { SelectOptionsService } from '@app/shared/services/select-options.service'
import { RoutingStateService } from '@app/shared/services/routing-state.service'
import { ValidationService } from '@app/shared/services/validation.service'
import { CompanyService } from '@app/modules/company/company.service'
import { PersonService } from '@app/modules/person/person.service'
import { Opportunity } from '@app/modules/opportunity/opportunity.model'
import { OpportunityService } from '@app/modules/opportunity/opportunity.service'
import { OpportunityFormService } from '@app/modules/opportunity/opportunity-form.service'
import { ComponentCanDeactivate } from '@app/shared/guards/pending-changes.guard'

@Component({
  selector: 'app-tab-project-overview',
  templateUrl: './tab-project-overview.component.html'
})

export class TabProjectOverviewComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  /**
   * Opportunity primary key
   */
  @Input() opportunityUuid: string

  /**
   * Display page
   */
  public display: boolean

  /**
   * Main form
   */
  public mainForm: FormGroup

  /**
   * Subscriptions
   */
  private subscriptions: Array<Subscription> = []

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload') canDeactivate (): Observable<boolean> | boolean {
    // if (this.mainForm) {
    //   return this.mainForm.pristine
    // }
    return true
  }

  /**
   * Constructor
   */
  public constructor (
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private alertService: AlertService,
    public routingStateService: RoutingStateService,
    public selectOptionsService: SelectOptionsService,
    public validationService: ValidationService,
    public opportunityService: OpportunityService,
    public companyService: CompanyService,
    public personService: PersonService,
    private opportunityFormService: OpportunityFormService,
  ) {}

  /**
   * On destroy
   */
  public ngOnDestroy (): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  }

  /**
   * On init
   */
  public ngOnInit (): void {
    console.log('this.opportunityUuid', this.opportunityUuid)
    this.getOpportunity(this.opportunityUuid)
    .then((result: Opportunity) => {
      this.mainForm = this.opportunityFormService.getForm(new Opportunity(result), 'edit')
      this.display = true
    })
    .catch((err) => {
      console.error(err)
    })
  }

  /**
   * Get opportunity
   *
   * @param opportunityUuid Opportunity primary key
   */
  public getOpportunity (opportunityUuid: string): Promise<any> {
    return this.opportunityService.api.getOne(opportunityUuid, {
      includes: [
        'salesPersonUser',
        'opportunityAgreements',
        'productServices',
        'company',
        'company.addresses',
        'company.addresses.country',
        'site',
        'site.address',
        'site.address.country',
        // 'tasks',
        // 'tasks.taskType',
        // 'tasks.organiserUser',
        'shutdown',
        'shutdown.person',
        'probability',
        'contacts',
        'contacts.companies',
        'contacts.companies.addresses',
        'contacts.companies.addresses.country',
        'contacts.telephones',
        'contacts.telephones.telephoneName',
        'contacts.emailAddresses',
        'contacts.emailAddresses.emailAddressName'
      ]
    })
    .toPromise()
    .then((result) => {
      return result
    })
    .catch((err) => {
      throw err
    })
  }

  /**
   * Save opportunity
   */
  public saveOpportunity (): void {
    this.validationService.runValidation(this.mainForm)
    if (!this.mainForm.valid) {
      const formErrors: Array<any> = this.validationService.extractErrors(this.mainForm)
      console.log('Validation errors', formErrors)
      this.alertService.error('Validation errors: Please check the form.', 10000)
      return
    }

    this.opportunityService.saveOpportunity(this.mainForm.value)
    .then((result) => {
      // Get the updated opportunity.
      this.getOpportunity(result.uuid)
      .then(() => {
        this.alertService.success('Saved: ' + this.mainForm.value.name, 10000)
        this.ngOnInit()
      })
      .catch((err) => {
        console.error(err)
      })
    })
    .catch((err) => {
      console.error(err)
      this.alertService.error('Error: ' + err)
    })
  }

  /**
   * On cancel
   */
  public onCancel (): void {
    this.alertService.warning('Reverted: ' + this.mainForm.value.name, 10000)
    this.ngOnInit()
  }

  /**
   * Delete opportunity
   *
   * @param opportunityUuid Opportunity primary key
   */
  public deleteOpportunity (opportunityUuid: string): void {
    this.opportunityService.api.delete(opportunityUuid)
    .toPromise()
    .then(() => {
      this.alertService.success('Deleted: ' + this.mainForm.value.name, 10000)
      this.routingStateService.goBack(['/opportunities'])
    })
    .catch((err) => {
      console.error(err)
    })
  }
}
