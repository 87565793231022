import { Injectable } from '@angular/core'
import { HttpService } from '@app/shared/services/http.service'
import { LocalStorageService } from 'angular-2-local-storage'
import * as jwt_decode from 'jwt-decode'

@Injectable()

export class AuthService {

  /**
   * Admin
   */
  public admin: boolean

  /**
   * Client id
   */
  public clientId: number

  /**
   * Constructor
   *
   * @returns void
   */
  public constructor (
    public httpService: HttpService,
    public localStorageService: LocalStorageService
  ) {
    this.admin = false
    this.clientId = null
  }

  /**
   * Get api key
   *
   * @return string
   */
  public getApiKey (): any {
    return this.localStorageService.get('apiKey')
  }

  /**
   * Is authenticated
   *
   * @returns {boolean}
   */
  public isAuthenticated (): boolean {
    const user: any = this.localStorageService.get('currentUser')

    if (!user) {
      return false
    }

    if (!user.roles) {
      return false
    }

    this.admin = false
    this.clientId = null

    user.roles.map((role) => {
      if (role.uuid === 1 || role.uuid === 2) {
        this.admin = true
      }

      if (role.parentUuid === 5 && role.client) {
        this.clientId = role.client.uuid
      }
    })

    return true
  }

  /**
   * Has any role
   *
   * @param {Array<string>} roles [description]
   *
   * @return {boolean}       [description]
   */
  public hasAnyRole (roles: Array<string>): boolean {
    const user: any = this.localStorageService.get('currentUser')

    if (!user) {
      console.log('user empty', user)
      return false
    }

    let hasOne: boolean

    hasOne = false

    if (!user.roles || user.roles.length < 1) {
      return hasOne
    }

    const rolesArr: Array<any> = []

    user.roles.map((role) => {
      rolesArr.push(role.name)
    })

    roles.map((r) => {
      if (rolesArr.indexOf(r) !== -1) {
        hasOne = true
      }
    })

    return hasOne
  }

  /**
   * Is super user
   *
   * @return {boolean}
   */
  public isSuperUser (): boolean {
    return this.hasAnyRole(['super_users'])
  }

  /**
   * Is admin
   *
   * @return {boolean}
   */
  public isAdmin (): boolean {
    return this.hasAnyRole(['super_users', 'administrators'])
  }

  /**
   * Decode token
   *
   * @param token Token
   *
   * @return {any}
   */
  public decodeToken (token: any): any {
    try {
      return jwt_decode(token)
    } catch (Error) {
      return null
    }
  }

  /**
   * Login
   *
   * @returns {Object}
   */
  public login (data: any): any {
    return this.httpService.login('v1/login', data)
    .toPromise()
    .then((result) => {
      this.localStorageService.set('apiKey', result['token'])
      return result
    })
    .catch((err) => {
      this.localStorageService.set('apiKey', null)
      throw err
    })
  }

  /**
   * Logout
   */
  public logout (): void {
    this.httpService.get('v1/logout', null)
    .toPromise()
    .then(() => {
      this.localStorageService.remove('apiKey')
      this.localStorageService.remove('currentUser')
    })
    .catch(() => {
      this.localStorageService.remove('apiKey')
      this.localStorageService.remove('currentUser')
    })
  }

}
