import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ValidationService } from '@app/shared/services/validation.service'

@Component({
  selector: 'app-control-messages',
  template: `<span *ngIf="errorMessage !== null" class="validation-error-msg">{{ errorMessage }}</span>`
})

export class ControlMessagesComponent {

  @Input() control: FormControl

  /**
   * Constructor
   */
  public constructor () {

    if (!this.control) {
      // console.log(this)
    }
  }

  /**
   * Get error message
   *
   * @returns {string}
   */
  get errorMessage (): string {

    if (!this.control) {
      return null
    }

    // Loop through errors and return an error message if validation fails.
    for (const propertyName in this.control.errors) {
      // console.log(propertyName, this.control.errors)
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched === true) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName])
      }
    }

    // Return null because no error messages were found.
    return null
  }
}
