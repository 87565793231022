import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({ name: 'html', pure: false })
export class HtmlPipe implements PipeTransform {
  constructor (private sanitizer: DomSanitizer) {
  }

  transform (content: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(content)
  }
}
